import { createSelector } from '@reduxjs/toolkit'

export const selectBuildings = (state) => {
  return state.buildings
}

export const selectListBuildings = createSelector(
  (state) => state.buildings.listBuildings,
  (listBuildings) => listBuildings
)

export const selectIsUniqueBuilding = createSelector(
  (state) => state.buildings.listBuildings,
  (listBuildings) => listBuildings?.length === 1
);

export const selectBuildingsIsLoading = createSelector(
  (state) => state.buildings.isLoading,
  (isLoading) => isLoading
)

export const selectBuilding = (building_id) => {
  return createSelector(
    (state) => state.buildings.listBuildings,
    (listBuildings) => {
        if (!building_id) {
            return null
        }
        return listBuildings.find(building => building.building_id === building_id.replace(/:/g, ""))
    }
  )
}
  
export const selectRooms = (building_id) => createSelector(
  [selectBuildings],
  (buildings) => {
    if (buildings && buildings.listBuildings) {
      const building = buildings.listBuildings.find((building) => building.building_id === building_id)
      return building ? building.rooms : null
    }
    return null
  }
)
    
export const selectRoom = (building_id, room_id) => createSelector(
  [selectBuildings],
  (buildings) => {
    if (buildings && buildings.listBuildings) {
      const building = buildings.listBuildings.find((building) => building.building_id === building_id)
      if (building && building.rooms) {
        return building.rooms.find((room) => room.room_id === room_id)
      }
    }
    return null
  }
)

export const selectDevices = (building_id) => createSelector(
  [selectBuilding(building_id)],
  (building) => {
    if (!building || !building.rooms) return [];
    return building.rooms.flatMap(room =>
      room.devices ? room.devices : []
    )
  }
)

export const selectOnlineDevices = (building_id) => createSelector(
  [selectBuilding(building_id)],
  (building) => {
    if (!building || !building.rooms) return [];
    return building.rooms.flatMap(room =>
      room.devices ? room.devices.filter(device => device.is_online) : []
    )
  }
)

export const selectOfflineDevices = (building_id) => createSelector(
  [selectBuilding(building_id)],
  (building) => {
    if (!building || !building.rooms) return [];
    return building.rooms.flatMap(room =>
      room.devices ? room.devices.filter(device => !device.is_online) : []
    )
  }
)

export const selectWeatherStationId = (building_id) => createSelector(
  [selectBuildings],
  (buildings) => {
    if (buildings && buildings.listBuildings) {
      const building = buildings.listBuildings.find((building) => building.building_id === building_id)
      if (building?.weather_station?.weather_station_id) {
        return building.weather_station.weather_station_id
      } else {
        return null
      }
    }
    return null
  }
)

export const selectSgeAuthorization = (building_id, point_id) => createSelector(
  [selectBuildings],
  (buildings) => {
    if (buildings && buildings.listBuildings) {
      const building = buildings.listBuildings.find((building) => building.building_id === building_id)
      if (building && building.sge_authorizations) {
        return building.sge_authorizations.find((sge_authorization) => sge_authorization.point_id === point_id)
      }
    }
    return null
  }
)

export const selectSgeAuthorizations = (building_id) => createSelector(
  [selectBuildings],
  (buildings) => {
    if (buildings && buildings.listBuildings) {
      const building = buildings.listBuildings.find((building) => building.building_id === building_id)
      return building ? building.sge_authorizations : []
    }
    return []
  }
)    

