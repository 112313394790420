export const COLORS_CHART = {
  blue: '#2477ff',
  green: '#2d8515',
  orange: '#e49400',
  red: '#db2a34',
  purple: '#474D84',
  dark: '#040620',
  teal: '#4179cf',
  pink: '#e671b8',
  gray: '#d6dee5',
  default: '#595d78',
  textColor: '#34495e',
  gridLineColor: '#e0e0e0'
}

export const COLORS_CHART_ARRAY = [
  COLORS_CHART.blue,
  COLORS_CHART.green,
  COLORS_CHART.orange,
  COLORS_CHART.red,
  COLORS_CHART.purple,
  COLORS_CHART.dark,
  COLORS_CHART.teal,
  COLORS_CHART.pink,
  COLORS_CHART.gray,
  COLORS_CHART.default,
];