import React from 'react'
import s from './HeaderSettings.module.scss'
import NavigationSettings from '../../Navigation/NavigationSettings/NavigationSettings'

function HeaderSettings() {
 
  return (
    <div className={s.header}>
      <NavigationSettings/>
    </div>
  )
}

export default HeaderSettings
