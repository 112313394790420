import React, { useEffect, useState } from 'react'
import s from './Modal.module.scss'
import cx from 'classnames'

function Modal({ show, children }) {

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setVisible(true);
    } else {
      const timeout = setTimeout(() => setVisible(false), 500);
      return () => clearTimeout(timeout);
    }
  }, [show]);

  if (!visible) {
    return null;
  }

  return (
    <div className={s.modalContainer}>

      <div className={cx(s.modalCard, {[s.show]: show})}>
        
        <div className={s.content}>
          {children}
        </div>

      </div>

    </div>
  )
}

export default Modal