import React, { useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import s from './CardChartCO2.module.scss'
import Loader from '../Loader/Loader'
import ExportCSV from '../ExportCsv/ExportCsv'
import { selectActiveBuilding, selectActivePeriodUnit, selectActiveRoom, selectPeriod } from '../../selectors/selectNavigation'
import moment from 'moment';
import { getCO2ByDevices } from '../../features/measures/measuresActions'
import { selectTemperatureIsLoading, selectMeasuresForChart, selectDevicesByBuildingAndRoom } from '../../selectors/selectDevices'
import ChartCO2 from '../Chart/ChartCO2/ChartCO2';

moment.locale('fr');

function CardChartCO2() {

  const dispatch = useDispatch()

  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id

  const room = useSelector(selectActiveRoom, shallowEqual)
  const room_id = room?.room_id

  const devices = useSelector(selectDevicesByBuildingAndRoom(building_id, room_id), shallowEqual)

  const isLoading = useSelector(selectTemperatureIsLoading, shallowEqual)

  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual)

  const dataSets = useSelector(selectMeasuresForChart(devices, 'co2'), shallowEqual);

  useEffect(() => {
    if (building_id !== '' && devices?.length > 0) {
      dispatch(getCO2ByDevices({ measurement: 'co2', building_id, room_id, devices, periodUnit, period }));  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building_id, devices, periodUnit, period])

  return (
    <div className={s.root}>

      <div className={s.header}>
        <div className={s.title}>CO2 en PPM <ExportCSV chartData={dataSets} /></div>
      </div>

      <div className={s.content}>
          
        {isLoading && <Loader/>}
        
        {dataSets && dataSets.datasets.length > 0 && building && 
        <ChartCO2 dataSets={dataSets} periodUnit={periodUnit}/>
        }

        {!isLoading && dataSets && dataSets.datasets.length === 0 && building &&
          <div className={s.noMeasure}>Aucune mesure disponible pour la période sélectionnée</div>
        }

      </div>
    </div>      
  )
}

export default CardChartCO2
