import React from 'react'
import { useDispatch } from "react-redux"
import s from './CardBuildingLight.module.scss'
import { Button } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { changeActiveBuilding, changeActiveRoute } from '../../../../features/navigation/navigationSlice'
import { saveNavigationState } from '../../../../features/navigation/navigationActions'
import store from '../../../../redux/store';
import Icon from '../../../../components/Icon/Icon'
import { getBuildings, updateBuilding } from '../../../../features/buildings/buildingsActions'

function CardBuildingLight({ building, startPeriod, endPeriod}) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const handleNavigateTo = (sub_nav) => {
    dispatch(changeActiveBuilding(building))
    dispatch(changeActiveRoute(`/consult/${sub_nav}`))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    navigate(`/consult/${sub_nav}`)
  }

  const handleClickUpdate = () => {
    dispatch(changeActiveRoute(`/settings/building/:${building.building_id}`))
    dispatch(changeActiveBuilding(building))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    navigate(`/settings/building/:${building.building_id}`)
  }

  const handleClickFavorite = () => {
    const updatedBuilding = {
      ...building,
      settings: {
        ...building.settings,
        favorite: !building.settings.favorite,
      },
    };
    dispatch(updateBuilding({ user_id, data: updatedBuilding }))
    .unwrap()
    .then(() => {
      dispatch(getBuildings({ user_id }));
    });
  }
  
  return (
    <div className={s.root}>
      
      <div className={s.header}>
        <div className={s.title}>{building.name}</div>
        <div className={s.building_location}>
          <span><Icon name="location_on" size={'1.5rem'} color={'#000'}/></span>
          <span>{building.address.city}</span>
          <span>---°C</span>
        </div>
      </div>

      <div className={s.content}>

          <Button color="secondary" outline onClick={() => handleNavigateTo('consumption')}>
            <Icon name="bolt" size={'1.5rem'} color={'#30465E'} fill={1}/>
            <span>Consommation</span>
            <Icon name="arrow_forward" size={'1.5rem'} color={'#30465E'} fill={1} cursor/> 
          </Button>



          <Button color="secondary" outline onClick={() => handleNavigateTo('comfort')}>
            <Icon name="chair" size={'1.5rem'} color={'#30465E'} fill={1}/>
            <span>Confort</span>
            <Icon name="arrow_forward" size={'1.5rem'} color={'#30465E'} fill={1} cursor/> 
          </Button>



          <Button color="secondary" outline onClick={() => handleNavigateTo('air_quality')}>
            <Icon name="aq_indoor" size={'1.5rem'} color={'#30465E'} fill={1}/>
            <span>Qualité d'air</span>
            <Icon name="arrow_forward" size={'1.5rem'} color={'#30465E'} fill={1} cursor/> 
          </Button>
 
          

          <Button color="secondary" outline onClick={() => handleNavigateTo('occupancy')}>
            <Icon name="sensor_occupied" size={'1.5rem'} color={'#30465E'} fill={1}/>
            <span>Occupation</span>
            <Icon name="arrow_forward" size={'1.5rem'} color={'#30465E'} fill={1} cursor/> 
          </Button>

      </div>

      <div className={s.control}>
        <Icon name="settings" size={'2.5rem'} color={'#30465E'} fill={1} onClick={() => handleClickUpdate()} cursor/>
        <Icon name="star" size={'2.5rem'} color={building.settings.favorite ? '#3192D3' : '#30465E'} fill={building.settings.favorite ? 1 : 0} onClick={() => handleClickFavorite()} cursor animationClass={building.settings.favorite ? "icon--rotRight": "icon--rotLeft"}/>
      </div> 

    </div>
  )
}

export default CardBuildingLight
