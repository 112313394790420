import React, { useEffect } from 'react'
import s from './CardConsumption.module.scss'
import { useCardLogic } from '../../../../hooks/useCardLogic';
import Icon from '../../../Icon/Icon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeActiveBuilding, changeActiveRoute } from '../../../../features/navigation/navigationSlice';
import { saveNavigationState } from '../../../../features/navigation/navigationActions';
import store from '../../../../redux/store';
import { selectSgeAuthorization } from '../../../../selectors/selectBuildings';
import moment from 'moment';
import { getLoadCurveData } from '../../../../features/sge/sgeActions';
import { selectAggregateData } from '../../../../selectors/selectSGE';

moment.locale('fr')

function CardConsumption({ building, params }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const building_id = building?.building_id || null

  const point_id = building?.sge_authorizations?.[0]?.point_id
  const counter = useSelector(selectSgeAuthorization(building_id, point_id), shallowEqual)
  const sge_authorization_id = counter?.sge_authorization_id || ''

  const aggregateData = useSelector(selectAggregateData(sge_authorization_id, 'week'), shallowEqual)

  const { dataIndicator } = useCardLogic(params);

  useEffect(() => {
    if (sge_authorization_id) {
      const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD 00:00:00');
      const endOfLastWeek = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD 23:59:59');
      const period = {
        start: startOfLastWeek,
        end: endOfLastWeek,
      };
      dispatch(getLoadCurveData({ sge_authorization_id, point_id, periodUnit: 'week', period }))  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building_id, sge_authorization_id, point_id])

  useEffect(() => {
    if (sge_authorization_id) {
      const startOfLastWeek = moment().subtract(2, 'weeks').startOf('week').format('YYYY-MM-DD 00:00:00');
      const endOfLastWeek = moment().subtract(2, 'weeks').endOf('week').format('YYYY-MM-DD 23:59:59');
      const period = {
        start: startOfLastWeek,
        end: endOfLastWeek,
      };
      dispatch(getLoadCurveData({ sge_authorization_id, point_id, periodUnit: 'week', period }))  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building_id, sge_authorization_id, point_id])

  const handleNavigateTo = () => {
    dispatch(changeActiveBuilding(building))
    dispatch(changeActiveRoute('/consult/consumption'))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    navigate(`/consult/consumption`)
  }

  const isMonday = setIsMonday()

  function setIsMonday() {
    const today = new Date();
    const dayOfWeek = today.getDay()

    return dayOfWeek === 1
  }

  return (
    <div className={s.root}>
      
      <header className={s.header}>
        <div className={s.title}>
          <Icon name="bolt" size={'1.5rem'} color={'#30465E'} fill={1}/>
          <span>CONSOMMATION</span>
        </div>
        <Icon name="arrow_forward" size={'1.5rem'} color={'#30465E'} fill={1} onClick={() => handleNavigateTo()} cursor/> 
      </header>

      <div className={s.content}>
        
        <div className={s.item}>
          <div className={s.value}>{aggregateData?.sumSubscriptionHPHC} €</div>
          <div className={s.label}>Coût de l'énergie</div>
        </div>

        <div className={s.item}>
          {dataIndicator?.values?.data2 != null && dataIndicator?.values?.data2 !== undefined ?
          (
          <div className={s.value}>{dataIndicator?.values?.data2 > 0 ? "+" + dataIndicator?.values?.data2 : dataIndicator?.values?.data2} %</div>
          ) : (
          <div className={s.not_value}>--- %</div>
          )}  
          <div className={s.label}>Par rapport à la semaine précédente</div>
        </div>
      </div>

      <footer className={s.footer}>
        <small> {isMonday ? "Semaine en cours" : "La semaine dernière"}</small>
      </footer>
      
    </div>

  );
}

export default CardConsumption