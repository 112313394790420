import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import s from './Consult.module.scss';
import HeaderConsult from '../../components/Headers/HeaderConsult/HeaderConsult';
import { useMatch } from 'react-router-dom';
import Summary from './components/Summary/Summary';
import Consumption from './components/Consumption/Consumption';
import Comfort from './components/Comfort/Comfort';
import AirQuality from './components/AirQuality/AirQuality';
import Occupancy from './components/Occupancy/Occupancy';
import { getBuildings } from '../../features/buildings/buildingsActions';
import { getDevices } from '../../features/devices/devicesActions';

function Consult() {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;
  
  const isMatchSummary = useMatch('/consult/summary')
  const isMatchConsumption = useMatch('/consult/consumption')
  const isMatchComfort = useMatch('/consult/comfort')
  const isMatchAirQuality = useMatch('/consult/air_quality')
  const isMatchOccupancy = useMatch('/consult/occupancy')

  useEffect(() => { 
    if (user_id) {
      dispatch(getBuildings({ user_id }))
      dispatch(getDevices({ user_id })) 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [user_id])

  return (
    <div className={s.root}>
      <HeaderConsult/>
      <div className={s.content}>
        {isMatchSummary && <Summary/>}
        {isMatchConsumption && <Consumption/>}
        {isMatchComfort && <Comfort/>}
        {isMatchAirQuality && <AirQuality/>}
        {isMatchOccupancy && <Occupancy/>}       
      </div>
    </div>
  );
}

export default Consult;
