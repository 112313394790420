import React, { useEffect, useState } from 'react';
import s from './Step07AffectToBuilding.module.scss';
import { Button } from 'reactstrap';
import CardDeviceSelected from '../../../CardDeviceSelected/CardDeviceSelected';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectActiveBuilding } from '../../../../../../../../selectors/selectNavigation';
import { toast } from 'react-toastify';
import { updateDevice } from '../../../../../../../../features/devices/devicesActions';

function Step07AffectToBuilding({ device, dec, affectBuilding}) {

  const dispatch = useDispatch()

  const building = useSelector(selectActiveBuilding, shallowEqual)

  useEffect(() => {
    console.log(device)
    console.log(building)
  },[device])
  

  return (
    <div className={s.root}>
      <div className={s.devices}>
        <CardDeviceSelected device={device}/>
      </div>
      <div className={s.control}>
        <Button color="primary" outline onClick={dec}>
          Annuler
        </Button>
        <Button color='primary' onClick={affectBuilding} disabled={false}>
          Terminé
        </Button>
      </div>
    </div>
  );
}

export default Step07AffectToBuilding;
