import React, { useEffect, useState } from 'react'
import s from './Building.module.scss'
import CardBuildingData from './components/CardBuildingData/CardBuildingData'
import CardMap from './components/CardMap/CardMap'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectActiveBuilding } from '../../../../selectors/selectNavigation'
import { createBuilding, getBuildingById, getBuildings, updateBuilding } from '../../../../features/buildings/buildingsActions'
import { changeActiveBuilding, changeActiveRoute, initActiveBuilding } from '../../../../features/navigation/navigationSlice'
import { useMatch, useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { saveNavigationState } from '../../../../features/navigation/navigationActions'
import store from '../../../../redux/store';
import Icon from '../../../../components/Icon/Icon'
import { Button } from 'reactstrap'
import ModalDeleteBuilding from './components/ModalDeleteBuilding/ModalDeleteBuilding'
import { getDevices } from '../../../../features/devices/devicesActions'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Building({ handleUpdateList }) {

  const building = useSelector(selectActiveBuilding, shallowEqual)
  
  const [data, setData] = useState(building)


  useEffect(() => {
    setData(building)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [building])

  useEffect(() => { 
    if (data === null) {
      setData(prevData => ({
        ...prevData,
        building_id: uuid(),
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [data])

  return (
      <div className={s.content}>
                
        <div className={s.cardBuildingData}>
          <CardBuildingData setData={setData} data={data} handleUpdateList={handleUpdateList}/>
        </div>

        <div className={s.cardMap}>
          <CardMap data={data}/>  
        </div>

      </div>
  )
}

export default Building
