import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import s from './Register.module.scss';
import './customPasswordChecklist.css';
import { Button, FormGroup, Input, Form, Label, InputGroup, InputGroupText } from "reactstrap";
import Logo from '../../../assets/logo/LogoInsensiaRow'
import Copyright from "../../../components/Copyright/Copyright";
import Icon from "../../../components/Icon/Icon";
import PasswordChecklist from "react-password-checklist";
import PasswordStrengthBar from 'react-password-strength-bar';
import { userRegister } from '../../../features/auth/authActions';
import { resetAuth } from "../../../features/auth/authSlice";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const changeEmail = (event) => {
    setEmail(event.target.value);
  };

  const changePassword = (event) => {
    setPassword(event.target.value);
  };

  const changeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const isEmailValid = () => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return pattern.test(email);
  };

  const doRegister = () => {
    if (isEmailValid() && isPasswordValid) {
      dispatch(userRegister({ email, password }))
        .unwrap()
        .then(() => {
          toast.success("Votre compte a été enregistré avec succès. Veuillez consulter votre boîte mail et cliquer sur le lien de vérification");
          navigate('/login')
        }
      );
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

  const doLogin = () => {
    dispatch(resetAuth());
    navigate("../login");
  };

  return (
    <div className={s.root}>
      <div className={s.logo}><Logo className={s.img}/></div>
      <div className={s.card}>
        
        <div className={s.content}>
          
          <header>S'inscrire</header>

          <Form className={s.form}>
            <FormGroup className={s.formGroup}>
              <Label>Email</Label>
              <Input id="email" value={email} onChange={changeEmail} type="text" name="email" placeholder="Email" />
            </FormGroup>

            <FormGroup className={s.formGroup}>
              <Label>Mot de passe</Label>
              <InputGroup>
                <Input id="password" value={password} onChange={changePassword} type={showPassword ? "text" : "password"} required name="password" placeholder="Mot de passe" />
                <InputGroupText>
                  <Icon name={showPassword ? "visibility_off" : "visibility"} size={'1.5rem'} color={'#3192D3'} fill={1} cursor onClick={togglePasswordVisibility} />
                </InputGroupText>
              </InputGroup>
            </FormGroup>

            <FormGroup className={s.formGroup}>
              <Label>Confirmez votre mot de passe</Label>
              <InputGroup>
                <Input id="confirm_password" value={confirmPassword} onChange={changeConfirmPassword} type={showConfirmPassword ? "text" : "password"} required name="confirmPassword" placeholder="Confirmez Mot de passe" />
                <InputGroupText>
                  <Icon name={showConfirmPassword ? "visibility_off" : "visibility"} size={'1.5rem'} color={'#3192D3'} fill={1} cursor onClick={toggleConfirmPasswordVisibility} />
                </InputGroupText>
              </InputGroup>
            </FormGroup>

            <div className={s.password_checklist}>Votre mot de passe doit avoir :</div>
            
            <PasswordChecklist
              className={"password_checklist"}
              rules={["minLength", "maxLength", "specialChar", "number", "capitalAndLowercase", "match"]}
              minLength={12}
              maxLength={50}
              value={password}
              valueAgain={confirmPassword}
              messages={{
                minLength: "12 caractères minimum.",
                maxLength: "50 caractères maximum.",
                specialChar: "au moins un caractère spécial.",
                number: "contenir au moins un chiffre.",
                capitalAndLowercase: "au moins une lettre minuscule et une lettre majuscule.",
                match: "Les mots de passe doivent correspondre."
              }}
              onChange={(isValid) => setIsPasswordValid(isValid)}
              hideIcon={true}
            />

            <PasswordStrengthBar
              className={s.passwordStrengthBar}
              password={password}
              scoreWords={[
                'Force du mot de passe : faible',
                'Force du mot de passe : faible',
                'Force du mot de passe : moyen',
                'Force du mot de passe : bon',
                'Force du mot de passe : fort'
              ]}
              minLength={12}
              shortScoreWord='Trop court'
            />
            <div className={s.control}>
              <Button color="primary" onClick={doRegister} disabled={!isPasswordValid}>Continuer</Button>   
            </div>
          </Form>

          <div className={s.separator}><span></span></div>

          <footer>
            <div className={s.label}>Vous avez déjà un compte ?</div>
            <Button color="primary" outline onClick={doLogin}>Se connecter</Button>
          </footer>
          
        </div>
        <Copyright/>
      </div>
    </div>
  );
}

export default Register;
