import React, { useEffect } from "react";
import s from './CardConsumption.module.scss'
import Icon from "../../../Icon/Icon";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeActiveRoute } from "../../../../features/navigation/navigationSlice";
import { saveNavigationState } from "../../../../features/navigation/navigationActions";
import store from '../../../../redux/store';
import Loader from "../../../Loader/Loader";
import { selectAggregateData, selectDataIsLoading } from "../../../../selectors/selectSGE";
import { selectActiveBuilding, selectActiveConsoUnit, selectActivePeriodUnit, selectPeriod } from "../../../../selectors/selectNavigation";
import { selectSgeAuthorization } from "../../../../selectors/selectBuildings";
import { extendMoment } from 'moment-range';
import { getLoadCurveData } from "../../../../features/sge/sgeActions";
const moment = extendMoment(require('moment'));
moment.locale('fr')

function CardConsumption() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;
  
  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id || null
  const point_id = building?.sge_authorizations?.[0]?.point_id
  const counter = useSelector(selectSgeAuthorization(building_id, point_id), shallowEqual)
  const sge_authorization_id = counter?.sge_authorization_id
  const contract_type = counter?.technical_and_contract?.contract_type
  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual)
  const consoUnit = useSelector(selectActiveConsoUnit)

  const aggregateData = useSelector(selectAggregateData(sge_authorization_id, periodUnit), shallowEqual)

  const isLoading = useSelector(selectDataIsLoading)

  const handleNavigateTo = () => {
    dispatch(changeActiveRoute('/consult/consumption'))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    navigate(`/consult/consumption`)
  }

  useEffect(() => {
    const today = moment().startOf('day');
    const periodStart = moment(period.start, "YYYY-MM-DD HH:mm:ss");
    if (periodStart.isBefore(today)) {
      if (sge_authorization_id) {
        dispatch(getLoadCurveData({ sge_authorization_id, point_id, periodUnit, period }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building_id, sge_authorization_id, point_id, periodUnit, period])

  return (
    <div className={s.root}>
      
      <header className={s.header}>
        <div className={s.title}>
          <Icon name="bolt" size={'1.5rem'} color={'#30465E'} fill={1}/> 
          <span>CONSOMMATION</span>
        </div>
        <Icon name="arrow_forward" size={'1.5rem'} color={'#30465E'} fill={1} onClick={() => handleNavigateTo()} cursor/> 
      </header>

      {(isLoading || !aggregateData) && <Loader/>}
      
      {!isLoading && aggregateData &&
      <div className={s.content}>

        {consoUnit !== 'kgCO2' &&
        <div className={s.item}>
          <div className={s.value}>
            {consoUnit === '€' && <span>{contract_type !== 'Base' ? aggregateData?.sumSubscriptionHPHC : aggregateData?.sumSubscriptionBase} {consoUnit}</span>}
            {consoUnit === 'kWh' && <span>{aggregateData?.sumConsumption} {consoUnit}</span>}
          </div>
        </div>}

        {consoUnit !== 'kgCO2' &&
        <div className={s.item}>
          <div className={s.value}>
            {consoUnit === '€' && <span>{aggregateData?.sumConsumption} kWh</span>}
            {consoUnit === 'kWh' && <span>{contract_type !== 'Base' ? aggregateData?.sumSubscriptionHPHC : aggregateData?.sumSubscriptionBase} €</span>}
          </div>
        </div>}

        {consoUnit === 'kgCO2' &&
        <div className={s.item}>
          <div className={s.value}>
            {consoUnit === 'kgCO2' && <span>{aggregateData?.sumCarbonEquivalent} kgCO2</span>}
          </div>
        </div>}
        {consoUnit === 'kgCO2' && <div className={s.item}></div>}

      </div>}
      
      {!isLoading && aggregateData &&
      <footer className={s.footer}>
        Consommation électrique
      </footer>}
    </div>
  );
    
}

export default CardConsumption;