import React, { useEffect, useMemo, useState } from 'react'
import s from './CardComfort.module.scss'
import { useDispatch } from 'react-redux';
import { useNavigate, useMatch } from 'react-router-dom';
import { useInterval } from '../../../../hooks/useInterval'
import Icon from '../../../Icon/Icon';
import { changeActiveBuilding, changeActiveRoute } from '../../../../features/navigation/navigationSlice';
import { saveNavigationState } from '../../../../features/navigation/navigationActions';
import store from '../../../../redux/store';
import { getLastSensorData } from '../../../../features/measures/measuresActions';

function CardComfort({ building, devices_ids }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const isMatchBuildings = useMatch('/buildings/*')

  const [data, setData] = useState(null);

  // Fonction pour récupérer les données du capteur
  const fetchSensorData = async () => {
    try {
      // dispatch retourne une promesse, on attend donc son résultat
      const result = await dispatch(
        getLastSensorData({
          building_id: building.building_id,
          devices_ids: devices_ids,
          measurement: ['temperature', 'humidity']
        })
      );
      // Selon votre configuration, le résultat peut être dans result.payload
      setData(result.payload ? result.payload : result);
    } catch (error) {
      console.error('Erreur lors de la récupération des données', error);
    }
  };

  // Appel initial lors du montage
  useEffect(() => {
    if (isMatchBuildings) {
      fetchSensorData();
    }
    // Vous pouvez ajouter building.building_id et devices_ids aux dépendances si nécessaire
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [user_id, building.building_id, devices_ids, isMatchBuildings]);

  // Mise à jour périodique
  useInterval(() => {
    if (isMatchBuildings) {
      fetchSensorData();
    }
  }, isMatchBuildings ? 15000 : null);

    // Calculer la moyenne des mesures pour tous les devices
    const averageData = useMemo(() => {
      if (!data) return null;
      const values = Object.values(data);
      if (values.length === 0) return null;
      const sumTemperature = values.reduce((acc, cur) => acc + (cur.temperature || 0), 0);
      const sumHumidity = values.reduce((acc, cur) => acc + (cur.humidity || 0), 0);
      return {
        temperature: sumTemperature / values.length,
        humidity: sumHumidity / values.length
      };
    }, [data]);

  const handleNavigateTo = () => {
    dispatch(changeActiveBuilding(building))
    dispatch(changeActiveRoute('/consult/comfort'))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    navigate(`/consult/comfort`)
  }

  return (
    <div className={s.root}>
      
      <header className={s.header}>
        <div className={s.title}>
          <Icon name="chair" size={'1.5rem'} color={'#30465E'} fill={1}/>
          <span>CONFORT</span>
        </div>
        <Icon name="arrow_forward" size={'1.5rem'} color={'#30465E'} fill={1} onClick={() => handleNavigateTo()} cursor/> 
      </header>
 
      <div className={s.content}>

        <div className={s.item}>
          {averageData && averageData.temperature !== undefined ?
          (
          <div className={s.value}>{averageData.temperature.toFixed(2)} °C</div>
          ) : (
          <div className={s.not_value}>--- °C</div>  
          )} 
          <div className={s.label}>
            <span>Températures</span>
            <small>19 - 21 °C</small>
          </div> 
        </div>

        <div className={s.item}>
          {averageData && averageData.humidity !== undefined ?
          (
          <div className={s.value}>{averageData.humidity.toFixed(2)} %</div>
          ) : (
          <div className={s.not_value}>--- %</div> 
          )}
          <div className={s.label}>
            <span>Humidité</span>
            <small>45 - 60 %</small>
          </div>
        </div>
        
      </div>

    </div>

  );
}

export default CardComfort