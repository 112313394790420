import React from 'react';
import s from './HeaderAutomation.module.scss';
import ControlSelectBuilding from '../../Control/ControlSelectBuilding/ControlSelectBuilding'
import NavigationDevices from '../../Navigation/NavigationDevices/NavigationDevices';
import { Button } from 'reactstrap';
import Icon from '../../Icon/Icon';
import NavigationAutomation from '../../Navigation/NavigationAutomation/NavigationAutomation';

function HeaderAutomation() {
  
  return (
    <div className={s.header}>
      
      <div className={s.selectBuilding}>
        <ControlSelectBuilding/>  
      </div>

      <div className={s.navigation}>
        <NavigationAutomation/>  
      </div>
      
    </div>
  );
}

export default HeaderAutomation;
