import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import s from './HeaderAccount.module.scss'
import { Button } from 'reactstrap'
import { selectProfile } from '../../../selectors/selectUser'
import { userLogout } from '../../../features/auth/authSlice'
import { resetState } from '../../../features/auth/authActions'
import { useNavigate } from 'react-router-dom'
import Icon from '../../Icon/Icon'

function HeaderAccount({ tooltipPlacement = "top" }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const fileInputRef = useRef(null)

  const profile = useSelector(selectProfile, shallowEqual)

  const [imageURL, setImageURL] = useState('')

  useEffect(() => {
    if (!profile?.avatar_url) {
      setImageURL(`https://ui-avatars.com/api/?background=random&name=${profile?.firstname}+${profile?.lastname}`)
    } else {
      setImageURL(profile.avatar_url)
    }
  }, [profile])
  
  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const fileURL = URL.createObjectURL(file)
      setImageURL(fileURL)
    }
  }

  const handleDeleteImage = () => {
    setImageURL(`https://ui-avatars.com/api/?background=random&name=${profile.firstname}+${profile.lastname}`)
    fileInputRef.current.value = ''
  }

  const disconnect = () => {
    dispatch(userLogout())
    dispatch((resetState()))
    navigate('/login')
  }
 
  return (
    <div className={s.header}>

      <div className={s.avatar}>
        {!imageURL.includes('ui-avatars.com') && <div className={s.delete} onClick={handleDeleteImage}><Icon name="close" size={'1.5rem'} color={'#30465E'} fill={0} cursor/></div>}
        <img className={s.image} onClick={handleAvatarClick} src={imageURL} aria-hidden alt="Photo de mon profil" />
        <div className={s.overlay_circle} onClick={handleAvatarClick}><Icon name="photo_camera" size={'1.5rem'} color={'#30465E'} fill={0} cursor/></div>
      </div>
      <input type="file" accept="image/*" style={{display: 'none' }} ref={fileInputRef} onChange={handleFileChange}/>

      <div className={s.disconnect}> 
        <Button color="primary" onClick={disconnect}>Déconnexion</Button>
      </div>

    </div>
  )
}

export default HeaderAccount
