export const LIST_BUILDING_TYPE = [
    {index: 0, value: '...'},
    {index: 1, value: 'Résidentiel'},
    {index: 2, value: 'Commercial'},
    {index: 3, value: 'Industriel'},
    {index: 4, value: 'Gouvernemental'},
    {index: 5, value: 'Educatif'},
    {index: 6, value: 'Médical'},
    {index: 7, value: 'Culturel'},
    {index: 8, value: 'Religieux'},
    {index: 9, value: 'Récréatif'},
    {index: 10, value: 'Agricole'},
    {index: 11, value: 'Transport'},
    {index: 12, value: 'Autre'}
]

export const LIST_HEATING_ENERGY = [
    {index: 0, value: '...'},
    {index: 1, value: 'Electricité'},
    {index: 2, value: 'Gaz'}
]