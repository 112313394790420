import { createSelector } from '@reduxjs/toolkit';

export const selectMeasures = (state) => state.measures;

export const selectMeasuresIsLoading = createSelector (
  (state) => state.measures.isLoading,
  (isLoading) => isLoading  
)

export const selectMeasuresDataComfort = createSelector(
  [selectMeasures],
  (measures) => {
    let result = {}
    if (measures.stat_summary.temperature !== null) {
      const { mean, max, min } = measures.stat_summary.temperature;
      if (mean !== null && max !== null && min !== null) {
        result = {...result, statTemperature: measures.stat_summary.temperature};
      }
    }
    if (measures.stat_summary.humidity !== null) {
      const { mean, max, min } = measures.stat_summary.humidity;
      if (mean !== null && max !== null && min !== null) {
        result = {...result, statHumidity: measures.stat_summary.humidity};
      }
    }
    return result;
  }
);

export const selectMeasuresDataAirQuality = createSelector(
  [selectMeasures],
  (measures) => {
    let result = {}
    if (measures.stat_summary.co2 !== null) {
      const { mean, max, min } = measures.stat_summary.co2;
      if (mean !== null && max !== null && min !== null) {
        result = {...result, statCo2: measures.stat_summary.co2};
      }
    }
    return result;
  }
);

