import React from 'react'
import s from './Step04CardPairingSuccess.module.scss'
import { Button } from 'reactstrap'

function Step04CardPairingSuccess({ inc }) {


  return (
    <div className={s.root}> 

      <div className={s.content}>
          <p>Vous allez pouvoir lui donner un nom.</p>
          <p>Il ne vous reste plus que quelques étapes !</p>
      </div>
      
      <div className={s.control}>
        <Button color="primary" onClick={inc} disabled={false}>Suivant</Button>
      </div>
    </div>
  )
}

export default Step04CardPairingSuccess