import React from 'react'
import s from './Popup.module.scss'
import cx from 'classnames'
import {Button } from 'reactstrap'

function Popup({ title, show, onAccept, onDiscard, children }) {

  if (!show) {
    return null
  }

  return (
    <div className={s.popupContainer}>

      <div className={cx(s.popupCard, {[s.show]: show})}>

        <div className={s.header}>
          <div className={s.title}>{title}</div>
        </div>
        
        <div className={s.content}>
          {children}
        </div>

        <div className={s.control}>
          <Button color="primary" outline onClick={onDiscard}>Non</Button>  
          <Button color="primary" onClick={onAccept}>Oui</Button>
        </div>

      </div>

    </div>
  )
}

export default Popup
