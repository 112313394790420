import React, { useEffect } from 'react'
import './customResponsive.css';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale, TimeScale } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import { generateTickCallback, externalTooltipHandler, titleTooltip, labelTooltip, footerTooltip } from '../../../utils/functions/chartSensorHandler';

ChartJS.register(LineElement, PointElement, LinearScale, Title, Tooltip, Legend, CategoryScale, TimeScale);
moment.locale('fr');

function ChartTemperature({ dataSets, periodUnit }) {

  const options = {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: false,
    type: 'line',
    scales: {
      x: {
        type: 'time',
        time: {
          unit: periodUnit === 'year' ? 'month' : (periodUnit === 'day' ? 'hour' : 'day'),
          tooltipFormat: 'dddd DD MMM YYYY HH:mm'
        },
        grid: {
          display: false,
        },
        ticks: {
          labelRotation: 0,
          callback: generateTickCallback(periodUnit),
        },
      },
      y: {
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        enabled: false,
        mode: 'index',
        intersect: false,
        external: externalTooltipHandler(periodUnit, '°C'),
        callbacks: {
          title: titleTooltip(periodUnit),
          label: labelTooltip('°C'),
          // footer: footerTooltip('°C')
        }
      }
    },
    elements: {
      point: {
        radius: 0,
        hoverRadius: 5,
        hitRadius: 10,
        hoverBorderWidth: 2
      }
    }
  };

  return (
    <div className={'chart-container'}>
      <Line data={dataSets} options={options}/>
    </div>
  )
}

export default ChartTemperature
