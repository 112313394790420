import React from 'react'
import s from './HeaderCompare.module.scss'
import ControlSelectBuilding from '../../Control/ControlSelectBuilding/ControlSelectBuilding'
import ControlPeriod from '../../Control/ControlPeriod/ControlPeriod'
import NavigationConsult from '../../Navigation/NavigationConsult/NavigationConsult'

function HeaderCompare() {

  return (
    <div className={s.header}>
      <ControlSelectBuilding/>
      <ControlPeriod/>
      <NavigationConsult/>
    </div>
  )
}

export default HeaderCompare
