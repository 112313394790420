import React from 'react'
import s from './Copyright.module.scss'

function Copyright() {
  return (
    <footer className={s.root}>
      <div>&copy;{new Date().getFullYear()} Insensia - Made by <a href="https://www.insensia.com/" rel="noopener noreferrer" target="_blank">Insensia</a></div>
    </footer>
  )
}

export default Copyright

