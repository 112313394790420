import React from 'react'
import { useDispatch } from 'react-redux'
import s from './Step06CardValidation.module.scss'
import { Button } from 'reactstrap'
import { createAuthorization } from '../../../../../../../../features/sge/sgeActions'
import { getBuildings } from '../../../../../../../../features/buildings/buildingsActions'

function Step06CardValidation({ inc, dec, setAuthorizationData, authorizationData }) {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id

  const incStep = () => {
    dispatch(createAuthorization({ authorizationData }))
    .unwrap()
    .then(() => dispatch(getBuildings({ user_id }))) 
    inc()
  }

  return (
    <div className={s.root}>

      <div className={s.content}>
        <div className={s.description}>
          <div className={s.descriptionParts}>
            <span>Ces données nous permettent de vous proposer des conseils et informations personnalisées pour vous faire faire des économies.</span>
          </div>
          <div className={s.descriptionParts}>
            <span>Après la complétion de ce formulaire, l’autorisation est valable à partir d’aujourd’hui et pendant toute la durée de l’abonnement au module consommation, l’existence du bâtiment auquel le compteur est rattaché et de l’existence de votre compte.</span>
          </div>
          <div className={s.descriptionParts}>
            <span>Vous acceptez expressément l’utilisation de ces données à des fins de gestion et de traçabilité.</span>
            <span>La présente autorisation est conservée pendant 5 ans à compter de la validation de ce formulaire.</span>
            <span>Conformément à la loi Informatique et Libertés du 6 janvier 1978, vous disposez d’un droit  d’accès, de rectification, de suppression et d’opposition pour motifs légitimes sur l’ensemble des données vous concernant directement depuis les paramètres de votre compte Insensia.</span>
          </div>
        </div>
      </div>

      <div className={s.control}>
          <Button color="primary" onClick={dec}>Précédent</Button> 
          <Button color="primary" onClick={() => incStep()}>Valider</Button> 
      </div>

    </div>
  )
}

export default Step06CardValidation
