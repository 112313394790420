import React, { useState } from "react";
import s from './ModalCreateRule.module.scss'
import Step01SelectBuilding from "./Step01SelectBuilding/Step01SelectBuilding";
import Modal from "../../../../components/Modal/Modal";
import Icon from "../../../../components/Icon/Icon";
import { shallowEqual, useSelector } from "react-redux";
import { selectActiveBuilding } from "../../../../selectors/selectNavigation";
import Step02CustomScenario from "./Step02CustomRule/Step02CustomRule";
import Step03CustomHours from "./Step03CustomHours/Step03CustomHours";
import Step04ConfirmScenario from "./Step04ConfirmRule/Step04ConfirmRule";
import StepBarV1 from "../../../../components/StepBarV1/StepBarV1";

function ModalCreateRule({showModalCreateRule, setShowModalCreateRule}) {

  const building = useSelector(selectActiveBuilding, shallowEqual)

  const [step, setStep] = useState(1)

  const maxStep = 4;

  const increment = () => {
    setStep(step + 1)
  }
  
  const decrement = () => {
    setStep(step - 1)
  }

  const handleAdd = () => {
    alert('le règle a été créé');
    handleClose()
  }

  const handleClose = () => {
    setShowModalCreateRule(false)
    setStep(1)
  }
  const [dataTitle, setDataTitle] = useState('');
  const [comparatorsTitle, setComparatorsTitle] = useState('');
  const [valueTitle, setValueTitle] = useState('');
  const [firstValue, setFirstValue] = useState('');
  const [secondValue, setSecondValue] = useState('');
  const [unitTitle, setUnitTitle] = useState('');
  const [selectedComparatorBetween, setSelectedComparatorBetween] = useState(false)

  const value = selectedComparatorBetween ? `entre ${firstValue} et ${secondValue}` : `${comparatorsTitle} à ${valueTitle}`;

  const recap = `La ${dataTitle} doit être ${value} ${unitTitle}`;

  const StepCard = () => {
      switch(step) {
        case 1:
          return (<Step01SelectBuilding  inc={increment}/>)
        case 2:
          return (<Step02CustomScenario 
            inc={increment} 
            dec={decrement} 
            setDataTitle={setDataTitle} 
            setComparatorsTitle={setComparatorsTitle} 
            setValueTitle={setValueTitle} 
            setUnitTitle={setUnitTitle}
            setFirstValue={setFirstValue}
            setSecondValue={setSecondValue}
            setSelectedComparatorBetween={setSelectedComparatorBetween} />)
        case 3: 
          return (<Step03CustomHours inc={increment} dec={decrement} setSelectedComparatorBetween={setSelectedComparatorBetween} />)
        case 4:
          return (<Step04ConfirmScenario handleAdd={handleAdd} dec={decrement}/>)
        default:
          return
      }
    }

  return(
    <Modal show={showModalCreateRule}>
      <div className={s.header}>
        <div className={s.title}>CRÉATION D'UNE RÈGLE</div>
        <Icon name="close" size={'1.5rem'} color={'#30465E'} fill={0} onClick={handleClose} cursor/>
      </div>
      <div className={s.content}>
        {step === 3 && 
        <div>
          Les informations que vous avez fournis permettent de créer la règle suivant.  
        </div>}
        {step >= 2 && <div className={s.recap}>
          <div> Récap de la règle :</div>
          <div className={s.location}>
            <Icon name={"location_on"} fill={1} />
            <div>{building?.name}</div>
          </div>
          <div>
            {dataTitle ? recap : ''}
          </div>
        </div>}
            {step > 0 && step <4 && <StepBarV1 step={step} maxStep={maxStep} />}
        {StepCard()}
      </div>
    </Modal>  
  )
}

export default ModalCreateRule