import { defaultAxiosInstance } from '../../axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit'

export const createWeatherStation = createAsyncThunk(
  'weather/createWeatherStation',
  async ({ building_id, inseecode, latitude, longitude }, { rejectWithValue }) => {
    try {
      await defaultAxiosInstance.post(`/weather/weather_station/`, { building_id, inseecode, latitude, longitude })
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getWeatherStations = createAsyncThunk(
  'weather/getWeatherStations',
  async ({ inseecode, latitude, longitude }, { rejectWithValue }) => {
    try {
      const params = { inseecode, latitude, longitude }
      const response = await defaultAxiosInstance.get(`/weather/weather_stations/`, { params })
      const weather_station = response.data
      return { weather_station }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getWeatherStation = createAsyncThunk(
  'weather/getWeatherStation',
  async ({ inseecode, latitude, longitude }, { rejectWithValue }) => {
    try {
      const params = { inseecode, latitude, longitude }
      const response = await defaultAxiosInstance.get(`/weather/weather_station/`, { params })
      const weather_station = response.data
      return { weather_station }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getWeatherHourlyData = createAsyncThunk(
  'weather/get_weather_hourly_data',
  async ({ weather_station_id, start, end }, { rejectWithValue }) => {
    try {
      const params = { weather_station_id, start, end }
      const response = await defaultAxiosInstance.get(`/weather/hourly_data/`, { params })
      const data = response.data
      return { weather_station_id, data }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getWeatherData = createAsyncThunk(
  'weather/get_weather_data',
  async ({ weather_station_id, periodUnit, period }, { rejectWithValue }) => {
    try {
      const params = { weather_station_id, periodUnit, period }
      const response = await defaultAxiosInstance.get(`/weather/weather_data/`, { params })
      const data = response.data
      return { weather_station_id, data }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)




