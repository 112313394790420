import React, { useState } from 'react'
import s from './ModalCreateAssociatedAccount.module.scss'
import { Button, Form, FormGroup, Input, Label } from "reactstrap"
import Modal from '../../../../components/Modal/Modal'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Icon from '../../../../components/Icon/Icon'

function ModalCreateAssociatedAccount({ showModalAdd, handleClose}) {

  const [data, setData] = useState({
    firstname: '',
    lastname: '',
    email: '',
  })

  const handleFormChange = (e) => {
    const { name, value } = e.target
    switch(name) {
      case 'firstname':
        setData(prevData => ({
          ...prevData,
          [name]: value 
        }))
        break;
      default:
        break;
    }
  }

  const handleCreate = () => {
    // dispatch(createAssociatedAccount(data))
    toast.success("Création effectuées !")
    handleClose()
  }

  return (
    <Modal show={showModalAdd}>
        
      <header className={s.header}>
        <div className={s.title}>Ajouter un compte associé</div>
        <Icon name="close" size={'1.5rem'} color={'#30465E'} fill={0} onClick={handleClose} cursor/>
      </header>

      <Form className={s.form}>
        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Prénom</Label>
          <Input className={s.input} type="text" name="firstname" onChange={handleFormChange}/>
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Nom</Label>
          <Input className={s.input} type="text" name="lastname" onChange={handleFormChange}/>
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Email</Label>
          <Input className={s.input} type="email" name="email" onChange={handleFormChange}/>
        </FormGroup>
      </Form>


      <div className={s.control}>
        <Button color="primary" onClick={handleCreate} disabled={data.name === ''}>Valider</Button> 
      </div>

    </Modal>
  )
}

export default ModalCreateAssociatedAccount
