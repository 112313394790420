import React, { useState } from 'react'
import s from './CardRule.module.scss'
import 'moment/locale/fr';
import { Button, Collapse } from 'reactstrap';
import Icon from '../../../../components/Icon/Icon'
import Popup from '../../../../components/Popup/Popup';

function CardRule({ scenario,  sensorInfo1, sensorInfo2, sensorInfo3, sensorInfo4, sensorInfo5,}) {
  
  const [showSensors, setshowSensors] = useState(false)

  const separation = ">"
  const [isOn, setIsOn] = useState(true)
  const [showPopupDelete , setShowPopupDelete] = useState(false)

  const handleDelete = () => {
    alert(`scénario "${scenario.name}" supprimé`)
    setShowPopupDelete(false)
  }

  return (
    <div className={s.root}>
      <div className={s.header}>
        <div className={s.options}>
          <Button className={s.btnModify}>
            <Icon name={"edit"} color={"#3192D3"} />
            modifier
          </Button>
          <div className={s.toggleButton} onClick={() => setIsOn(!isOn)}>
            <div className={s.background}>
              <div className={`${s.label} ${isOn ? s.on : s.off}`}>
                  {isOn ? "ON" : "OFF"}
              </div>
            </div>      
          </div>
          <Button className={ isOn ? s.btnDisable :  s.btnDelete} onClick={isOn === false ? () => setShowPopupDelete(true) : null} >
            <Icon name={"delete"} color={isOn ? "#798892" : "#E6514C" } />
            supprimer
          </Button>
        </div>
        <div className={s.title}>
          <div className={s.name}>{scenario.name}</div>
          <div className={s.localisation}>
            <div>{scenario.building}</div>
            {separation}
            <div>{scenario.room}</div>
          </div>
        </div>
              
      </div>
      <div className={s.content}>
        <div className={s.action}>
          <div className={s.title}>
            Action
          </div>
          <div className={s.infos}>
            <div className={s.info}>
              {scenario.unit}
            </div>
            <div className={s.info}>
              {scenario.comparator}
            </div>
            <div className={s.info}>
              {scenario.value}
            </div>
          </div>
        </div>
        <div className={s.trigger}>
          <div className={s.title}>
            Déclencheur
          </div>

          <div className={s.infos}>
            <div className={s.info}>
              heure
            </div>
            <div className={s.info}>
              entre
            </div>
            <div className={s.info}>
              {scenario.timeStart} et {scenario.timeEnd}
            </div>
          </div>
        </div>
        <Collapse className={s.dropdown} isOpen={showSensors === true}>
          <div className={s.layout}>
            {scenario.nbrEquipment} équipements impliqués 
            <div className={`${s.icon}  ${ showSensors? s.close : s.open}`}>
              <Icon name={"keyboard_arrow_down"}  onClick={() => setshowSensors(!showSensors)}/>
            </div>
          </div>
          <div className={`${s.Sensors} ${showSensors ? s.showSensors : s.hideSensors}`}>
            {sensorInfo1 && 
              <div className={s.sensor}>
                <div className={s.title}>{sensorInfo1?.name} </div>
                <div className={s.infos}> 
                  <div className={s.info}>Type de donnée : {sensorInfo1?.type}</div>
                  <div className={s.info}>Numéro de série : {sensorInfo1?.serial_number}</div>
                  <div className={s.info}>Dernière donnée : {sensorInfo1?.last_data}</div>
                </div>
              </div> }
            {sensorInfo2 && 
            <div className={s.sensor}>
              <div className={s.title}>{sensorInfo2?.name} </div>
              <div className={s.infos}> 
                <div className={s.info}>Type de donnée : {sensorInfo2?.type}</div>
                <div className={s.info}>Numéro de série : {sensorInfo2?.serial_number}</div>
                <div className={s.info}>Dernière donnée : {sensorInfo2?.last_data}</div>
              </div>
            </div> }
            {sensorInfo3 && 
            <div className={s.sensor}>
              <div className={s.title}>{sensorInfo3?.name} </div>
              <div className={s.infos}> 
                <div className={s.info}>Type de donnée : {sensorInfo3?.type}</div>
                <div className={s.info}>Numéro de série : {sensorInfo3?.serial_number}</div>
                <div className={s.info}>Dernière donnée : {sensorInfo3?.last_data}</div>
              </div>
            </div> }
            {sensorInfo4 && 
            <div className={s.sensor}>
              <div className={s.title}>{sensorInfo4?.name} </div>
              <div className={s.infos}> 
                <div className={s.info}>Type de donnée : {sensorInfo4?.type}</div>
                <div className={s.info}>Numéro de série : {sensorInfo4?.serial_number}</div>
                <div className={s.info}>Dernière donnée : {sensorInfo4?.last_data}</div>
              </div>
            </div> }
            {sensorInfo5 && 
            <div className={s.sensor}>
              <div className={s.title}>{sensorInfo5?.name} </div>
              <div className={s.infos}> 
                <div className={s.info}>Type de donnée : {sensorInfo5?.type}</div>
                <div className={s.info}>Numéro de série : {sensorInfo5?.serial_number}</div>
                <div className={s.info}>Dernière donnée : {sensorInfo5?.last_data}</div>
              </div>
            </div> }
          </div>
        </Collapse>
      </div>  

      <Popup title={`SUPPRIMER "${scenario.name}" ?`} show={showPopupDelete} onDiscard={() => setShowPopupDelete(false)} onAccept={handleDelete}  />
  </div>
  )
}

export default CardRule
