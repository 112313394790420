import moment from 'moment';
moment.locale('fr')

export const isDateMax = (date) => {
  const today = moment().startOf('day');
  return moment(date).isSame(today, 'day');
};

export const getDayPeriod = (date = moment()) => ({
  start: date.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
  end: date.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
});

export const getWeekPeriod = (date = moment()) => ({
  start: date.startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'),
  end: date.endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'),
});

export const getMonthPeriod = (date = moment()) => ({
  start: date.startOf('month').format('YYYY-MM-DD HH:mm:ss'),
  end: date.endOf('month').format('YYYY-MM-DD HH:mm:ss'),
});

export const getYearPeriod = (date = moment()) => ({
  start: date.startOf('year').format('YYYY-MM-DD HH:mm:ss'),
  end: date.endOf('year').format('YYYY-MM-DD HH:mm:ss'),
});

export const getFreePeriod = (start, end) => ({
  start: moment(start).format('YYYY-MM-DD HH:mm:ss'),
  end: moment(end).format('YYYY-MM-DD HH:mm:ss'),
});