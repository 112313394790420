import { defaultAxiosInstance } from '../../axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit'

export const checkDeviceExist = createAsyncThunk(
  'pairing/check_device_exist',
  async ({ user_id, serial_number }, { rejectWithValue }) => {
    try {
      const params = { user_id, serial_number }
      const response = await defaultAxiosInstance.get(`/pairing/duplicate`, { params })
      return response.data.device_id || null
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getPairingMessage = createAsyncThunk(
  'pairing/get_pairing_message',
  async ({ user_id, serial_number }, { rejectWithValue }) => {
    try {
      const params = { user_id, serial_number }
      const response = await defaultAxiosInstance.get(`/pairing/pairing_message`, { params })
      const data = response.data
      return { data }
    } catch (error) {
      // Construction de l'objet d'erreur pour le rejeter de manière plus informative
      let errorInfo = {};
      if (error.response) {
        // Si l'API a renvoyé une réponse d'erreur structurée
        errorInfo = {
          status: error.response.status,
          message: error.response.data.message || 'Unknown error occurred.',
          code: error.response.data.code || 'UNKNOWN_ERROR'
        };
      } else {
        // Pour les erreurs qui n'ont pas généré de réponse (problèmes de réseau, etc.)
        errorInfo = {
          message: error.message || 'Network error or no response from server',
        };
      }
      return rejectWithValue(errorInfo);
    }
  }
);
