import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import s from './CardTimeline.module.scss'
import { selectListAlerts } from '../../../../selectors/selectAlerts'
import { Collapse, Button } from 'reactstrap'
import moment from 'moment'
import Moment from 'react-moment'
import Icon from '../../../../components/Icon/Icon'

function CardTimeline({ hash_id }) {
  
  const listAlerts = useSelector(selectListAlerts, shallowEqual)
  
  const [selectedCard, setSelectedCard] = useState(hash_id !== null ? hash_id : null)
  const [readMore, setReadMore] = useState([parseInt(selectedCard)])
  const [update, setUpdate] = useState(false)
  
  const dropdownReadMore = (id) => {
    let index = readMore.indexOf(id)
    setSelectedCard(null)
    if (index > -1) {
      readMore.splice(index, 1)
    } else {
      readMore.splice(0, 0, id)
    }
    setReadMore(readMore)
    setUpdate(!update)
  }

  const selectIcon = (label_id, active) => {
    switch (label_id) {
      case 1:
        if (!active) {
          return <span className={`${s.eventIcon} ${s.eventIconSecondary}`}><Icon name="wifi" size={'1.5rem'} color={'#30465E'} fill={0}/></span>
        } else {
          return <span className={`${s.eventIcon} ${s.eventIconInfo}`}><Icon name="wifi" size={'1.5rem'} color={'#30465E'} fill={0}/></span>
        }
      case 2:
        if (!active) {
          return <span className={`${s.eventIcon} ${s.eventIconSecondary}`}><Icon name="battery_1_bar" size={'1.5rem'} color={'#30465E'} fill={0}/></span> 
        } else {
          return <span className={`${s.eventIcon} ${s.eventIconInfo}`}><Icon name="battery_1_bar" size={'1.5rem'} color={'#30465E'} fill={0}/></span> 
        }
      case 3:
        if (!active) {
          return <span className={`${s.eventIcon} ${s.eventIconSecondary}`}><Icon name="wifi" size={'1.5rem'} color={'#30465E'} fill={0}/></span>
        } else {
          return <span className={`${s.eventIcon} ${s.eventIconInfo}`}><Icon name="wifi" size={'1.5rem'} color={'#30465E'} fill={0}/></span>
        } 
      case 4:
        if (!active) {
          return <span className={`${s.eventIcon} ${s.eventIconSecondary}`}><Icon name="warning" size={'1.5rem'} color={'#30465E'} fill={0}/></span>
        } else {
          return <span className={`${s.eventIcon} ${s.eventIconDanger}`}><Icon name="warning" size={'1.5rem'} color={'#30465E'} fill={0}/></span>
        }  
      case 5:
        if (!active) {
          return <span className={`${s.eventIcon} ${s.eventIconSecondary}`}><Icon name="thermometer" size={'1.5rem'} color={'#30465E'} fill={0}/></span>
        } else {
          return <span className={`${s.eventIcon} ${s.eventIconWarning}`}><Icon name="thermometer" size={'1.5rem'} color={'#30465E'} fill={0}/></span>
        }
      case 6:
        if (!active) {
          return <span className={`${s.eventIcon} ${s.eventIconSecondary}`}><Icon name="humidity_percentage" size={'1.5rem'} color={'#30465E'} fill={0}/> </span>
        } else {
          return <span className={`${s.eventIcon} ${s.eventIconWarning}`}><Icon name="humidity_percentage" size={'1.5rem'} color={'#30465E'} fill={0}/> </span>
        }
      default:
        return
    }
  }

  const calculateDuration = (start, end) => {
    let duration = moment(end).diff(moment(start), 'seconds')
    let seconds
    let minutes
    let hours
    let days

    let result = ""

    if (duration < 60) {
      result = duration.toString() + " secondes"
    }
    if (duration >= 60 && duration < 3600) {
      minutes = Math.floor(duration/60)
      seconds = duration - (minutes * 60)
      result = minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0') + " minutes"
    }
    if (duration >= 3600 && duration < 86400) {
      hours = Math.floor(duration / 3600)
      minutes = Math.floor((duration - (hours * 3600)) / 60)
      seconds = duration - (hours * 3600) - (minutes * 60)
      result = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0') + " heures"
    }
    if (duration >= 86400) {
      days = Math.floor(duration / 86400)
      hours = Math.floor((duration - (days * 86400)) / 3600)
      minutes = Math.floor((duration - (days * 86400) - (hours * 3600)) / 60)
      seconds = duration - (days * 86400) - (hours * 3600) - (minutes * 60)
      result = days.toString() + " jours et " + hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0') + " heures"
    }

    return result
  }

  return (
    <div className={s.timeline}>
      {listAlerts?.length > 0 && 
      <ul className={s.ul}>
        {listAlerts?.map((alert, index) => (
          <li key={index} id={`id${alert.device_alert_id}`} className={s.timeLineCard} >
            <div className={s.eventTime}>
              <span className={s.date}>Il y a <Moment ago fromNow locale="fr">{alert.alert_start}</Moment></span>
            </div>
            
            <div className={s.timeline}>
              <div className={s.timebar}></div>
              {selectIcon(alert.alert_label.device_alert_label_id,alert.active)}
            </div>
            
            <div className={s.arrow}></div> 
            
            <section className={s.card}>
              <div className={s.header}>
                <div className={s.location}>
                  <div className={s.name}><Icon name="domain" size={'1.5rem'} color={'#30465E'} fill={0}/>{alert.building.name}</div>
                  <span className={s.separator}> - </span>
                  <div className={s.name}>{alert.room.name}</div>
                </div> 
                <div className={s.label}>{alert.alert_label.label} - {alert.device.name}</div>
                <time className={s.time}><small className={s.timeAgo}>Il y a <Moment ago fromNow locale="fr">{alert.alert_start}</Moment></small></time>
              </div>
              
              <div className={s.content}>
              
                <div className={s.left}>
                  <time className={s.time}>
                    <span className={s.timeAgo}><Moment fromNow locale="fr">{alert.alert_start}</Moment></span>
                  </time>
                  <div className={s.alert_time}>
                    <div className={s.start}>
                      <div className={s.time_start}><span>Début : </span><Moment format="DD/MM/YY HH:mm:ss" locale="fr">{alert.alert_start}</Moment></div>
                    </div>
                    {!alert.active &&
                    <div className={s.end}>
                      <div className={s.time_end}><span>Fin : </span><Moment format="DD/MM/YY HH:mm:ss" locale="fr">{alert.alert_end}</Moment></div>
                      <div className={s.duration}><span className={s.separator}> - </span><span>Durée : </span>{calculateDuration(alert.alert_start, alert.alert_end)}</div> 
                    </div>}
                  </div>
                </div>

                <div className={s.right}>
                  <Button color="primary" onClick={() => {dropdownReadMore(alert.device_alert_id)}}>En savoir plus</Button>  
                </div>
              </div>
              
              <Collapse className={s.collapse} isOpen={readMore.indexOf(alert.device_alert_id) > -1}>               
                <div className={s.detail}>{alert.alert_label.comment}</div>
              </Collapse>             
            </section>
          </li>
        ))}
      </ul>}

      {listAlerts?.length === 0 &&
        <div className={s.noAlert}>Aucune alerte</div>
      } 

    </div>
    )
  }
  
export default CardTimeline
