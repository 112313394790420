import { defaultAxiosInstance } from '../../axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { decodeToken } from "react-jwt"
import { userLogout } from './authSlice'
import { getNavigationState } from '../navigation/navigationActions';

export const RESET_STATE = 'reset_state'
export const resetState = () => ({ type: RESET_STATE })

export const doInit = createAsyncThunk(
  'auth/init',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      let user = null
      let isAuthenticated = false
      let token = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).token
      if (token) {
        const response = await defaultAxiosInstance.get('/auth/me')
        user = response.data
        isAuthenticated = true
      }
      return { isAuthenticated, user }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      const response = await defaultAxiosInstance.post('auth/login', { email, password })
      const token = response.data
      let user = decodeToken(token)
      let isAuthenticated
      let storageData = {
        user: user,
        token: token,
        navigation: '/consult/summary',
        activeBuilding: null
      }
      localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE, JSON.stringify(storageData))
      if (token) {
        defaultAxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        const response = await defaultAxiosInstance.get('/auth/me')
        user = response.data
        const user_id = user.user_id
        isAuthenticated = true
        await dispatch(getNavigationState({ user_id }));
      }
      return { isAuthenticated, user }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue({ 
          message: error.response.data.message, 
          code: error.response.data.code 
        });
      }
    }
  }
)

export const userRegister = createAsyncThunk(
  'auth/register',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      await defaultAxiosInstance.post(`auth/register`, { email, password })
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const sendPasswordResetEmail = createAsyncThunk(
  'auth/send_password_reset_email',
  async ({ email }, { rejectWithValue }) => {
    try {
      await defaultAxiosInstance.post(`/auth/send-password-reset-email`, { email })
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }  
)

export const resetPassword = createAsyncThunk(
  'auth/reset_password',
  async ({ token, password }, {dispatch, rejectWithValue }) => {
    try {
      await defaultAxiosInstance.put("/auth/password-reset", { token, password })
      toast.success("Votre mot de passe a été mis à jour")
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const checkEmail = createAsyncThunk(
  'auth/check_email',
  async ({ token }, { dispatch, rejectWithValue }) => {
    try {
      const verified = await defaultAxiosInstance.put("/auth/verify-email", { token })
        if (verified) {
          toast.success("Votre email a été vérifié avec succès")
        }
    } catch (error) {
      toast.error(error)
    }
  }
)

export const updatePassword = createAsyncThunk(
  'auth/update_password',
  async({ current_password, new_password, confirm_password }, {  dispatch, rejectWithValue }) => {
    try {
      if (new_password === confirm_password) {
        await defaultAxiosInstance.put('/auth/password-update', {new_password, current_password})
        toast.success('Votre nouveau mot de passe a été modifié, veuillez vous reconnecter')
        dispatch(userLogout())
      } else {
        toast.error('Votre nouveau mot de passe n\' a pas été confirmé')
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    } 
  }
)