import React, { useState, useEffect } from 'react';
import s from './CardMap.module.scss';
import { MapContainer, TileLayer, Marker, Tooltip, useMap, useMapEvents } from 'react-leaflet';
import { Button } from 'reactstrap';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Configuration des icônes Leaflet
const PlaceLocationIcon = L.icon({
  iconUrl: require('./location_on.png'),
  iconSize: [30, 30],
  className: 'leaflet-place-icon',
});

const WeatherStationLocationIcon = L.icon({
  iconUrl: require('./flag.png'),
  iconSize: [30, 30],
  className: 'leaflet-place-icon',
});

// Configuration des icônes Leaflet
const PlaceCityIcon = L.icon({
  iconUrl: require('./location_city.png'),
  iconSize: [30, 30],
  className: 'leaflet-place-icon',
});

function CardMap({ data }) {

  const [mapMoved, setMapMoved] = useState(false);
  const [mapInstance, setMapInstance] = useState(null); // Stocker l'instance de la carte
  const [cities, setCities] = useState([]);

  const showCities = false

  const positionParis = [48.866667, 2.333333];
  const zoomFrance = 5;
  const zoomBuilding = 10;

  const styleMap = { borderRadius: '1.25rem', width: '100%', height: '100%' };

  // Charger les villes depuis le fichier JSON
  useEffect(() => {
    fetch('/villes_france.json')
      .then((response) => response.json())
      .then((data) => setCities(data))
      .catch((error) => console.error('Erreur lors du chargement des villes:', error));
  }, []);

  const handleResetView = () => {
    if (mapInstance) {
      const position = data?.address?.geolocation?.length
        ? data.address.geolocation
        : positionParis;
      const zoomLevel = data?.address?.geolocation?.length ? zoomBuilding : zoomFrance;
      mapInstance.flyTo(position, zoomLevel);
      setMapMoved(false);
    }
  };

  const MapEvents = () => {
    const map = useMap();

    useEffect(() => {
      // Enregistrer l'instance de la carte dans l'état
      setMapInstance(map);
    }, [map]);

    useMapEvents({
      dragend: () => {
        setMapMoved(true);
      },
    });

    return null;
  };

  const AdjustView = ({ markers }) => {
    const map = useMap();

    useEffect(() => {
      if (markers.length === 1) {
        map.setView(markers[0], zoomBuilding);
      } else if (markers.length > 1) {
        const bounds = L.latLngBounds(markers);
        map.fitBounds(bounds, { padding: [50, 50], maxZoom: 15 });
      }
    }, [markers, map]);

    return null;
  };

  const markers = [
    data?.address?.geolocation?.length ? data.address.geolocation : null,
    data?.weather_station?.latitude && data?.weather_station?.longitude
      ? [data.weather_station.latitude, data.weather_station.longitude]
      : null,
  ].filter(Boolean);

  return (
    <div className={s.root}>

      <MapContainer
        center={data?.address?.geolocation?.length ? data.address.geolocation : positionParis}
        zoom={data?.address?.geolocation?.length ? zoomBuilding : zoomFrance}
        scrollWheelZoom
        attributionControl={false}
        zoomControl={false}
        style={styleMap}
        height={'100%'}
      >
        <TileLayer
          url="https://api.mapbox.com/styles/v1/vas17000/cl2uco8py001k14o1f3x7t51a/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidmFzMTcwMDAiLCJhIjoiY2wydWJndHNsMDAzYzNrcXB5b3R0aG15dCJ9.Doz92MDFxAcxJdimCKRk9A"
        />

        <AdjustView markers={markers} />
        <MapEvents />

        {data?.address?.geolocation?.length && (
          <Marker
            position={data.address.geolocation}
            icon={PlaceLocationIcon}
          >
            {data.name && (
              <Tooltip sticky direction="top" offset={[0, -10]}>
                <div className="poup-text">{data.name}</div>
                <div className="poup-text">{data.address.street}</div>
                <div className="poup-text">{data.address.zipcode} - {data.address.city}</div>
              </Tooltip>
            )}
          </Marker>
        )}

        {data?.weather_station?.latitude && data?.weather_station?.longitude && (
          <Marker
            position={[data.weather_station.latitude, data.weather_station.longitude]}
            icon={WeatherStationLocationIcon}
          >
            {data.weather_station.name && (
              <Tooltip sticky direction="top" offset={[0, -10]}>
                <div className="poup-text">Station météo de</div>
                <div className="poup-text">{data.weather_station.name}</div>
              </Tooltip>
            )}
          </Marker>
        )}

        {/* Affichage des villes issues du fichier JSON */}
        {showCities && cities.map((city, index) => (
        <Marker key={index} position={[city.latitude, city.longitude]} icon={PlaceCityIcon}>
          <Tooltip sticky direction="top" offset={[0, -10]}>
            <div className="poup-text">{city.city}</div>
          </Tooltip>
        </Marker>
        ))}

        {mapMoved && (
          <Button color="primary" onClick={handleResetView}>Centrer la carte</Button>
        )}

      </MapContainer>
    </div>
  );
}

export default CardMap;
