import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import s from './CardPreference.module.scss'

function CardPreference() {

  return (
    <div className={s.root}>
      
      <div className={s.header}>
        <div className={s.title}>Préférences</div>
      </div>

      <div className={s.content}>
        <ul className={s.cardInfo}>
          <li><span>Thème :</span><div className={s.themeBall}><div className={s.diagonal}></div></div></li>
          <li><span>Notification:</span><span> actives </span></li>
        </ul>
      </div>

    </div>
  )
}

export default CardPreference
