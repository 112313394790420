import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { defaultAxiosInstance, config } from './axiosConfig';
import store from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

defaultAxiosInstance.defaults.baseURL = config.baseURLApi;
defaultAxiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

const storedData = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE);
const parsedData = storedData ? JSON.parse(storedData) : null;
const token = parsedData?.token || null;
const user = parsedData?.user || null;

if (token && user) {
  defaultAxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

console.log('React version', React.version); 

root.render(
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={false}
          theme="colored"
          transition={Slide}
        />
        <App />
      </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
