import { createSlice } from "@reduxjs/toolkit";
import { getReports, deleteReport, getReportFile } from './reportsActions';

export const initialStateReports = {
  isLoading: false,
  listReports: [],
  listBinders: [],
  error: null,
  pdfUrl:null
}

const reportsSlice = createSlice({
  name: 'reports',
  initialState: initialStateReports,
  reducers: {
    resetReports: () => initialStateReports,
    clearPdfUrl: (state) => {
      state.pdfUrl = null;
    }
  },
  extraReducers: builder => {
    builder
      //GET REPORTS
      .addCase(getReports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReports.fulfilled, (state, { payload }) => {
        state.listReports = payload;
        state.error = null;
        state.isLoading = false;
      })
      .addCase(getReports.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.listReports = [];
        state.error = payload
      })
      //DELETE REPORT
      .addCase(deleteReport.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteReport.fulfilled, (state) => {
        state.error = null
        state.isLoading =  false
      })
      .addCase(deleteReport.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
      //DELETE REPORT FILE
      .addCase(getReportFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReportFile.fulfilled, (state, { payload }) => {
        state.pdfUrl = payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getReportFile.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.pdfUrl = null;
        state.error = payload;
      })
      .addDefaultCase((state, action) => {})
  }
});

export const { resetReports } = reportsSlice.actions;

export default reportsSlice.reducer;




