import React, { useState } from 'react';
import s from './Step03CustomHours.module.scss';
import { Button, Input } from 'reactstrap';
import classnames from 'classnames/bind';
import Icon from '../../../../../components/Icon/Icon';

const cx = classnames.bind(s);

function Step03CustomHours({ inc, dec, setSelectedComparatorBetween }) {

  const [navigationDataType, setNavigationDataType] = useState(null);
  const [navigationComparatorType, setNavigationComparatorType] = useState(null);
  const [isDataSelected, setIsDataSelected] = useState(false);
  const [isComparatorSelected, setIsComparatorSelected] = useState(false);

  const dataType = [
    { name: 'Constamment' },
    { name: 'Température' },
    { name: "Qualité d'air" },
    { name: 'Présence' },
    { name: 'Humidité' },
    { name: 'Horaire' },
    { name: 'Date et horaire' },
  ];
  const comparators = [{ name: 'entre... et...' }, { name: 'au dessus de' }, { name: 'en dessous de' }];

  const handleClickNavigationDataType = (index, name, unit) => {
    setNavigationDataType(index);
    setIsDataSelected(true);
  };

  const handleClickNavigationComparatorType = (index, name) => {
    setNavigationComparatorType(index);
    {
      navigationComparatorType === 0 && setSelectedComparatorBetween(true);
    }
    setIsComparatorSelected(true);
  };

  return (
    <div className={s.root}>
      <div className={s.scenariosParam}>
        <div className={s.subject}>
          Vous pouvez maintenant choisir sous quelle condition doit s’effectuer cette consigne.
        </div>
        <div className={s.message}>
          <div className={s.icon}>
            <Icon name={'info'} fill={1} />{' '}
          </div>
          Les données disponibles dépendent des équipements présents dans l’espace que vous avez sélectionné.
        </div>
        <div className={s.scenarioSteps}>
          <div className={s.title}>Choisissez une combinaison afin de créer la consigne de ce règle.</div>
          <div className={s.selector}>
            <div className={s.data}>
              <div className={s.title}>Donnée</div>
              <div className={s.buttons}>
                {dataType.map((item, index) => (
                  <Button key={index} className={cx(s.btn, { [s.active]: navigationDataType === index })} onClick={() => handleClickNavigationDataType(index, item.name, item.unit)}>
                    {item.name}
                  </Button>
                ))}
              </div>
            </div>
            <div className={`${s.data} ${isDataSelected ? '' : s.disable}`}>
              <div className={s.title}>Comparateur</div>
              {isDataSelected && (
                <div className={s.buttons}>
                  {comparators.map((item, index) => (
                    <Button key={index} className={cx(s.btn, { [s.active]: navigationComparatorType === index })} onClick={() => handleClickNavigationComparatorType(index, item.name)}>
                     {item.name}
                    </Button>
                  ))}
                </div>
              )}
            </div>
            <div className={`${s.data} ${isComparatorSelected ? '' : s.disable}`}>
              <div className={s.title}>Valeur</div>
              {isComparatorSelected && navigationComparatorType !== 0 && (
                <Input className={s.input} type='text' placeholder='Entrez votre nombre ici' />
              )}
              {isComparatorSelected && navigationComparatorType === 0 && (
                <div className={s.inputs}>
                  <Input className={s.input} type='text' placeholder='Entrez la première valeur' />
                  <Input className={s.input} type='text' placeholder='Entrez la deuxième valeur' />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={s.btnSteps}>
        <Button className={s.btn} onClick={dec}>
          Précédent
        </Button>
        <Button className={`${isComparatorSelected && isDataSelected ? s.btnActive : s.btnDisable}`} disabled={!isComparatorSelected || !isDataSelected} onClick={inc}>
          Valider
        </Button>
      </div>
    </div>
  );
}

export default Step03CustomHours;
