import { createSlice } from "@reduxjs/toolkit"
import {
  getProfile,
} from './userActions'

import moment from 'moment'
moment.locale('fr')

export const initialStateUser = {
  isLoading: false,
  profile: null,
  user_id: null, // Nouvelle propriété ajoutée pour stocker user_id
  error: null
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialStateUser,
  reducers: {
    resetUser: () => initialStateUser,
  },
  extraReducers: builder => {
    builder
      //GET PROFILE
      .addCase(getProfile.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        state.profile = payload.profile;
        state.user_id = payload.profile.user_id; // Nouvelle ligne ajoutée
        state.error = null;
        state.isLoading = false;
      })
      .addCase(getProfile.rejected, (state, { payload }) => {
        state.profile = null;
        state.error = payload;
        state.isLoading = false;
      })
  }
})

export const { resetUser } = userSlice.actions

export default userSlice.reducer
