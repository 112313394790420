import { createAsyncThunk } from "@reduxjs/toolkit";
import { defaultAxiosInstance } from '../../axiosConfig';

export const createReport = createAsyncThunk(
  'reports/createReport',
  async ({ formData }, { rejectWithValue }) => {
    try {     
      const response = await defaultAxiosInstance.post('/reports/', formData, {
        headers: { 'Content-Type': 'multipart/form-data',
        }}
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);


export const getReportFile =  createAsyncThunk(
  'reports/getReportFile',
  async ({ filename }, {rejectWithValue}) => {
    try{
      const params = { filename }
      const response =  await defaultAxiosInstance.get(`/reports/get_report_file/`, { params, responseType: 'arraybuffer'})
      const pdfBlob =  new Blob([response.data], {type: 'application/pdf'});
      const pdfUrl = URL.createObjectURL(pdfBlob);
      return pdfUrl; 
    }
    catch(error){
      alert("Fichier PDF non trouvé")
      return rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
)

export const getReports = createAsyncThunk(
  'reports/get', 
  async ({ user_id }, { rejectWithValue }) => {
    try {
      const params = { user_id };
      const response = await defaultAxiosInstance.get(`/reports/`, { params });
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data.message || error.message);
    }
  }
);

export const deleteReport = createAsyncThunk(
  'reports/deleteReport', 
  async ({ report_id, filename }, { rejectWithValue }) => {
    try {
      const params = { filename }
      await defaultAxiosInstance.delete(`reports/${report_id}`, { params });
    } catch (error) {
      console.error('deleteReport action error: ', error);
      return rejectWithValue(error.response.data.message || error.message);
    }
  }
);