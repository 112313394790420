import { createSlice } from "@reduxjs/toolkit"
import { checkDeviceExist, getPairingMessage } from './pairingActions'
import { createDevice } from '../devices/devicesActions'

const initialStatePairing = {
  serial_number: null,
  deviceExist: null,
  device_id: null,
  pairing_message: null,
  pairing_status: null,
  error: null
}

const pairingSlice = createSlice({
  name: 'pairing',
  initialState: initialStatePairing,
  reducers: {
    saveSerialNumber: (state, action) => {
      state.serial_number = action.payload
    },
    deleteSerialNumber: (state, action) => {
      state.serial_number = null
      state.deviceExist = null
      state.device_id = null
    },
    resetDeviceExist: (state) => {
      state.deviceExist = null
      state.device_id = null
    },
    initPairing: (state) => {
      state.pairing_message = null
      state.pairing_status = null
      state.error = null  
      state.device_id = null     
    }
  },
  extraReducers: builder => {
    builder
      //GET PAIRING MESSAGE
      .addCase(getPairingMessage.pending, (state) => {
        state.pairing_message = null
        state.pairing_status = null
      })
      .addCase(getPairingMessage.fulfilled, (state, { payload }) => {
        state.pairing_message = payload.data.topic
        state.pairing_status = payload.data.status
      })
      .addCase(getPairingMessage.rejected, (state, { payload }) => {
        state.error = payload
        state.pairing_message = null
        state.pairing_status = null
      })
      //CHECKING DEVICE EXIST
      .addCase(checkDeviceExist.pending, (state) => {
        state.deviceExist = null
      })
      .addCase(checkDeviceExist.fulfilled, (state, { payload }) => {
        state.deviceExist = payload
      })
      .addCase(checkDeviceExist.rejected, (state) => {
        state.deviceExist = null
      })
      //CREATE DEVICE
      .addCase(createDevice.pending, (state) => {
        state.device_id = null
      })
      .addCase(createDevice.fulfilled, (state, { payload }) => {
        state.device_id = payload.device_id
      })
      .addCase(createDevice.rejected, (state) => {
        state.device_id = null
      })
  }
})

export const { saveSerialNumber, deleteSerialNumber, resetDeviceExist, initPairing } = pairingSlice.actions

export default pairingSlice.reducer



