import React from 'react'
import s from './Step06CardCongratulations.module.scss'
import { Button } from 'reactstrap'

function Step06CardCongratulations({ finish }) {

  return (        
    <div className={s.root}>   

      <div className={s.content}>
        <p>Votre capteur est configuré et prêt à fonctionner.</p>
      </div>
      
      <div className={s.control}>
        <Button color="primary" onClick={finish} disabled={false}>Terminé</Button>
      </div>
    </div>
  )
}

export default Step06CardCongratulations