import React from 'react'
import s from './Step07CardCongratulations.module.scss'
import { Button } from 'reactstrap'

function Step07CardCongratulations({ handleCreate}) {

  return (
    <div className={s.root}>

      <div className={s.background_container}></div>
     
      <div className={s.content}>
        <div className={s.description}>Nous avons accès à vos données de consommation !</div>
        <div className={s.description}>Vous pouvez maintenant commencer à faire des économies avec Insensia !</div>      
      </div>

      <div className={s.control}>
        <Button color="primary" onClick={handleCreate} disabled={false}>C'est parti !</Button>
      </div>

    </div>     
  )
}
export default Step07CardCongratulations
