import { createSelector } from '@reduxjs/toolkit'

const universeTranslations = {
  summary: 'Synthèse',
  consumption: 'Consommation',
  comfort: 'Confort',
  air_quality: 'Qualité d\'air',
  occupancy: 'Occupation',
};

export const selectActiveRoute = (state) => {
  return state.navigation.activeRoute
}

// export const selectMainRouteActive = (state) => {
//   const activeRoute = state.navigation.activeRoute
//   if (activeRoute) {
//     const parts = activeRoute.split('/')
//     if (parts.length >= 3) {
//       return parts[2]
//     }
//   }
//   return null
// }

export const selectActiveSubRoute = (state) => {
  const extractedRoute = state.navigation.activeRoute.includes('/') ? state.navigation.activeRoute.split('/')[2] : ''
  return universeTranslations[extractedRoute] || '';
};

export const selectActiveBuilding = (state) => {
  return state.navigation.activeBuilding
}

export const selectActiveBuildingDevice = (state) => {
  return state.navigation.activeBuildingDevice
}

export const selectRooms = createSelector(
  [selectActiveBuilding],
  (activeBuilding) => activeBuilding?.rooms
);

export const selectRoomsDevice = createSelector(
  [selectActiveBuildingDevice],
  (activeBuildingDevice) => activeBuildingDevice?.rooms
)

export const selectActiveRoom = (state) => {
  return state.navigation.activeRoom
}

export const selectActiveRoomDevice = (state) => {
  return state.navigation.activeRoomDevice
}

export const selectActiveDevice = (state) => {
  return state.navigation.activeDevice
}

export const selectActiveLinky = (state) => {
  return state.navigation.activeLinky
}

export const selectActiveConsoUnit = (state) => {
  return state.navigation.activeConsoUnit
}

export const selectActivePeriodUnit = (state) => {
  return state.navigation.activePeriod.periodUnit
}

export const selectDay = (state) => {
  const activeDay = state.navigation && state.navigation.activePeriod.day;
  return activeDay;
};

export const selectWeek = (state) => {
  const activeWeek = state.navigation && state.navigation.activePeriod.week;
  return activeWeek;
};

export const selectMonth = (state) => {
  const activeMonth = state.navigation && state.navigation.activePeriod.month;
  return activeMonth;
};

export const selectYear = (state) => {
  const activeYear = state.navigation && state.navigation.activePeriod.year;
  return activeYear;
};

export const selectFreePeriod = (state) => {
  const activeFree = state.navigation && state.navigation.activePeriod.free;
  return activeFree;
};

export const selectPeriod = createSelector(
  [selectActivePeriodUnit, selectDay, selectWeek, selectMonth, selectYear],
  (periodUnit, activeDay, activeWeek, activeMonth, activeYear, activeFree) => { 
    return {
      day: activeDay,
      week: activeWeek,
      month: activeMonth,
      year: activeYear,
      free: activeFree
    }[periodUnit] || activeDay;
  }  
)
