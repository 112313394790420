import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import s from './CardEnedis.module.scss'

function CardEnedis() {

  return (
    <div className={s.root}>
      
      <div className={s.header}>
        <div className={s.title}>Consentement Enedis</div>
      </div>

      <div className={s.content}>
        <ul className={s.cardInfo}>
          <li><span>N° PRM :</span><span> 0812570596348</span></li>
          <li><span>Expiration :</span><span> 12/11/2025</span></li>
        </ul>
      </div>

    </div>
  )
}

export default CardEnedis
