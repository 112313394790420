import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import s from './Loader.module.scss'

function Loader({size, className}) {
    return (
        <div className={cx(s.root, className)}>
            <div className={s.logo_animate}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            </div>
        </div>
    )
}

Loader.propTypes = {
    size: PropTypes.number
}

Loader.default = {
    size: 21
}

export default Loader