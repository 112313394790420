import React from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import s from './CardBuilding.module.scss'
import { Button } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import CardAirQuality from '../../../../components/Indicators/Buildings/CardAirQuality/CardAirQuality'
import CardOccupancy from '../../../../components/Indicators/Buildings/CardOccupancy/CardOccupancy'
import CardConsumption from '../../../../components/Indicators/Buildings/CardConsumption/CardConsumption'
import CardComfort from '../../../../components/Indicators/Buildings/CardComfort/CardComfort'
import { selectActiveRoom } from '../../../../selectors/selectNavigation'
import { changeActiveBuilding, changeActiveRoute } from '../../../../features/navigation/navigationSlice'
import { saveNavigationState } from '../../../../features/navigation/navigationActions'
import store from '../../../../redux/store';
import { selectDevicesByBuildingAndRoom, selectDevicesSerialByBuildingAndRoom } from '../../../../selectors/selectDevices'
import Icon from '../../../../components/Icon/Icon'
import { getBuildings, updateBuilding } from '../../../../features/buildings/buildingsActions'
import moment from 'moment';

moment.locale('fr')

function CardBuilding({ building, startPeriod, endPeriod}) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const building_id = building?.building_id || null

  const room = useSelector(selectActiveRoom, shallowEqual)
  const room_id = room?.room_id || null
  const devices_ids = useSelector(selectDevicesByBuildingAndRoom(building_id, room_id), shallowEqual)
  
  const handleNavigateToBuilding = (building) => {
    dispatch(changeActiveBuilding(building))
    dispatch(changeActiveRoute(`/consult/summary`))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
    navigate(`/consult/summary`)
  }

  const handleClickUpdate = () => {
    dispatch(changeActiveRoute(`/settings/building/:${building.building_id}`))
    dispatch(changeActiveBuilding(building))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    navigate(`/settings/building/:${building.building_id}`)
  }

  const handleClickFavorite = () => {
    const updatedBuilding = {
      ...building,
      settings: {
        ...building.settings,
        favorite: !building.settings.favorite,
      },
    };
    dispatch(updateBuilding({ user_id, data: updatedBuilding }))
    .unwrap()
    .then(() => {
      dispatch(getBuildings({ user_id }));
    });
  }
  
  return (
    <div className={s.root}>
      
      <div className={s.header}>
        <div className={s.title}>{building.name}</div>
        <div className={s.building_location}>
          <span><Icon name="location_on" size={'1.5rem'} color={'#000'}/></span>
          <span>{building.address.city}</span>
          <span>---°C</span>
        </div>
      </div>

      <div className={s.content}>
        <div className={s.consumption}>
          <CardConsumption building={building} params={{ id: "mean_last_week:price;diff_last_week:consumption", building_id: building_id, room_id: room_id, periodStart: startPeriod, periodEnd: endPeriod, periodUnit: "week" }}/>
        </div>

        <div className={s.comfort}> 
          <CardComfort building={building} devices_ids={devices_ids}/>
        </div>
          
        {/* <div className={s.occupancy}>
          <CardOccupancy building={building} devices_ids={devices_ids}/>
        </div> */}

        <div className={s.air_quality}>
          <CardAirQuality building={building} devices_ids={devices_ids}/>
        </div>  
          

      </div>

      <div className={s.control}>
        <Icon name="settings" size={'2.5rem'} color={'#30465E'} fill={1} onClick={() => handleClickUpdate()} cursor/>
        <Icon name="star" size={'2.5rem'} color={building.settings.favorite ? '#3192D3' : '#30465E'} fill={building.settings.favorite ? 1 : 0} onClick={() => handleClickFavorite()} cursor animationClass={building.settings.favorite ? "icon--rotRight": "icon--rotLeft"}/>
      </div> 

      <div className={s.link_summary}>
        <Button color="primary" onClick={() => handleNavigateToBuilding(building)}>
          <span>Consulter</span>
          <Icon name="arrow_right_alt" size={'1.5rem'} color={'#FFF'} fill={0}/>
        </Button>
      </div>
      
    </div>
  )
}

export default CardBuilding
