import React, { useEffect } from "react";
import s from './CardComfort.module.scss'
import Icon from "../../../Icon/Icon";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeActiveRoute } from "../../../../features/navigation/navigationSlice";
import { saveNavigationState } from "../../../../features/navigation/navigationActions";
import store from '../../../../redux/store';
import Loader from "../../../Loader/Loader";
import { selectActiveBuilding, selectActiveRoom, selectPeriod, selectActivePeriodUnit } from "../../../../selectors/selectNavigation";
import { selectDevicesByBuildingAndRoom } from "../../../../selectors/selectDevices";
import { getAlgoData } from "../../../../features/measures/measuresActions";
import { selectMeasuresIsLoading, selectMeasuresDataComfort } from "../../../../selectors/selectMeasures";

function CardComfort() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id || null

  const room = useSelector(selectActiveRoom, shallowEqual)
  const room_id = room?.room_id || null

  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual)

  const devices = useSelector(selectDevicesByBuildingAndRoom(building_id, room_id), shallowEqual)

  const comfortData = useSelector(selectMeasuresDataComfort, shallowEqual)
  const isLoading = useSelector(selectMeasuresIsLoading)

  useEffect(() => {
    if (devices?.length > 0) {
      let parameters = {
        algo: "stat_summary",
        measurement: "temperature",
        building_id,
        room_id,
        devices,
        periodUnit,
        period
      }
      dispatch(getAlgoData({ parameters }));
      parameters = {
        algo: "stat_summary",
        measurement: "humidity",
        building_id,
        room_id,
        devices,
        periodUnit,
        period
      }
      dispatch(getAlgoData({ parameters }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building_id, room_id, devices, period]);

  const handleNavigateTo = () => {
    dispatch(changeActiveRoute('/consult/comfort'))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    navigate(`/consult/comfort`)
  }

  return (
    <div className={s.root}>
      
      <header className={s.header}>
        <div className={s.title}>
        <Icon name="chair" size={'1.5rem'} color={'#30465E'} fill={1}/> 
          <span>CONFORT</span>
        </div>
        <Icon name="arrow_forward" size={'1.5rem'} color={'#30465E'} fill={1} onClick={() => handleNavigateTo()} cursor/> 
      </header>

      {isLoading && <Loader/>}

      {!isLoading && comfortData &&
      <div className={s.content}>
        <div className={s.item}>
          <div className={s.value}>{comfortData?.statTemperature?.mean?.toFixed(1)} °C</div>
        </div>
        <div className={s.item}>
          <div className={s.value}>{comfortData?.statHumidity?.mean?.toFixed(1)} %</div>
        </div>
      </div>}
      
      {!isLoading && comfortData && 
      <footer className={s.footer}>
        Température/Humidité moyenne
      </footer>}
      
    </div>
  );
    
}

export default CardComfort;