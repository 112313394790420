import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch } from 'react-router-dom'
import s from './Buildings.module.scss'
import HeaderBuildings from '../../components/Headers/HeaderBuildings/HeaderBuildings'
import CardBuilding from './components/CardBuilding/CardBuilding'
import CardBuildingLight from './components/CardBuildingLight/CardBuildingLight'
import { selectListBuildings } from '../../selectors/selectBuildings'
import { getBuildings } from '../../features/buildings/buildingsActions'
import { getDevices } from '../../features/devices/devicesActions'

const setDate = () => {
  const today = new Date();
  const dayOfWeek = today.getDay()
  const isMonday = dayOfWeek === 1;

  let startDate, endDate;

  if (isMonday) {
    const previousMonday = new Date(today);
    previousMonday.setDate(today.getDate() - 7);
    startDate = previousMonday;

    const previousSunday = new Date(previousMonday);
    previousSunday.setDate(previousMonday.getDate() + 6);
    endDate = previousSunday;
  } else {
    const currentMonday = new Date(today);
    currentMonday.setDate(today.getDate() - (dayOfWeek + 6) % 7);
    startDate = currentMonday;

    const currentSunday = new Date(currentMonday);
    currentSunday.setDate(currentMonday.getDate() + 6);
    endDate = currentSunday;
  }
  return { "start": startDate.toISOString().split('T')[0], "end": endDate.toISOString().split('T')[0] }
}

function Buildings() {

  const dispatch = useDispatch()
  const matchSettings = useMatch('/settings/*')
  
  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;
  
  const buildings = useSelector(selectListBuildings);

  const [navigationIndex, setNavigationIndex] = useState(0)
  const [displayMode, setDisplayMode] = useState(0)
  const [searchQuery, setSearchQuery] = useState('');
  const {start, end } = setDate();

  useEffect(() => { 
    if (user_id) {
      dispatch(getBuildings({ user_id }))
      dispatch(getDevices({ user_id })) 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [user_id])

  useEffect(() => { 
    if (matchSettings) {
      setNavigationIndex(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [matchSettings])

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const filteredBuildings = buildings.filter((building) => {
    const matchesSearchQuery = building.name.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesFavorite = navigationIndex === 1 ? building.settings.favorite === true : true;
    return matchesSearchQuery && matchesFavorite;
  });

  return (
    <div className={s.root}>
      
      <HeaderBuildings onSearch={handleSearch} setNavigationIndex={setNavigationIndex} navigationIndex={navigationIndex} displayMode={displayMode} setDisplayMode={setDisplayMode} />
  
      <div className={s.content}>
        {displayMode === 0 && filteredBuildings && filteredBuildings.map((building, index) => (
          <CardBuilding key={index} building={building} startPeriod={start} endPeriod={end}/>
        ))}
        {displayMode === 1 && filteredBuildings && filteredBuildings.map((building, index) => (
          <CardBuildingLight key={index} building={building} startPeriod={start} endPeriod={end}/>
        ))}
        {filteredBuildings?.length === 0 && navigationIndex === 1 && <div className={s.message}>Aucun bâtiment dans vos favoris !</div>}
      </div>

      
    </div>
  )
}
export default Buildings