import React, { useEffect, useState } from 'react';
import s from './CardHighConsumptionAlert.module.scss';
import Icon from '../../../Icon/Icon';
import { Button } from 'reactstrap';
import ModalHighConsumptionAlert from '../ModalHighConsumptionAlert/ModalHighConsumptionAlert';
import { selectActiveBuilding, selectActivePeriodUnit, selectPeriod } from '../../../../selectors/selectNavigation';
import { selectIndicatorType, selectIsLoading, selectError } from "../../../../selectors/selectIndicator";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { selectSgeAuthorization } from '../../../../selectors/selectBuildings';
import { saveNavigationState } from '../../../../features/navigation/navigationActions';
import store from '../../../../redux/store';
import { changeActiveRoute } from '../../../../features/navigation/navigationSlice';
import { getHighConsumptionAlert } from '../../../../features/indicators/indicatorsActions'
import { useNavigate } from 'react-router-dom';


function CardHighConsumptionAlert() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const highConsumptionAlert = useSelector((state) => state.indicators.high_consumption_alert);

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const periodUnit = useSelector(selectActivePeriodUnit);
  const period = useSelector(selectPeriod, shallowEqual);
  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id || null
  const point_id = building?.sge_authorizations?.[0]?.point_id
  const counter = useSelector(selectSgeAuthorization(building_id, point_id), shallowEqual)
  const sge_authorization_id = counter?.sge_authorization_id

  const [showModalHighConsumptionAlert, setShowModalHighConsumptionAlert] = useState(false);
  const [periodTime, setPeriod] = useState(null);
  const [title, setTitle] = useState(null)

  const indicatorData = useSelector((state) => selectIndicatorType(state, "high_consumption_alert", period));
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);

  const handleClose = () => {
    setShowModalHighConsumptionAlert(false);
  };

  useEffect(() => {
    switch(periodUnit) {
      case 'day':
        setTitle(`PIC INHABITUEL DE CONSO. À 15 HEURE LE ${moment(period.start).format('DD MMMM').toUpperCase()}`)
        setPeriod(`${moment(period.start).format('DD MMMM')}`);
        break;
      case 'week':
        setTitle(`PIC INHABITUEL DE CONSO. LE MARDI DE LA SEMAINE DU ${moment(period.start).format('DD MMMM').toUpperCase()} AU ${moment(period.end).format('DD MMMM').toUpperCase()}`)
        setPeriod(`${moment(period.start).format('DD MMMM')} au ${moment(period.end).format('DD MMMM')}`);
        break;
      default:
        setTitle(`PIC INHABITUEL DE CONSO. LA 3ÈME SEMAINE DE ${moment(period.start).format('MMMM').toUpperCase()}`)
        setPeriod(`${moment(period.start).format('DD MMMM')} au ${moment(period.end).format('DD MMMM')}`);
        break;
    }
    // eslint-disable-next-line
  },[periodUnit])

  const handleNavigateToServices = () => {
    dispatch(changeActiveRoute(`/settings/services/${building_id}`))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    navigate(`/settings/services/${building_id}`)
  }

  useEffect(() => {
    const today = moment().startOf('day');
    const periodStart = moment(period.start, "YYYY-MM-DD HH:mm:ss");
    if (periodStart.isBefore(today)) {
      dispatch(getHighConsumptionAlert({parameters: { sge_authorization_id, point_id, periodUnit, period }}));
    }
  }, [dispatch, sge_authorization_id, point_id, periodUnit, period]);

  useEffect(() => {
    console.log(indicatorData)
  }, [indicatorData])

  return (
    <div className={s.root}>
      {sge_authorization_id ? 
      <> 
        <div className={s.content}>
          <div className={s.title}>{title}</div>
          <div className={s.description}>
            + 20% de consommation de plus cette journée par rapport à la moyenne dans la période du {periodTime}
          </div>
        </div>
        <div className={s.button}>
          <Button className={s.btn} onClick={() => setShowModalHighConsumptionAlert(true)}>
            Voir les détails
            <Icon name='arrow_forward' size={'1.5rem'} color={'#ffffff'} fill={1} />
          </Button>
        </div>
      </> 
      :
      <>
        <div className={s.content}>
          <div className={s.title}>{title}</div>
          <div className={s.description}>
            Cette information vous intéresse et vous avez 5 minute devant vous ?
          </div>
        </div>
        <div className={s.button}>
          <Button className={s.btn} onClick={handleNavigateToServices}>
            Remplir mes informations
            <Icon name='arrow_forward' size={'1.5rem'} color={'#ffffff'} fill={1} />
          </Button>
        </div>
      </>}
      <ModalHighConsumptionAlert showModalHighConsumptionAlert={showModalHighConsumptionAlert} handleClose={handleClose}/>
    </div>
  );
}

export default CardHighConsumptionAlert;
