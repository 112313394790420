import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import s from './ModalUpdateRoom.module.scss'
import { Button, Form, FormGroup, Input, Label } from "reactstrap"
import Modal from '../../../../../../components/Modal/Modal'
import { selectActiveRoom } from '../../../../../../selectors/selectNavigation'
import { updateRoom } from '../../../../../../features/rooms/roomsActions'
import DualListBoxUpdateDevice from '../DualListBoxUpdateDevice/DualListBoxUpdateDevice'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Icon from '../../../../../../components/Icon/Icon'

function ModalUpdateRoom({ showModalUpdateRoom, handleClose }) {

  const dispatch = useDispatch()

  const room = useSelector(selectActiveRoom, shallowEqual)

  const [data, setData] = useState({
    building_id: '',
    room_id: '',
    name: '',
    settings: {
      surface: '',
      floor_number: ''
    },
    devices: []
  })

  useEffect(() => {
    if (showModalUpdateRoom) {
      setData(room)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [room])

  const handleFormChange = (e) => {
    const { name, value } = e.target
    switch(name) {
      case 'name':
        setData(prevData => ({
          ...prevData,
          [name]: value 
        }))
        break;
      case 'surface':
      case 'floor_number':
        setData(prevData => ({
          ...prevData, 
          settings: {
            ...prevData.settings,
            [name]: e.target.value
          }
        }))
        break;
      default:
        break;
    }
  }

  const handleSetLinkedDevices = (devices) => {
    setData(prevData => ({
      ...prevData,
      devices: devices
    }));
  };

  const handleUpdateRoom = () => {
    const updatedData = {
      ...data,
      devices: data?.devices?.map(device => device?.device_id)
    };
    dispatch(updateRoom({ data: updatedData }))
    toast.success("Espace modifié !")
    handleClose()
  }

  return (
    <Modal show={showModalUpdateRoom}>

      <div className={s.header}>
        <div className={s.title}>Modifier un espace</div>
        <Icon name="close" size={'1.5rem'} color={'#30465E'} fill={0} onClick={handleClose} cursor/>
      </div>
        
      <Form className={s.form}>
        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Nom</Label>
          <Input className={s.input} type="text" name="name" onChange={handleFormChange} value={data?.name}/>
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Surface (m²)</Label>
          <Input className={s.input} type="text" name="surface" onChange={handleFormChange} placeholder={"Ex: 150"} value={data?.settings?.surface}/>          
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Etage</Label>
          <Input className={s.input} type="text" name="floor_number" onChange={handleFormChange} placeholder={"Ex: 1"} value={data?.settings?.floor_number}/>          
        </FormGroup>

        <div className={s.message}>
          Transférez ici vos équipements non-affectés à cet espace. 
          Pour plus de détails sur vos équipements, allez dans le module “Équipement”.
        </div>

        <div className={s.dualListBox}>
          <DualListBoxUpdateDevice handleSetLinkedDevices={handleSetLinkedDevices}/>
        </div>

      </Form>


      <div className={s.control}>
        <Button color="primary" onClick={handleUpdateRoom} disabled={data?.name === ''}>Valider</Button> 
      </div>
      
    </Modal>
  )
}

export default ModalUpdateRoom
