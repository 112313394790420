import React from 'react';
import s from './CardCurrentCO2.module.scss';
import Icon from '../../../Icon/Icon';
import { shallowEqual, useSelector } from 'react-redux';
import { selectActiveRoom } from '../../../../selectors/selectNavigation';

function CardCurrentCO2() {
  const activeRoom = useSelector(selectActiveRoom, shallowEqual);

  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.items}>
          <Icon name={'aq_indoor'} color={'#30465E'} size={'2.5rem'} />
          <div className={s.measure}>880 ppm</div>
        </div>
        <div className={s.description}>
          {activeRoom === null ? 'Taux de CO2 actuel en moyenne sur le bâtiment' : 'Taux de CO2 actuel'}
        </div>
      </div>
    </div>
  );
}

export default CardCurrentCO2;
