import React from 'react';
import s from './HeaderDevices.module.scss';
import ControlSelectBuilding from '../../Control/ControlSelectBuilding/ControlSelectBuilding'
import NavigationDevices from '../../Navigation/NavigationDevices/NavigationDevices';
import { Button } from 'reactstrap';
import Icon from '../../Icon/Icon';

function HeaderDevices() {
  
  return (
    <div className={s.header}>
      
      <div className={s.selectBuilding}>
        <ControlSelectBuilding/>  
      </div>
      
      <div className={s.addDevice}>
        <Button className={s.btn}>
          <Icon name="add" size={'1.5rem'} color={'#ffffff'} fill={1} cursor/>
          <span className={s.item}>Ajouter un équipement</span>
        </Button>
      </div>

      <div className={s.navigation}>
        <NavigationDevices/>  
      </div>
      
    </div>
  );
}

export default HeaderDevices;
