import React from 'react'
import { useSelector } from "react-redux"
import './customResponsive.css';
import { selectActiveConsoUnit } from '../../../selectors/selectNavigation';
import { Bar } from 'react-chartjs-2';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend} from 'chart.js';
import moment from 'moment'
import { labelsModel, ticksLabel, externalTooltipHandler, titleTooltip, labelTooltip, footerTooltip } from '../../../utils/functions/chartEnergyHandler'

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);
moment.locale('fr')

function ChartEnergy({ dataSets, category, step, periodUnit }) {

  const consoUnit = useSelector(selectActiveConsoUnit)

  // Calcul de la valeur maximale de la température et définition de la marge
  const tempDataset = dataSets?.datasets?.find(ds => ds.label === 'T°C Ext.');
  const tempValues = tempDataset ? tempDataset?.data?.filter(val => val !== null) : [];
  const maxTemp = tempValues?.length ? Math.max(...tempValues) : 0;
  const margin = (Math.ceil(maxTemp) - maxTemp) < 0.5 ? 2 : 1;
  const yAxis2Max = Math.ceil(maxTemp) + margin;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    scales: {
      x: {
        type: 'category',
        labels: labelsModel(periodUnit, category),
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: ticksLabel(periodUnit, category, step),
          color: function(context) {
            const tickIndex = context.index;
            const dateStr = category[tickIndex];
            // On utilise moment pour déterminer le jour de la semaine.
            // Attention : assurez-vous que dateStr est un format reconnu (ex. "YYYY-MM-DD")
            const mDate = moment(dateStr);
            // Si on est en week ou month, la catégorie est une date complète
            if (periodUnit === 'week' || periodUnit === 'month') {
              // En moment, 0 = dimanche et 6 = samedi
              if (mDate.day() === 0 || mDate.day() === 6) {
                return '#30465E'; // Couleur pour samedi et dimanche (par exemple, rouge)
              }
            }
            return '#30465EA0'; // Couleur par défaut pour les jours de semaine (par exemple, noir)
          } 
        }
      },
      y: {
        stacked: true,
        title: 'Conso'
      },
      'y-axis-2': {
        type: 'linear',
        position: 'right',
        grid: { drawOnChartArea: false },
        max: yAxis2Max,  
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        enabled: false,
        external: externalTooltipHandler(consoUnit, periodUnit, category),
        callbacks: {
          title: titleTooltip(periodUnit, category, step),
          label: labelTooltip(consoUnit),
          footer: footerTooltip(consoUnit)
        }
      }
    },
    interaction: {
      mode: 'index',
      intersect: false
    }
  }
  
  return (
    <div className={'chart-container'}>
      <Bar data={dataSets} options={options}/>
    </div>
  )
}

export default ChartEnergy