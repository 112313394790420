import React, { useState, useEffect } from 'react'
import s from './Step03CardWaitingForPairing.module.scss'
import { Button } from 'reactstrap'
import Loader from '../../../../../../../../components/Loader/Loader'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectPairingError, selectPairingStatus, selectSerialNumber } from '../../../../../../../../selectors/selectPairing'
import { createDevice } from '../../../../../../../../features/devices/devicesActions'
import { getPairingMessage } from '../../../../../../../../features/pairing/pairingActions'
import { initPairing } from '../../../../../../../../features/pairing/pairingSlice'
import { selectActiveBuilding } from '../../../../../../../../selectors/selectNavigation'

function Step03CardWaitingForPairing({ discard, inc, dec }) {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id

  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building.building_id

  const pairingStatus = useSelector(selectPairingStatus)
  const pairingError = useSelector(selectPairingError)
  const serial_number = useSelector(selectSerialNumber)
  const [internalError, SetInternalError] = useState(false)

  useEffect(() => {
    if (serial_number !== null) {
      dispatch(getPairingMessage({ user_id, serial_number })) 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[serial_number])

  useEffect(() => {
    if (pairingStatus === 'success' ) {
      dispatch(createDevice({ user_id, building_id, serial_number }))
      .unwrap()
      .then(() => inc()) 
    }
    if (pairingStatus === 'error' ) {
      SetInternalError(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pairingStatus])

  const onDec = () => {
    dispatch(initPairing())
    dec()
  }

  return (
    <div className={s.root}>
      
      <div className={s.content}>
        <p>Veuillez faire un appui bref sur le bouton bleu de votre capteur</p>
        <small>Attention ! Un appui trop long va réinitialiser la configuration Wifi</small>
        {!pairingError && !internalError && <Loader/>}
        {pairingError && <p>Error Timeout</p>}
        {internalError && <p>Une erreur interne est survenue</p>}
      </div>

      <div className={s.control}>
        <Button color="primary" onClick={onDec} disabled={false}>Précédent</Button>
        <Button color="primary" onClick={discard}>Annuler</Button>
      </div>

    </div>
  )
}

export default Step03CardWaitingForPairing