import axios from 'axios';

const baseURLApi = process.env.REACT_APP_BASE_URL_API;

console.log('BASE URL API', baseURLApi, process.env.REACT_APP_BASE_URL_API)

const config = {
  baseURLApi,  // Base URL for your default backend (NodeJS)
  auth: {
    email: 'contact@insensia.com',
    password: 'azerty',
  },
};

// Création des instances axios
const defaultAxiosInstance = axios.create({
  baseURL: config.baseURLApi,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Ajouter le token si disponible
const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.token;
if (token) {
  defaultAxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}


const handleExpiredToken = (error) => {
  const status = error.response?.status;
  if (status === 401) {
    localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE);
    window.location.href = '/login';
  }
  return Promise.reject(error);
};

[defaultAxiosInstance].forEach((instance) => {
  instance.interceptors.response.use(
    (response) => response,
    (error) => handleExpiredToken(error)
  );
});

export { defaultAxiosInstance, config };