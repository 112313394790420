import { defaultAxiosInstance } from '../../axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

export const getProfile = createAsyncThunk(
  'user/get_profile',
  async ({ user_id }, { rejectWithValue }) => {
    try {
      const response = await defaultAxiosInstance.get(`/users/${user_id}`)
      const profile = response?.data
      return { profile }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    } 
  }
)

export const updateProfile = createAsyncThunk(
  'user/update_profile',
  async ({  user_id, data }, { rejectWithValue }) => {
    try {
      await defaultAxiosInstance.put(`/users/profil/${user_id}`, data)
      toast.success('Votre profil a été modifié avec succès')
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    } 
  }
)

export const deleteUser = createAsyncThunk(
  'user/delete_profile',
  async ({ user_id }, { rejectWithValue }) => {
    try {
      await defaultAxiosInstance.delete(`/users/${user_id}`)
      toast.success('Votre compte a été supprimé, nous espérons vous revoir bientôt')
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    } 
  }
)

export const updateAvatar = createAsyncThunk(
  'user/update_avatar',
  async ({ user_id, data, file, filename }, { rejectWithValue }) => {
    try {
      await defaultAxiosInstance.put(`/users/avatar/${user_id}`, data)
      if (file) {
        const path = 'users/avatar/'
        const formData = new FormData();
        formData.append('file', file);
        formData.append('filename', filename);
        formData.append('path', path);
        defaultAxiosInstance.post(`/file/upload/${path}`, formData, { headers: {'Content-Type': 'multipart/form-data'}});   
      }
      toast.success('Votre photo de profil a été modifié avec succès')
      setTimeout(() => {
        return data
      }, 1000)
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteAvatar = createAsyncThunk(
  'user/delete_avatar',
  async ({ user_id }, { rejectWithValue }) => {
    try {
      const headers = { user_id }
      defaultAxiosInstance.delete(`/file/delete/avatar`, {headers})
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
