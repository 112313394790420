import { createAsyncThunk } from '@reduxjs/toolkit'

export const getAddresses = createAsyncThunk(
  'address/get_addresses',
  async ({ queryAddress, maxResults, postcode }) => {
    try {
      const url = new URL('https://api-adresse.data.gouv.fr/search/');
      // url.searchParams.append('q', queryAddress.replace(/ /g, '+'));
      url.searchParams.append('q', queryAddress);
      url.searchParams.append('type', 'housenumber');
      url.searchParams.append('limit', maxResults);
      if (postcode) {
        url.searchParams.append('postcode', postcode);
      }
      const response = await fetch(url)
      const data = await response.json()
      return data.features || []
    } catch (error) {
      console.error('Error fetching addresses:', error);
      return [];
    }
  }
)

export const getCities = createAsyncThunk(
  'address/get_cities',
  async ({ queryCity, maxResults }) => {
    try {
      const url = new URL('https://api-adresse.data.gouv.fr/search/');
      url.searchParams.append('q', queryCity.replace(/ /g, '+'));
      url.searchParams.append('type', 'municipality');
      url.searchParams.append('limit', maxResults);
      const response = await fetch(url);
      const data = await response.json();
      return data.features || [];
    } catch (error) {
      console.error('Error fetching cities:', error);
      return [];
    }
  }
)

