import { useEffect } from 'react';
import html2canvas from 'html2canvas';
import { pdf } from '@react-pdf/renderer';
import PdfConsult from '../components/Reports/pdfConsult/pdfConsult';
import { createReport } from '../features/reports/reportsActions';
import { toast } from 'react-toastify';
import moment from 'moment';

function useCaptureReport({ isCapture, refs, reportData, callbacks, dispatch }) {

  useEffect(() => {
    if (!isCapture || !refs || refs.length === 0) return;

    const captureImage = async (ref) => {
      if (ref?.current) {
        try {
          const canvas = await html2canvas(ref.current, { backgroundColor: null });
          return canvas.toDataURL('image/png');
        } catch (error) {
          console.error("Erreur lors de la capture de l'image:", error);
          return null;
        }
      }
      return null;
    };

    const captureAllImages = async () => {
      try {
        const [mainImage, graphImage, optionalImage] = await Promise.all([
          captureImage(refs[0]),
          captureImage(refs[1]),
          refs[2] ? captureImage(refs[2]) : Promise.resolve(null),
        ]);

        callbacks.handleCaptureComplete();

        if (reportData && mainImage && graphImage) {
          await createPdfAndDispatchReport([mainImage, graphImage, optionalImage]);
          callbacks.handleDiscard();
        } else {
          toast.error("Erreur lors de la capture d'images.");
        }
      } catch (error) {
        console.error('Erreur lors de la capture des images ou la création du PDF:', error);
        toast.error("Erreur lors de la création du rapport.");
        callbacks.handleDiscard();
      }
    };   

    const createPdfAndDispatchReport = async (images) => {
      try {
        const pdfBlob = await pdf(
          <PdfConsult
            reportData={reportData}
            createdAt={moment().locale('fr').format('DD-MM-YYYY')}
            images={images}
            periodUnit={reportData.period_unit}
            period={reportData.period}
          />
        ).toBlob();

        const formData = new FormData();
        formData.append('file', pdfBlob, `${reportData.report_id}.pdf`);
        formData.append('user_id', reportData.user_id);
        formData.append('data', JSON.stringify(reportData));
        
        await dispatch(createReport({ formData }));
        toast.success('Rapport créé !');
      } catch (error) {
        console.error('Erreur lors de la création du PDF et l’envoi du rapport:', error);
        toast.error("Erreur lors de la création du rapport PDF.");
      }
    };

    captureAllImages();
    // Dépendances : attention à bien inclure toutes les dépendances pertinentes.
  }, [isCapture, refs, reportData, callbacks, dispatch]);


}

export default useCaptureReport;
