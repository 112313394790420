import React from 'react'
import s from './Compare.module.scss'
import HeaderCompare from '../../components/Headers/HeaderCompare/HeaderCompare'
import {  Outlet } from 'react-router-dom'

function Compare() {

  return (
    <div className={s.root}>

      <HeaderCompare/>

      <div className={s.content}>
      </div>
    </div>
  )
}

export default Compare
