import React from 'react';
import { useDispatch } from 'react-redux';
import s from './CardLinky.module.scss';
import Icon from '../../../../../../components/Icon/Icon';
import { changeActiveLinky } from '../../../../../../features/navigation/navigationSlice';
import { saveNavigationState } from '../../../../../../features/navigation/navigationActions';
import store from '../../../../../../redux/store';
import moment from 'moment';
import 'moment/locale/fr';

function CardLinky({ sge_authorization, handleShowModal }) {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;
  
  const energyProvider = sge_authorization?.pricing_history[0].energy_provider || '---';
  const contractOffer = sge_authorization?.pricing_history[0].contract_offer || '---';
  const contractType = sge_authorization?.technical_and_contract?.point?.situationContractuelle?.structureTarifaire?.calendrierFrn?.libelle || '---';
  const isBaseTariff = contractType === 'Base';

  const lowTariff = sge_authorization?.pricing_history[0].pricing?.kwh_hc || '---';
  const highTariff = sge_authorization?.pricing_history[0].pricing?.kwh_hp || '---';
  const baseTariff = sge_authorization?.pricing_history[0].pricing?.kwh_base || '---';

  const subscriptionCost = isBaseTariff? sge_authorization?.pricing_history[0].pricing?.subscription_base : sge_authorization?.pricing_history[0].pricing?.subscription_hphc || '---';

  const offPeakHours = sge_authorization?.technical_and_contract?.point?.situationComptage?.dispositifComptage?.relais?.plageHeuresCreuses || '---';

  const effective_at = sge_authorization?.pricing_history[0].effective_at

  const expiresAt = sge_authorization?.expires_at ? moment(sge_authorization.expires_at).format('DD-MM-YYYY') : '---';

  const handleSetting = () => {
    handleShowModal('pricingHistory');
    dispatch(changeActiveLinky(sge_authorization))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
  }

  const handleDelete = () => {
    handleShowModal('delete');
    dispatch(changeActiveLinky(sge_authorization))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
  }

  return (
    <div className={s.root}>
      
      <div className={s.header}>
        <div className={s.title}>Linky n° : {sge_authorization?.point_id || '---'}</div>
        <div className={s.control}>
          <div className={s.delete}>
            <Icon name={'link_off'} size={'1.5rem'} color={'#FF3C3C'} fill={0} onClick={handleDelete} cursor/>
          </div>
          <div className={s.modify}>
            <Icon name={"edit"} size={'1.5rem'} color={'#3192D3'} fill={0} onClick={handleSetting} cursor/>
          </div> 
        </div>
      </div>

      <div className={s.content}>
        <ul className={s.info}>
          <li><span>Fournisseur d'énergie :</span><span>{energyProvider}</span></li>
          <li><span>Offre de contrat :</span><span>{contractOffer}</span></li>
          <li><span>Type de contrat :</span><span>{contractType}</span></li>
          {!isBaseTariff && (<li><span>Plage HC :</span><span>{offPeakHours}</span></li>)}
          <li><span>Tarification effective depuis :</span><span>{moment(effective_at).format('DD/MM/YYYY')}</span></li>
          <li><span>{isBaseTariff ? 'Tarif :' : 'Tarif HC :'}</span><span>{isBaseTariff ? `${baseTariff} €/kwh` : `${lowTariff} €/kwh`}</span></li>
          {!isBaseTariff && (<li><span>Tarif HP :</span><span>{`${highTariff} €/kwh`}</span></li>)}
          <li><span>Tarif abonnement :</span><span>{`${subscriptionCost} €/mois`}</span></li>
        </ul>
      </div>
      <div className={s.footer}>
        <span>ACCÈS DES DONNÉES JUSQU'AU </span>
        <span>{expiresAt.toUpperCase()}</span>
      </div>
    </div>
  );
}

export default CardLinky
