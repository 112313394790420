import React, { useState, useEffect } from 'react'
import s from './Step01CardIntro.module.scss'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'

function Step01CardIntro({ inc, setAuthorizationData, authorizationData }) {

  const [radio, setRadio] = useState(null)

  useEffect(() => {
      if (authorizationData.identity.person === 'natural') {
        setRadio('1')
      }
      if (authorizationData.identity.person === 'legal') {
        setRadio('2')
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const incStep = () => {
    inc()
  }

  const changeCustomerType = (e) => {
    if (e.target.value === '1') {
      setAuthorizationData((prevData) => ({
        ...prevData, 
        identity: {
          ...prevData.identity,
          person: 'natural'
        }
      }))
      setRadio('1')
    }
    if (e.target.value === '2') {
      setAuthorizationData((prevData) => ({
        ...prevData, 
        identity: {
          ...prevData.identity,
          person: 'legal'
        }
      }))
      setRadio('2')    
    }
  }

  return (
    <div className={s.root}>

      <div className={s.content}>
        
        <div className={s.description}>
          <span>Notre partenariat avec ENEDIS nous permet d’analyser vos données à partir de J+1 et de vous proposer des informations personnalisées.</span>
          <span>Nous avons simplement besoin de quelques informations de votre part pour pouvoir commencer nos calculs.</span>
          <span>Vous aurez besoin des informations liées à votre contrat de fourniture d’électricité et de 3 minutes de votre temps.</span>
        </div>
        
        <Form className={s.form}>
          <FormGroup className={s.formGroup} tag="fieldset" onChange={changeCustomerType}>
            <Label className={s.label}>En tant que titulaire du contrat, vous êtes : </Label>
            <FormGroup className={s.checkBox}>
              <FormGroup check>
                <Input className={s.checkbox} type='radio' name="radio1" value={1} defaultChecked={authorizationData?.identity?.person === 'natural'}/>{' '}
                <Label check className={s.labelRadio}>Un particulier titulaire du contrat</Label>  
              </FormGroup>
              <FormGroup check>
                <Input className={s.checkbox} type='radio' name="radio1" value={2} defaultChecked={authorizationData?.identity?.person === 'legal'}/>{' '}
                <Label check className={s.labelRadio}>Un professionnel ou une collectivité titulaire du contrat</Label>  
              </FormGroup>
            </FormGroup>
          </FormGroup>
        </Form>

      </div>

      <div className={s.control}>
        <Button color="primary" onClick={incStep} disabled={radio === null}>Suivant</Button> 
      </div>

    </div>
  )
}

export default Step01CardIntro
