import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import s from './Account.module.scss'
import { selectProfile } from '../../selectors/selectUser'
import { getProfile } from '../../features/user/userActions'
import { useNavigate } from 'react-router-dom'
import { resetState } from '../../features/auth/authActions'
import { userLogout } from '../../features/auth/authSlice'
import { selectIsTiersUserConnected } from '../../selectors/selectTiersUser'
import { getTiersUserStatus, connectTiersUser, disconnectTiersUser } from '../../features/tiersUser/tiersUserAction'
import Icon from '../../components/Icon/Icon'
import CardProfile from './components/CardProfile/CardProfile'
import CardTiersAccount from './components/CardTiersAccount/CardTiersAccount'
import CardEnedis from './components/CardEnedis/CardEnedis'
import CardSubscription from './components/CardSubscription/CardSubscription'
import CardCompany from './components/CardCompany/CardCompany'
import CardPreference from './components/CardPreference/CardPreference'
import CardAssociatedAccount from './components/CardAssociatedAccount/CardAssociatedAccount'
import ModalCreateAssociatedAccount from './components/ModalCreateAssociatedAccount/ModalCreateAssociatedAccount'
import HeaderAccount from '../../components/Headers/HeaderAccount/HeaderAccount'

function Account() {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id

  const profile = useSelector(selectProfile, shallowEqual)
  
  const IsNetatmoUserConnected = useSelector((state) => selectIsTiersUserConnected(state, "netatmo"));
  const IsSmartthingsUserConnected = useSelector((state) => selectIsTiersUserConnected(state, "smartthings"));

  const [data, setData] = useState(null)

  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)

  useEffect(() => {
    dispatch(getProfile({ user_id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id])
  
  useEffect(() => { 
    setData(profile)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [profile])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get('code');
    const state = urlParams.get('state');
    
    if (authCode) {
      let api;
      if (state === 'netatmo') {
        api = 'netatmo'
      } else {
        api = 'smartthings'
      }
      dispatch(connectTiersUser({ user_id, data: { authCode, api } }));
      urlParams.delete('code');
      urlParams.delete('state');
      navigate(`?${urlParams.toString()}`, { replace: true });
    } else {
      dispatch(getTiersUserStatus({ user_id, data: { api: "netatmo" }  }));
      dispatch(getTiersUserStatus({ user_id, data: { api: "smartthings" } }));
    }
  }, [dispatch, user_id, navigate, IsNetatmoUserConnected, IsSmartthingsUserConnected]);

  const handleShowModal = (modalType) => {
    switch (modalType) {
      case 'add':
        setShowModalAdd(true);
        break;
      case 'update':

        break;
      case 'delete':

        break;
      default:
        break;
    }
  }

  const handleCloseModal = (modalType) => {
    switch (modalType) {
      case 'add':
        setShowModalAdd(false);
        break;
      case 'update':

        break;
      case 'delete':

        break;
      default:
        break;
    }
  }

  return (
    <div className={s.root}>

      <HeaderAccount/>

      <div className={s.content}>

        <div className={s.cardProfile}>
          <CardProfile/>  
        </div>

        <div className={s.cardAssociatedAccount}>
          <CardAssociatedAccount handleShowModal={handleShowModal}/>
        </div>

        <div className={s.cardTiersAccount}>
          <CardTiersAccount/>  
        </div>

        <div className={s.cardEnedis}>
          <CardEnedis/>
        </div>

        <div className={s.cardCompany}>
          <CardCompany/>
        </div>

        <div className={s.cardPreference}>
          <CardPreference/>
        </div>

        <div className={s.cardSubscription}>
          <CardSubscription/>
        </div>

        <ModalCreateAssociatedAccount showModalAdd={showModalAdd} handleClose={() => handleCloseModal('add')}/>
                   
      </div>
    </div>
  )
}

export default Account
