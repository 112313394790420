import { createSelector } from '@reduxjs/toolkit'

export const selectReports = createSelector(
  (state) => state.reports.listReports,
  (listReports) => listReports
)

export const selectReportsIsLoading = createSelector(
  (state) => state.reports.isLoading,
  (isLoading) => isLoading
)

export const selectListReportBinders = createSelector(
  (state) => state.reports.listBinders,
  (listBinders) => listBinders
)

export const selectReportById = (report_id) => {
  return createSelector(
    (state) => state.reports.listReports,
    (listReports) => {
      if(!report_id) {
        return null
      }
      return listReports.find(report => report.report_id === report_id)
    }
  )
}

export const selectPdfUrl = createSelector(
  (state) => state.reports.pdfUrl,
  (pdfUrl) => pdfUrl
);


