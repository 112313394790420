import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import s from './CardSubscription.module.scss'

function CardSubscription() {

  return (
    <div className={s.root}>
      
      <div className={s.header}>
        <div className={s.title}>Abonnement</div>
      </div>

      <div className={s.content}>
        <ul className={s.cardInfo}>
          <li><span>Abonnement en cours :</span> Abonnement standard</li>
          <li><span>Début de l'abonnement :</span><span>lun. 16 décembre 2024</span></li>
          <li><span>Fin de l'abonnement :</span><span>mar. 16 décembre 2025</span></li>
        </ul>
      </div>

    </div>
  )
}

export default CardSubscription
