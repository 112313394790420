import React from "react";

const ExportMotionCSV = ({ chartData }) => {
    const formatDate = (timestamp) => {
        if (!timestamp) return "";
        return new Date(timestamp).toLocaleString("fr-FR"); // Formats date in French format (DD/MM/YYYY, HH:MM:SS)
      };
    
      const downloadCSV = () => {
        if (!chartData || !chartData.length) return;
    
        let csvContent = "data:text/csv;charset=utf-8,";
    
        // CSV Header
        csvContent += "Sensor,Start Date,End Date\n";
    
        // CSV Rows (Formatted Dates)
        chartData.forEach((sensor) => {
          sensor.data.forEach((event) => {
            const [startTime, endTime] = event.y;
            csvContent += `${sensor.name},${formatDate(startTime)},${formatDate(endTime)}\n`;
          });
        });
    
        // Create downloadable link
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "motion_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

  return (
    <button onClick={downloadCSV} style={{ background: "none", border: "none", cursor: "pointer", marginLeft: "10px" }}>
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
        <polyline points="7 10 12 15 17 10" />
        <line x1="12" y1="15" x2="12" y2="3" />
        </svg>
    </button>
  );
};

export default ExportMotionCSV;