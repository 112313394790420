import React from 'react'
import s from './Step02CardIdentity.module.scss'
import { Form, Button, FormGroup, InputGroup, InputGroupText, Input, Label } from 'reactstrap'

function Step02CardIdentity({ inc, dec, setAuthorizationData, authorizationData }) {

  const personType = authorizationData?.identity?.person
 
  const changeFirstname = (e)  => {
    setAuthorizationData((prevData) => ({
      ...prevData, 
      identity: {
        ...prevData.identity,
        firstname: e.target.value || ''
      }
    }))
  }

  const changeLastname = (e)  => {
    setAuthorizationData((prevData) => ({
      ...prevData, 
      identity: {
        ...prevData.identity,
        lastname: e.target.value || ''
      }
    }))
  }

  const changeEmail = (e)  => {
    setAuthorizationData((prevData) => ({
      ...prevData, 
      contact: {
        ...prevData.contact,
        email: e.target.value || ''
      }
    }))
  }

  const decStep = () => {
    dec()
  }

  const incStep = () => {
    inc()
  }

  return (
    <div className={s.root}>
      
      <div className={s.content}>

        <div className={s.description}>
          {personType === 'natural' && `Veuillez renseigner votre identité figurant sur le contrat de fourniture de d'électricité.`}
          {personType === 'legal' && `Veuillez renseigner votre identité de mandataire pour donner votre autorisation d'accès aux données de consommation d'électricité de l'entreprise.`}
        </div>
        
        <Form className={s.form}>
          <FormGroup className={s.formGroup}> 
            <Label className={s.label}>Prénom</Label>
            <Input className={s.input} type="text" name="firstname" onChange={changeFirstname} placeholder={"Votre prénom"} value={authorizationData?.identity?.firstname}/>              
          </FormGroup>

          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Nom</Label>
            <Input className={s.input} type="text" name="lastname" onChange={changeLastname} placeholder={"Votre nom"} value={authorizationData?.identity?.lastname}/>
          </FormGroup>

          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Email</Label>
            <Input className={s.input} type="mail" name="email" onChange={changeEmail} placeholder={"Votre email"} value={authorizationData?.contact?.email}/>
          </FormGroup>
        </Form>

      </div>

      <div className={s.control}>
        <Button color="primary" onClick={() => decStep()}>Précédent</Button> 
        <Button color="primary" onClick={() => incStep()} disabled={authorizationData?.identity?.firstname === '' || authorizationData?.identity?.lastname === '' || authorizationData?.contact?.email === ''}>Suivant</Button>
      </div>
      
    </div>
  )
}

export default Step02CardIdentity
