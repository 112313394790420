import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import s from './Rooms.module.scss'
import { Button } from 'reactstrap'
import Icon from '../../../../components/Icon/Icon'
import CardRoom from './components/CardRoom/CardRoom'
import ModalCreateRoom from './components/ModalCreateRoom/ModalCreateRoom'
import ModalUpdateRoom from './components/ModalUpdateRoom/ModalUpdateRoom'
import ModalDeleteRoom from './components/ModalDeleteRoom/ModalDeleteRoom'
import { initActiveRoom } from '../../../../features/navigation/navigationSlice'
import { selectRooms } from '../../../../selectors/selectBuildings'
import { saveNavigationState } from '../../../../features/navigation/navigationActions'
import { getDevices } from '../../../../features/devices/devicesActions'
import { getBuildings } from '../../../../features/buildings/buildingsActions'
import { selectActiveBuilding } from '../../../../selectors/selectNavigation'
import store from '../../../../redux/store';

function Rooms() {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id

  const rooms = useSelector(selectRooms(building_id), shallowEqual)

  const [showModalAddRoom, setShowModalAddRoom] = useState(false)
  const [showModalUpdateRoom, setShowModalUpdateRoom] = useState(false)
  const [showModalDeleteRoom, setShowModalDeleteRoom] = useState(false)

  const handleShowModal = (modalType) => {
    switch (modalType) {
      case 'add':
        setShowModalAddRoom(true);
        break;
      case 'update':
        setShowModalUpdateRoom(true);
        break;
      case 'delete':
        setShowModalDeleteRoom(true);
        break;
      default:
        break;
    }
  }

  const handleCloseModal = (modalType) => {
    switch (modalType) {
      case 'add':
        setShowModalAddRoom(false);
        break;
      case 'update':
        setShowModalUpdateRoom(false);
        break;
      case 'delete':
        setShowModalDeleteRoom(false);
        break;
      default:
        break;
    }
    dispatch(initActiveRoom())
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
    .then(() => dispatch(getDevices({ user_id })))
    .then(() => dispatch(getBuildings({ user_id }))) 
  }

  return (
    <div className={s.root}>

      <div className={s.header}>
        <div className={s.title}>Mes espaces</div>
        <Button color="primary" onClick={() => handleShowModal('add')}>
          <Icon name="add" size={'1.5rem'} color={'#FFF'} fill={1}/>
          <span className={s.item}>Ajouter un espace</span>
        </Button>
      </div>

      {!rooms &&
      <div className={s.message}>
        <div>Aucun espace !</div>
        <div>Veuillez ajouter un espace.</div>
      </div>}

      <div className={s.content}>

        {rooms?.map((room, index) => (
          <CardRoom key={index} room={room} handleShowModal={handleShowModal}/>
        ))}
        
        {building_id !== '' && <ModalCreateRoom showModalAddRoom={showModalAddRoom} handleClose={() => handleCloseModal('add')}/>}
        {building_id !== '' && <ModalUpdateRoom showModalUpdateRoom={showModalUpdateRoom} handleClose={() => handleCloseModal('update')}/>}
        {building_id !== '' && <ModalDeleteRoom showModalDeleteRoom={showModalDeleteRoom} handleClose={() => handleCloseModal('delete')}/>}
      </div>
    </div>
  )
}

export default Rooms
