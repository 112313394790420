import React from 'react'
import classNames from 'classnames/bind'
import s from './StepBarV1.module.scss'

const cx = classNames.bind(s);

function StepBarV1({step, maxStep}) {  

  const drawStepLine = (step) => {
    const divs = []
    for (let i = 1; i < maxStep; i++) {
      divs.push(
      <div key={i} className={s.currentProgress}>
        <span className={s.line}></span>
        <div className={cx(s.circle, { [s.active]: i===step, [s.pass]: i<step })}><span>{i}</span></div>
      </div>)
    }
    return divs
  }


  return (
    <div className={s.progressStep}>
      {drawStepLine(step)}
    </div>
  )
}

export default StepBarV1
