import { defaultAxiosInstance } from '../../axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment'
moment.locale('fr')

const calculateSums = (data, periodUnit) => {
  let sumSubscriptionHPHC = 0;
  let sumSubscriptionBase = 0;
  let sumConsumption = 0;
  let sumCarbonEquivalent = 0;

  let precision
  switch (periodUnit) {
    case "day":
    case "week":
    case "month":
      precision = 2
      break
    case "year":
      precision = 0
      break
    default:
      precision = 2
      break
  }

  data.transformedData.forEach(item => {
      const normData = item;
      if (normData) {
        sumSubscriptionHPHC += normData.pricingHP + normData.pricingHC + normData.subscriptionHpHc;
        sumSubscriptionBase += normData.pricingBase + normData.subscriptionBase;
        sumConsumption += normData.kwhBase;
        sumCarbonEquivalent += normData.equivalentCO2;
      }
  });

  return {
      sumSubscriptionHPHC: sumSubscriptionHPHC.toFixed(precision),
      sumSubscriptionBase: sumSubscriptionBase.toFixed(precision),
      sumConsumption: sumConsumption.toFixed(precision),
      sumCarbonEquivalent: sumCarbonEquivalent.toFixed(precision)
  };
}

export const searchPoints = createAsyncThunk(
  'sge/search_point',
  async ({ street, zipcode, inseecode, lastname }, { rejectWithValue }) => {
    try {
      const params = { street, zipcode, inseecode, lastname }
      const response = await defaultAxiosInstance.get(`/sge/search_point`, { params })
      return response.data
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const createAuthorization = createAsyncThunk(
  'sge/createAuthorization',
  async ({ authorizationData }, { rejectWithValue }) => {
    try {
      await defaultAxiosInstance.post(`/sge/create_authorization`, { authorizationData });
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const checkExistingPoint = createAsyncThunk(
  'sge/checkExistingPoint',
  async ({ user_id, point_id }, { rejectWithValue }) => {
    try {
      const params = { user_id, point_id }
      const response = await defaultAxiosInstance.get(`/sge/check_existing_point`, { params })
      const sge_authorization_id = response.data.sge_authorization_id || null
      if (sge_authorization_id)
        return true
      else {
        return false
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteAuthorization = createAsyncThunk(
  'sge/deleteAuthorization',
  async ({ sge_authorization_id }, { rejectWithValue }) => {
    try {
      await defaultAxiosInstance.delete(`/sge/${sge_authorization_id}`)    
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getPricingHistory = createAsyncThunk(
  'sge/getPricingHistory',
  async ({ sge_authorization_id }, { rejectWithValue }) => {
    try { 
      const params = { sge_authorization_id } 
      const pricingHistory = await defaultAxiosInstance.get(`/sge/pricing_history`, { params })
      return { pricingHistory }    
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateContract = createAsyncThunk(
  'sge/update_contract',
  async ({ data }, { rejectWithValue }) => {
    try {  
      const sge_authorization_id = data.sge_authorization_id
      const building_id = data.building_id
      await defaultAxiosInstance.put(`/sge/contract/${sge_authorization_id}`, { data })
      return { building_id, sge_authorization_id, data }    
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getTechnicalContractData = createAsyncThunk(
  'sge/get_technical_contract_data',
  async ({ user_id, point_id }, { rejectWithValue }) => {
    try {
      const params = { user_id, point_id }
      const response = await defaultAxiosInstance.get(`/sge/technical_contract_data/`, { params })
      return response.data
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getLoadCurveData = createAsyncThunk(
  'sge/get_load_curve',
  async ({ sge_authorization_id, point_id, periodUnit, period }, { rejectWithValue }) => {
    try {
      const params = { sge_authorization_id, point_id, periodUnit, period }
      const response = await defaultAxiosInstance.get(`/sge/load_curve/`, { params })
      const data = response.data
      const aggregateData = calculateSums(response.data, periodUnit)
      const transformedData = data.transformedData
      const contractType = data.contractType
      return {sge_authorization_id, point_id, periodUnit, transformedData, contractType, aggregateData }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error)
      }
    }
  }
)

