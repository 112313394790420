import React, { useState } from 'react'
import s from './Step05CardDeviceInfo.module.scss'
import { Button, FormGroup, InputGroup, InputGroupText, Input, Label, Form } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { selectPairingDeviceId } from '../../../../../../../../selectors/selectPairing'
import { updateDevice } from '../../../../../../../../features/devices/devicesActions'

function Step05CardDeviceInfo({ discard, inc, dec}) {

  const dispatch = useDispatch()

  const device_id = useSelector(selectPairingDeviceId)

  const [data, setData] = useState({
    name: '',
    description: ''
  })

  const changeName = (e)  => {
    setData({
      ...data,
      name: e.target.value
    })
  }

  const changeDescription = (e) => {
    setData({
      ...data,
      description: e.target.value
    })
  }

  const onInc = () => {
    dispatch(updateDevice({ device_id, data}))
    inc()
  }

  //RENDER
  return (
    <div className={s.root}>
   
      <div className={s.content}>
        <Form className={s.form}>
          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Nom du capteur</Label>
            <Input className={s.input} type="text" placeholder="Saisissez un nom (35 caractères max.)" onChange={changeName} maxLength={35}/>  
          </FormGroup>

          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Description</Label>
            <Input className={s.input} type="text" placeholder="Saisissez votre description" onChange={changeDescription} maxLength={50}/>   
          </FormGroup>
        </Form>
      </div>

      <div className={s.control}>
        <Button color="primary" onClick={discard}>Annuler</Button>
        <Button color="primary" onClick={onInc} disabled={false}>Suivant</Button>
      </div>
    </div>
  )
}

export default Step05CardDeviceInfo