import React, { useRef, useState, useCallback } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import s from './AirQuality.module.scss'
import { selectActiveBuilding, selectActiveRoom, selectActivePeriodUnit, selectPeriod, selectActiveSubRoute } from '../../../../selectors/selectNavigation'
import { selectDevicesByBuildingAndRoom } from '../../../../selectors/selectDevices'
import { selectRooms } from '../../../../selectors/selectBuildings'
import Icon from '../../../../components/Icon/Icon'
import { Button } from 'reactstrap'
import { v4 as uuid } from 'uuid';
import CardChartCO2 from '../../../../components/CardChartCO2/CardChartCO2'
import useCaptureReport from '../../../../hooks/useCaptureReport';
import Popup from '../../../../components/Popup/Popup';
import moment from 'moment';
import CardCurrentCO2 from '../../../../components/Indicators/AirQuality/CardCurrentCO2/CardCurrentCO2'
moment.locale('fr');

function AirQuality() {

  const dispatch = useDispatch();
  
  const cardChartCO2Ref = useRef(null)
  const cardStatSummaryRef= useRef(null)

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user.user_id;
  
  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id
  const rooms =  useSelector(selectRooms(building_id, shallowEqual))
  const room = useSelector(selectActiveRoom, shallowEqual)
  const activeSubRoute = useSelector(selectActiveSubRoute)
  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual)

  const [showModalCreateReport, setShowModalCreateReport] = useState(false);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  const currentPeriod = useCallback(() => {
    if (!period) return '';
    switch (periodUnit) {
      case 'day':
        return `du ${moment(period.start).format('dddd DD MMM YYYY')}`;
      case 'week':
        return `du ${moment(period.start).format('DD-MM-YYYY')} au ${moment(period.end).format('DD-MM-YYYY')}`;
      case 'month':
        return `du mois de ${moment(period.start)
          .format('MMMM YYYY')
          .replace(/(^|\s)\S/g, l => l.toUpperCase())}`;
      case 'year':
        return `du ${moment(period.start).format('DD-MM-YYYY')} au ${moment(period.end).format('DD-MM-YYYY')}`;
      case 'free':
        return `du ${moment(period.start).format('DD-MM-YYYY')} au ${moment(period.end).format('DD-MM-YYYY')}`;
      default:
        return '';
    }
  }, [period, periodUnit]);

  const reportId = uuid();
  const reportData = {
    report_id: reportId,
    user_id,
    building,
    rooms,
    room,
    universe: activeSubRoute,
    period: period,
    period_unit: periodUnit
  };

  const handleDiscard = useCallback(() => {
    setShowModalCreateReport(false);
    setIsGeneratingReport(false);
  }, []);

  const handleCaptureComplete = useCallback(() => {
    setIsGeneratingReport(false);
  }, []);

  const handleCreateReport = useCallback(() => {
    setIsGeneratingReport(true);
  }, []);

  useCaptureReport({
    isCapture: isGeneratingReport,
    refs: [cardStatSummaryRef, cardChartCO2Ref,null],
    reportData,
    callbacks: { handleDiscard, handleCaptureComplete },
    dispatch,
  });

  const noSelectedBuilding = () => {
    return (
      <div className={s.noBuilding}>
        Vous n'avez pas sélectionné de bâtiment
      </div>
    )
  }

  return (
    <div className={s.root}>

      {building && 
      <div className={s.header}>
        <div className={s.title}>
          <span className={s.universe}>Qualité d'air</span>
          <span className={s.building}>{building?.name}</span>
          <span className={s.period}>{currentPeriod()}</span>
        </div>
        <div className={s.control}>
          <Button color="primary" onClick={() => setShowModalCreateReport(true)}>
            <Icon name={'post_add'} size={'1.5rem'} color={'#FFFFFF'} fill={1}/>
            <span className={s.label}>Créer un rapport</span>
          </Button>
        </div>
      </div>}
      
      <div className={s.content}>        
     
        {building &&
        <div className={s.cardChart} ref={cardChartCO2Ref}>
          <CardChartCO2/>
        </div>}

        {building &&
        <div className={s.cardStatSummary} ref={cardStatSummaryRef}>
          {/* <CardCO2 params={{id: "now:co2;min:co2;mean:co2;max:co2", building_id: building_id, room_id: room_id, devices: devices, periodStart: start, periodEnd: end, periodUnit: periodUnit}}/> */}
          <CardCurrentCO2 />
        </div>}

        {!building && noSelectedBuilding()}

      </div>
 
      <Popup title={"création d'un rapport"} show={showModalCreateReport} onDiscard={() => setShowModalCreateReport(false)} onAccept={handleCreateReport}>
        Êtes-vous sûr de vouloir créer ce rapport ?
      </Popup>

    </div>
  )
}

export default AirQuality

