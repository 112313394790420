import React, { useState, useEffect, useRef } from 'react'
import { shallowEqual, useSelector } from "react-redux"
import { Button } from "reactstrap"
import s from './DualListBoxUpdateDevice.module.scss'
import { selectDevicesLinkedByBuilding, selectDevicesLinkedByRoom, selectDevicesUnlinked, selectDevicesUnlinkedRoom } from '../../../../../../selectors/selectDevices'
import Icon from '../../../../../../components/Icon/Icon'
import { selectActiveBuilding, selectActiveRoom } from '../../../../../../selectors/selectNavigation'

function DualListBoxUpdateDevice({ handleSetLinkedDevices }) {

  const listUnlinkedRef = useRef(null)
  const listLinkedRef = useRef(null)

  const building = useSelector(selectActiveBuilding,shallowEqual)
  const building_id = building?.building_id

  const room = useSelector(selectActiveRoom,shallowEqual)
  const room_id = room?.room_id

  const devicesUnlinked = useSelector(selectDevicesUnlinkedRoom(building_id), shallowEqual)
  const devicesLinkedByRoom = useSelector(selectDevicesLinkedByRoom(building_id, room_id), shallowEqual)
   
  const [listUnlinkedDevices, setListUnlinkedDevices] = useState([])
  const [listLinkedDevices, setListLinkedDevices] = useState([])

  const [selectedForLinking, setSelectedForLinking] = useState([])
  const [selectedForUnlinking, setSelectedForUnlinking] = useState([])

  const [disabledAdd, setDisabledAdd] = useState(true)
  const [disabledRemove, setDisabledRemove] = useState(true)
  const [errorMaxSelected, setErrorMaxSelected] = useState(false)

  useEffect(() => { 
    setListUnlinkedDevices(devicesUnlinked)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [devicesUnlinked])

  useEffect(() => { 
    setListLinkedDevices(devicesLinkedByRoom)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [devicesLinkedByRoom])
  
  const onAdd = () => { 
    let newUnlinkedArray = listUnlinkedDevices.slice()
    let newLinkedArray = listLinkedDevices.slice()
    for (let i = 0; i < selectedForLinking.length; i++) {
      newLinkedArray.push(selectedForLinking[i]) 
    }
    newLinkedArray.sort((a,b) => {
      let a_created_at = new Date(a.created_at).getTime()
      let b_created_at = new Date(b.created_at).getTime()
      return a_created_at - b_created_at}
    )
    setListLinkedDevices(newLinkedArray)
    handleSetLinkedDevices(newLinkedArray)

    for (let i = 0; i < selectedForLinking.length; i++) {
      const index = newUnlinkedArray.findIndex((item) => item.name === selectedForLinking[i].name)
      newUnlinkedArray.splice(index, 1)
    }
    newUnlinkedArray.sort((a,b) => {
      let a_created_at = new Date(a.created_at).getTime()
      let b_created_at = new Date(b.created_at).getTime()
      return a_created_at - b_created_at}
    )
    setListUnlinkedDevices(newUnlinkedArray)
    listUnlinkedRef.current.selectedIndex= -1
    setErrorMaxSelected(false)
    setDisabledAdd(true) 
  }

  const onRemove = () => { 

    let newUnlinkedArray = listUnlinkedDevices.slice()
    let newLinkedArray = listLinkedDevices.slice()
    setErrorMaxSelected(false)
    for (let i = 0; i < selectedForUnlinking.length; i++) {
      newUnlinkedArray.push(selectedForUnlinking[i])
    }
    newUnlinkedArray.sort((a,b) => {
      let a_created_at = new Date(a.created_at).getTime()
      let b_created_at = new Date(b.created_at).getTime()
      return a_created_at - b_created_at}
    )
    setListUnlinkedDevices(newUnlinkedArray)

    for (let i = 0; i < selectedForUnlinking.length; i++) {
      const index = newLinkedArray.findIndex((item) => item.name === selectedForUnlinking[i].name)
      newLinkedArray.splice(index, 1)
    }
    newLinkedArray.sort((a,b) => {
      let a_created_at = new Date(a.created_at).getTime()
      let b_created_at = new Date(b.created_at).getTime()
      return a_created_at - b_created_at}
    )
    setListLinkedDevices(newLinkedArray)
    handleSetLinkedDevices(newLinkedArray)
    listLinkedRef.current.selectedIndex= -1
    setDisabledRemove(true)
  }

  const handleClickListUnlinked = (e) => { 
    const array_temp = []
    if (e.target.selectedOptions.length > 0) {
      setDisabledAdd(false)  
      setDisabledRemove(true)  
    }
    for (let i = 0; i < e.target.selectedOptions.length; i++) {
      array_temp.push(listUnlinkedDevices.filter(device => device.name === e.target.selectedOptions[i].label)[0])
    }
    setSelectedForLinking(array_temp)
    listLinkedRef.current.selectedIndex= -1
  }

  const handleClickListLinked = (e) => { 
    const array_temp = []
    if (e.target.selectedOptions.length > 0) {
      setDisabledAdd(true)  
      setDisabledRemove(false)  
    }
    for (let i = 0; i < e.target.selectedOptions.length; i++) {
      array_temp.push(listLinkedDevices.filter(device => device.name === e.target.selectedOptions[i].label)[0])
    }
    setSelectedForUnlinking(array_temp)
    listUnlinkedRef.current.selectedIndex= -1
  }

  return (
    <div className={s.root}>
      <div className={s.dual_list_box}>
        <div className={s.list_option}>
          <label className={s.label}>Equipements disponibles</label>
          <select className={s.input} id="list_option" ref={listUnlinkedRef} name="select" type="select" multiple onChange={handleClickListUnlinked}>
            {listUnlinkedDevices?.map((device, index) => (
            <option className={s.option} key={index} value={device}>{device.name}</option>
            ))}
          </select>
        </div>

        <div className={s.control_btn}>
          <Button color="primary" onClick={disabledRemove === true && disabledAdd == false ? onAdd : onRemove} disabled={disabledAdd === true && disabledRemove === true}>
            {disabledRemove === true && disabledAdd === false && <Icon name="arrow_forward" size={'1.2rem'} color={'#3192D3'} fill={1} cursor/>}
            {disabledRemove === false && disabledAdd === true && <Icon name="arrow_back" size={'1.2rem'} color={'#3192D3'} fill={1} cursor/>}
            {disabledAdd === true && disabledRemove === true && <Icon name="compare_arrows" size={'1.5rem'} color={'#3192D3'} fill={1} cursor/>}
          </Button>
        </div>

        <div className={s.list_selected}>
          <label className={s.label}>Equipements affectés</label>
          <select className={s.input} id="list_selected" ref={listLinkedRef} name="select" type="select" multiple onChange={handleClickListLinked}>
            {listLinkedDevices?.map((device, index) => (
            <option className={s.option} key={index} value={device}>{device.name}</option>
            ))}
          </select>
        </div>
      </div>
      
      {errorMaxSelected &&
      <div className={s.error_box}>
        <small className={s.error_max}>Pas plus de 5 capteurs par groupe</small>
      </div>}
      
      
    </div>
  )
}

export default DualListBoxUpdateDevice
