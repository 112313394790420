import { defaultAxiosInstance } from '../../axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';

const structureDataBySerialNumber = (data) => {
  const structuredData = {};

  data.forEach((item) => {
    const device_id = item.device_id;
    const measurementType = item._measurement;
    if (!structuredData[device_id]) {
      structuredData[device_id] = {};
    }
    if (!structuredData[device_id][measurementType]) {
      structuredData[device_id][measurementType] = [];
    }
    structuredData[device_id][measurementType].push({
      time: item._time,
      value: item._value,
    });
  });

  Object.keys(structuredData).forEach((macAddress) => {
    Object.keys(structuredData[macAddress]).forEach((measurementType) => {
      structuredData[macAddress][measurementType].sort((a, b) => new Date(a.time) - new Date(b.time));
      structuredData[macAddress][measurementType] = structuredData[macAddress][measurementType].filter((item, index, arr) => {
        return index === 0 || new Date(item.time).getTime() !== new Date(arr[index - 1].time).getTime();
      });
    });
  });

  return structuredData;
};

export const getAlgoData = createAsyncThunk(
  'measures/algo_data',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const { algo, measurement, building_id, room_id, devices, periodUnit, period } = parameters;
      const params = {
        algo,
        measurement,
        building_id,
        room_id, 
        devices: devices.join(','),
        device_type: 'insensia',
        periodUnit,
        period
      };
      const response = await defaultAxiosInstance.get(`/measures/algo_data`, { params });
      const data = response.data;
      return { building_id, room_id, devices, measurement, data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getLastSensorData = createAsyncThunk(
  'measures/get_last_sensor_data',
  async ({ building_id, devices_ids, measurement }, { rejectWithValue }) => {
    try {
      const params = {
        building_id, 
        devices_ids: devices_ids.join(','),
        measurement: measurement.join(','),
      };
      const response = await defaultAxiosInstance.get(`/measures/last_sensor_data`, { params });
      const data = response.data;
      return data
      // return { building_id, room_id, devices, measurement, data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getTemperatureByDevices = createAsyncThunk(
  'measures/get_temperature_by_devices',
  async ({ measurement, building_id, room_id, devices, periodUnit, period }, { rejectWithValue }) => {
    try {
      const params = {
        measurement,
        building_id,
        room_id,
        devices: devices.join(','),
        periodUnit,
        period
      }
      const response = await defaultAxiosInstance.get(`/measures/by_devices`, { params })
      const data = structureDataBySerialNumber(response.data)
      return { measurement, data }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getHumidityByDevices = createAsyncThunk(
  'measures/get_humidity_by_devices',
  async ({ measurement, building_id, room_id, devices, periodUnit, period }, { rejectWithValue }) => {
    try {
      const params = {
        measurement,
        building_id,
        room_id,
        devices: devices.join(','),
        periodUnit,
        period
      }
      const response = await defaultAxiosInstance.get(`/measures/by_devices`, { params })
      const data = structureDataBySerialNumber(response.data)
      return { measurement, data }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getCO2ByDevices = createAsyncThunk(
  'measures/get_co2_by_devices',
  async ({ measurement, building_id, room_id, devices, periodUnit, period }, { rejectWithValue }) => {
    try {
      const params = {
        measurement,
        building_id,
        room_id,
        devices: devices.join(','),
        periodUnit,
        period
      }
      const response = await defaultAxiosInstance.get(`/measures/by_devices`, { params })
      const data = structureDataBySerialNumber(response.data)
      return { measurement, data }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getBrightnessByDevices = createAsyncThunk(
  'measures/get_brightness_by_devices',
  async ({ measurement, building_id, room_id, devices, periodUnit, period }, { rejectWithValue }) => {
    try {
      const params = {
        measurement,
        building_id,
        room_id,
        devices: devices.join(','),
        periodUnit,
        period
      }
      const response = await defaultAxiosInstance.get(`/measures/by_devices`, { params })
      const data = structureDataBySerialNumber(response.data)
      return { measurement, data }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getMotionByDevices = createAsyncThunk(
  'measures/get_motion_by_devices',
  async ({ measurement, building_id, room_id, devices, periodUnit, period }, { rejectWithValue }) => {
    try {
      const params = {
        measurement,
        building_id,
        room_id,
        devices: devices.join(','),
        periodUnit,
        period
      }
      const response = await defaultAxiosInstance.get(`/measures/by_devices`, { params })
      const data = structureDataBySerialNumber(response.data)
      return { measurement, data }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
