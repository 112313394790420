import React, { useEffect, useState } from 'react';
import s from './CardConsumptionTrend.module.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectActiveBuilding, selectActivePeriodUnit, selectPeriod } from '../../../../selectors/selectNavigation';
import { selectSgeAuthorization } from '../../../../selectors/selectBuildings';
import Icon from '../../../Icon/Icon';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { changeActiveRoute } from '../../../../features/navigation/navigationSlice';
import { saveNavigationState } from '../../../../features/navigation/navigationActions';
import { selectIndicatorType, selectIsLoading, selectError } from "../../../../selectors/selectIndicator";
import { getConsumptionTrend } from '../../../../features/indicators/indicatorsActions'
import store from '../../../../redux/store';
import moment from 'moment'
moment.locale('fr')

function CardConsumptionTrend({unit}) {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;
  const periodUnit = useSelector(selectActivePeriodUnit);
  const period = useSelector(selectPeriod, shallowEqual)
  
  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id || null
  const point_id = building?.sge_authorizations?.[0]?.point_id
  const counter = useSelector(selectSgeAuthorization(building_id, point_id), shallowEqual)
  const sge_authorization_id = counter?.sge_authorization_id
  
  const [periodTime, setPeriod] = useState(null);
  const [comparedPeriod,setComparedPeriod] = useState(null);
  const [startPeriod, setStartPeriod] = useState(null);
  const [endPeriod,setEndPeriod] = useState(null);
  
  const indicatorData = useSelector((state) => selectIndicatorType(state, "consumption_trend", period));
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);

  const [color, setColor] = useState("#98C648");

  useEffect(() => {
    switch(periodUnit) {
      case 'day':
        setComparedPeriod(`${moment(period.start).subtract(1, 'day').format('DD MMMM YYYY')}`)
        setStartPeriod(`${moment(period.start).format('DD MMMM YYYY')}`)
        setEndPeriod(`${moment(period.end).add(1, 'day').format('DD MMMM YYYY')}`)
        setPeriod('veille');
        break;
      case 'week':
        setComparedPeriod(`${moment(period.start).subtract(7, 'days').format('DD MMMM')}`)
        setStartPeriod(`${moment(period.start).format('DD MMMM')}`)
        setEndPeriod(`${moment(period.end).format('DD MMMM')}`)
        setPeriod('période précédente');
        break;
      case 'month':
        setComparedPeriod(`${moment(period.start).subtract(1, 'month').format('DD MMMM')}`)
        setStartPeriod(`${moment(period.start).format('DD MMMM')}`)
        setEndPeriod(`${moment(period.end).format('DD MMMM')}`)
        setPeriod('période précédente');
        break;
      case 'year':
        setComparedPeriod(`${moment(period.start).subtract(1, 'year').format('DD MMMM YYYY')}`)
        setStartPeriod(`${moment(period.start).format('DD MMMM YYYY')}`)
        setEndPeriod(`${moment(period.end).format('DD MMMM YYYY')}`)
        setPeriod('période précédente');
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[periodUnit, period])

  useEffect(() => {
    const today = moment().startOf('day');
    const periodStart = moment(period.start, "YYYY-MM-DD HH:mm:ss");
    if (periodStart.isBefore(today)) {
      dispatch(getConsumptionTrend({parameters: { sge_authorization_id, point_id, periodUnit, period }}));
    }
  }, [dispatch, sge_authorization_id, point_id, periodUnit, period]);

  useEffect(() => {
    setColor(indicatorData?.is_ratio_positive? '#98C648' : '#E6514C');
  }, [indicatorData]);

  const handleNavigateToServices = () => {
    dispatch(changeActiveRoute(`/settings/services/${building_id}`))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    navigate(`/settings/services/${building_id}`)
  }

  return (
    <div className={s.root}>
      { sge_authorization_id ?
      <>
        <div className={s.header}>
          <div className={s.label} style={{ color: color }}>
            {indicatorData?.is_ratio_positive ? '-' : '+'} {indicatorData?.ratio}% DE CONSOMMATION
          </div>
          <div className={s.period}>par rapport à la {periodTime}</div>
        </div>
        <div className={s.middleDates}>
          <div className={s.middleDate}>
            {startPeriod}
          </div>
        </div>
        <div className={s.content}>
          <div className={s.linesMiddle}>
            <div className={s.lineMiddle}></div>
          </div>
        <div className={s.measure}>
          <div className={s.last}>
            {indicatorData?.previous_consumption} {unit}
          </div>
          <div className={s.current}>
            {indicatorData?.current_consumption} {unit}
          </div>
        </div>
          <div className={s.lines}>
            <div className={s.lineLast}></div>
            <div className={s.lineCurrent}></div>
          </div>
        </div>
        <div className={s.dates}>
          <div className={s.label}>{comparedPeriod}</div>
          <div className={s.label}>{endPeriod}</div>
        </div>
      </>
      :
      <>
        <div className={s.header}>
          <div className={s.label} style={{ color: color }}>
            {indicatorData?.current_consumption <= indicatorData?.previous_consumption ? '-' : '+'} 15% DE CONSOMMATION
          </div>
          <div className={s.period}>par rapport à la {periodTime}</div>
        </div>
        <div className={s.content}>
          <div className={s.description}>
            Cette information vous intéresse et vous avez 5 minute devant vous ?
          </div>
        </div>
        <div className={s.button}>
          <Button className={s.btn} onClick={handleNavigateToServices}>
            Remplir mes informations
            <Icon name='arrow_forward' size={'1.5rem'} color={'#ffffff'} fill={1} />
          </Button>
        </div>
      </>}
    </div>
  );
}

export default CardConsumptionTrend;
