import React, { useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import s from './CardChartTemperature.module.scss'
import Loader from '../Loader/Loader'
import ExportCSV from '../ExportCsv/ExportCsv'
import { selectActiveBuilding, selectActivePeriodUnit, selectPeriod, selectActiveRoom } from '../../selectors/selectNavigation'
import moment from 'moment';
import { getTemperatureByDevices } from '../../features/measures/measuresActions'
import { selectTemperatureIsLoading, selectMeasuresForChart, selectDevicesByBuildingAndRoom } from '../../selectors/selectDevices'
import ChartTemperature from '../Chart/ChartTemperature/ChartTemperature';

moment.locale('fr');

function CardChartTemperature() {

  const dispatch = useDispatch()

  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id

  const room = useSelector(selectActiveRoom, shallowEqual)
  const room_id = room?.room_id

  const devices = useSelector(selectDevicesByBuildingAndRoom(building_id, room_id), shallowEqual)

  const isLoading = useSelector(selectTemperatureIsLoading)

  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual)

  const dataSets = useSelector(selectMeasuresForChart(devices, 'temperature'), shallowEqual);

  useEffect(() => {
    if (building_id !== '' && devices?.length > 0) {
      dispatch(getTemperatureByDevices({ measurement: 'temperature', building_id, room_id, devices, periodUnit, period })); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building_id, devices, periodUnit, period])

  return (
    <div className={s.root}>

      <div className={s.header}>
        <div className={s.title}>Température en °C <ExportCSV chartData={dataSets} /></div>
      </div>

      <div className={s.content}>
        
        {/* {isLoading && <Loader/>} */}
        

        {dataSets && dataSets.datasets.length > 0 && building &&
        <ChartTemperature dataSets={dataSets} periodUnit={periodUnit}/>
        }

        {!isLoading && dataSets && dataSets.datasets.length === 0 && building &&
          <div className={s.noMeasure}>Aucune mesure disponible pour la période sélectionnée</div>
        }
      
      </div>
    </div>
  )
}

export default CardChartTemperature
