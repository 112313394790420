import React, { useState } from 'react';
import s from './CardPricing.module.scss';
import { Button, Form, FormGroup, Label, Input, Spinner, Alert, InputGroup, InputGroupText } from 'reactstrap';
import Icon from '../../../../../../components/Icon/Icon';
import moment from 'moment';
import 'moment/locale/fr';

function CardPricing({ index, counter, pricing, setPricingIndex, setIsEditing, isEditing }) {
  
  const energyProvider = pricing.energy_provider || '---';
  const contractOffer = pricing.contract_offer || '---';
  const contractType = counter?.technical_and_contract?.point?.situationContractuelle?.structureTarifaire?.calendrierFrn?.libelle || '---';
  const isBasePricing = contractType === 'Base';

  const lowPricing = pricing.pricing?.kwh_hc || '---';
  const highPricing = pricing.pricing?.kwh_hp || '---';
  const basePricing = pricing.pricing?.kwh_base || '---';

  const subscriptionPricing = isBasePricing? pricing.pricing?.subscription_base : pricing.pricing?.subscription_hphc || '---';

  const offPeakHours = counter?.technical_and_contract?.point?.situationComptage?.dispositifComptage?.relais?.plageHeuresCreuses || '---';

  const effectiveAt = pricing.effective_at
  const endAt = pricing.end_at

  const [edit, setEdit] = useState(false)
  
  const [formData, setFormData] = useState({
    energyProvider: energyProvider || '',
    contractOffer: contractOffer || '',
    contractType: contractType || '',
    subscriptionPricing: subscriptionPricing || '',
    basePricing: basePricing || '',
    lowPricing: lowPricing || '',
    highPricing: highPricing || ''
  });

  const handleSave = () => {
    setIsEditing(false)
    setEdit(false)
  }

  const handleCancel = () => {
    setIsEditing(false)
    setEdit(false)
  }

  const handleSetting = () => {
    setIsEditing(true)
    setPricingIndex(index)
  }

  const handleDelete = () => {
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const id = existingEntry?.id; // Pass `id` for updates, otherwise `undefined` for new entry
    // dispatch(addOrUpdateEntry({ id, data: formData }));
  };

  return (
    <div className={s.root}>
      
      <div className={s.header}>
        <div className={s.title}>{energyProvider}</div>
        {!isEditing &&
        <div className={s.control}>
          {index > 0 && 
          <div className={s.delete}>
            <Icon name={'delete'} size={'1.5rem'} color={'#FF3C3C'} fill={0} onClick={handleDelete} cursor/>
          </div>}
          <div className={s.modify}>
            <Icon name={"edit"} size={'1.5rem'} color={'#3192D3'} fill={0} onClick={handleSetting} cursor/>
          </div> 
        </div>}
      </div>

      <div className={s.content}>
        <div className={s.info}>
          <div className={s.item}><span>Offre de contrat :</span><span>{contractOffer}</span></div>
          <div className={s.item}><span>Type de contrat :</span><span>{contractType}</span></div>
          {!isBasePricing && (<div className={s.item}><span>Plage HC :</span><span>{offPeakHours}</span></div>)}
          <div className={s.item}><span>{isBasePricing ? 'Tarif :' : 'Tarif HC :'}</span><span>{isBasePricing ? `${basePricing} €/kwh` : `${lowPricing} €/kwh`}</span></div>
          {!isBasePricing && (<div className={s.item}><span>Tarif HP :</span><span>{`${highPricing} €/kwh`}</span></div>)}
          <div className={s.item}><span>Tarif abonnement :</span><span>{`${subscriptionPricing} €/mois`}</span></div>
        </div>
      </div>
      {/* 
      {edit &&
        <footer className={s.footer}>
          <Button color="primary" onClick={handleSave}>
            <Icon name="check_circle" size={'1.5rem'} color={'#FFFFFF'} fill={0} cursor/>
            <span>Enregistrer</span>
          </Button>
          <Button color="primary" outline onClick={handleCancel}>
            <Icon name="cancel" size={'1.5rem'} color={'#FFFFFF'} fill={0} cursor/>
            <span>Annuler</span>
          </Button>
        </footer>
        }
        */}
    </div>
  );
}

export default CardPricing
