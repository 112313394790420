import React, { useEffect, useState } from 'react';
import s from './ModalDissociateDevice.module.scss';
import { Button } from 'reactstrap';
import Modal from '../../../../components/Modal/Modal';
import 'react-toastify/dist/ReactToastify.css';
import Icon from '../../../../components/Icon/Icon';
import CardDeviceSelected from '../CardDeviceSelected/CardDeviceSelected';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectActiveDevice } from '../../../../selectors/selectNavigation';
import { toast } from 'react-toastify';
import { updateDevice } from '../../../../features/devices/devicesActions';

function ModalDissociateDevice({ showModalDissociateDevice, handleClose }) {

  const dispatch = useDispatch()

  const device = useSelector(selectActiveDevice, shallowEqual)
 
  const [deviceData, setDeviceData] = useState(device)

  useEffect(() => {
    setDeviceData(device)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [device])

  const handleDissociate = () => {
    const updatedData = {
      ...deviceData,
      building: '',
      room: '',
    };
    console.log(updatedData)
    dispatch(updateDevice({ data: updatedData }))
    toast.warning("Equipement dissocié !")
    handleClose()
  }

  return (
    <Modal show={showModalDissociateDevice}>
      <div className={s.header}>
        <div className={s.title}>Dissocier un équipement</div>
        <Icon name='close' size={'1.5rem'} color={'#30465E'} fill={0} onClick={handleClose} cursor />
      </div>
      <div className={s.message}>
        Voulez vous dissocier cet équipement ? 
      </div>
      <div className={s.devices}>
        <CardDeviceSelected device={deviceData} />
      </div>

      <div className={s.control}>
        <Button color='primary' onClick={handleDissociate}>Valider</Button>
      </div>
    </Modal>
  );
}

export default ModalDissociateDevice;
