import { defaultAxiosInstance } from '../../axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getAlerts = createAsyncThunk(
  'alerts/get_alerts',
  async ({ building_id, device_name, label, active, period }, { rejectWithValue }) => {
    try {
      const params = { building_id, device_name, label, active, period }
      const response = await defaultAxiosInstance.get(`/alerts`, { params })
      const listAlerts = response && response.data
      return { listAlerts }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getAlertLabels = createAsyncThunk(
  'devices_filter/get_alerts_label',
  async (_, { rejectWithValue }) => {
    try {
      const response = await defaultAxiosInstance.get(`/alerts/label`)
      let array_label = []
      for (let i=0; i < response.data.length; i++) {
        array_label[i] = response.data[i].label
      }
      array_label.unshift('Toutes')
      return array_label
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
) 