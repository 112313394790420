import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import s from './CardChartEnergy.module.scss'
import { useNavigate } from "react-router-dom"
import ChartEnergy from '../Chart/ChartEnergy/ChartEnergy'
import { getLoadCurveData } from '../../features/sge/sgeActions'
import { selectDataIsLoading, selectLoadCurve, selectAggregateData, selectErrorSGE } from '../../selectors/selectSGE'
import { Button, InputGroup  } from 'reactstrap'
import Loader from '../Loader/Loader'
import { selectActiveBuilding, selectActiveConsoUnit, selectPeriod, selectActivePeriodUnit } from '../../selectors/selectNavigation'
import { changeActiveConsoUnit, changeActiveRoute } from '../../features/navigation/navigationSlice'
import { getWeatherData } from '../../features/weather/weatherActions';
import { selectWeatherDataByStation } from '../../selectors/selectWeatherData';

import { saveNavigationState } from '../../features/navigation/navigationActions';
import store from '../../redux/store';
import { selectSgeAuthorization, selectWeatherStationId } from '../../selectors/selectBuildings';
import { isDateMax } from '../../utils/functions/dateHandler';
import { extendMoment } from 'moment-range';
import ExportSgeCSV from '../ExportCsv/ExportSgeCsv';

const moment = extendMoment(require('moment'));
moment.locale('fr')

function CardChartEnergy() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id || null

  const point_id = building?.sge_authorizations?.[0]?.point_id
  const counter = useSelector(selectSgeAuthorization(building_id, point_id), shallowEqual)
  const sge_authorization_id = counter?.sge_authorization_id

  const weather_station_id = useSelector(selectWeatherStationId(building_id))

  const isLoading = useSelector(selectDataIsLoading)

  const consoUnit = useSelector(selectActiveConsoUnit)

  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual)

  const error = useSelector(selectErrorSGE(), shallowEqual)

  const { transformedData, contractType } = useSelector(selectLoadCurve(sge_authorization_id, periodUnit), shallowEqual)
  const weatherData = useSelector(selectWeatherDataByStation(weather_station_id), shallowEqual)
  
  const aggregateData = useSelector(selectAggregateData(sge_authorization_id, periodUnit), shallowEqual)

  const [dataSets, setDataSets] = useState()

  const [weatherDailyData, setWeatherDailyData] = useState()

  const [category, setCategory] = useState()
  const [step, setStep] = useState()

  useEffect(() => {
    if (weatherData) {
      setWeatherDailyData(weatherData)  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weatherData])

  const alignTemperatureDataWithCategory = (categories, periodUnit, data) => {
    const filteredData = periodUnit === 'day'
      ? data.filter(item => moment(item.date).minute() === 0)
      : data;
  
    const temperatureMap = filteredData.reduce((acc, item) => {
      const normalizedKey = periodUnit === 'day'
        ? moment(item.date).format('YYYY-MM-DDTHH:mm')
        : item.date;
      acc[normalizedKey] = item.temperature;
      return acc;
    }, {});
    
    return categories.map(date => {
      const normalizedDate = periodUnit === 'day'
        ? moment(date).format('YYYY-MM-DDTHH:mm')
        : date;
      return {
        x: date,
        y: temperatureMap[normalizedDate] !== undefined ? temperatureMap[normalizedDate] : null,
      };
    });
  };

  const alignTemperatureDataWithHourlyCategory = (categories, periodUnit, data) => { 
    return categories.map(categoryDate => {
      const categoryMoment = moment(categoryDate);
      let closestItem = null;
      let minDiff = Infinity;
      data.forEach(item => {
        const itemMoment = moment(item.date);
        const diff = Math.abs(itemMoment.diff(categoryMoment));
        if (diff < minDiff) {
          minDiff = diff;
          closestItem = item;
        }
      });
      return {
        x: categoryDate,
        y: closestItem ? closestItem.temperature : null,
      };
    });
  };
  

  useEffect(() => {
    const today = moment().startOf('day');
    const periodStart = moment(period.start, "YYYY-MM-DD HH:mm:ss");
    if (periodStart.isBefore(today)) {
      if (sge_authorization_id) {
        dispatch(getLoadCurveData({ sge_authorization_id, point_id, periodUnit, period }))
      }
      if (weather_station_id) {
        dispatch(getWeatherData({ weather_station_id, periodUnit, period }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building_id, sge_authorization_id, point_id, periodUnit, period])

  useEffect(() => {
    if (transformedData && error === null && contractType !== '') {

      let array_Base = [];
      let array_Price_Base = [];
      let array_Subscription_Base = [];

      let array_HC = [];
      let array_HP = [];
      let array_Price_HP = [];
      let array_Price_HC = [];
      let array_Subscription_HPHC = [];

      let array_equiv_carbon = [];
      let array_category = [];
      let array_step = [];

      const generateDateRange = (period, periodUnit) => {
        const start = moment(period.start);
        const end = moment(period.end);
        let range = Array.from(moment.range(start, end).by('day')).map(m => m.format('YYYY-MM-DD'));
        switch(periodUnit) {
          case 'week':
          case 'month':
            range = Array.from(moment.range(start, end).by('day')).map(m => m.format('YYYY-MM-DD'));
            break;
          case 'year':
            range = Array.from(moment.range(start, end).by('month')).map(m => m.format('YYYY-MM-01'));
            break;
          default:
            break;
        }
        return range;
      };

      transformedData?.forEach(item => {
        array_category.push(item.timestamp);
        array_step.push(item?.step)
      
        array_Base.push(item.kwhBase);
        array_Price_Base.push(item.pricingBase);
        array_Subscription_Base.push(item.subscriptionBase);
        
        array_HP.push(item.kwhHP);
        array_HC.push(item.kwhHC);
        array_Price_HP.push(item.pricingHP);
        array_Price_HC.push(item.pricingHC);
        array_Subscription_HPHC.push(item.subscriptionHpHc);

        array_equiv_carbon.push(item.equivalentCO2);
      });

      setStep(array_step)
      
      if (periodUnit === 'week') {
        const weekDates = generateDateRange(period, 'week');
        array_category = weekDates.map(date => array_category.includes(date) ? date : date);
      }
  
      if (periodUnit === 'month') {
        const monthDates = generateDateRange(period, 'month');
        array_category = monthDates.map(date => array_category.includes(date) ? date : date);
      }

      if (periodUnit === 'year') {
        const yearDates = generateDateRange(period, 'year');
        array_category = yearDates.map(date => array_category.includes(date) ? date : date);
      }
      
      switch (periodUnit) {
        case "day":
          setCategory(transformedData?.map(item => item.timestamp))
          break
        case "week":
          setCategory(transformedData?.map(item => item.timestamp))
          break
        case "month":
          setCategory(transformedData?.map(item => item.timestamp))
          break
        case "year":
          setCategory(transformedData?.map(item => item.timestamp))
          break
        default:
          return null
      }

      //€
      if (consoUnit === '€') {        
        if (contractType !== 'Base') {
          setDataSets({
            datasets: [
              {
                type: 'bar',
                label: 'Abonnement',
                data: array_Subscription_HPHC,
                backgroundColor: '#e49400',
                borderColor: '#e49400',
                borderWidth: 0,
                stack: 'stack1',
                order: 2
              },
              {
                type: 'bar',
                label: 'Coût Heure Pleine',
                data: array_Price_HP,
                backgroundColor: '#2477ff',
                borderColor: '#2477ff',
                borderWidth: 0,
                borderRadius: 5,
                stack: 'stack1',
                order: 3
              },
              {
                type: 'bar',
                label: 'Coût Heure Creuse',
                data: array_Price_HC,
                backgroundColor: '#02ae51',
                borderColor: '#02ae51',
                borderWidth: 0,
                borderRadius: 5,
                stack: 'stack1',
                order: 4
              },
              {
                type: 'line',
                label: 'T°C Ext.',
                // data: weatherDailyData,
                spanGaps: true,
                backgroundColor:'#474d84',
                borderColor: '#474d84',
                borderWidth: 2,
                pointRadius: 3,
                fill: false,
                yAxisID: 'y-axis-2',
                pointStyle: 'rectRounded',
                order: 1,
              },
            ],
          })
        } else {
          setDataSets({
            datasets: [
              {
                type: 'bar',
                label: 'Abonnement',
                data: array_Subscription_Base,
                backgroundColor: '#e49400',
                borderColor: '#e49400',
                borderWidth: 0,
                stack: 'stack1',
                order: 2
              },
              {
                type: 'bar',
                label: 'Coût Base',
                data: array_Price_Base,
                backgroundColor: '#2477ff',
                borderColor: '#2477ff',
                borderWidth: 0,
                borderRadius: 5,
                stack: 'stack1',
                order: 3
              },
              {
                type: 'line',
                label: 'T°C Ext.',
                // data: weatherDailyData,
                spanGaps: true,
                backgroundColor:'#474d84',
                borderColor: '#474d84',
                borderWidth: 2,
                pointRadius: 3,
                fill: false,
                yAxisID: 'y-axis-2',
                pointStyle: 'rectRounded',
                order: 1,
              }
            ],
          })
        }
      }
      //kWh
      if (consoUnit === 'kWh') {        
        if (contractType !== 'Base') {
          setDataSets({
            datasets: [
              {
                type: 'bar',
                label: 'Consommation Heure Pleine',
                data: array_HP,
                backgroundColor: '#2477ff',
                borderColor: '#2477ff',
                borderWidth: 0,
                borderRadius: 5,
                stack: 'stack1',
                order: 2
              },
              {
                type: 'bar',
                label: 'Consommation Heure Creuse',
                data: array_HC,
                backgroundColor: '#02ae51',
                borderColor: '#02ae51',
                borderWidth: 0,
                borderRadius: 5,
                stack: 'stack1',
                order: 3
              },
              {
                type: 'line',
                label: 'T°C Ext.',
                // data: weatherDailyData,
                spanGaps: true,
                borderColor: '#474d84',
                borderWidth: 2,
                pointRadius: 3,
                fill: false,
                yAxisID: 'y-axis-2',
                pointStyle: 'rectRounded',
                order: 1,
              },
            ],
          })
        } else {
          setDataSets({
            datasets: [
              {
                type: 'bar',
                label: 'Consommation Base',
                data: array_Base,
                backgroundColor: '#2477ff',
                borderColor: '#2477ff',
                borderWidth: 0,
                borderRadius: 5,
                stack: 'stack1',
                order: 2
              },
              {
                type: 'line',
                label: 'T°C Ext.',
                // data: weatherDailyData,
                spanGaps: true,
                borderColor: '#474d84',
                borderWidth: 2,
                pointRadius: 3,
                fill: false,
                yAxisID: 'y-axis-2',
                pointStyle: 'rectRounded',
                order: 1,
              },
            ],
          })
        }
      }
      //kgCO2
      if (consoUnit === 'kgCO2') {
        setDataSets({
          datasets: [
            {
              type: 'bar',
              label: 'Equivalent carbone',
              data: array_equiv_carbon,
              backgroundColor: '#bbbbbb',
              borderColor: '#bbbbbb',
              borderWidth: 0,
              borderRadius: 5,
              stack: 'stack1',
              order: 3
            },
            {
              type: 'line',
              label: 'T°C Ext.',
              // data: weatherDailyData,
              spanGaps: true,
              borderColor: '#474d84',
              borderWidth: 2,
              pointRadius: 3,
              fill: false,
              yAxisID: 'y-axis-2',
              pointStyle: 'rectRounded',
              order: 1,
            },
          ],
        })
      }

      if (transformedData && weatherDailyData) {
        const alignedTemperatureData = periodUnit === 'day' ?
        alignTemperatureDataWithHourlyCategory(array_category, periodUnit, weatherDailyData) : 
        alignTemperatureDataWithCategory(array_category, periodUnit, weatherDailyData);
        setDataSets(prevDataSets => ({
          ...prevDataSets,
          datasets: prevDataSets.datasets.map(dataset => {
            if (dataset.label === 'T°C Ext.') {
              return {
                ...dataset,
                data: alignedTemperatureData.map(point => point.y),
              };
            }
            return dataset;
          }),
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedData, weatherDailyData, consoUnit])

  const aggregate = () => {
    if (error === null) {
      switch (consoUnit) {
        case "€":
          return <span>{contractType !== 'Base' ? aggregateData?.sumSubscriptionHPHC : aggregateData?.sumSubscriptionBase} {consoUnit}</span>
        case "kWh":
          return <span>{aggregateData?.sumConsumption} {consoUnit}</span>
        case "kgCO2":
          return <span>{aggregateData?.sumCarbonEquivalent} {consoUnit}</span>
        default:
          return null
      }
    } else {
      return '---'
    }
      
  }

  const errorSGE = () => {
    return (
      <div className={s.error}>
        {error.code === 'SGT4K1' && <div className={s.description}>Vous ne pouvez pas afficher les données de consommations du jour.</div>}
        {error.code === 'SGT4K1' && <div className={s.description}>Veuillez changer la date !</div>}
        {error.code === 'SGT4K4' && <div className={s.description}>La date de début doit être antérieure à la date de fin.</div>}
        {error.code === 'SGT4K4' && <div className={s.description}>Veuillez contacter notre SAV !</div>}
        {error.code === 'SGT4G3' && <div className={s.description}>Vos données de consommation ne sont pas encore disponibles.</div>}
        {error.code === 'SGT4G3' && <div className={s.description}>Merci de réessayer plus tard !</div>}
      </div>
    )
  }

  const handleChangeConsoUnit = (unit) => {
    dispatch(changeActiveConsoUnit(unit))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
  }

  const handleNavigateToSettings = () => {
    dispatch(changeActiveRoute(`/settings/services/:${building_id}`)) 
    navigate(`/settings/services/:${building_id}`)
  }

  return (    
    <div className={s.root}>
      
      <div className={s.header}>
        <div className={s.title}>Consommations d'électricité <ExportSgeCSV dataSets={dataSets} category={category} /></div>
        <div className={s.control}>
          <InputGroup className={s.controlUnit}>
            <Button color="primary" outline={consoUnit !== '€'} onClick={() => handleChangeConsoUnit('€')}>€</Button>
            <Button color="primary" outline={consoUnit !== 'kWh'} onClick={() => handleChangeConsoUnit('kWh')}>kWh</Button>
            <Button color="primary" outline={consoUnit !== 'kgCO2'} onClick={() => handleChangeConsoUnit('kgCO2')}>kgCO2</Button>
          </InputGroup>
        </div>
        <div className={s.aggregate}>{!isLoading ? aggregate() : '---'}</div>
      </div>
      
      <div className={s.content}>
        
        {sge_authorization_id && isLoading && <Loader/>}
               
        {sge_authorization_id && !isLoading && error === null && dataSets && !isDateMax(period.start) &&
        <ChartEnergy dataSets={dataSets} category={category} step={step} periodUnit={periodUnit}/>
        }

        {sge_authorization_id && !isLoading && error === null && isDateMax(period.start) &&
        <div className={s.error}>
          <div className={s.description}>Vous ne pouvez pas afficher vos consommations du jour</div>
        </div>
        }

        {sge_authorization_id && !isLoading && error !== null && errorSGE()}
                
        {!sge_authorization_id && 
        <div className={s.error}>
          <div className={s.description}>Vous n'avez pas ajouté de compteur pour ce bâtiment</div>
          <Button color="primary" onClick={() => handleNavigateToSettings()}>Ajouter un compteur</Button>
        </div>
        }
        
      </div>
    </div>
  )
}
export default CardChartEnergy