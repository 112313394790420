import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import s from './ModalDeleteLinky.module.scss'
import Popup from '../../../../../../components/Popup/Popup'
import { selectActiveLinky } from '../../../../../../selectors/selectNavigation'
import { deleteAuthorization } from '../../../../../../features/sge/sgeActions'

function ModalDeleteLinky({ showModalDeleteLinky, handleClose }) {

  const dispatch = useDispatch()

  const counter = useSelector(selectActiveLinky, shallowEqual)

  const handleDelete = () => {
    const sge_authorization_id = counter?.sge_authorization_id
    dispatch(deleteAuthorization({ sge_authorization_id }))
    .then(() => { 
      handleClose()
    })
  }

  return (
    <Popup title={"Suppression d'un compteur"} show={showModalDeleteLinky} onDiscard={handleClose} onAccept={handleDelete}>
      <div className={s.message}>Etes vous sûr de vouloir supprimer ce compteur ?</div>
    </Popup>
  )
}

export default ModalDeleteLinky
