import React, { useState } from 'react';
import s from './AddPricingForm.module.scss';
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';

function AddPricingForm({ setIsAddPricing }) {

  const [isBaseContract, setIsBaseContract] = useState(false)
  const [isHpHcContract, setIsHpHcContract] = useState(false)

  const handleContract  = (contract) => {
    switch(contract) {
      case 'base':
        setIsBaseContract(true)
        setIsHpHcContract(false)
        break;
      case 'hphc':
        setIsBaseContract(false)
        setIsHpHcContract(true)
        break;
    }
  }

  const handleSave = () => {
    setIsAddPricing(false)
  }

  return (
    <div className={s.contentAdd}>
      <Form className={s.form}>
        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Date de début d'application de ce tarif</Label>
          <Input className={s.input} type='date' required/>
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Fournisseur</Label>
          <Input className={s.input} type='text' required/>
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Offre de contrat</Label>
          <Input className={s.input} type='text' required/>
        </FormGroup>

        <FormGroup className={s.checkBox}>
          <FormGroup check>
            <Input className={s.checkbox} type='radio' name='radio1' value={1} onClick={() =>handleContract('base')} />{' '}
            <Label check className={s.labelRadio}>
              Base
            </Label>
          </FormGroup>
          <FormGroup check>
            <Input className={s.checkbox} type='radio' name='radio1' value={2} onClick={() =>handleContract('hphc')} />{' '}
            <Label check className={s.labelRadio}>
              Heure pleine/Heure creuse
            </Label>
          </FormGroup>
        </FormGroup>

        {isBaseContract && (
          <div className={s.contract}>
            <FormGroup className={s.formGroup}>
                <Label className={s.label}>Tarif consomation</Label>
                <InputGroup className={s.inputGroup}>
                  <Input className={s.input} type='text' name='subscriptionPricing' id='subscriptionPricing' placeholder='25' required/>
                  <InputGroupText className={s.inputGroupText}>€ TTC/kWh</InputGroupText>
                </InputGroup>
              </FormGroup>

              <FormGroup className={s.formGroup}>
              <Label className={s.label} for='subscriptionPricing'>
                Tarif abonnement
              </Label>
              <InputGroup className={s.inputGroup}>
                <Input className={s.input} type='text' name='subscriptionPricing' id='subscriptionPricing' placeholder='25' required/>
                <InputGroupText className={s.inputGroupText}>€ TTC/mois</InputGroupText>
              </InputGroup>
            </FormGroup>
          </div>
        )}
        {isHpHcContract && (
          <>
            <div className={s.contract}>
              <FormGroup className={s.formGroup}>
                <Label className={s.label}>Début d'heure creuse</Label>
                <Input className={s.input} type='time' />
              </FormGroup>

              <FormGroup className={s.formGroup}>
                <Label className={s.label}>Fin d'heure creuse</Label>
                <Input className={s.input} type='time' />
              </FormGroup>
            </div>
            <div className={s.contract}>
            <FormGroup className={s.formGroup}>
                <Label className={s.label}>Tarif heure creuse</Label>
                <InputGroup className={s.inputGroup}>
                  <Input className={s.input} type='text' name='subscriptionPricing' id='subscriptionPricing' required/>
                  <InputGroupText className={s.inputGroupText}>€ TTC/kWh</InputGroupText>
                </InputGroup>
              </FormGroup>

              <FormGroup className={s.formGroup}>
                <Label className={s.label}>Tarif heure creuse</Label>
                <InputGroup className={s.inputGroup}>
                  <Input className={s.input} type='text' name='subscriptionPricing' id='subscriptionPricing' required/>
                  <InputGroupText className={s.inputGroupText}>€ TTC/kWh</InputGroupText>
                </InputGroup>
              </FormGroup>
            </div>
            <FormGroup className={s.formGroup}>
              <Label className={s.label} for='subscriptionPricing'>
                Tarif abonnement
              </Label>
              <InputGroup className={s.inputGroup}>
                <Input className={s.input} type='text' name='subscriptionPricing' id='subscriptionPricing' required/>
                <InputGroupText className={s.inputGroupText}>€ TTC/mois</InputGroupText>
              </InputGroup>
            </FormGroup>
          </>
        )}
      </Form>
      <div className={s.btn}>
        <Button color='primary' onClick={handleSave}>Valider</Button>
      </div>
    </div>
  );
}

export default AddPricingForm;
