import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import s from './CardAssociatedAccount.module.scss'
import { selectProfile } from '../../../../selectors/selectUser';
import { Button } from 'reactstrap';

function CardAssociatedAccount({ handleShowModal }) {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id
  
  const profile = useSelector(selectProfile, shallowEqual)

  const add = () => {

  }

  return (
    <div className={s.root}>

      <div className={s.header}>
        <div className={s.title}>Comptes associés</div>
      </div>

      <div className={s.content}>
        <ul className={s.cardInfo}>
          <li><span>Kevin Lousky</span><span>kevin@insensia.com</span></li>
          <li><span>Antoine Vidaling</span><span>antoine@insensia.com</span></li>
          <li><span>...</span><span>...</span></li>
        </ul>

        <div className={s.control}>
          <Button color="primary" onClick={() => handleShowModal('add')} disabled={false}>Créer un compte</Button>
        </div>
      </div>

    </div>
  )
}

export default CardAssociatedAccount
