import React from 'react';
import s from './CardDeviceSelected.module.scss';
import moment from 'moment';

function CardDeviceSelected({ device }) {
  return (
    <div className={s.root}>
      <div className={s.header}>
        <div className={s.title}>{device?.name}</div>
      </div>
      <div className={s.infos}>
        <div className={s.info}>
          <span>Marque : </span>
          {device?.manufacturer}
        </div>
        <div className={s.info}>
          <span>Numéro de série : </span>
          {device?.serial_number}
        </div>
        <div className={s.info}>
          <span>Dernière donnée : </span>
          {moment(device?.latest_online).format('DD/MM/YYYY HH:mm:ss')}
        </div>
      </div>
    </div>
  );
}

export default CardDeviceSelected;
