import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'

export const selectWeatherData = (state) => {
    return state.weather
}

export const selectListStations = createSelector(
  (state) => state.weather.listStations,
  (listStations) => listStations
)

export const selectWeatherDataByStationAndDate = (weather_station_id, startDate, endDate) => createSelector(
  [selectListStations],
  (listStations) => {
    const station = listStations.find(station => station.weather_station_id === weather_station_id);
    if (!station) return [];

    // Filtrer les entrées de données basées sur la plage de dates et transformer les valeurs
    const filteredData = station.data
      .filter(entry => {
        const entryDate = moment(entry.time_stamp);
        return entryDate.isSameOrAfter(moment(startDate)) && entryDate.isSameOrBefore(moment(endDate));
      })
      .map(entry => (
        entry.data.temperature !== null ? parseFloat(entry.data.temperature) : null
      ));

    return filteredData;
  }
);

export const selectWeatherDataByStation = (weather_station_id) => createSelector(
  [selectListStations],
  (listStations) => {
    const station = listStations.find(station => station.weather_station_id === weather_station_id);
    if (!station) return [];
    const data = station?.data
    return data;
  }
);





  