import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import s from './EquipmentsLinked.module.scss'
import { getBuildings } from '../../../../features/buildings/buildingsActions';
import { getDevices } from '../../../../features/devices/devicesActions';
import { selectActiveBuilding, selectActiveRoom } from '../../../../selectors/selectNavigation';
import ModalDissociateDevice from '../ModalDissociateDevice/ModalDissociateDevice';
import CardDevice from '../CardDevice/CardDevice';
import { changeActiveBuildingDevice, changeActiveRoomDevice, initActiveDevice } from '../../../../features/navigation/navigationSlice';
import { saveNavigationState } from '../../../../features/navigation/navigationActions';
import store from '../../../../redux/store';
import ModalDeviceSettings from '../ModalDeviceSettings/ModalDeviceUpdate';
import ModalDeleteDevice from '../ModalDeleteDevice/ModalDeleteDevice';
import { selectDevicesLinkedByBuilding, selectDevicesLinkedByRoom, selectDevicesUnlinkedRoom, selectDeviceWithBuilding, selectDeviceWithoutBuilding } from '../../../../selectors/selectDevices';
import ModalDeviceUpdate from '../../../BuildingSettings/components/Devices/components/ModalUpdateDevice/ModalUpdateDevice';

function EquipmentsLinked() {
  
  const dispatch =  useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;
    
  const building = useSelector(selectActiveBuilding, shallowEqual);
  const building_id = building?.building_id;
  const room = useSelector(selectActiveRoom,shallowEqual)
  const room_id = room?.room_id;
  
  const devicesLinkedBuilding = useSelector(selectDevicesLinkedByBuilding(building_id), shallowEqual)
  const devicesUnlinked = useSelector(selectDevicesUnlinkedRoom(building_id), shallowEqual)
  const deviceLinkedBuildingAndRoom = useSelector(selectDevicesLinkedByRoom(building_id, room_id), shallowEqual)
  
  const [showModalDissociateDevice, setShowModalDissociateDevice] = useState(false)
  const [showModalUpdateDevice, setShowModalUpdateDevice] = useState(false)
  const [showModalDeleteDevice, setShowModalDeleteDevice] = useState(false)


  useEffect(() => { 
      if (user_id) {
        dispatch(getBuildings({ user_id }))
        dispatch(getDevices({ user_id })) 
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [user_id])

  const handleShowModal = (modalType) => {
    switch (modalType) {
      case 'settings':
        setShowModalUpdateDevice(true);
        break;
      case 'dissociate':
        setShowModalDissociateDevice(true);
        break;
      case 'delete':
        setShowModalDeleteDevice(true)
        break;
      default:
        break;
    }
  }

  const handleCloseModal = (modalType) => {
    switch (modalType) {
      case 'settings' :
        setShowModalUpdateDevice(false);
        dispatch(changeActiveBuildingDevice(null))
        dispatch(changeActiveRoomDevice(null))
        break;
      case 'dissociate':
        setShowModalDissociateDevice(false);
        break;
      case 'delete':
        setShowModalDeleteDevice(false)
        dispatch(changeActiveBuildingDevice(null))
        dispatch(changeActiveRoomDevice(null))
        break;
      default:
        break;
    }
    dispatch(initActiveDevice())
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
    .then(() => dispatch(getDevices({ user_id })))
    .then(() => dispatch(getBuildings({ user_id })))
  }


  return (
    <div className={s.root}>
      <div className={s.content}>
        
        {building === null && 
        
        <div className={s.devices} >
          <div className={s.noResult}> 
            Veuillez sélectionner un bâtiment
          </div>
        </div>
        }

        {building !== null && room === null &&
        <> 
          <div className={s.title}>
            Équipements associés à une salle - <span>{building.name}</span>
          </div>
          <div className={s.devices}>
            {devicesLinkedBuilding?.length > 0 ? devicesLinkedBuilding.map((device, index) => (
              <CardDevice key={index} device={device} handleShowModal={handleShowModal} />
            )) 
            : 
              <div className={s.noResult}> 
                Aucun résultats
              </div>
            }
          </div>
          <div className={s.title}>
            Équipements non-associés à une salle - <span>{building.name}</span>
          </div>
          <div className={s.devices}>
            {devicesUnlinked?.length > 0 ? devicesUnlinked.map((device, index) => (
              <CardDevice key={index} device={device} handleShowModal={handleShowModal} />
            ))
            :
              <div className={s.noResult}> 
                Aucun résultats
              </div>
            }
          </div>
        </>}

        {building != null && room != null &&
        <> 
          <div className={s.title}>
            Équipements associés à l'espace :<span>{room.name}</span>-<span>{building.name}</span>
          </div>
          <div className={s.devices}>
            {deviceLinkedBuildingAndRoom?.length > 0 ? deviceLinkedBuildingAndRoom.map((device, index) => (
              <CardDevice key={index} device={device} handleShowModal={handleShowModal}/>
            ))
            :
            <div className={s.noResult}> 
              Aucun résultats
            </div>
            }
          </div>
        </>}
      </div>

      <ModalDeleteDevice showModalDeleteDevice={showModalDeleteDevice} handleClose={() => handleCloseModal('delete')}/>
      <ModalDeviceUpdate showModalUpdateDevice={showModalUpdateDevice} handleClose={() => handleCloseModal('settings')}/>
      <ModalDissociateDevice showModalDissociateDevice={showModalDissociateDevice} handleClose={() => handleCloseModal('dissociate')}/>

    </div>
  )
}

export default EquipmentsLinked
