import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import s from './Services.module.scss'
import { Button } from 'reactstrap'
import Icon from '../../../../components/Icon/Icon'
import CardLinky from './components/CardLinky/CardLinky'
import ModalAddLinky from './components/ModalAddLinky/ModalAddLinky'
import ModalPricingHistory from './components/ModalPricingHistory/ModalPricingHistory'
import ModalDeleteLinky from './components/ModalDeleteLinky/ModalDeleteLinky'
import { selectSgeAuthorizations } from '../../../../selectors/selectBuildings'
import { selectActiveBuilding } from '../../../../selectors/selectNavigation'
import { initActiveLinky } from '../../../../features/navigation/navigationSlice'
import { saveNavigationState } from '../../../../features/navigation/navigationActions'
import { getBuildings } from '../../../../features/buildings/buildingsActions'
import store from '../../../../redux/store';

function Services() {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id

  const sge_authorizations = useSelector(selectSgeAuthorizations(building_id), shallowEqual)

  const [showModalAddLinky, setShowModalAddLinky] = useState(false)
  const [showModalDeleteLinky, setShowModalDeleteLinky] = useState(false)

  const [showModalPricingHistory, setShowModalPricingHistory] = useState(false)
  
  const handleShowModal = (modalType) => {
    switch (modalType) {
      case 'add':
        setShowModalAddLinky(true);
        break;
      case 'delete':
        setShowModalDeleteLinky(true);
        break;
      case 'pricingHistory':
        setShowModalPricingHistory(true);
        break;
      default:
        break;
    }
  }

  const handleCloseModal = (modalType) => {
    switch (modalType) {
      case 'add':
        setShowModalAddLinky(false);
        break;
      case 'delete':
        setShowModalDeleteLinky(false);
        break;
      case 'pricingHistory':
        setShowModalPricingHistory(false);
        break;
      default:
        break;
    }
    dispatch(initActiveLinky())
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
    .then(() => dispatch(getBuildings({ user_id })))
  }

  const handleError = () => {
    handleCloseModal('add')
  }

  return (
    <div className={s.root}>

      <div className={s.header}>
        <div className={s.title}>Compteur d'électricité</div>
        <Button color="primary" onClick={() => handleShowModal('add')}>
          <Icon name="add" size={'1.5rem'} color={'#FFF'} fill={1}/>
          <span className={s.item}>Ajouter un compteur</span>
        </Button>
      </div>

      {!sge_authorizations && <div className={s.message}>
        <div>Aucun compteur !</div>
        <div>Veuillez ajouter un compteur pour suivre vos consommation d'énergie</div>
      </div>}

      <div className={s.content}>
        {sge_authorizations?.length > 0 && sge_authorizations?.map((sge_authorization, index) => (
          <CardLinky key={index} sge_authorization={sge_authorization} handleShowModal={handleShowModal}/>
        ))}
            
        <ModalAddLinky showModalAddLinky={showModalAddLinky} handleClose={() => handleCloseModal('add')} handleError={handleError}/>
        <ModalDeleteLinky showModalDeleteLinky={showModalDeleteLinky} handleClose={() => handleCloseModal('delete')}/>

        <ModalPricingHistory showModalPricingHistory={showModalPricingHistory} handleClose={() => handleCloseModal('pricingHistory')}/>

      </div>

    </div>
  )
}

export default Services