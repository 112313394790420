import React from 'react';
import s from './Anomalies.module.scss';
import HeaderAnomalies from '../../components/Headers/HeaderAnomalies/HeaderAnomalies';
import Filter from './components/Filter/Filter'
import CardTimeline from './components/CardTimeline/CardTimeline';
import { shallowEqual, useSelector } from 'react-redux';
import { selectActiveBuilding, selectPeriod, selectActivePeriodUnit } from '../../selectors/selectNavigation';
import { selectAlertsIsLoading } from '../../selectors/selectAlerts';
import moment from 'moment'
import Loader from '../../components/Loader/Loader';

moment.locale('fr')

function Anomalies() {
  
  const building = useSelector(selectActiveBuilding, shallowEqual)

  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual)
  
  const alertsIsLoading = useSelector(selectAlertsIsLoading);

  const title = () => {     
    switch (periodUnit) {
      case "day":
        return <div className={s.title}>{building?.name} - Alertes du {moment(period.start).locale('fr').format('dddd DD MMM YYYY')}</div>
      case "week":
        return <div className={s.title}>{building?.name} - Alertes du {moment(period.start).format('DD-MM-YYYY')} au {moment(period.end).format('DD-MM-YYYY')}</div>
      case "month":
        return <div className={s.title}>{building?.name} - Alertes du mois de {moment(period.start).locale('fr').format('MMMM YYYY').replace(/(^|\s)\S/g, l => l.toUpperCase())}</div>
      case "year":
        return <div className={s.title}>{building?.name} - Alertes du {moment(period.start).format('DD-MM-YYYY')} au {moment(period.end).format('DD-MM-YYYY')}</div>
      case "free":
        return <div className={s.title}>{building?.name} - Alertes du {moment(period.start).format('DD-MM-YYYY')} au {moment(period.end).format('DD-MM-YYYY')}</div>
      default:
        return null
    }
  }
  
  return (
    <div className={s.root}>
      
      <HeaderAnomalies/>
      
      <div className={s.content}>
        {building ? title() : null}
        <Filter className={s.control}/>
        {alertsIsLoading && <Loader/>}
        {!alertsIsLoading && <CardTimeline/>}
      </div>

    </div>
  );
}

export default Anomalies;