import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from 'react-router-dom'
import { checkEmail } from '../../../features/auth/authActions'
import { useNavigate } from "react-router-dom"

function Verify() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const token = params.get('token')    
    if (token) {
      dispatch(checkEmail({ token }))
      .unwrap()
      .then(() => navigate('/login')) 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <></>
  )
}

export default Verify





