import React from "react";

const ExportCSV = ({ chartData }) => {
    const downloadCSV = () => {
      if (!chartData || !chartData.datasets.length) return;
  
      let csvContent = "data:text/csv;charset=utf-8,";
  
      // Extract unique timestamps
      const timestamps = new Set();
      chartData.datasets.forEach((ds) => ds.data.forEach((point) => timestamps.add(point.x)));
      const sortedTimestamps = Array.from(timestamps).sort();
  
      // CSV Header
      csvContent += ["Timestamp", ...chartData.datasets.map((ds) => ds.label)].join(",") + "\n";
  
      // CSV Data Rows
      sortedTimestamps.forEach((timestamp) => {
        let row = [timestamp];
        row = row.concat(
          chartData.datasets.map((ds) => {
            const dataPoint = ds.data.find((point) => point.x === timestamp);
            return dataPoint ? dataPoint.y : "";
          })
        );
        csvContent += row.join(",") + "\n";
      });
  
      // Create downloadable link
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "timeseries_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
  
    return (
      <button onClick={downloadCSV} style={{ background: "none", border: "none", cursor: "pointer", marginLeft: "10px" }}>
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
          <polyline points="7 10 12 15 17 10" />
          <line x1="12" y1="15" x2="12" y2="3" />
        </svg>
      </button>
    );
  };
  
  export default ExportCSV;