import { createSlice } from '@reduxjs/toolkit';
import { 
  getConsumptionTrend,
  getOptimalContract,
  getPeakOffpeakRatio,
  getHighConsumptionAlert,
  getDayNightDiff,
  getCO2Mix,
  getPerformanceIndex,
  getBuildingRanking,
  getTypicalDay,
  getIndoorTempTrend,
  getHumidityRate,
  getComfortRange,
  getHoursOutsideComfort,
  getCO2Level,
  getGoodAirQualityTime,
} from './indicatorsActions';

const initialStateIndicators = {
  isLoading: false,
  consumption_trend: null,
  optimal_contract: null,
  peak_offpeak_ratio: null,
  high_consumption_alert: null,
  day_night_diff: null,
  co2_mix: null,
  performance_index: null,
  building_ranking: null,
  typical_day: null,
  indoor_temp_trend: null,
  humidity_rate: null,
  comfort_range: null,
  hours_outside_comfort: null,
  co2_level: null,
  good_air_quality_time: null,
  error: null,
};

const indicatorsSlice = createSlice({
  name: 'indicators',
  initialState: initialStateIndicators,
  reducers: {},
  extraReducers: builder => {
    builder
      // GET CONSUMPTION TREND
      .addCase(getConsumptionTrend.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getConsumptionTrend.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const { indicatorType, period, data } = payload;
        const periodKey = JSON.stringify(period);

        if (!state[indicatorType])
            state[indicatorType] = {};

        state[indicatorType][periodKey] = data; 
      })
      .addCase(getConsumptionTrend.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET OPTIMAL CONTRACT
      .addCase(getOptimalContract.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getOptimalContract.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const type = payload.type
        state[type] = payload.data
      })
      .addCase(getOptimalContract.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET PEAK OFFPEAK RATIO
      .addCase(getPeakOffpeakRatio.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPeakOffpeakRatio.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const { indicatorType, period, data } = payload;
        const periodKey = JSON.stringify(period);

        if (!state[indicatorType])
            state[indicatorType] = {};

        state[indicatorType][periodKey] = data; 
      })
      .addCase(getPeakOffpeakRatio.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET HIGH CONSUMPTION ALERT
      .addCase(getHighConsumptionAlert.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getHighConsumptionAlert.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const { indicatorType, period, data } = payload;
        const periodKey = JSON.stringify(period);

        if (!state[indicatorType])
            state[indicatorType] = {};

        state[indicatorType][periodKey] = data; 
      })
      .addCase(getHighConsumptionAlert.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET DAY NIGHT DIFF
      .addCase(getDayNightDiff.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDayNightDiff.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const type = payload.type
        state[type] = payload.data
      })
      .addCase(getDayNightDiff.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET CO2 MIX
      .addCase(getCO2Mix.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCO2Mix.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const type = payload.type
        state[type] = payload.data
      })
      .addCase(getCO2Mix.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET PERFORMANCE INDEX
      .addCase(getPerformanceIndex.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPerformanceIndex.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const type = payload.type
        state[type] = payload.data
      })
      .addCase(getPerformanceIndex.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET BUILDING RANKING
      .addCase(getBuildingRanking.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getBuildingRanking.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const { indicatorType, period, data } = payload;
        const periodKey = JSON.stringify(period);

        if (!state[indicatorType])
            state[indicatorType] = {};

        state[indicatorType][periodKey] = data; 
      })
      .addCase(getBuildingRanking.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET TYPICAL DAY
      .addCase(getTypicalDay.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTypicalDay.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const type = payload.type
        state[type] = payload.data
      })
      .addCase(getTypicalDay.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET INDOOR TEMP TREND
      .addCase(getIndoorTempTrend.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getIndoorTempTrend.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const { indicatorType, period, data } = payload;
        const periodKey = JSON.stringify(period);

        if (!state[indicatorType])
            state[indicatorType] = {};

        state[indicatorType][periodKey] = data; 
      })
      .addCase(getIndoorTempTrend.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET HUMIDITY RATE
      .addCase(getHumidityRate.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getHumidityRate.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const type = payload.type
        state[type] = payload.data
      })
      .addCase(getHumidityRate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET COMFORT RANGE
      .addCase(getComfortRange.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getComfortRange.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const type = payload.type
        state[type] = payload.data
      })
      .addCase(getComfortRange.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET HOURS OUTSIDE COMFORT
      .addCase(getHoursOutsideComfort.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getHoursOutsideComfort.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const type = payload.type
        state[type] = payload.data
      })
      .addCase(getHoursOutsideComfort.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET CO2 LEVEL
      .addCase(getCO2Level.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCO2Level.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const type = payload.type
        state[type] = payload.data
      })
      .addCase(getCO2Level.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // GET GOOD AIR QUALITY TIME
      .addCase(getGoodAirQualityTime.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getGoodAirQualityTime.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const type = payload.type
        state[type] = payload.data
      })
      .addCase(getGoodAirQualityTime.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default indicatorsSlice.reducer;
