import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import s from './ControlSelectBuilding.module.scss';
import { FormGroup, InputGroup, InputGroupText, Input, ListGroup, ListGroupItem } from 'reactstrap';
import { changeActiveBuilding, changeActiveRoom, initActiveBuilding, initActiveRoom } from '../../../features/navigation/navigationSlice';
import { selectIsUniqueBuilding, selectListBuildings } from '../../../selectors/selectBuildings';
import { selectActiveBuilding, selectActiveRoom, selectRooms, } from '../../../selectors/selectNavigation';
import { saveNavigationState } from '../../../features/navigation/navigationActions';
import store from '../../../redux/store';
import Icon from '../../Icon/Icon';

function ControlSelectBuilding() {

  const dispatch = useDispatch();

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const buildings = useSelector(selectListBuildings, shallowEqual);

  const isUniqueBuilding = useSelector(selectIsUniqueBuilding)

  const rooms = useSelector(selectRooms, shallowEqual)

  const building = useSelector(selectActiveBuilding, shallowEqual);
  const room = useSelector(selectActiveRoom, shallowEqual);

  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownBuildingOpen, setIsDropdownBuildingOpen] = useState(false);
  const [isDropdownRoomOpen, setIsDropdownRoomOpen] = useState(false);

  useEffect(() => {
    if (buildings?.length === 1 && !building) {
      const uniqueBuilding = buildings[0];
      setSearchTerm(uniqueBuilding.name);
      dispatch(changeActiveBuilding(uniqueBuilding));
      dispatch(initActiveRoom());
      dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    }
  }, [buildings, building, dispatch, user_id]);

  useEffect(() => {
    if (building) {
      setSearchTerm(building.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building]);

  const handleSearchChange = (e) => {
    if (e.target.value === '') {
      setSearchTerm('');
      setIsDropdownBuildingOpen(false);
      setIsDropdownRoomOpen(false);
      handleClickBuildingAll();
      dispatch(initActiveBuilding());
      dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    } else {
      setIsDropdownBuildingOpen(true);
      setSearchTerm(e.target.value);
    }
  };

  const filteredBuildings = buildings?.filter((building) => building?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()));

  const handleClickBuildingItem = (building) => {
    setSearchTerm(building.name);
    setIsDropdownBuildingOpen(false);
    dispatch(changeActiveBuilding(building));
    dispatch(initActiveRoom());
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
  };

  const handleClickBuildingAll = () => {
    setSearchTerm('');
    setIsDropdownBuildingOpen(false);
    dispatch(initActiveBuilding());
    dispatch(initActiveRoom());
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
  };

  const handleClickRoomItem = (room) => {
    dispatch(changeActiveRoom(room));
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    setIsDropdownRoomOpen(false)
  };

  const handleClickResetRoomItem = () => {
    dispatch(initActiveRoom());
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    setIsDropdownRoomOpen(false)
  };

  const handleDropdownListBuilding = (e) => {
    e.stopPropagation()
    setIsDropdownRoomOpen(false)
    setIsDropdownBuildingOpen(!isDropdownBuildingOpen)
  };

  const handleDropdownListRoom = (e) => {
    e.stopPropagation()
    setIsDropdownBuildingOpen(false)
    setIsDropdownRoomOpen(!isDropdownRoomOpen)
  };

  return (
    <div className={s.controler}>
      
      <FormGroup className={s.formGroupBuilding}>
        <InputGroup className={s.inputGroupBuilding}>
          <InputGroupText className={s.inputGroupText}>
            <span><Icon name={'domain'} size={'2rem'} color={'#3192D3'} fill={0} cursor/></span>
          </InputGroupText>
          <Input className={`${s.input} ${searchTerm ? s.activeText : ''}`} type={!isUniqueBuilding && 'search' && 'text'} placeholder={'Rechercher un bâtiment...'} value={searchTerm} onChange={!isUniqueBuilding && handleSearchChange} onClick={(e) => !isUniqueBuilding && handleDropdownListBuilding(e)} readOnly={isUniqueBuilding}/>
        </InputGroup>
        
        {!isUniqueBuilding && filteredBuildings.length > 0 && isDropdownBuildingOpen &&
        <ListGroup className={s.listGroup}>
          {(searchTerm && searchTerm !== building?.name ? filteredBuildings : buildings)?.map((building, index) => (
          <div className={s.item} key={index}>
            <Icon name={'circle'} size={'1.2rem'} color={'#2d8515'} fill={1} cursor/>
            <ListGroupItem className={s.name} onClick={() => handleClickBuildingItem(building)}>{building.name}</ListGroupItem> 
          </div>
          ))}
        </ListGroup>
        }
      </FormGroup>

      <FormGroup className={s.formGroupRoom}>
        <InputGroup className={s.inputGroupRoom} onClick={(e) => handleDropdownListRoom(e)}>
          <InputGroupText className={s.inputGroupText}>
            <span><Icon name={'meeting_room'} size={'2rem'} color={'#3192D3'} fill={0} cursor/></span>
          </InputGroupText>
          <Input className={s.input} name='room' value={room ? room?.name :  'Mes espaces'} readOnly/>
          <InputGroupText className={s.inputGroupText}>
            <Icon name={'keyboard_arrow_down'} size={'2rem'} color={'#000000'} fill={0} cursor/>
          </InputGroupText>
        </InputGroup>
        

        {rooms?.length > 0 && isDropdownRoomOpen &&
        <ListGroup className={s.listGroup}>
          <div className={s.item}>
            <ListGroupItem className={s.name} onClick={() => handleClickResetRoomItem()}>Mes espaces</ListGroupItem>
          </div>
          {rooms?.map((room, index) => (
          <div className={s.item} key={index}>
            <ListGroupItem className={s.name} onClick={() => handleClickRoomItem(room)}>{room.name}</ListGroupItem>
          </div>))}
        </ListGroup>
        }

      </FormGroup>
    </div>
  );
}

export default ControlSelectBuilding;