import moment from 'moment';
moment.locale('fr')

export const generateTickCallback = (periodUnit) => {
  return (value, index, values) => {
    const time = moment(values[index].value);
    const hours = time.hours();
    const minutes = time.minutes();
    switch (periodUnit) {
      case 'day':
        if ((hours % 3 === 0 && minutes === 0) || (hours === 0 && minutes === 0)) {
          return time.format('H[h]');
        }
        return '';
      case 'week':
        if (hours === 0 && minutes === 0) {
          const day = moment(time).locale('fr').format('dd');
          return day.charAt(0).toUpperCase() + day.charAt(1).toLowerCase();
        }
        return '';
      case 'month':
        if (hours === 0 && minutes === 0) {
          return time.format('D');
        }
        return '';
      case 'year':
        const formatted = moment(time).locale('fr').format('MMM');
        return formatted.charAt(0).toUpperCase() + formatted.slice(1);
      default:
        return ''
    }
  };
};


export const externalTooltipHandler = (periodUnit, unit) => (context) => {

  let tooltipEl = document.getElementById('chartjs-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.style.background = '#f4f4f5';
    tooltipEl.style.color = '#30465E';
    tooltipEl.style.fontSize = '0.8rem';
    tooltipEl.style.borderRadius = '1.25rem';
    tooltipEl.style.boxShadow = '0px 0px 10px rgba(0, 0, 0, 0.1)';
    tooltipEl.style.padding = '1rem';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transition = 'opacity 0.3s';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem;
  }

  // Filter out data points where raw is null
  const dataPoints = tooltipModel.dataPoints.filter(dp => dp.raw !== null && dp.raw !== 0);


  // Return if all data points are null
  if (dataPoints.length === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];

    const bodyLines = dataPoints.map((dp) => getBody(dp));
    const labelColors = dataPoints.map((dp) => dp.element.options);

    const bodyLinesReverse = [...bodyLines];
    const labelColorsReverse = [...labelColors];

    let innerHtml = '<thead>';

    titleLines.forEach(function (title) {
      innerHtml += '<tr><th>' + title + '</th></tr>';
    });
    innerHtml += '</thead><tbody>';

    bodyLinesReverse.forEach(function (body, i) {
      const colors = labelColorsReverse[i];
      let style = 'background:' + colors.backgroundColor;
      style += '; border-color:' + colors.borderColor;
      style += '; display:inline-block; width:10px; height:10px; border-radius:50%; margin-right:5px;';
      const span = '<span style="' + style + '"></span>';
      innerHtml += `<tr><td style="padding-bottom: 0rem;">${span}${body.dataset.label} : ${body.formattedValue} ${unit}</td></tr>`;      
    });

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  // `this` will be the overall tooltip
  const position = context.chart.canvas.getBoundingClientRect();
  const chartWidth = context.chart.width;
  const tooltipWidth = tooltipEl.offsetWidth;
  let left = position.left + window.pageXOffset + tooltipModel.caretX;

  // Adjust left position for the first bar
  if (tooltipModel.dataPoints[0].dataIndex >= 0 && tooltipModel.dataPoints[0].dataIndex <= 1) {
    left -= 15;
  }

  const offsetByCategoryLength = () => {
    return 8
  }

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = left + 'px';
  tooltipEl.style.top = position.top + window.scrollY - 20 + 'px';
}

export const titleTooltip = (periodUnit) => (tooltipItems) => {
  const index = tooltipItems[0].dataIndex;
  const allNull = tooltipItems.every(item => item.raw === null);
  return tooltipItems[0].label
}

export const labelTooltip = (consoUnit) => (tooltipItem) => {
  const { dataset, raw } = tooltipItem;
  if (raw === null) {
    return '';
  }
  let label = `${dataset.label}: ${raw?.y?.toFixed(2)}`;
  return label;
}

export const footerTooltip = (consoUnit) => (tooltipItems) => {
  const allNull = tooltipItems.every(item => item.raw === null);
  if (allNull) {
    return 'Données en cours de récupération';
  }
  let sum = 0;
  tooltipItems.forEach(function(tooltipItem) {
    if (tooltipItem.dataset.type === 'bar' && tooltipItem.raw !== null) {
      sum += tooltipItem.raw;
    }
  });
  return `Total: ${sum.toFixed(2)} ${consoUnit}`;
}
