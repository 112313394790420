import React from 'react';
import s from './HeaderBuildings.module.scss';
import NavigationBuildings from '../../Navigation/NavigationBuildings/NavigationBuildings';

function HeaderBuildings({ setNavigationIndex, navigationIndex, displayMode, setDisplayMode, onSearch }) {
  
  return (
    <div className={s.header}>
      <NavigationBuildings setNavigationIndex={setNavigationIndex} navigationIndex={navigationIndex} displayMode={displayMode} setDisplayMode={setDisplayMode} onSearch={onSearch}/>
    </div>
  );
}

export default HeaderBuildings;
