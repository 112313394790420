import { createSlice } from '@reduxjs/toolkit';
import { getAlerts, getAlertLabels } from './alertsActions';


export const initialStateAlerts = {
  isLoading: false,
  listAlerts: [],
  filtering: {
   alertLabels: [],
   alertStatus: ['Tous', 'En cours', 'Terminée']
  },
  error: null,
};

const alertsSlice = createSlice({
  name: 'alerts',
  initialState: initialStateAlerts,
  reducers: {
    initAlerts: () => initialStateAlerts,
  },
  extraReducers: builder => {
    builder
      //GET ALERTS
      .addCase(getAlerts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAlerts.fulfilled, (state, { payload }) => {
        state.listAlerts = payload.listAlerts;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getAlerts.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.listAlerts = [];
        state.error = payload;
      })
      //GET ALERT LABELS
      .addCase(getAlertLabels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAlertLabels.fulfilled, (state, { payload }) => {
        state.filtering.alertLabels = payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getAlertLabels.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.listAlerts = [];
        state.error = payload;
      })
      .addDefaultCase((state, action) => {});
  },
});

export const { initAlerts } = alertsSlice.actions;

export default alertsSlice.reducer;
