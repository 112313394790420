import React, { useState, useEffect } from 'react'
import s from './Step03CardAddress.module.scss'
import { Form, Button, FormGroup, InputGroup, InputGroupText, Input, Label } from 'reactstrap'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { searchPoints } from '../../../../../../../../features/sge/sgeActions'
import { selectDataIsLoading, selectPoints } from '../../../../../../../../selectors/selectSGE'
import { initSearchPoints } from '../../../../../../../../features/sge/sgeSlice'
import Loader from '../../../../../../../../components/Loader/Loader';

function Step03CardAddress({ inc, dec, setAuthorizationData, authorizationData }) {

  const dispatch = useDispatch()

  const points = useSelector(selectPoints, shallowEqual)

  const isLoadingSearchPoints = useSelector(selectDataIsLoading)

  const [noPoint, setNoPoint] = useState(false)

  useEffect(() => {
    const street = authorizationData.address.street
    const zipcode = authorizationData.address.zipcode
    const inseecode = authorizationData.address.inseecode
    const lastname = authorizationData.identity.lastname
    
    if (street !== '' && zipcode !== '' && inseecode !== ''  && lastname !== '' ) {
      dispatch(searchPoints({ street, zipcode, inseecode, lastname }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationData])

  useEffect(() => {
    if (points === null) {
      setNoPoint(true)
    } else {
      setNoPoint(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points, authorizationData])

  const decStep = () => {
    setNoPoint(false)
    dispatch(initSearchPoints())
    dec()
  }

  const incStep = () => {
    inc()
  }

  return (
    <div className={s.root}>

      <div className={s.content}>
        <div className={s.description}>
          Veuillez renseigner l'identité de l'entreprise figurant sur le contrat de fourniture d'électricité.
        </div>
        <Form className={s.form}>
          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Adresse</Label>
            <Input className={s.input} type="text" value={authorizationData?.address?.street.toUpperCase()} readOnly/>
          </FormGroup>

          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Commune</Label>
            <Input className={s.input} type="text" value={authorizationData?.address?.zipcode + ' ' + authorizationData?.address?.city.toUpperCase()} readOnly/>
          </FormGroup> 

          {isLoadingSearchPoints && <Loader/>}

          {!isLoadingSearchPoints && noPoint && <div className={s.message}>Nous n'avons trouvé aucun point de livraison avec vos Informations</div>}
          {!isLoadingSearchPoints && !noPoint && <div className={s.message}>Nous avons trouvé un ou plusieurs compteurs avec vos Informations</div>}
        </Form>

      </div>

      <div className={s.control}>
        <Button color="primary" onClick={() => decStep()}>Précédent</Button>
        <Button color="primary" onClick={() => incStep()} disabled={isLoadingSearchPoints || authorizationData?.address?.street === '' || authorizationData?.address?.zipcode === '' || authorizationData?.address?.inseecode === '' || authorizationData?.address?.city === '' || noPoint}>Suivant</Button> 
      </div>

    </div>
  )
}

export default Step03CardAddress
