import React from 'react';
import s from './ModalHighConsumptionAlert.module.scss';
import Modal from '../../../Modal/Modal';
import Icon from '../../../Icon/Icon';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin);

function ModalHighConsumptionAlert({showModalHighConsumptionAlert, handleClose}) {

  const data = [59.2, 59.6, 59.8, 60.1, 59.4, 61, 59.3, 59.7, 59.5, 59.6]; // Une valeur à 61
  const labels = ["00h", "3h", "6h", "9h", "12h", "15h", "18h", "21h", "00h", "3h"];

  // Configuration du graphique
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Consommation (kWh)',
        data,
        borderColor: '#3192D3', 
        backgroundColor: '#3192D3',
        pointBackgroundColor: data.map(value => (value === 61 ? '#3192D3' : '#3192D3')),
        pointBorderColor: data.map(value => (value === 61 ? '#3192D3' : '#3192D3')),
        pointRadius: data.map(value => (value === 61 ? 6 : 4)),
        fill: false,
      },
      {
        label: 'Pic de consommation',
        backgroundColor: '#3192d334',
        borderColor: '#3192d334'
      }
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 59,
        max: 62,
      },
      x: {
        ticks: {
          callback: function (val, index) {
            return labels[index]; // Affiche uniquement certaines heures
          },
        },
      },
    },
    plugins: {
      annotation: {
        annotations: {
          highConsumption: {
            type: 'box',
            xMin: labels.indexOf("15h") - 0.5, // Met en évidence l'heure où la consommation est 61
            xMax: labels.indexOf("15h") + 0.5,
            backgroundColor: '#3192d334',
            borderColor: '#3192d334'
          },
        },
      },
    },
  };

  return (
    <Modal show={showModalHighConsumptionAlert}>
      <div className={s.header}>
        <div className={s.title}>OBSERVER MES PICS DE CONSOMMATION</div>
        <Icon name='close' size={'1.5rem'} color={'#30465E'} fill={0} onClick={handleClose} cursor />
      </div>
      <div className={s.content}>
        <div className={s.message}>
          Sur la période que vous avez sélectionné, vous avez particulièrement plus consommé à XX le XX/le XX MOIS/la semaine du XX MOIS par rapport à la moyenne dans cette période.
        </div>
        <div className={s.chartContainer}>
          <Line data={chartData} options={chartOptions} />
        </div>
        <div className={s.solution}>
          <div className={s.title}>
            Que faire de cette information ?
          </div>
          <div className={s.advice}>
            Cette information vous permet de savoir ce qui, dans vos activités, engendre une bien plus forte consommation par rapport à vos habitudes. En prendre conscience vous permet, même inconsciemment, de réduire ou optimiser (lorsque c’est possible) ces activités.
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalHighConsumptionAlert;
