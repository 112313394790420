import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import s from './NavigationBuildings.module.scss';
import classnames from 'classnames/bind';
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import Icon from '../../Icon/Icon';
import { changeActiveRoute, initActiveBuilding } from '../../../features/navigation/navigationSlice';
import { saveNavigationState } from '../../../features/navigation/navigationActions';
import store from '../../../redux/store';

const cx = classnames.bind(s);

function NavigationBuildings({ setNavigationIndex, navigationIndex,  displayMode, setDisplayMode, onSearch }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

   const [isDropdown, setIsDropdown] = useState(false);

  const [hoverIndex, setHoverIndex] = useState(null);

  const itemNav = [
    {icon_name: 'domain', label: 'Tous'},
    {icon_name: 'star', label: 'Mes favoris'}
  ]

  const iconsDisplaying = ['web', 'view_list'];

  const [searchQuery, setSearchQuery] = useState('');

  const handleClickNavigation = (index) => {
    setNavigationIndex(index)
  }

  const handleMouseEnter = (index) => setHoverIndex(index);
  const handleMouseLeave = () => setHoverIndex(null);

  const isBtnDisplayingDisabled = (index) => {
    return displayMode === index;
  }

  const handleChangeDisplaying = (index) => {
    setDisplayMode(index);
    setIsDropdown(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    onSearch(e.target.value);
  };

  const handleAdd = () => {
    dispatch(changeActiveRoute('/settings/building/add'))
    dispatch(initActiveBuilding())
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
    navigate('/settings/building/add')
  }

  return (
    <div className={s.nav}>

      <div className={s.nav_h}>
        <div className={s.displaying}>
          <Button className={s.btn} onClick={() => setIsDropdown(!isDropdown)}>
            <Icon name='filter_list' size={'1.5rem'} color='#30465E' fill={0}/>
            <span className={s.item}>Affichage</span>
          </Button>    
          {isDropdown && (
          <div className={s.dropdown}>
            {iconsDisplaying.map((icon_name, index) => (
            <Button key={index} className={cx(s.btn, { [s.active]: displayMode === index, [s.disabled]: isBtnDisplayingDisabled(index) })} onClick={() => handleChangeDisplaying(index)} disabled={isBtnDisplayingDisabled(index)}>
              <Icon name={icon_name} size={'1.5rem'} color={displayMode === index ? '#3192D3' : '#30465E'} opsz={48} wght={400} fill={0}/>
            </Button>
            ))}
          </div>
          )}
        </div>
        <div className={s.menu}>
          {itemNav.map((item, index) => (
          <Button key={index} className={cx(s.btn, { [s.active]: navigationIndex === index})} 
          onClick={() => handleClickNavigation(index)}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}>
            <Icon name={item.icon_name} size={'1.5rem'} color={hoverIndex === index || navigationIndex === index ? '#3192D3' : '#30465E'} fill={0}/>
            <span className={s.item}>{item.label}</span>
          </Button>
          ))}
        </div>
        <div className={s.search}>
          <InputGroup className={s.inputGroup}>
            <Input className={s.input} type='text' placeholder='Rechercher un bâtiment' value={searchQuery} onChange={handleSearchChange}/>
            <InputGroupText className={s.inputGroupText}><Icon name="search" size={'1.4rem'} color={'#30465E'} fill={1}/></InputGroupText>
          </InputGroup>
        </div>
        <div className={s.add}>
          <Button className={s.btn} onClick={handleAdd}>
            <Icon name="domain_add" size={'1.5rem'} color={'#FFF'} fill={0}/>
            <span className={s.item}>Ajouter un bâtiment</span>
          </Button>
        </div>

      </div>
      <div className={s.nav_v}>
        <Input className={s.input} name="select" type="select" value={navigationIndex} onChange={(e) => handleClickNavigation(parseInt(e.target.value))}>
          {itemNav.map((item, index) => (
          <option className={s.option} key={index} value={index}>{item.label}</option>
          ))}
        </Input>
      </div>

    </div>
  );
}

export default NavigationBuildings;