import { defaultAxiosInstance } from '../../axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit'

export const createDevice = createAsyncThunk(
  'devices/create',
  async ({ user_id, building_id, serial_number }, { rejectWithValue }) => {
    try {
      const response = await defaultAxiosInstance.post(`/devices`, { user_id, building_id, serial_number })
      const device_id = response.data
      return { device_id }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getDevices = createAsyncThunk(
  'devices/get',
  async ({ user_id }, { rejectWithValue }) => {
    try {
      const params = { user_id }
      const response = await defaultAxiosInstance.get(`/devices/`, { params })
      return response
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getDevicesByRoom = createAsyncThunk(
  'devices/get_by_room',
  async ({ room_id }, { rejectWithValue }) => {
    try {
      const params = { room_id }
      const response = await defaultAxiosInstance.get(`/devices/by_room`, { params })
      return response
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateDevice = createAsyncThunk(
  'devices/update',
  async ({ data }, { rejectWithValue }) => {
    try { 
      const device_id = data.device_id
      await defaultAxiosInstance.put(`/devices/${device_id}`, { data })    
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteDevice = createAsyncThunk(
  'devices/delete',
  async ({ device_id }, { rejectWithValue }) => {
    try {
      const params = { device_id }
      await defaultAxiosInstance.delete(`/devices/${device_id}`, { params })
      return { device_id }      
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

