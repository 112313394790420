import { defaultAxiosInstance } from '../../axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit'


export const saveNavigationState = createAsyncThunk(
  'navigation/saveState',
  async ({ user_id, state }, { rejectWithValue }) => {
    try {
      await defaultAxiosInstance.post(`/navigation/`, { user_id, state });
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getNavigationState = createAsyncThunk(
  'navigation/get',
  async ({ user_id }, { rejectWithValue }) => {
    try {
      const params = { user_id }
      const response = await defaultAxiosInstance.get(`/navigation/state`, { params })
      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)