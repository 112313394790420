import React, { useEffect, useState } from 'react'
import s from './ModalAddDevice.module.scss'
import Modal from '../../../../../../components/Modal/Modal'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Step01CardWelcome from './components/Step01CardWelcome/Step01CardWelcome'
import Step02CardSerialNumber from './components/Step02CardSerialNumber/Step02CardSerialNumber'
import Step03CardWaitingForPairing from './components/Step03CardWaitingForPairing/Step03CardWaitingForPairing'
import Step04CardPairingSuccess from './components/Step04CardPairingSuccess/Step04CardPairingSuccess'
import Step05CardDeviceInfo from './components/Step05CardDeviceInfo/Step05CardDeviceInfo'
import Step06CardCongratulations from './components/Step06CardCongratulations/Step06CardCongratulations'
import { deleteSerialNumber, initPairing, resetDeviceExist } from '../../../../../../features/pairing/pairingSlice'
import Icon from '../../../../../../components/Icon/Icon'
import Step00SelectOptions from './components/Step00SelectOptions/Step00SelectOptions'
import StepBarV1 from '../../../../../../components/StepBarV1/StepBarV1'
import Step07AffectToBuilding from './components/Step07AffectToBuilding/Step07AffectToBuilding'
import { selectActiveBuilding } from '../../../../../../selectors/selectNavigation'
import { updateDevice } from '../../../../../../features/devices/devicesActions'
import { toast } from 'react-toastify'

function ModalAddDevice({ showModalAddDevice, handleClose, onFinish }) {

  const dispatch = useDispatch()

  const building = useSelector(selectActiveBuilding, shallowEqual)

  const [step, setStep] = useState(0)
  const [selectedDevice, setSelectedDevice] = useState(null)
  const maxStep = 6
  const stepTitle = [
    "",
    "",
    "Veuillez saisir l'identifiant de votre capteur",
    "Veuillez faire un appui bref sur le bouton bleu de votre capteur",
    "Votre équipement a été associé avec succès !",
    "Donnez un nom et une description à votre équipement",
    "Félicitations !",
    "Associer cet équipement à votre bâtiment ?",
  ]

  const handleCloseModal = () => {
    setStep(0)
    dispatch(deleteSerialNumber())
    dispatch(initPairing())
    handleClose()
  }

  useEffect(() => {
    console.log(selectedDevice)
    console.log(step)
  },[selectedDevice,step])

  const onDiscard = () => {
    setStep(0)
    dispatch(deleteSerialNumber())
    dispatch(resetDeviceExist())
    dispatch(initPairing())
    handleClose()
  }

  const hardInc = () => {
    setStep(step + 7)
  }

  const hardDec = () => {
    setStep(step - 7)
  }

  const doFinish = () => {
    setStep(0)
    dispatch(deleteSerialNumber())
    dispatch(resetDeviceExist())
    dispatch(initPairing())
    onFinish()
  }

  const handleDevice = (device) => {
    setSelectedDevice(device)
    hardInc()
  }

  const inc = () => {
    setStep(step + 1)
  }

  const dec = () => {
    setStep(step - 1)
    dispatch(resetDeviceExist())
  }

  const affectBuilding = () => {
    const updatedData = {
      ...selectedDevice,
      building: building,
      room: '',
    };
    console.log(updatedData)
    dispatch(updateDevice({ data: updatedData }))
    toast.success("Equipement associé !")
    handleCloseModal()
  }

  const StepCard = () => {
    switch(step) {
      case 0 : 
        return (
          <Step00SelectOptions discard={onDiscard} inc={inc} handleDevice={handleDevice} setSelectedDevice={setSelectedDevice} />
        )
      case 1:
        return (
          <Step01CardWelcome discard={onDiscard} inc={inc}/>
        )
      case 2:
        return (
          <Step02CardSerialNumber discard={onDiscard} inc={inc} dec={dec}/>
          )
      case 3:
        return (
          <Step03CardWaitingForPairing discard={onDiscard} inc={inc} dec={dec}/>  
        )
      case 4:
        return (
          <Step04CardPairingSuccess inc={inc}/>  
        )
      case 5:
        return (
          <Step05CardDeviceInfo discard={onDiscard} inc={inc} dec={dec}/>  
        )
      case 6:
        return (
          <Step06CardCongratulations finish={doFinish}/>  
        )
      case 7: 
        return (
          <Step07AffectToBuilding inc={hardInc} dec={hardDec} device={selectedDevice} affectBuilding={affectBuilding}  />
        )
      default:
        return
    }
  }

  return (
    <Modal show={showModalAddDevice}>

      <div className={s.header}>
        <div className={s.title}>Ajouter un équipement</div>
        <Icon name="close" size={'1.5rem'} color={'#30465E'} fill={0} onClick={handleCloseModal} cursor/>
      </div>

      <div className={s.stepper}>
        {step > 0 && step < 7 && <StepBarV1 step={step} maxStep={maxStep}/>}
        <div className={s.step_title}>{stepTitle[step]}</div>
      </div>

      {StepCard()}

    </Modal>
  )
}

export default ModalAddDevice