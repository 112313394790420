import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'

export const selectSGE = (state) => {
    return state.sge
}

// ############################################################################
// RP IS LOADING
export const select_RP_IsLoading = createSelector(
    (state) => state.sge.RP_is_loading,
    (RP_is_loading) => RP_is_loading
)

// RP RESULT
export const select_RP_Result = createSelector(
    (state) => state.sge.RP_result,
    (RP_result) => RP_result
)

// RP ERROR
export const select_RP_Error = createSelector(
    (state) => state.sge.RP_error,
    (RP_error) => RP_error
)

export const selectErrorSGE = () => state => state.sge?.error || null

export const selectDataIsLoading = createSelector(
  (state) => state.sge.isLoading,
  (isLoading) => isLoading
)

export const selectPoints = createSelector(
  [selectSGE],
  (sge) => {
    if (
      sge &&
      sge.searchPoints &&
      sge.searchPoints.points &&
      sge.searchPoints.points.point
    ) {
      const points = sge.searchPoints.points.point;
      return points.map((point) => point.attributes.id); // Retourne un tableau des ids
    }
    return []; // Retourne un tableau vide si les données ne sont pas présentes
  }
);


export const selectLoadCurve = (sge_authorization_id, periodUnit) => {
  return (state) => {
    if (!state.sge) {
      return []
    }

    let data;

    switch (periodUnit) {
      case 'day':
        data = state.sge.dataByHour.find(d => d.sge_authorization_id === sge_authorization_id);
        break
      case 'week':
        data = state.sge.dataByDay.find(d => d.sge_authorization_id === sge_authorization_id);
        break
      case 'month':
        data = state.sge.dataByMonth.find(d => d.sge_authorization_id === sge_authorization_id);
        break
      case 'year':
        data = state.sge.dataByYear.find(d => d.sge_authorization_id === sge_authorization_id);
        break
      default:
        return []
    }
    return data ? data : []
  }
}

export const selectAggregateData = (sge_authorization_id, periodUnit) => {
    return (state) => {
      if (!state.sge) return null
  
      const dataKey = {
        day: 'dataByHour',
        week: 'dataByDay',
        month: 'dataByMonth',
        year: 'dataByYear',
      }[periodUnit] || 'dataByHour';
  
      const dataGroup = state.sge[dataKey]

      if (!dataGroup) return null;
  
      const dataIndex = dataGroup.findIndex(data => data.sge_authorization_id === sge_authorization_id);

      if (dataIndex === -1) return null;
  
      return dataGroup[dataIndex].aggregateData;
    };
}

export const selectRatioConsumption = (sge_authorization_id, periodUnit) => {
  return (state) => {

    if (!state.sge) return null

    const dataKey = {
      day: 'dataByHour',
      week: 'dataByDay',
      month: 'dataByMonth',
      year: 'dataByYear',
    }[periodUnit] || 'dataByHour';

    const dataGroup = state.sge[dataKey]

    if (!dataGroup) return null;

    const dataIndex = dataGroup.findIndex(item => item.sge_authorization_id === sge_authorization_id);

    if (dataIndex === -1) return null;

    const data = dataGroup[dataIndex].transformedData

    const totals = data.reduce(
      (acc, curr) => {
        acc.kwhHP += curr.kwhHP || 0;
        acc.kwhHC += curr.kwhHC || 0;

        acc.pricingHP += curr.pricingHP || 0;
        acc.pricingHC += curr.pricingHC || 0;

        return acc;
      },
      {
        kwhHP: 0,
        kwhHC: 0,
        pricingHP: 0,
        pricingHC: 0,
      }
    );

    const totalPricingHC = totals.pricingHC + data[0].subscriptionHpHc * 16
    const totalPricingHP = totals.pricingHP + data[0].subscriptionHpHc * 32

    const totalKwh = totals.kwhHP + totals.kwhHC;
    const totalPricing = totalPricingHP + totalPricingHC;

    return {
      kwhRatios: {
        hp: totalKwh ? (totals.kwhHP / totalKwh) * 100 : 0,
        hc: totalKwh ? (totals.kwhHC / totalKwh) * 100 : 0,
      },
      pricingRatios: {
        hp: totalPricing ? (totalPricingHP / totalPricing) * 100 : 0,
        hc: totalPricing ? (totalPricingHC / totalPricing) * 100 : 0,
      },
    };


  }
}



  