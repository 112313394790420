import React, { useState, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import s from './ModalAddLinky.module.scss'
import { v4 as uuid } from 'uuid'
import Step01CardIntro from './components/Step01CardIntro/Step01CardIntro'
import Step02CardIdentity from './components/Step02CardIdentity/Step02CardIdentity'
import Step02CardIdentityPro from './components/Step02CardIdentityPro/Step02CardIdentityPro'
import Step03CardAddress from './components/Step03CardAddress/Step03CardAddress'
import Step04CardPRM from './components/Step04CardPRM/Step04CardPRM'
import Step05CardAuthorization from './components/Step05CardAuthorization/Step05CardAuthorization'
import Step06CardValidation from './components/Step06CardValidation/Step06CardValidation'
import Step07CardCongratulations from './components/Step07CardCongratulations/Step07CardCongratulations'
import StepBarV2 from '../../../../../../components/StepBarV2/StepBarV2'
import Modal from '../../../../../../components/Modal/Modal'
import { selectActiveBuilding } from '../../../../../../selectors/selectNavigation'
import { selectProfile } from '../../../../../../selectors/selectUser'
import StepBarV1 from '../../../../../../components/StepBarV1/StepBarV1'
import Icon from '../../../../../../components/Icon/Icon'

function ModalAddLinky({ showModalAddLinky, handleClose, handleError }) {
  
  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id

  const profile = useSelector(selectProfile, shallowEqual)
  
  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id

  const [stepIndex, setStepIndex] = useState(0)
  const [hideCloseBtn, setHideCloseBtn] = useState(false)
  
  const stepTitleIndividual = [
    "",
    "Votre identité",
    "Votre adresse de point de livraison",
    "Votre numéro de compteur",
    "Autorisations",
    "Récapitulatif",
    "Félicitations !"
  ]

  const stepTitleBusiness = [
    "",
    "Votre identité",
    "L'identité de l'entreprise",
    "L'adresse de point de livraison",
    "Le numéro de compteur",
    "Autorisations",
    "Récapitulatif",
    "Félicitations !"
  ]

  const [authorizationData, setAuthorizationData] = useState({
    sge_authorization_id: '',
    user_id: '',
    building_id: '',
    point_id: '',
    identity: {
      firstname: '',
      lastname: '',
      company_name: '',
      legal_status: '',
      registration_number: '',
      person: '' //legal or natural
    },
    contact: {
      email: ''
    },
    address: {
      street: '',
      zipcode: '',
      inseecode: '',
      city: '',
      geolocation: []
    },
    authorization: {
      measure_history: false,
      index_history: false,
      pmax_history: false,
      load_curve_history: false,
      technical_and_contract: false
    },
    expires_at: ''
  })
  
  const maxStep = authorizationData.identity.person === 'natural' ? 7 : 8

  useEffect(() => {
    if (showModalAddLinky) {
      setAuthorizationData((prevData) => ({
        ...prevData, 
        sge_authorization_id: uuid(),
        user_id: user_id,
        building_id: building_id,
        identity: {
          ...prevData.identity,
          firstname: profile?.firstname,
          lastname: profile?.lastname
        },
        contact: {
          ...prevData.contact,
          email: profile?.email
        },
        address: {
          ...prevData.address,
          street: building?.address?.street,
          zipcode: building?.address?.zipcode,
          city: building?.address?.city,
          inseecode: building?.address?.inseecode,
          geolocation: building?.address?.geolocation
        },
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalAddLinky])

  useEffect(() => {
    if (stepIndex === 6) {
      setHideCloseBtn(true)
    }
    if (stepIndex === 7) {
      setHideCloseBtn(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIndex])


  const inc = () => {
    setStepIndex(stepIndex + 1)
  }

  const dec = () => {
    setStepIndex(stepIndex - 1)
  }

  const onError = () => {
    setStepIndex(1)
    handleError()
  }

  const handleCreate = () => {
    setStepIndex(0)
    setHideCloseBtn(false)
    handleClose()
    setAuthorizationData({
      sge_authorization_id: '',
      user_id: '',
      building_id: '',
      point_id: '',
      identity: {
        firstname: '',
        lastname: '',
        company_name: '',
        legal_status: '',
        registration_number: '',
        person: '' //legal or natural
      },
      contact: {
        email: ''
      },
      address: {
        street: '',
        zipcode: '',
        inseecode: '',
        city: '',
        geolocation: []
      },
      authorization: {
        measure_history: false,
        index_history: false,
        pmax_history: false,
        load_curve_history: false,
        technical_and_contract: false
      },
      expires_at: ''
    })
  }

  const closeModal = () => {
    setStepIndex(0)
    setHideCloseBtn(false)
    setAuthorizationData({
      sge_authorization_id: '',
      user_id: '',
      building_id: '',
      point_id: '',
      identity: {
        firstname: '',
        lastname: '',
        company_name: '',
        legal_status: '',
        registration_number: '',
        person: '' //legal or natural
      },
      contact: {
        email: ''
      },
      address: {
        street: '',
        zipcode: '',
        inseecode: '',
        city: '',
        geolocation: []
      },
      authorization: {
        measure_history: false,
        index_history: false,
        pmax_history: false,
        load_curve_history: false,
        technical_and_contract: false
      },
      expires_at: ''
    })
    handleClose()
  }

  return (
    <Modal show={showModalAddLinky}>

      <div className={s.header}>
        <div className={s.title}>LIER MES DONNÉES DE CONSOMMATIONS ÉLECTRIQUES À INSENSIA</div>
        <Icon name="close" size={'1.5rem'} color={'#30465E'} fill={0} onClick={closeModal} cursor/>
      </div>

      <div className={s.stepper}>
        {stepIndex > 0 && <StepBarV1 step={stepIndex} maxStep={maxStep}/>}
      </div>


      {stepIndex === 0 && <Step01CardIntro inc={inc} setAuthorizationData={setAuthorizationData} authorizationData={authorizationData}/>}
      {authorizationData.identity.person === 'natural' &&
        <>
        {stepIndex === 1 && <Step02CardIdentity inc={inc} dec={dec} setAuthorizationData={setAuthorizationData} authorizationData={authorizationData}/>}
        {stepIndex === 2 && <Step03CardAddress inc={inc} dec={dec} setAuthorizationData={setAuthorizationData} authorizationData={authorizationData}/>}
        {stepIndex === 3 && <Step04CardPRM inc={inc} dec={dec} onError={onError} setAuthorizationData={setAuthorizationData} authorizationData={authorizationData}/>}
        {stepIndex === 4 && <Step05CardAuthorization inc={inc} dec={dec} setAuthorizationData={setAuthorizationData} authorizationData={authorizationData}/>}
        {stepIndex === 5 && <Step06CardValidation inc={inc} dec={dec} setAuthorizationData={setAuthorizationData} authorizationData={authorizationData}/>}
        {stepIndex === 6 && <Step07CardCongratulations handleCreate={handleCreate}/>}
        </>
      }

      {authorizationData.identity.person === 'legal' &&
        <>
        {stepIndex === 1 && <Step02CardIdentity inc={inc} dec={dec} setAuthorizationData={setAuthorizationData} authorizationData={authorizationData}/>}
        {stepIndex === 2 && <Step02CardIdentityPro inc={inc} dec={dec} setAuthorizationData={setAuthorizationData} authorizationData={authorizationData}/>}
        {stepIndex === 3 && <Step03CardAddress inc={inc} dec={dec} setAuthorizationData={setAuthorizationData} authorizationData={authorizationData}/>}
        {stepIndex === 4 && <Step04CardPRM inc={inc} dec={dec} onError={onError} setAuthorizationData={setAuthorizationData} authorizationData={authorizationData}/>}
        {stepIndex === 5 && <Step05CardAuthorization inc={inc} dec={dec} setAuthorizationData={setAuthorizationData} authorizationData={authorizationData}/>}
        {stepIndex === 6 && <Step06CardValidation inc={inc} dec={dec} setAuthorizationData={setAuthorizationData} authorizationData={authorizationData}/>}
        {stepIndex === 7 && <Step07CardCongratulations handleCreate={handleCreate}/>}
        </>
      }

      

    </Modal>
  )
}

export default ModalAddLinky
