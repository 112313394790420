import React, { useState, useEffect } from 'react'
import s from './Step05CardAuthorization.module.scss'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import moment from 'moment'
moment.locale('fr')

function Step05CardAuthorization({ inc, dec, setAuthorizationData, authorizationData }) {

  const [allCheck, setAllCheck] = useState(false)
  const [historyMeasureAuth, setHistoryMeasureAuth] = useState(false)
  const [historyIndexAuth, setHistoryIndexAuth] = useState(false)
  const [historyPmaxAuth, setHistoryPmaxAuth] = useState(false)
  const [historyLoadCurveAuth, setHistoryLoadCurveAuth] = useState(false)
  const [contractAuth, setContractAuth] = useState(false)

  useEffect(() => {
    if (historyMeasureAuth && historyIndexAuth && historyPmaxAuth && historyLoadCurveAuth && contractAuth) {
      setAllCheck(true)
    } else {
      setAllCheck(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyMeasureAuth, historyIndexAuth, historyPmaxAuth, historyLoadCurveAuth, contractAuth])

  const changeAllCheck = (e) => {
    if (e.target.checked) {
      setAllCheck(true)
      setHistoryMeasureAuth(true) 
      setHistoryIndexAuth(true)
      setHistoryPmaxAuth(true)
      setHistoryLoadCurveAuth(true)
      setContractAuth(true)
      setAuthorizationData(prevData => ({
        ...prevData, 
        authorization: {
          ...prevData.authorization,
          measure_history: true,
          index_history: true,
          pmax_history: true,
          load_curve_history: true,
          technical_and_contract: true
        }
      }))
    } else {
      setAllCheck(false)
      setHistoryMeasureAuth(false) 
      setHistoryIndexAuth(false)
      setHistoryPmaxAuth(false)
      setHistoryLoadCurveAuth(false)
      setContractAuth(false)
      setAuthorizationData(prevData => ({
        ...prevData, 
        authorization: {
          ...prevData.authorization,
          measure_history: false,
          index_history: false,
          pmax_history: false,
          load_curve_history: false,
          technical_and_contract: false
        }
      }))
    } 
  }

  const changeHistoryMeasureAuth = (e) => {
    setHistoryMeasureAuth(e.target.checked) 
    setAuthorizationData(prevData => ({
      ...prevData, 
      authorization: {
        ...prevData.authorization,
        measure_history: e.target.checked
      }
    }))
  }

  const changeHistoryIndexAuth = (e) => {
    setHistoryIndexAuth(e.target.checked)
    setHistoryPmaxAuth(e.target.checked)
    setAuthorizationData(prevData => ({
      ...prevData, 
      authorization: {
        ...prevData.authorization,
        index_history: e.target.checked,
        pmax_history: e.target.checked,
      }
    }))
  }

  const changeHistoryPmaxAuth = (e) => {
    setAuthorizationData(prevData => ({
      ...prevData, 
      authorization: {
        ...prevData.authorization,
        pmax_history: e.target.checked
      }
    }))
  }

  const changeHistoryLoadCurveAuth = (e) => {
    setHistoryLoadCurveAuth(e.target.checked)
    setAuthorizationData(prevData => ({
      ...prevData, 
      authorization: {
        ...prevData.authorization,
        load_curve_history: e.target.checked
      }
    }))
  }

  const changeContractAuth = (e) => {
    setContractAuth(e.target.checked)
    setAuthorizationData(prevData => ({
      ...prevData, 
      authorization: {
        ...prevData.authorization,
        technical_and_contract: e.target.checked
      }
    }))
  }

  const decStep = () => {
    setAuthorizationData(prevData => ({
      ...prevData, 
      authorization: {
        ...prevData.authorization,
        measure_history: historyMeasureAuth,
        index_history: historyIndexAuth,
        pmax_history: historyPmaxAuth,
        load_curve_history: historyLoadCurveAuth,
        technical_and_contract: contractAuth
      },
      expires_at: (moment().add(3, 'y')).toISOString(),
    }))
    dec()
  }

  const incStep = () => {
    setAuthorizationData(prevData => ({
      ...prevData, 
      authorization: {
        ...prevData.authorization,
        measure_history: historyMeasureAuth,
        index_history: historyIndexAuth,
        pmax_history: historyPmaxAuth,
        load_curve_history: historyLoadCurveAuth,
        technical_and_contract: contractAuth
      },
      expires_at: (moment().add(3, 'y')).toISOString(),
    }))
    inc()
  }

  return (
    <div className={s.root}>

      <div className={s.content}>
        
        <div className={s.description}>Ce consentement est applicable pour les données suivantes :</div>
        
        <Form className={s.form}>
          
          <FormGroup className={s.checkgroup} check>
            <Input className={s.checkbox} type='checkbox' onChange={(e) => changeAllCheck(e)} checked={allCheck}/>
            <Label check className={s.label}>Tout cocher</Label>
          </FormGroup>

          <FormGroup className={s.checkgroup} check>
            <Input className={s.checkbox} type='checkbox'onChange={changeHistoryMeasureAuth} checked={historyMeasureAuth}/>
            <Label check className={s.label}>L’historique des mesures, en kWh (ainsi que les puissances atteintes et les dépassements de puissance), sur les 3 dernières années ;</Label>
          </FormGroup>

          <FormGroup className={s.checkgroup} check>
            <Input className={s.checkbox} type='checkbox'onChange={changeHistoryIndexAuth} checked={historyIndexAuth}/>
            <Label check className={s.label}>L’historique des relevés d’index quotidiens, en kWh, et la puissance maximale quotidienne, en kVA ou kWh, du site (sur les 3 dernières années) ;</Label>
          </FormGroup>
       
          <FormGroup className={s.checkgroup} check>
            <Input className={s.checkbox} type='checkbox' onChange={changeHistoryLoadCurveAuth} checked={historyLoadCurveAuth}/>
            <Label check className={s.label}>L’historique de courbe de charge jusqu’au pas du site (sur les 2 dernières années) ;</Label>
          </FormGroup>

          <FormGroup className={s.checkgroup} check>
            <Input className={s.checkbox} type='checkbox' onChange={changeContractAuth} checked={contractAuth}/>  
            <Label check className={s.label}>Les données techniques et contractuelles disponibles</Label>
          </FormGroup>

        </Form>

      </div>

      <div className={s.control}>
        <Button color="primary" onClick={() => decStep()}>Précédent</Button> 
        <Button color="primary" onClick={() => incStep()} disabled={!allCheck}>Suivant</Button> 
      </div>

    </div>
  )
}

export default Step05CardAuthorization
