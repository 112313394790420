import React from 'react'
import { Document, Image, Page, Path, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import moment from 'moment';
moment.locale('fr');

const PdfConsult = ({ reportData, createdAt, images, periodUnit, period }) => {

  const styles = StyleSheet.create({
    presentationPage:{
      backgroundColor:'#EAF4FB',
      padding: 80,
      color : '#274AC7',
      justifyContent:'space-between',
    },
    presentationPageTitle:{
      justifyContent:'space-between',
      flexDirection:'column',
      borderBottom : '2px solid #2D54D5'
    },
    presentationPageBody:{
      flexDirection:'column',
      gap:8,
      
    },
    presentationPageFooter:{
      borderTop : '2px solid #2D54D5',
      flexDirection:'row',
      justifyContent:'space-between'
    },
    buildingInfosFooter:{
      paddingTop:35,
      gap:5,
      flexDirection:'column'
    },
    infosFooter:{
      alignItems:'flex-end',
      paddingTop:35,
      gap:5,
      flexDirection:'column'
    },
    logo:{
      right:22,
    },
    title:{
      fontSize: 38,
      paddingBottom:40,
    },
    highText:{
      fontSize:20,
    },
    smallText:{
      fontSize:14,
      color:"#274AC7"
    },
    smallTextGrey:{
      fontSize:14,
      color:"#495057"
    },
  
    page: {
      backgroundColor: '#EAF4FB',
      paddingTop:24,
      paddingBottom:16,
      paddingHorizontal:24,
    },
    contentPageBody:{
      backgroundColor:'#FFFFFF',
      height:'100%',
      borderRadius:25,
      padding:16,
      gap:16,
    },
    contentPageFooter:{
      flexDirection:'row',
      justifyContent:'space-between',
      paddingTop:16,
    },
    contentPageMainTitle:{
      fontSize:24,
    },
    contentPageFoorterInfo:{
      flexDirection:'column',
      gap:4,
    },
    contentPageFoorterLogo:{
  
    },
  
  });
  
  const LogoPicto = "M200.4,156.9c-4.1,4.1-10.8,4.1-15,0L158,129.5c-4.1-4.1-4.1-10.8,0-15l27.4-27.4c4.1-4.1,10.8-4.1,15,0l27.4,27.4c4.1,4.1,4.1,10.8,0,15L200.4,156.9z M152,141.4c0,5.9-4.7,10.6-10.6,10.6h-38.7c-5.8,0-10.6-4.7-10.6-10.6v-38.7c0-5.8,4.7-10.6,10.6-10.6h38.7c5.9,0,10.6,4.7,10.6,10.6L152,141.4L152,141.4z M129.5,227.8c-4.1,4.1-10.8,4.1-15,0l-27.4-27.4c-4.1-4.1-4.1-10.8,0-15l27.4-27.4c4.1-4.1,10.8-4.1,15,0l27.4,27.4c4.1,4.1,4.1,10.8,0,15L129.5,227.8z M212.2,162.9c5.9,0,10.6,4.7,10.6,10.6v38.7c0,5.9-4.7,10.6-10.6,10.6h-38.7c-5.9,0-10.6-4.7-10.6-10.6v-38.7c0-5.9,4.7-10.6,10.6-10.6L212.2,162.9L212.2,162.9z M200.4,156.9c-4.1,4.1-10.8,4.1-15,0L158,129.5c-4.1-4.1-4.1-10.8,0-15l27.4-27.4c4.1-4.1,10.8-4.1,15,0l27.4,27.4c4.1,4.1,4.1,10.8,0,15L200.4,156.9z"; 
  const LogoTitle = "M310.7,129.6v93.2h16.1v-93.2H310.7z M426.8,165.9v56.9h-16.1v-55.6c0-19.4-8.1-26.4-21.5-26.4c-13.2,0-24.2,9.9-24.2,28.6v53.4h-16.1v-93.2h15.6v12.3h0.4c5.7-8.6,15.8-14.3,28.2-14.3C414.4,127.6,426.8,140.4,426.8,165.9zM515.6,158.7h-15.8c0-12.5-7.7-19.3-20.7-19.3c-11.2,0-18.2,5.1-18.2,13.2c0,9.7,6.8,11.4,22.4,15c17.1,4.2,35.2,8.1,35.2,29.7c0,16.7-14.3,27.9-36.9,27.9c-24.4,0-38.9-11.7-39.1-33.6h16.3c0,13.4,8.4,20.7,22.9,20.7c12.1,0,20.2-5.5,20.2-13.9c0-10.8-7.7-12.8-25.7-17.2c-15.6-3.9-31.5-7-31.5-27.3c0-16,14.1-27,34.5-27C502,127,515.6,138.2,515.6,158.7z M597.7,194.9h16.5c-3.1,16.3-17.2,30.4-41.1,30.4c-27.5,0-43.8-20-43.8-50.4c0-27.5,16.1-47.9,43.3-47.9c21.1,0,36,12.5,40.7,32.5c1.3,5.3,1.8,11.9,1.8,20h-69.7c0.7,22.9,12.5,33.4,27.7,33.4C586.7,212.8,595.5,205.3,597.7,194.9z M545.6,167.2h53.6c-1.7-18.2-12.3-27.7-26.6-27.7C557.7,139.5,547.3,149.4,545.6,167.2z M710.1,165.9v56.9h-16.1v-55.6c0-19.4-8.1-26.4-21.5-26.4c-13.2,0-24.2,9.9-24.2,28.6v53.4h-16.1v-93.2h15.6v12.3h0.4c5.7-8.6,15.8-14.3,28.2-14.3C697.6,127.6,710.1,140.4,710.1,165.9zM798.8,158.7H783c0-12.5-7.7-19.3-20.7-19.3c-11.2,0-18.2,5.1-18.2,13.2c0,9.7,6.8,11.4,22.4,15c17.1,4.2,35.2,8.1,35.2,29.7c0,16.7-14.3,27.9-36.9,27.9c-24.4,0-38.9-11.7-39.1-33.6h16.3c0,13.4,8.4,20.7,22.9,20.7c12.1,0,20.2-5.5,20.2-13.9c0-10.8-7.7-12.8-25.7-17.2c-15.6-3.9-31.5-7-31.5-27.3c0-16,14.1-27,34.5-27C785.2,127,798.8,138.2,798.8,158.7z M818.6,129.6v93.2h16.1v-93.2H818.6z M892.1,127c-23.7,0-36.5,13.4-37.4,31h16.1c0.5-11,7.7-18.5,21.3-18.5c12.5,0,21.1,6.4,21.1,21.8v3.7l-27,3.1c-26.8,4.2-35.4,13.9-35.4,29.3c0,15.8,12.1,27.3,31.5,27.3c15,0,25.9-5.7,32.3-15.6h0.4v13.6h13.8v-59.6C928.8,138.6,914.8,127,892.1,127z M913.2,182.9c0,11-1.8,16.1-7.5,22c-4.6,4.8-11.7,7.3-19.3,7.3c-12.1,0-19.1-6.4-19.1-16c0-9.2,5.5-13.8,21.5-16.1l24.4-2.9L913.2,182.9L913.2,182.9z M835.8,92.2v18.2h-18.2V92.2H835.8z M327.8,92.2v18.2h-18.2V92.2H327.8z"  
  
  const InsensiaLogo = ({height,width, style}) => (
    <Svg viewBox="0 0 1012.8 306.9" fill="#274AC7" height={height} width={width} style={style}  >
      <Path d={LogoPicto}/>
      <Path d={LogoTitle}/>
    </Svg>
  )
   
  const PdfFooter = ({building, createdAt}) => (
    <>
      <View style={styles.contentPageFoorterInfo}>
        <Text style={styles.smallText}>Bâtiment : {building?.name || 'N/A'}</Text>
        <Text style={styles.smallText}>Rapport créé le {createdAt}</Text>
      </View>
      <View style={styles.contentPageFoorterLogo}>
        <InsensiaLogo height={50} width={138}/>
      </View>
    </>
  )
  
  const currentPeriod = (period, periodUnit) => {
    if (!period) return '';
    switch (periodUnit) {
      case 'day':
        return `du ${moment(period.start).format('dddd DD MMM YYYY')}`;
      case 'week':
        return `du ${moment(period.start).format('DD-MM-YYYY')} au ${moment(period.end).format('DD-MM-YYYY')}`;
      case 'month':
        return `du mois de ${moment(period.start).format('MMMM YYYY').replace(/(^|\s)\S/g, l => l.toUpperCase())}`;
      case 'year':
        return `du ${moment(period.start).format('DD-MM-YYYY')} au ${moment(period.end).format('DD-MM-YYYY')}`;
      case 'free':
        return `du ${moment(period.start).format('DD-MM-YYYY')} au ${moment(period.end).format('DD-MM-YYYY')}`;
      default:
        return '';
    }
  }

  return(  
    <Document title={`${reportData?.universe} du bâtiment ${reportData?.building?.name} ${currentPeriod(period, periodUnit)}`} style={{ "height" : "100%"}}>
        <Page size={"A4"} style={styles.presentationPage}>
          <View style={styles.presentationPageTitle} >
            <InsensiaLogo style={styles.logo} height={100} width={300}/>
          </View>
          <View style={styles.presentationPageBody}>
            <Text style={styles.highText}>{reportData?.universe?.toUpperCase()} DU BÂTIMENT</Text>
            <Text style={styles.highText}>{reportData?.building?.name.toUpperCase() || 'N/A'}</Text>
            <Text style={styles.highText}>{reportData?.room?.name.toUpperCase() || reportData?.rooms?.map(room => room.name.toUpperCase()).join(', ') }</Text>
            <Text style={styles.smallTextGrey}>{currentPeriod(period, periodUnit)}</Text>
            <Text style={styles.smallText}>{reportData?.building?.address?.street}</Text>
            <Text style={styles.smallText}>{reportData?.building?.address?.zipcode} {reportData?.building.address.city}</Text>
          </View>
          <View style={styles.presentationPageFooter}>
            <View style={styles.buildingInfosFooter}>
              <Text style={styles.smallText}>Bâtiment : {reportData?.building?.name || 'N/A'} </Text>
              <Text style={styles.smallText}>Pièce(s) : {reportData?.rooms?.map(room => room.name).join(', ') || 'N/A'} </Text>
            </View>
            <View style={styles.infosFooter}>
              <Text style={styles.smallText}>Univers : {reportData?.universe}</Text>
              <Text style={styles.smallText}>Créé le {createdAt}</Text>
            </View>
          </View>
        </Page>
        
        <Page size={"A4"} style={styles.page}>
          <View>
            <View style={styles.contentPageBody}>
              <Text style={styles.contentPageMainTitle}>Indicateurs de performance</Text>
              <Image source={images[0]} /> 
            </View>
            <View style={styles.contentPageFooter}>
              <PdfFooter building={reportData?.building} createdAt={createdAt} />
            </View>
          </View>  
        </Page>
        
        <Page size={"A4"} style={styles.page}>
          <View>
            <View style={styles.contentPageBody}>
              <Text style={styles.contentPageMainTitle}>Courbe de {reportData?.universe.toLowerCase()}</Text>
              <Image source={images[1]}/>
              {reportData?.universe === 'Confort' ? <Image source={images[2]}/> : null }
            </View>
            <View style={styles.contentPageFooter}>
              <PdfFooter building={reportData?.building} createdAt={createdAt} />
            </View>
          </View>    
        </Page>

        {reportData?.universe === 'Synthèse' ?
        <Page size={"A4"} style={styles.page}>
          <View>
            <View style={styles.contentPageBody}>
              <Text style={styles.contentPageMainTitle}>Température de vos pièces</Text>
              <Image source={images[2]}/>
            </View>
            <View style={styles.contentPageFooter}>
              <PdfFooter building={reportData?.building} createdAt={createdAt} />
            </View>
          </View>
        </Page>
        : null}

    </Document>
  );
}

export default PdfConsult;