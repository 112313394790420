import moment from 'moment'
moment.locale('fr')

export const labelsModel = (periodUnit, category) => {
  let labels = []
  switch (periodUnit) {
    case 'day':
      labels = category
      break;
    case 'week':
      labels = category.map(date => {
        const day = moment(date).locale('fr').format('dd');
        return day.charAt(0).toUpperCase() + day.charAt(1).toLowerCase();
      })
      break;
    case 'month':
      labels = category.map(date => moment(date).format('DD'))
      break;
    case 'year':
      labels = category.map(date => {
        const formatted = moment(date).locale('fr').format('MMM');
        return formatted.charAt(0).toUpperCase() + formatted.slice(1);
      });
      break;
    default:
      break;
  }
  return labels
}

export const ticksLabel = (periodUnit, category, step) => (index) => {
  switch (periodUnit) {
    case 'day':
      const time = moment(category[index]).locale('fr');
      const hours = time.hours();
      const minutes = time.minutes(); 
      switch (step[index]) {
        case 'PT5M':
          if ((hours % 1 === 0 && minutes === 5)) {
            return time.format('H[h]');
          }
          break;
        case 'PT10M':
          if ((hours % 1 === 0 && minutes === 10)) {
            return time.format('H[h]');
          }
          break;
        case 'PT15M':
          if ((hours % 1 === 0 && minutes === 15)) {
            return time.format('H[h]');
          }
          break; 
        case 'PT30M':
          if ((hours % 3 === 0 && minutes === 30)) {
            return time.format('H[h]');
          }
          break;
        default:
          return ''
      }
      break;
    case 'week':
      const day = moment(category[index]).locale('fr').format('dd');
      return day.charAt(0).toUpperCase() + day.charAt(1).toLowerCase();
    case 'month':
      return moment(category[index]).format('DD')
    case 'year':
      return moment(category[index]).locale('fr').format('MMM').charAt(0).toUpperCase() +
             moment(category[index]).locale('fr').format('MMM').slice(1);
    default:
      return ''
  }
}

export const externalTooltipHandler = (consoUnit, periodUnit, category) => (context) => {

  let tooltipEl = document.getElementById('chartjs-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.style.background = '#f4f4f5';
    tooltipEl.style.color = '#30465E';
    tooltipEl.style.fontSize = '0.8rem';
    tooltipEl.style.borderRadius = '1.25rem';
    tooltipEl.style.boxShadow = '0px 0px 10px rgba(0, 0, 0, 0.1)';
    tooltipEl.style.padding = '1rem';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transition = 'opacity 0.3s';
    tooltipEl.innerHTML = '<table></table>';
    document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem;
  }

  function getUnit(label) {
    if (label.includes('Coût')) return consoUnit;
    if (label.includes('Consommation')) return consoUnit;
    if (label.includes('Abonnement')) return consoUnit;
    if (label.includes('Equivalent carbone')) return consoUnit;
    if (label.includes('T°C')) return '°C';
    return '';
  }

  // Filter out data points where raw is null
  const dataPoints = tooltipModel.dataPoints.filter(dp => dp.raw !== null && dp.raw !== 0);


  // Return if all data points are null
  if (dataPoints.length === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];

    const bodyLines = dataPoints.map((dp) => getBody(dp));
    const labelColors = dataPoints.map((dp) => dp.element.options);

    const bodyLinesReverse = [...bodyLines].reverse();
    const labelColorsReverse = [...labelColors].reverse();

    let innerHtml = '<thead>';

    titleLines.forEach(function (title) {
      innerHtml += '<tr><th>' + title + '</th></tr>';
    });
    innerHtml += '</thead><tbody>';

    bodyLinesReverse.forEach(function (body, i) {
      const colors = labelColorsReverse[i];
      const unit = getUnit(body.dataset.label);
      let style = 'background:' + colors.backgroundColor;
      style += '; border-color:' + colors.borderColor;
      style += '; display:inline-block; width:10px; height:10px; border-radius:50%; margin-right:5px;';
      const span = '<span style="' + style + '"></span>';
      innerHtml += `<tr><td style="padding-bottom: 0rem;">${span}${body.dataset.label} : ${body.formattedValue} ${unit}</td></tr>`;      
    });

    // Calcul du total de la barre (valeur du point survolé)
    let currentTotal = 0;
    dataPoints.forEach(function(tooltipItem) {
      if (tooltipItem.dataset.type === 'bar' && tooltipItem.raw !== null) {
        currentTotal += tooltipItem.raw;
      }
    });

    // --- Calcul du total cumulé depuis le début de la période ---
    const hoveredIndex = tooltipModel.dataPoints[0].dataIndex;
    const currentDate = moment(category[hoveredIndex]);
    let startDate;
    if (periodUnit === 'week') {
      startDate = moment(currentDate).startOf('isoWeek').format('YYYY-MM-DD');
    } else if (periodUnit === 'month') {
      startDate = moment(currentDate).startOf('month').format('YYYY-MM-DD');
    } else if (periodUnit === 'year') {
      startDate = moment(currentDate).startOf('year').format('YYYY-MM-DD');
    }
    // Recherche de l'index correspondant dans le tableau category
    let startIndex = category.findIndex(date => moment(date).format('YYYY-MM-DD') === startDate);
    if (startIndex === -1) {
      startIndex = 0;
    }
    const chartData = context.chart.data;
    let cumulativeTotal = 0;
    chartData.datasets.forEach(dataset => {
      if (dataset.type === 'bar') {
        for (let i = startIndex; i <= hoveredIndex; i++) {
          const value = dataset.data[i];
          cumulativeTotal += (value !== null ? value : 0);
        }
      }
    });
    // --- Fin calcul cumulatif ---

    // Affichage des deux totaux dans le footer du tooltip
    if (consoUnit === '€' || periodUnit !== 'day') {
      innerHtml += `<tfoot>
                      <tr>
                        <td style="padding-top: 0rem; border-top: 1px solid #30465E;">Total: ${currentTotal.toFixed(2)} ${consoUnit}</td>
                      </tr>
                      <tr>
                        <td style="">Cumul: ${cumulativeTotal.toFixed(2)} ${consoUnit}</td>
                      </tr>
                    </tfoot>`;
    }
    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  // `this` will be the overall tooltip
  const position = context.chart.canvas.getBoundingClientRect();
  const chartWidth = context.chart.width;
  const tooltipWidth = tooltipEl.offsetWidth;
  let left = position.left + window.pageXOffset + tooltipModel.caretX;

  // Adjust left position for the first bar
  if (tooltipModel.dataPoints[0].dataIndex >= 0 && tooltipModel.dataPoints[0].dataIndex <= 1) {
    left -= 15;
  }

  const offsetByCategoryLength = () => {
    switch (category.length) {
      case 7:
        return 1
      case 12:
        return 2
      case 24:
        return 4
      case 28:
      case 29:
      case 30:
      case 31:
        return 5
      case 48:
        return 8
      case 96:
        return 18
      case 144:
        return 48
      case 288:
        return 96
      default:
        return 8
    }
  }

  // Adjust left position for the last bar
  if (tooltipModel.dataPoints[0].dataIndex >= category.length - offsetByCategoryLength() && tooltipModel.dataPoints[0].dataIndex <= category.length - 1) {
    left = position.left + window.scrollY + chartWidth - tooltipWidth - 15;
  }

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = left + 'px';
  tooltipEl.style.top = position.top + window.scrollY - 20 + 'px';
}

export const titleTooltip = (periodUnit, category, step) => (tooltipItems) => {
  const index = tooltipItems[0].dataIndex;
  const allNull = tooltipItems.every(item => item.raw === null);
  switch (periodUnit) {
    case 'day':
      const currentLabel = moment(category[index]);
      let prevLabel = ''
      switch (step[index]) {
        case 'PT5M':
          prevLabel = moment(category[index]).locale('fr').subtract(5, 'minutes');
          break;
        case 'PT10M':
          prevLabel = moment(category[index]).locale('fr').subtract(10, 'minutes');
          break;
        case 'PT15M':
          prevLabel = moment(category[index]).locale('fr').subtract(15, 'minutes');
          break; 
        case 'PT30M':
          prevLabel = moment(category[index]).locale('fr').subtract(30, 'minutes');
          break;
        default:
          return ''
      }
      const currentFormatted = prevLabel.format('dddd DD MMMM YYYY - HH:mm').replace(/(^|\s)\S/g, l => l.toUpperCase());
      const nextFormatted = currentLabel.format('HH:mm');
      return `${currentFormatted} à ${nextFormatted}`;
    case 'week':
      if (allNull) {
        return '';
      }
      return moment(category[index]).locale('fr').format('dddd DD MMMM YYYY').replace(/(^|\s)\S/g, l => l.toUpperCase());
    case 'month':
      if (allNull) {
        return '';
      }
      return moment(category[index]).locale('fr').format('dddd DD MMMM YYYY').replace(/(^|\s)\S/g, l => l.toUpperCase());
    case 'year':
      if (allNull) {
        return '';
      }
      return moment(category[index]).locale('fr').format('MMMM').replace(/(^|\s)\S/g, l => l.toUpperCase());
    default:
      return
  }

}

export const labelTooltip = (consoUnit) => (tooltipItem) => {

  const { dataset, raw } = tooltipItem;

  if (raw === null) {
    return '';
  }

  let label = `${dataset.label}: ${raw?.toFixed(2)}`;

  if (dataset.type === 'line') {
    label += '°C';
  } else {
    switch (consoUnit) {
      case '€':
        label += ' €'; 
        break;
      case 'kWh':
        label += ' kWh'; 
        break;
      case 'kgCO2':
        label += ' kgCO2'; 
        break;
      default:
        break;   
    } 
  }
  return label;
}

export const footerTooltip = (consoUnit) => (tooltipItems) => {
  const allNull = tooltipItems.every(item => item.raw === null);
  if (allNull) {
    return 'Données en cours de récupération';
  }
  let sum = 0;
  tooltipItems.forEach(function(tooltipItem) {
    if (tooltipItem.dataset.type === 'bar' && tooltipItem.raw !== null) {
      sum += tooltipItem.raw;
    }
  });
  return `Total: ${sum.toFixed(2)} ${consoUnit}`;
}

