import React, { useEffect, useState } from 'react'
import s from './HeaderMobile.module.scss'
import Icon from '../../Icon/Icon'
import Logo from '../../Logo/Logo'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { NavLink, useMatch } from 'react-router-dom'
import { selectProfile } from '../../../selectors/selectUser'
import { getProfile } from '../../../features/user/userActions'
import { getAlerts } from '../../../features/alert/alertsActions'
import { changeActiveRoute } from '../../../features/navigation/navigationSlice'
import { saveNavigationState } from '../../../features/navigation/navigationActions'
import store from '../../../redux/store';

function HeaderMobile() {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const isMatchBuildings = useMatch('/buildings/*')
  const isMatchSettings = useMatch('/settings/*')
  const isMatchDevices = useMatch('/devices/*')
  const isMatchConsult = useMatch('/consult/*')
  const isMatchCompare =  useMatch('/compare/*')
  const isMatchAnomalies = useMatch('/anomalies/*')
  const isMatchReports = useMatch('/reports/*')

  const isMatchAccount = useMatch('/account/*')

  const profile = useSelector(selectProfile, shallowEqual)

  const [imageURL, setImageURL] = useState('')
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
      dispatch(getProfile({ user_id }))
      dispatch(getAlerts())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_id])

  useEffect(() => {
      if (!profile?.avatar_url) {
        setImageURL(`https://ui-avatars.com/api/?background=random&name=${profile?.firstname}+${profile?.lastname}`)
      } else {
        setImageURL(profile.avatar_url)
      }
    }, [profile])

  const handleChangeActiveItem = (e, link, section) => {
    e.stopPropagation()
    dispatch(changeActiveRoute(link))
    if (section !== 'account' && section !== 'compare' && section !== 'security') {
      dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
    }
    setShowSidebar(false)
  }

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  }

  return (
    <div className={s.root}>
      <header className={s.header}>
        <Logo/>
        <Icon name="menu" size={'1.5rem'} color={'#30465E'} fill={0} onClick={toggleSidebar} cursor/>
      </header>
      <div className={`${s.slideMenu} ${showSidebar ? s.show : s.hide}`}>

        <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/buildings", 'buildings')}>
          <NavLink to="/buildings" className={() => isMatchBuildings || isMatchSettings ? s.link_active : s.link}>
            <Icon name="domain" size={'1.5rem'} color={isMatchBuildings ?'#FFF' : '#3192D3'} fill={0}/>
            <span className={s.item}>BÂTIMENTS</span>
          </NavLink>
        </div>

        <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/devices", 'devices')}>
          <NavLink to="/devices" className={() => isMatchDevices ? s.link_active : s.link}>
            <Icon name="memory" size={'1.5rem'} color={isMatchDevices ?'#FFF' : '#3192D3'} fill={0}/>
            <span className={s.item}>ÉQUIPEMENTS</span>
          </NavLink>
        </div>
      
        <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/consult/summary", 'consult')}>
          <NavLink to="/consult" className={() => isMatchConsult ? s.link_active : s.link}>
            <Icon name="dashboard" size={'1.5rem'} color={isMatchConsult ?'#FFF' : '#3192D3'} fill={0}/>
            <span className={s.item}>CONSULTER</span>
          </NavLink> 
        </div>
  
        <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/compare", 'compare')}>
          <NavLink className={s.disable}>
            <Icon name="compare_arrows" size={'1.5rem'} color={'#595d78'} fill={1}/>
            <span className={s.item}>COMPARER</span>
          </NavLink> 
        </div>
  
        <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/anomalies", "anomalies")} >
          <NavLink to='/anomalies' className={() => isMatchAnomalies ? s.link_active : s.link}>
            <Icon name="warning" size={'1.5rem'} color={isMatchAnomalies ?'#FFF' : '#3192D3'} fill={0}/>
            <span className={s.item}>ANOMALIES</span>
          </NavLink>
        </div>
  
        <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/reports", 'reports')}>
          <NavLink to="/reports" className={() => isMatchReports ? s.link_active : s.link}>
            <Icon name="description" size={'1.5rem'} color={isMatchReports ?'#FFF' : '#3192D3'} fill={0}/>
            <span className={s.item}>RAPPORTS</span>
          </NavLink>
        </div>
  
        <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/security", 'security')}>
          <NavLink className={s.disable}>
            <Icon name="shield" size={'1.5rem'} color={'#595d78'} fill={0}/>
            <span className={s.item}>SÉCURITÉ</span>
          </NavLink>
        </div>
  
        <div className={s.profile} to={`/account`} onClick={(e) => handleChangeActiveItem(e,'/account', 'account')}>
            <NavLink to="/account" className={() => isMatchAccount ? s.link_active : s.link}>
              <div className={s.avatar} id="tooltip-avatar">
                <img className={s.image} src={imageURL} aria-hidden alt="Photo de mon profil" />
              </div>
              <span className={s.item}>COMPTE</span>
            </NavLink> 
        </div>
  
        <div className={s.helper}>
          <div className={s.link}>
            <NavLink to={'#'}>SUPPORT</NavLink>
          </div>
        </div>
  
      </div> 
    </div>
  )
}

export default HeaderMobile

