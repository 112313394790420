import React, { useEffect } from 'react';
import s from './CardWeather.module.scss';
import Icon from '../../../Icon/Icon';
import RainyIcon from '../../../Weather/RainyIcon';
import SunnyIcon from '../../../Weather/SunnyIcon';
import CloudyIcon from '../../../Weather/CloudyIcon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectActiveBuilding } from '../../../../selectors/selectNavigation';
import { selectWeatherDataByStation } from '../../../../selectors/selectWeatherData';
import { selectWeatherStationId } from '../../../../selectors/selectBuildings';

function CardWeather() {

  const dispatch = useDispatch()

  const building =  useSelector(selectActiveBuilding,shallowEqual)
  const building_id = building?.building_id || null

  const weather_station_id = useSelector(selectWeatherStationId(building_id))
  const weatherData = useSelector(selectWeatherDataByStation(weather_station_id), shallowEqual)

  return (
    <div className={s.root}>
      <div className={s.header}>
        <CloudyIcon/>
        <div className={s.value}>
        {weatherData[0]?.temperature}°C
        </div>
      </div>
      <div className={s.content}>
        <div className={s.items}>
          <Icon name={'humidity_percentage'} size={'1.5rem'} color={'#ffffff'} />
          <div className={s.labels}>
            Humidité
            <div className={s.item}>
              80%
            </div>
          </div>
        </div>
        <div className={s.items}>
          <Icon name={'air'} size={'1.5rem'} color={'#ffffff'} />
          <div className={s.labels}>
            Vent
            <div className={s.item}>
              20 km/h
            </div>
          </div>
        </div>
      </div>
    </div>
  ) 
}

export default CardWeather;