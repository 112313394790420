export const selectPairingMessage = (state) => {
    return state.pairing.pairing_message
}

export const selectPairingStatus = (state) => {
    return state.pairing.pairing_status
}

export const selectPairingError = (state) => {
    return state.pairing.error
}

export const selectSerialNumber = (state) => {
    return state.pairing.serial_number || ''
}

export const selectCheckingDeviceExist = (state) => {
    return !!state.pairing.deviceExist;
};

export const selectPairingDeviceId = (state) => {
    return state.pairing.device_id
}

