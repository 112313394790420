import React, { useState } from 'react';
import s from './Step02CustomRule.module.scss';
import { Button, Input } from 'reactstrap';
import classnames from 'classnames/bind';
import Icon from '../../../../../components/Icon/Icon';

const cx = classnames.bind(s);

function Step02CustomScenario({inc,dec,setDataTitle,setValueTitle, setComparatorsTitle, setUnitTitle, setFirstValue, setSecondValue, setSelectedComparatorBetween}) {
  
  const [isTypeSelected, setIsTypeSelected] = useState(false);
  const [isDataSelected, setIsDataSelected] = useState(false);
  const [isComparatorSelected, setIsComparatorSelected] = useState(false);

  const typeScenario = [{ name: 'Notification simple' }, { name: 'Pilotage sous condition' }];
  const dataType = [
    { name: "Qualité d'air", unit: 'ppm' },
    { name: 'Température', unit: '°C' },
    { name: 'Lumière', unit: 'lux' },
  ];
  const comparators = [{ name: 'entre... et...' }, { name: 'au dessus de' }, { name: 'en dessous de' }];

  const [navigationTypeIndex, setNavigationTypeIndex] = useState(null);
  const [navigationDataType, setNavigationDataType] = useState(null);
  const [navigationComparatorType, setNavigationComparatorType] = useState(null);

  const handleClickNavigationType = (index) => {
    setNavigationTypeIndex(index);
    setIsTypeSelected(true);
  };

  const handleClickNavigationDataType = (index, name, unit) => {
    setNavigationDataType(index);
    setIsDataSelected(true);
    setDataTitle(name.toLowerCase());
    setUnitTitle(unit);
  };

  const handleClickNavigationComparatorType = (index, name) => {
    setNavigationComparatorType(index);
    {
      navigationComparatorType === 0 && setSelectedComparatorBetween(true);
    }
    setIsComparatorSelected(true);
    setComparatorsTitle(name);
  };

  return (
    <div className={s.root}>
      <div className={s.scenarioType}>
        <div className={s.title}>Quel type de règle souhaitez-vous créer ?</div>
        <div className={s.buttons}>
          {typeScenario.map((item, index) => (
            <Button key={index} className={cx(s.btn, { [s.active]: navigationTypeIndex === index })} onClick={() => handleClickNavigationType(index)}>
              {item.name}
            </Button>
          ))}
        </div>
      </div>
      {isTypeSelected && (
        <div className={s.scenariosParam}>
          <div className={s.message}>
            <div className={s.icon}>
              <Icon name={'info'} fill={1} />{' '}
            </div>
            Les données disponibles dépendent des équipements présents dans l’espace que vous avez sélectionné.
          </div>
          <div className={s.scenarioSteps}>
            <div className={s.title}>Choisissez une combinaison afin de créer la consigne de cette règle.</div>
            <div className={s.selector}>
              <div className={s.data}>
                <div className={s.title}>Donnée</div>
                <div className={s.buttons}>
                  {dataType.map((item, index) => (
                    <Button key={index} className={cx(s.btn, { [s.active]: navigationDataType === index })} onClick={() => handleClickNavigationDataType(index, item.name, item.unit)}>
                      {item.name}
                    </Button>
                  ))}
                </div>
              </div>
              <div className={`${s.data} ${isDataSelected ? '' : s.disable}`}>
                <div className={s.title}>Comparateur</div>
                {isDataSelected && (
                  <div className={s.buttons}>
                    {comparators.map((item, index) => (
                      <Button key={index} className={cx(s.btn, { [s.active]: navigationComparatorType === index })} onClick={() => handleClickNavigationComparatorType(index, item.name)}>
                        {item.name}
                      </Button>
                    ))}
                  </div>
                )}
              </div>
              <div className={`${s.data} ${isComparatorSelected ? '' : s.disable}`}>
                <div className={s.title}>Valeur</div>
                {isComparatorSelected && navigationComparatorType !== 0 && (
                  <Input className={s.input} type='text' placeholder='Entrez votre nombre ici' onChange={(e) => setValueTitle(e.target.value)}/>
                )}
                {isComparatorSelected && navigationComparatorType === 0 && (
                  <div className={s.inputs}>
                    <Input className={s.input} type='text' placeholder='Entrez la première valeur' onChange={(e) => setFirstValue(e.target.value)}/>
                    <Input className={s.input} type='text' placeholder='Entrez la deuxième valeur' onChange={(e) => setSecondValue(e.target.value)}/>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={s.btnSteps}>
        <Button className={s.btn} onClick={dec}>
          Précédent
        </Button>
        <Button className={`${isComparatorSelected && isDataSelected ? s.btnActive : s.btnDisable}`} disabled={!isComparatorSelected || !isDataSelected} onClick={inc}>
          Valider
        </Button>
      </div>
    </div>
  );
}

export default Step02CustomScenario;
