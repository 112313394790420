import React from "react";
import moment from "moment";

const ExportSgeCSV = ({ dataSets, category }) => {
  const downloadCSV = () => {
    if (!dataSets || !dataSets.datasets || dataSets.datasets.length === 0 || !category) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    // **1️⃣ CSV Header (Date + Sensor Labels)**
    csvContent += ["Date", ...dataSets.datasets.map((ds) => ds.label)].join(",") + "\n";

    // **2️⃣ Iterate through each category (x-axis)**
    category.forEach((dateLabel, index) => {
      let row = [moment(dateLabel).format("YYYY-MM-DD HH:mm")]; // Format date

      // **3️⃣ Match data values for each dataset**
      row = row.concat(
        dataSets.datasets.map((ds) => {
          const dataPoint = ds.data[index]; // Directly use index from category
          return dataPoint !== undefined && dataPoint !== null ? dataPoint : "";
        })
      );

      csvContent += row.join(",") + "\n";
    });

    // **4️⃣ Create and trigger CSV download**
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "energy_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button onClick={downloadCSV} style={{ background: "none", border: "none", cursor: "pointer", marginLeft: "10px" }}>
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
        <polyline points="7 10 12 15 17 10" />
        <line x1="12" y1="15" x2="12" y2="3" />
      </svg>
    </button>
  );
};

export default ExportSgeCSV;
