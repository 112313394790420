import { createSlice } from "@reduxjs/toolkit"
import { createWeatherStation, getWeatherStations, getWeatherData } from './weatherActions'

export const initialStateWeatherStation = {
  isLoading: false,
  listStations: [],
  error: null
}

const weatherSlice = createSlice({
  name: 'weatherStations',
  initialState: initialStateWeatherStation,
  reducers: {
    resetWeatherStations: () => initialStateWeatherStation,
  },
  extraReducers: builder => {
    builder
      //GET WEATHER STATIONS
      .addCase(getWeatherStations.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getWeatherStations.fulfilled, (state, { payload }) => {
        state.listStations = payload
        state.error = null
        state.isLoading = false
      })
      .addCase(getWeatherStations.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
        state.listStations = []
      })
      //CREATE STATION
      .addCase(createWeatherStation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createWeatherStation.fulfilled, (state, { payload }) => {
        state.error = null
        state.isLoading = false
      })
      .addCase(createWeatherStation.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
      // GET WEATHER DATA
      .addCase(getWeatherData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getWeatherData.fulfilled, (state, { payload }) => {
        const index = state.listStations.findIndex(station => station.weather_station_id === payload.weather_station_id)
        if (index !== -1) {
          state.listStations[index] = {weather_station_id: payload.weather_station_id, data: payload.data}
        } else {
          state.listStations.push({weather_station_id: payload.weather_station_id, data: payload.data})
        }
        state.error = null
        state.isLoading = false
      })
      .addCase(getWeatherData.rejected, (state, { payload }) => {
        state.error = payload
        state.isLoading = false
      })
      .addDefaultCase((state, action) => {})
  }
})

export const { resetStations } = weatherSlice.actions

export default weatherSlice.reducer