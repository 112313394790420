import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import s from './CardBuildingData.module.scss'
import classnames from 'classnames/bind';
import { Form, Collapse, FormGroup, Input, ListGroup, ListGroupItem, Label, Button } from "reactstrap"
import { LIST_BUILDING_TYPE } from '../../../../../../utils/constants/list_building'
import { getAddresses, getCities } from '../../../../../../features/address/addressActions'
import { getWeatherStation } from '../../../../../../features/weather/weatherActions'
import { useMatch, useNavigate } from 'react-router-dom'
import { selectDevicesLinkedByBuilding } from '../../../../../../selectors/selectDevices';
import { changeActiveBuilding, changeActiveRoute, initActiveBuilding } from '../../../../../../features/navigation/navigationSlice';
import { getDevices } from '../../../../../../features/devices/devicesActions';
import { createBuilding, getBuildingById, getBuildings, updateBuilding } from '../../../../../../features/buildings/buildingsActions';
import { saveNavigationState } from '../../../../../../features/navigation/navigationActions';
import store from '../../../../../../redux/store';
import Icon from '../../../../../../components/Icon/Icon';
import ModalDeleteBuilding from '../ModalDeleteBuilding/ModalDeleteBuilding';
import { toast } from 'react-toastify';

const cx = classnames.bind(s);

function CardBuildingData({ setData, data, handleUpdateList }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const matchBuildingAdd = useMatch('/settings/building/add')

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const building_id = data?.building_id || null

  const noCounter = data?.sge_authorizations === null ? true : false;

  const linkedDevices = useSelector(selectDevicesLinkedByBuilding(building_id), shallowEqual)

  const [resultsAddress, setResultsAddress] = useState([])
  const [resultsCity, setResultsCity] = useState([])
  const [detectBlur, setDetectBlur] = useState(false)
  const [showModalDeleteBuilding, setShowModalDeleteBuilding] = useState(false)

  const handleChangeName = (e) => {
    setData(prevData => ({
      ...prevData, 
      name: e.target.value 
    }))
  }

  const handleChangeStreet = (e) => {
    setData(prevData => ({
      ...prevData, 
      address: {
        ...prevData.address,
        street: e.target.value
      }
    }))
    if (e.target.value.length === 0) {
      setResultsAddress([])
      setResultsCity([])
      setData(prevData => ({
        ...prevData, 
        address: {
          ...prevData.address,
          street: '',
          zipcode: '',
          city: '',
          inseecode: '',
          geolocation: []
        },
        weather_station: null
      }))
    }
  }

  const handleChangeCity = (e) => {
    setData(prevData => ({
      ...prevData, 
      address: {
        ...prevData.address,
        city: e.target.value
      }
    }))
    if (e.target.value.length === 0) {
      setResultsCity([])
      setData(prevData => ({
        ...prevData, 
        address: {
          ...prevData.address,
          street: '',
          zipcode: '',
          city: '',
          inseecode: '',
          geolocation: []
        }
      }))
    }
  }
 
  const getResultsAddress = ()  => {
    const maxResults =  20
    const minCharacters = 3
    const queryAddress = data?.address?.street ?? ''
    if (queryAddress.length < minCharacters) return
    dispatch(getAddresses({ queryAddress, maxResults})).then((response) => {
      setResultsAddress(response?.payload)
    })
  }

  const getResultsCity = () => {
    const maxResults =  20
    const minCharacters = 4
    const queryCity = data?.address?.city
    if (queryCity?.length < minCharacters) return
    dispatch(getCities({ queryCity, maxResults})).then((response) => {
      setResultsCity(response?.payload)
    })
  }

  const itemClickAddress = (item)  => {
    setDetectBlur(false)
    setResultsAddress([])
    setResultsCity([])
    setData(prevData => ({
      ...prevData, 
      address: {
        ...prevData.address,
        street: item.properties.name,
        zipcode: item.properties.postcode,
        city: item.properties.city,
        inseecode: item.properties.citycode,
        geolocation: [item.geometry.coordinates[1], item.geometry.coordinates[0]]
      }
    }))
    const inseecode = item.properties.citycode
    const latitude = item.geometry.coordinates[1]
    const longitude = item.geometry.coordinates[0]

    dispatch(getWeatherStation({ inseecode, latitude, longitude })).then((response) => {
      setData(prevData => ({
        ...prevData, 
        weather_station: response?.payload?.weather_station
      }))
    })
  }

  const itemClickAddressNull = ()  => {
    setResultsAddress([])
    setResultsCity([])
    setData(prevData => ({
      ...prevData, 
      address: {
        ...prevData.address,
        street: 'Aucune adresse trouvée',
        zipcode: '',
        city: '',
        inseecode: '',
        geolocation: []
      }
    }))
  }

  const itemClickCity = (item)  => {
    setResultsCity([])
    setData(prevData => ({
      ...prevData, 
      address: {
        ...prevData.address,
        street: '',
        zipcode: item.properties.postcode,
        city: item.properties.city,
        inseecode: item.properties.citycode,
        geolocation: [item.geometry.coordinates[1], item.geometry.coordinates[0]]
      }
    }))
  }

  const handleBlurAdress = () => {
    if (detectBlur) {
      setResultsAddress([])
      setDetectBlur(false)
    }
  }

  const handleBlurCity = () => {
    if (detectBlur) {
      setResultsCity([])
      setDetectBlur(false)
    }
  }

  const handleChangeType = (e) => {
    setData(prevData => ({
      ...prevData, 
      settings: {
        ...prevData.settings,
        type: e.target.value
      }
    }))
  }

  const handleChangeSurface = (e) => {
    setData(prevData => ({
      ...prevData, 
      settings: {
        ...prevData.settings,
        surface: e.target.value
      }
    }))
  }

  const handleChangeFloor = (e) => {
    setData(prevData => ({
      ...prevData, 
      settings: {
        ...prevData.settings,
        floor: e.target.value
      }
    }))
  }

  const buttonDisabled = () => {
    return (data?.building_id === '' || data?.name === '' || !data?.hasOwnProperty('address') || data?.address?.geolocation?.length === 0)
  }

  const handleCreate = async () => {
    try {
      await dispatch(createBuilding({ user_id, data })).unwrap();
      toast.success('Bâtiment créé avec succès!');
      const building = await dispatch(getBuildingById({ building_id: data.building_id })).unwrap();
      handleUpdateList()
      dispatch(changeActiveBuilding(building));
      dispatch(changeActiveRoute(`/settings/rooms/:${data.building_id}`))
      dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
      navigate(`/settings/rooms/:${data.building_id}`)
    } catch(error) {
      toast.error(`Erreur lors de la création : ${error.message || 'Une erreur est survenue.'}`);
      console.error('Erreur lors de la connexion:', error)
    }
  }

  const handleUpdate = () => {
    dispatch(updateBuilding({ user_id, data }))
    .unwrap()
    .then(() => {
      toast.success('Bâtiment modifié avec succès!');
      dispatch(changeActiveRoute('/buildings'))
      dispatch(initActiveBuilding())
      dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
      navigate('/buildings')
      handleUpdateList()
    })
    .catch((error) => {
      console.error('Erreur lors de la connexion:', error)
    })
  }
  
  const handleDiscard = () => {
    dispatch(changeActiveRoute('/buildings'))
    dispatch(initActiveBuilding())
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
    navigate('/buildings')
  }

  const handleShowModalDelete = () => {
    setShowModalDeleteBuilding(true)
  }

  const handleCloseModalDelete = () => {
    setShowModalDeleteBuilding(false)
  }

  const handleDelete = () => {
    setShowModalDeleteBuilding(false)
    dispatch(changeActiveRoute('/buildings'))
    dispatch(initActiveBuilding())
    dispatch(getDevices({ user_id }))
    dispatch(getBuildings({ user_id }))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
    navigate('/buildings')
  }


  return (
    <div className={s.root}>

      <div className={s.content}>
        
        <Form className={s.form}>
          
          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Nom</Label>
            <Input className={s.input} type="text" name="name" value={data?.name || ''} onChange={handleChangeName} placeholder={"Ex: Ma maison"}/>
          </FormGroup>       

          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Adresse</Label>
            <Input className={cx(s.input, { [s.readOnly]: !noCounter && !matchBuildingAdd})} type="text" name="street" value={data?.address?.street} onChange={handleChangeStreet} onKeyUp={getResultsAddress} onBlur={handleBlurAdress} placeholder={"Ex: 1 rue Jean Torlais 17000 La Rochelle"} readOnly={!noCounter && !matchBuildingAdd}/>
            {matchBuildingAdd && resultsAddress?.length > 0 ? (
            <div className={s.resultAddress}>
                <Collapse className={s.resultCollapse} isOpen={resultsAddress.length > 0}>
                  <ListGroup className={s.result}>
                    <ListGroupItem className={s.listItem0} onClick={itemClickAddressNull}>
                      Adresse non trouvée ? Ajouter manuellement
                    </ListGroupItem>
                    {resultsAddress.map((item, index) => (
                      <ListGroupItem className={s.listItem} key={index} onClick={() => itemClickAddress(item)}>
                        {item.properties.label}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Collapse> 
            </div>
            ) : null}
          </FormGroup>            

          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Ville</Label>
            <Input className={cx(s.input, { [s.readOnly]: !matchBuildingAdd})} type="text" name="city" value={`${data?.address?.zipcode ? data.address.zipcode : ''} ${data?.address?.city ? data.address.city : ''}`} onChange={handleChangeCity} onKeyUp={getResultsCity} onBlur={handleBlurCity} placeholder={"Ex: La Rochelle"} readOnly={(!noCounter && !matchBuildingAdd) || (data?.address?.street !== 'Aucune adresse trouvée')}/>
            {matchBuildingAdd && resultsCity?.length > 0 ? (
            <div className={s.resultCity}>
              <Collapse className={s.resultCollapse} isOpen={resultsCity.length > 0}>
                <ListGroup className={s.result}>
                  {resultsCity.map((item, index) => <ListGroupItem className={s.listItem} key={index} onClick={() => itemClickCity(item)}>{item.properties.postcode} - {item.properties.city}</ListGroupItem>)}
                </ListGroup>
              </Collapse>
            </div>
            ) : null}
          </FormGroup>

          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Type de bâtiment</Label>
            <Input className={s.input} type="select" name="building_type" value={data?.settings?.type} onChange={handleChangeType}>
              {LIST_BUILDING_TYPE.map((item, index) => 
                <option key={index} value={item.value}>{item.value}</option>
              )}
            </Input>
          </FormGroup>
          
          <div className={s.group}>      
            <FormGroup className={s.formGroup}>
              <Label className={s.label}>Surface (m²)</Label>
              <Input className={s.input} type="text" name="surface" value={data?.settings?.surface} onChange={handleChangeSurface} placeholder={"Ex: 150"}/>      
            </FormGroup>

            <FormGroup className={s.formGroup}>
              <Label className={s.label}>Nombre d'étage</Label>
              <Input className={s.input} type="text" name="floor" value={data?.settings?.floor} onChange={handleChangeFloor} placeholder={"Ex: 2"}/>             
            </FormGroup>
          </div>
          
        </Form>

        <div className={s.summary}>
          <h6>Aperçu de la configuration</h6>
          <ul>
            <li>Nombre d'espace: {data?.rooms?.length || 0}</li>
            <li>Nombre d'équipement: {linkedDevices.length}</li>
            <li>Nombre de compteur: {data?.sge_authorizations?.length || 0}</li>
          </ul>
        </div>

        <div className={s.content}> 
          <ul>
            <li>Station météo: {data?.weather_station?.name}</li>
            {/* <li>Nombre d'équipement: 0</li>
            <li>Nombre de service: 0</li> */}
          </ul>
        </div>

        <div className={s.control}>
          <div className={s.deleteControl}>
            {!matchBuildingAdd && 
            <Button className={s.btn} color="white" onClick={handleShowModalDelete}>
              <Icon name={'delete'} size={'1.5rem'} color={'#FF3C3C'} fill={0} cursor/>
              <span className={s.label}>Supprimer</span>
            </Button>}
          </div>
          <div className={s.rowControl}>
          <Button className={s.btn} color="primary" outline onClick={handleDiscard}>Annuler</Button>
          {matchBuildingAdd && <Button className={s.btn} color="primary" onClick={handleCreate} disabled={buttonDisabled()}>Valider</Button>}
          {!matchBuildingAdd && <Button className={s.btn} color="primary" onClick={handleUpdate} disabled={buttonDisabled()}>Modifier</Button>}
          </div>
        </div>

        <ModalDeleteBuilding showModalDeleteBuilding={showModalDeleteBuilding} handleClose={handleCloseModalDelete} handleDelete={handleDelete}/>
      </div>

    </div>
  )
}

export default CardBuildingData
