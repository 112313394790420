import React from 'react';
import s from './CardCurrentTemperature.module.scss';
import Icon from '../../../Icon/Icon';
import { shallowEqual, useSelector } from 'react-redux';
import { selectActiveRoom } from '../../../../selectors/selectNavigation';

function CardCurrentTemperature() {
  const activeRoom = useSelector(selectActiveRoom, shallowEqual);

  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.items}>
          <Icon name={'thermometer'} color={'#30465E'} size={'2.5rem'} fill={1} />
          <div className={s.item}>19,5°C</div>
        </div>
        <div className={s.description}>
          {activeRoom === null ? 'Température actuelle en moyenne sur le bâtiment' : 'Température actuelle'}
        </div>
      </div>
    </div>
  );
}

export default CardCurrentTemperature;
