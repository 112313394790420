import React from 'react'
import s from './Step01CardWelcome.module.scss'
import { Button } from 'reactstrap'

function Step01CardWelcome({ discard, inc}) {

  return (        
    <div className={s.root}>    
      <div className={s.header}>
        <div className={s.title}>Bienvenue dans l'assistant d'ajout d'équipement</div>
      </div>
      <div className={s.content}>
        <p>Cet assistant va vous guider dans l'ajout d'un équipement connecté à votre compte.</p>
      </div>
      <div className={s.control}>
        <Button color="primary" outline onClick={discard}>Annuler</Button>
        <Button color="primary" onClick={inc} disabled={false}>Suivant</Button>
      </div>
    </div>
  )
}

export default Step01CardWelcome