import React from 'react'
import s from './HeaderConsult.module.scss'
import ControlPeriod from '../../Control/ControlPeriod/ControlPeriod'
import ControlSelectBuilding from '../../Control/ControlSelectBuilding/ControlSelectBuilding'
import NavigationConsult from '../../Navigation/NavigationConsult/NavigationConsult'

function HeaderConsult() {

  return (
    <div className={s.header}>
      <ControlSelectBuilding/>
      <ControlPeriod/>
      <NavigationConsult/>
    </div>
  )
}

export default HeaderConsult
