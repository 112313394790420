import React, { useState } from 'react'
import s from './Monitoring.module.scss'
import InConstruction from '../../../../components/InConstruction/InConstruction'

function Monitoring() {
  
  const [searchQuery, setSearchQuery] = useState('');
  const [isHover, setIsHover] = useState(false);
  const [showModalCreateScenario, setShowModalCreateScenario] = useState(false)

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClose = () => {
    setShowModalCreateScenario(false)
  }

  const handleAdd = () => {
    alert('le scénario a été créé');
    handleClose()
  }

  return (
    <div className={s.root}>
      <div className={s.content}>
        <InConstruction />
      </div>
    </div>
  )
}

export default Monitoring
