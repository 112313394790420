import React from 'react'
import s from './Error.module.scss'

function Error() {
  return (
    <div className={s.root}>
      <h1 className={s.errorCode}>404</h1>
      <p className={s.errorInfo}>
        Opps, cette page n'existe pas.
      </p>
    </div>
  )
}

export default Error
