import { createSlice } from '@reduxjs/toolkit';
import { getAlgoData } from './measuresActions';

const initialStates = {
  isLoading: false,
  stat_summary: {
    temperature : null,
    humidity: null,
    co2: null,
    motion: null,
    consumption: null,
  },
  consumption: null,
  comfort: null,
  air_quality: null,
  occupancy: null,
  error: null,
};

const measuresSlice = createSlice({
  name: 'measures',
  initialState: initialStates,
  reducers: {},
  extraReducers: builder => {
    builder
      // GET ALGO DATA
      .addCase(getAlgoData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAlgoData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const measurement = payload.measurement
        state.stat_summary[measurement] = payload.data
      })
      .addCase(getAlgoData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default measuresSlice.reducer;
