import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import s from './CardDeviceModal.module.scss'
import Icon from '../../../../../../components/Icon/Icon';
import { changeActiveDevice } from '../../../../../../features/navigation/navigationSlice';
import { saveNavigationState } from '../../../../../../features/navigation/navigationActions';
import store from '../../../../../../redux/store';
import moment from 'moment';
import 'moment/locale/fr';

function CardDeviceModal({ device, handleDevice }) {

  return (
    <div className={s.root} onClick={() => handleDevice(device)}>
      <div className={s.header}>
        <div className={s.title}>{device?.name}</div>
        <div className={s.arrowIcon}>
          <Icon name={"arrow_forward"} />
        </div>
      </div>
      <div className={s.infos}>
        <div className={s.info}>
          <span>Marque : </span>
          {device?.manufacturer}
        </div>
        <div className={s.info}>
          <span>Numéro de série : </span>
          {device?.serial_number}
        </div>
        <div className={s.info}>
          <span>Dernière donnée : </span>
          {moment(device.latest_online).format('DD/MM/YYYY HH:mm:ss')}
        </div>
      </div>
    </div>
  )
}

export default CardDeviceModal
