export const selectUser = (state) => {
  return state.user
}

export const selectProfile = (state) => {
  return state.user.profile
}

export const selectAvatarURL = (state) => {
  return state.user.profile && state.user.profile.avatar_url
}

export const selectUserId = (state) => {
  return state.user.profile && state.user.profile.user_id
}

export const selectUserId2 = () => {
  return (state) =>
      state.user &&
      state.user.user_id
}

export const selectIsLoading = (state) => {
  return state.user.isLoading
}

export const selectErrorMessage = (state) => {
  return state.user.error
}

export const selectSettings = (state) => {
  return state.user.profile && state.user.profile.settings
}

export const selectRangeTime = () => {
  return (state) => 
      state.user.profile && 
      state.user.profile.settings.range_time
}

export const selectRangeDate = () => {
  return (state) => 
  state.navigation.activePeriod.startDateTime
}

export const selectElectricityUnit = (state) => {
  return state.user.profile && state.user.profile.settings.electricity_unit
}
