import React, { useEffect, useState } from 'react'
import s from './Sidebar.module.scss'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { NavLink, useMatch } from 'react-router-dom'
import { changeActiveRoute } from '../../features/navigation/navigationSlice'
import { saveNavigationState } from '../../features/navigation/navigationActions'
import store from '../../redux/store';
import { selectProfile } from '../../selectors/selectUser'
import { getProfile } from '../../features/user/userActions'
import { getAlerts } from '../../features/alert/alertsActions'
import Icon from '../Icon/Icon'

function Sidebar() {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const isMatchBuildings = useMatch('/buildings/*')
  const isMatchSettings = useMatch('/settings/*')
  const isMatchDevices = useMatch('/devices/*')
  const isMatchAutomation = useMatch('/automation/*')
  const isMatchConsult = useMatch('/consult/*')
  //const isMatchCompare =  useMatch('/compare/*')
  const isMatchAnomalies = useMatch('/anomalies/*')
  const isMatchReports = useMatch('/reports/*')

  const isMatchAccount = useMatch('/account/*')

  const profile = useSelector(selectProfile, shallowEqual)

  const [imageURL, setImageURL] = useState('')

  useEffect(() => {
      dispatch(getProfile({ user_id }))
      dispatch(getAlerts())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_id])

  useEffect(() => {
      if (!profile?.avatar_url) {
        setImageURL(`https://ui-avatars.com/api/?background=random&name=${profile?.firstname}+${profile?.lastname}`)
      } else {
        setImageURL(profile.avatar_url)
      }
      // eslint-disable-next-line
    }, [profile])

  const handleChangeActiveItem = (e, link, section) => {
    e.stopPropagation()
    dispatch(changeActiveRoute(link))
    if (section !== 'compare' && section !== 'security' && section !== 'account') {
      dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
    }
  }

  return (
    <div className={s.mainMenu}>

      <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/buildings", 'buildings')}>
        <NavLink to="/buildings" className={() => isMatchBuildings || isMatchSettings ? s.link_active : s.link}>
          <Icon name="domain" size={'1.5rem'} color={isMatchBuildings || isMatchSettings ?'#FFF' : '#3192D3'} fill={0}/>
          <span className={s.item}>BÂTIMENTS</span>
        </NavLink>
      </div>
    
      <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/consult/summary", 'consult')}>
        <NavLink to="/consult" className={() => isMatchConsult ? s.link_active : s.link}>
          <Icon name="dashboard" size={'1.5rem'} color={isMatchConsult ?'#FFF' : '#3192D3'} fill={0}/>
          <span className={s.item}>CONSULTER</span>
        </NavLink> 
      </div>

      <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/devices/equipements", 'devices')}>
        <NavLink to="/devices" className={() => isMatchDevices ? s.link_active : s.link}>
          <Icon name="memory" size={'1.5rem'} color={isMatchDevices ?'#FFF' : '#3192D3'} fill={0}/>
          <span className={s.item}>ÉQUIPEMENTS</span>
        </NavLink> 
      </div>

      {/* onClick={(e) => handleChangeActiveItem(e, "/automation/rules", 'automation')} */}
      <div className={s.headerLink}>
        <NavLink className={s.disable}>
          <Icon name="automation" size={'1.5rem'} color={'#595d78'}  fill={0}/>
          <span className={s.item}>AUTOMATISATION</span>
        </NavLink>
      </div>

      {/* onClick={(e) => handleChangeActiveItem(e, "/compare", 'compare')} */}
      <div className={s.headerLink}>
        <NavLink className={s.disable}>
          <Icon name="compare_arrows" size={'1.5rem'} color={'#595d78'} fill={1}/>
          <span className={s.item}>COMPARER</span>
        </NavLink> 
      </div>

      <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/anomalies", "anomalies")} >
        <NavLink to='/anomalies' className={() => isMatchAnomalies ? s.link_active : s.link}>
          <Icon name="warning" size={'1.5rem'} color={isMatchAnomalies ?'#FFF' : '#3192D3'} fill={0}/>
          <span className={s.item}>ANOMALIES</span>
        </NavLink>
      </div>

      <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/reports", 'reports')}>
        <NavLink to="/reports" className={() => isMatchReports ? s.link_active : s.link}>
          <Icon name="description" size={'1.5rem'} color={isMatchReports ?'#FFF' : '#3192D3'} fill={0}/>
          <span className={s.item}>RAPPORTS</span>
        </NavLink>
      </div>

      <div className={s.account} to={`/account`} onClick={(e) => handleChangeActiveItem(e,'/account', 'account')}>
          <NavLink to="/account" className={() => isMatchAccount ? s.link_active : s.link}>
            <div className={s.avatar} id="tooltip-avatar">
              <img className={s.image} src={imageURL} aria-hidden alt="Photo de mon profil" />
            </div>
            <span className={s.item}>COMPTE</span>
          </NavLink> 
      </div>

      <div className={s.helper}>
          <NavLink to="#">
            <span className={s.link}>SUPPORT</span>
          </NavLink> 
      </div>

    </div> 

  )
}

export default Sidebar
