import React, { useEffect, useState } from 'react';
import s from './CardBuildingRanking.module.scss';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getBuildings } from '../../../../features/buildings/buildingsActions';
import { selectListBuildings } from '../../../../selectors/selectBuildings';
import { selectActiveBuilding, selectActiveConsoUnit, selectActivePeriodUnit, selectPeriod } from "../../../../selectors/selectNavigation";
import { selectIndicatorType, selectIsLoading, selectError } from "../../../../selectors/selectIndicator";
import { getBuildingRanking } from '../../../../features/indicators/indicatorsActions'
import Icon from '../../../Icon/Icon';
import moment from 'moment'
moment.locale('fr')

function CardBuildingsRanking() {

  const dispatch = useDispatch();

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;
  
  const buildings = useSelector(selectListBuildings);
  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual);
  const [isClicked, setIsClicked] = useState(false)

  const unity =  isClicked ? 'KWM/M²' : 'KWH';
  const mockDataConsumption = ['13','16','10','17','14','20','18']

  const indicatorData = useSelector((state) => selectIndicatorType(state, "building_ranking", period));
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  
  useEffect(() => {
    dispatch(getBuildings({ user_id }));
  },[]);

  useEffect(() => {
    const today = moment().startOf('day');
    const periodStart = moment(period.start, "YYYY-MM-DD HH:mm:ss");
    if (periodStart.isBefore(today)) {
      dispatch(getBuildingRanking({parameters: { user_id, periodUnit, period }}));
    }
  }, [dispatch, user_id, periodUnit, period]);

  return (
    <div className={s.root}>
      <div className={s.header}>
        <div className={s.label}>BÂTIMENTS</div>
        <div className={s.switch}>
          <div className={s.label}>{unity}</div>
          <div className={s.button}>
            <Icon name={'expand_all'} size={'1.2rem'} onClick={() => setIsClicked(!isClicked)}/>
          </div>  
        </div>
      </div>
      <div className={s.content}>
        {indicatorData?.building_names?.map((building, index) => (
          <div key={index} className={index % 2 === 0 ? s.ranking : s.rankingGray}>
            <div className={s.label}>{building.toUpperCase()}</div>
            <div className={s.label}>{indicatorData?.building_values[index]}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CardBuildingsRanking;
