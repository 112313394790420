import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import s from './ModalUpdateDevice.module.scss'
import { Button, Form, FormGroup, Input, InputGroup, Label, ListGroup, ListGroupItem } from "reactstrap"
import Modal from '../../../../../../components/Modal/Modal'
import { selectActiveBuildingDevice, selectActiveDevice, selectActiveRoomDevice, selectRoomsDevice } from '../../../../../../selectors/selectNavigation'
import { updateDevice } from '../../../../../../features/devices/devicesActions'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Icon from '../../../../../../components/Icon/Icon'
import { changeActiveBuildingDevice, changeActiveRoomDevice, initActiveBuildingDevice, initActiveRoomDevice } from '../../../../../../features/navigation/navigationSlice'
import { selectListBuildings } from '../../../../../../selectors/selectBuildings'
import store from '../../../../../../redux/store'
import { saveNavigationState } from '../../../../../../features/navigation/navigationActions'

function ModalUpdateDevice({ showModalUpdateDevice, handleClose }) {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const device = useSelector(selectActiveDevice, shallowEqual)
  const buildings = useSelector(selectListBuildings, shallowEqual);
  const buildingSelected = buildings.find(building => building?.building_id === device?.building?.building_id);

  const buildingDevice = useSelector(selectActiveBuildingDevice, shallowEqual)
  const rooms = useSelector(selectRoomsDevice,shallowEqual)
  const roomDevice = useSelector(selectActiveRoomDevice, shallowEqual)

  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownBuildingOpen, setIsDropdownBuildingOpen] = useState(false);
  const [isDropdownRoomOpen, setIsDropdownRoomOpen] = useState(false);
  const [deviceData, setDeviceData] = useState(device)

  useEffect(() => {
    if(buildingSelected) {
      dispatch(changeActiveBuildingDevice(buildingSelected))
      dispatch(initActiveRoomDevice())
      console.log(rooms)
    }
  },[device])

  const handleFormChange = (e) => {
    const { name, value } = e.target
    setDeviceData(prevData => ({
      ...prevData,
      [name]: value 
    }))
  }

  const handleUpdateDevice = () => {
    const updatedData = {
      ...device,
      name: device?.name,
      description: device?.description,
      building: buildingDevice,
      room:  roomDevice || '',
    };
    console.log(updatedData)
    dispatch(updateDevice({ data: updatedData }))
    toast.success("Equipement modifié !")
    handleClose()
  }

  const handleSearchChange = (e) => {
    if (e.target.value === '') {
      setSearchTerm('');
      setIsDropdownBuildingOpen(false);
      setIsDropdownRoomOpen(false);
      handleClickBuildingAll();
      dispatch(initActiveBuildingDevice());
      
    } else {
      setIsDropdownBuildingOpen(true);
      setSearchTerm(e.target.value);
    }
  };

  const filteredBuildings = buildings?.filter((building) => building?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()));

  const handleClickBuildingItem = (building) => {
    setSearchTerm(building.name);
    dispatch(changeActiveBuildingDevice(building))
    setIsDropdownBuildingOpen(false);
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
  };

  const handleClickBuildingAll = () => {
    setSearchTerm('');
    setIsDropdownBuildingOpen(false);
    dispatch(initActiveBuildingDevice());
  };

  const handleClickRoomItem = (room) => { 
    dispatch(changeActiveRoomDevice(room))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    setIsDropdownRoomOpen(false)
  };

  const handleClickResetRoomItem = () => {
    setIsDropdownRoomOpen(false)
  };

  const handleDropdownListBuilding = (e) => {
    e.stopPropagation()
    setIsDropdownRoomOpen(false)
    setIsDropdownBuildingOpen(!isDropdownBuildingOpen)
  };

  const handleDropdownListRoom = (e) => {
    e.stopPropagation()
    setIsDropdownBuildingOpen(false)
    setIsDropdownRoomOpen(!isDropdownRoomOpen)
  };

  return (
    <Modal show={showModalUpdateDevice}>
      
      <div className={s.header}>
        <div className={s.title}>Modifier un équipement</div>
        <Icon name="close" size={'1.5rem'} color={'#30465E'} fill={0} onClick={handleClose} cursor/>
      </div>
      
      <Form className={s.form}>
        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Nom</Label>
          <Input className={s.input} type="text" name="name" onChange={handleFormChange} value={device?.name}/>
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Description</Label>
          <Input className={s.input} type="text" name="description" onChange={handleFormChange} value={device?.description}/>
        </FormGroup>

        <FormGroup className={s.formGroupBuilding}>
          <Label className={s.label}>Bâtiment</Label>
          <InputGroup className={s.inputGroupBuilding}>
              <Input className={`${device?.building !==null ? s.disable : s.input}`} type='search' placeholder={'Rechercher un bâtiment...'} value={buildingDevice !== null ? buildingDevice?.name : searchTerm} onChange={handleSearchChange} onClick={(e) => handleDropdownListBuilding(e)} disabled={device?.building !==null}/>
          </InputGroup>

          {filteredBuildings.length > 0 && isDropdownBuildingOpen &&
          <ListGroup className={s.listGroup}>
          {(searchTerm && searchTerm !== buildingDevice?.name ? filteredBuildings : buildings)?.map((building, index) => (
          <div className={s.item} key={index}>
              <Icon name={'circle'} size={'1.2rem'} color={'#2d8515'} fill={1} cursor/>
              <ListGroupItem className={s.name} onClick={() => handleClickBuildingItem(building)}>{building.name}</ListGroupItem> 
          </div>
          ))}
          </ListGroup>
          }
        </FormGroup>

        <FormGroup className={s.formGroupRoom}>
          <Label className={s.label}>Pièce</Label>
          <InputGroup className={s.inputGroupRoom} onClick={(e) => handleDropdownListRoom(e)}>   
              <Input className={`${device?.room !== null ? s.disable : s.input}`} name='room' placeholder='sélectionnez une salle' value={device?.room !== null ? device?.room?.name : roomDevice?.name} disabled={device?.room !== null} />
          </InputGroup>

          {rooms?.length > 0 && isDropdownRoomOpen &&
          <ListGroup className={s.listGroup}>
            <div className={s.item}>
              <ListGroupItem className={s.name} onClick={() => handleClickResetRoomItem()}>Mes espaces</ListGroupItem>
            </div>
            {rooms?.map((room, index) => (
            <div className={s.item} key={index}>
              <ListGroupItem className={s.name} onClick={() => handleClickRoomItem(room)}>{room.name}</ListGroupItem>
            </div>))}
          </ListGroup>}
        </FormGroup>
      </Form>

      <div className={s.control}>
        <Button color="primary" onClick={handleUpdateDevice} disabled={deviceData?.name === ''}>Valider</Button> 
      </div>

    </Modal>
  )
}

export default ModalUpdateDevice
