import React, { useEffect } from 'react'
import s from './ModalDeleteBuilding.module.scss'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectActiveBuilding } from '../../../../../../selectors/selectNavigation'
import { deleteBuilding } from '../../../../../../features/buildings/buildingsActions'
import Popup from '../../../../../../components/Popup/Popup'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ModalDeleteBuilding({ showModalDeleteBuilding, handleClose, handleDelete }) {

  const dispatch = useDispatch()

  const building = useSelector(selectActiveBuilding, shallowEqual)

  const onDelete = () => {
    const building_id = building?.building_id
    dispatch(deleteBuilding({ building_id }))
    .unwrap()
    .then(() => {
      toast.warn('Bâtiment supprimé avec succès!');
      handleDelete()
    })
    .catch((error) => {
      toast.error(`Erreur lors de la suppression : ${error.message || 'Une erreur est survenue.'}`);
      console.error('Erreur lors de la connexion:', error)
    })
  }

  return (
    <Popup title={"Suppression d'un bâtiment"} show={showModalDeleteBuilding} onDiscard={handleClose} onAccept={onDelete}>
      <div className={s.message}>Etes vous sûr de vouloir supprimer ce bâtiment ?</div>
    </Popup>
  )
}

export default ModalDeleteBuilding
