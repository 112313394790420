import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import s from './ModalPricingHistory.module.scss'
import { Button, Form, FormGroup, Input, Label } from "reactstrap"
import Modal from '../../../../../../components/Modal/Modal'
import Icon from '../../../../../../components/Icon/Icon'
import CardPricing from '../CardPricing/CardPricing'
import { selectActiveLinky } from '../../../../../../selectors/selectNavigation'
import moment from 'moment'
import AddPricingForm from '../AddPrincingForm/AddPricingForm'
import ModifyPricingForm from '../ModifyPricingForm/ModifyPricingForm'
moment.locale('fr')

function ModalPricingHistory({ showModalPricingHistory, handleClose }) {

  const counter = useSelector(selectActiveLinky, shallowEqual)
  const contract = counter?.technical_and_contract?.point?.situationContractuelle?.structureTarifaire?.calendrierFrn?.libelle || '---';
  const [isEditing, setIsEditing] = useState(false)
  const [isAddPricing, setIsAddPricing] = useState(false)
  const [pricingIndex, setPricingIndex] = useState(null)
  const [isHpHcContract, setIsHpHcContract] = useState(false)
  const [isBaseContract , setIsBaseContract] = useState(false)

  const handleAddPricing = () => {
    setIsAddPricing(true)
  }

  const handleCloseModal = () => {
    setIsEditing(false)
    setIsAddPricing(false)
    setIsBaseContract(false)
    setIsHpHcContract(false)
    handleClose()
  }

  return (
    <Modal show={showModalPricingHistory}>

    <div className={s.container}>

      <div className={s.header}>
        {isAddPricing ?
          <div className={s.title}>Ajouter un nouveau tarif</div> 
          : 
          <div className={s.title}>Paramétrage du prix de votre électricité</div>
        }
        <Icon name="close" size={'1.5rem'} color={'#30465E'} fill={0} onClick={handleCloseModal} cursor/>
      </div>

      {!isEditing && !isAddPricing &&
      <Button className={s.btnAdd} onClick={() => handleAddPricing()}>
        <Icon name="add" size={'1.5rem'} color={'#FFFFFF'} fill={1} cursor/>
        <span className={s.item}>Ajouter un tarif</span>
      </Button>
      }

      {isAddPricing && <AddPricingForm setIsAddPricing={setIsAddPricing} /> }

      {!isAddPricing && !isEditing &&
      <div className={s.timeline}>
        {counter?.pricing_history?.map((pricing, index) => (
          <div key={index} className={s.timelineItem}>
            <div className={s.timelineDot}></div>
            <div className={s.timelineContent}>
              {index === 0 ? (
                <div className={s.title}>depuis le {moment(pricing.effective_at).format('DD/MM/YYYY')}</div>
              ) : (
                <div className={s.title}>
                  du {moment(pricing.effective_at).format('DD/MM/YYYY')} au {moment(pricing.end_at).format('DD/MM/YYYY')}
                </div>
              )}
              <CardPricing key={index} index={index} setPricingIndex={setPricingIndex} counter={counter} pricing={pricing} setIsEditing={setIsEditing} isEditing={isEditing} />
            </div>
          </div>
        ))}
      </div>
      }

      {isEditing && <ModifyPricingForm setIsEditing={setIsEditing} pricing={counter?.pricing_history[pricingIndex]} contract={contract} />}
    </div>

    </Modal>
  )
}

export default ModalPricingHistory
