export const WEEK_DAYS = [
    "Di",
    "Lu",
    "Ma",
    "Me",
    "Je",
    "Ve",
    "Sa"
]

export const WEEK = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche"
]

export const WEEK_SHORT = [
    "Lu",
    "Ma",
    "Me",
    "Je",
    "Ve",
    "Sa",
    "Di"
]

export const DAYS_LONG = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi"
]

export const DAYS_SHORT = [
    "Di",
    "Lu",
    "Ma",
    "Me",
    "Je",
    "Ve",
    "Sa"
]

export const MONTHS = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
]

export const PERIODS = [
    "Hier",
    "La semaine dernière",
    "Le mois dernier",
    "L'année dernière"
]