import React from 'react'
import s from './Logo.module.scss'
import { useNavigate } from 'react-router-dom'
import LogoRow from '../../assets/logo/LogoInsensiaRow'
import { useDispatch } from 'react-redux'
import { changeActiveRoute, initActiveBuilding, initActiveDevice, initActiveLinky, initActiveRoom } from '../../features/navigation/navigationSlice'
import { saveNavigationState } from '../../features/navigation/navigationActions'
import store from '../../redux/store';

function Logo() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const handleClickNavigation = () => {
    dispatch(changeActiveRoute('/buildings'))
    dispatch(initActiveBuilding())
    dispatch(initActiveRoom()) 
    dispatch(initActiveDevice())
    dispatch(initActiveLinky())
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
    navigate(`/buildings`)
  }

  return (
    <div onClick={() => handleClickNavigation()}>
      <LogoRow className={s.img}/>
    </div>
  )
}

export default Logo