import React, { useState } from 'react'
import '../../styles/app.scss';
import './icon.scss'

function Icon({ name, size = 24, color = '#000', fill = 0, grad = 0, opsz = 24, wght = 300, onClick, cursor = false, animationClass, opacity=1}) {

  const style = {
    fontSize: size,
    color: color,
    fontVariationSettings: `'FILL' ${fill}, 'GRAD' ${grad}, 'opsz' ${opsz}, 'wght' ${wght}`,
    cursor: cursor ? 'pointer' : 'default',
    opacity :opacity
  };

  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    if (onClick) onClick();
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 300);
  };

  return (
    <span className={`material-symbols-rounded ${isAnimating ? animationClass : ''}`} style={style} onClick={handleClick}>
      {name}
    </span>
  );
}

export default Icon