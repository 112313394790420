// smartThingsSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { getTiersUserStatus, connectTiersUser, disconnectTiersUser } from './tiersUserAction';

const tiersUserSlice = createSlice({
  name: 'tiersUsers',
  initialState: { isConnected: {}, user_id: null, data: {}, api: null, loading: false, error: null},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTiersUserStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTiersUserStatus.fulfilled, (state, action) => {
        const { user_id } = action.meta.arg;
        const api = action.meta.arg.data.api
        state.isConnected = {
          ...state.isConnected,
          [api]: action.payload,
        };
        state.user_id = user_id
        state.loading = false;
      })
      .addCase(getTiersUserStatus.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(connectTiersUser.fulfilled, (state, action) => {
        const api = action.meta.arg.data.api;
        state.isConnected = {
          ...state.isConnected,
          [api]: true,
        };
      })
      .addCase(disconnectTiersUser.fulfilled, (state, action) => {
        const api= action.meta.arg.data.api;
        state.isConnected = {
          ...state.isConnected,
          [api]: false,
        };
      });
  }
});

export default tiersUserSlice.reducer;