import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import s from './CardDevice.module.scss'
import Icon from '../../../../components/Icon/Icon';
import store from '../../../../redux/store';
import moment from 'moment';
import 'moment/locale/fr';
import { changeActiveBuildingDevice, changeActiveDevice } from '../../../../features/navigation/navigationSlice';
import { saveNavigationState } from '../../../../features/navigation/navigationActions';
import { selectActiveBuilding } from '../../../../selectors/selectNavigation';

function CardDevice({ device, handleShowModal }) {

  const dispatch = useDispatch()
  
  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;
  const building = useSelector(selectActiveBuilding, shallowEqual)

  const handleSetting = () => {
    handleShowModal('settings');
    dispatch(changeActiveDevice(device))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
  }

  const handleDissociate = () => {
    handleShowModal('dissociate');
    dispatch(changeActiveDevice(device))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation} }))
  }

  const handleDelete = () => {
    handleShowModal('delete');
    dispatch(changeActiveDevice(device))
    dispatch(saveNavigationState({ user_id, state: {...store.getState().navigation} }))
  }

  useEffect(() => {
    console.log(device)
  },[device])

  return (
    <div className={s.root}>
      <div className={s.header}>
        <div className={s.title}>
          {device?.name}
        </div>
        <div className={s.control}>
          {device?.building === null ?
          <div className={s.delete}>
            <Icon name={'delete'} color='#E6514C' onClick={handleDelete} cursor/>
          </div> :
          <div className={s.unlink}>
            <Icon name={'link_off'} color='#E6514C' onClick={handleDissociate} cursor/>
          </div>
          }
          <div className={s.modify}>
            <Icon className={s.icon} name={'edit'} color={'#3192D3'} onClick={handleSetting} cursor/>
          </div>
        </div>
      </div>
      <div className={s.infos}>
        <div className={s.affectations}>
          <div className={s.label}> 
            <Icon name="apartment" size={'1.5rem'} color={device?.building ? '#3192D3' : '#FF8F0B'} cursor/>
            <span>{device?.building ? `${device?.building.name}` : '---' }</span>
          </div>
          <div className={s.label}> 
            <Icon name="meeting_room" size={'1.5rem'} color={device?.room ? '#3192D3' : '#FF8F0B'} cursor/>
            <span>{device?.room ? `${device?.room.name}` : '---' }</span>
          </div>
        </div>
        <div className={s.info}>
          <span>Marque : </span>
          {device?.manufacturer}
        </div>
        <div className={s.info}>
          <span>Dernière donnée : </span>
          {moment(device.latest_online).format('DD/MM/YYYY HH:mm:ss')}
        </div>
        <div className={s.info}>
          <span>Numéro de série : </span>
          {device?.serial_number}
        </div>
      </div>
    </div>
  )
}

export default CardDevice
