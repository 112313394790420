import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { checkDeviceExist } from '../../../../../../../../features/pairing/pairingActions'
import { resetDeviceExist, saveSerialNumber} from '../../../../../../../../features/pairing/pairingSlice'
import s from './Step02CardSerialNumber.module.scss'
import { Button, FormGroup, InputGroup, Input } from 'reactstrap'
import { selectSerialNumber, selectCheckingDeviceExist } from '../../../../../../../../selectors/selectPairing'

function Step02CardSerialNumber({ discard, inc, dec }) {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id

  const deviceExist = useSelector(selectCheckingDeviceExist)
  const serial_number = useSelector(selectSerialNumber)

  const [enableButton, setEnableButton] = useState(true)
  const [serialNotMatch, setSerialNotMatch] = useState(null)

  useEffect(() => {
    const regex = /^[a-fA-F0-9]{12}$/
    if ((serial_number !== null || serial_number !== '') && regex.test(serial_number)) {
      dispatch(checkDeviceExist({ user_id, serial_number }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serial_number])

  useEffect(() => {
    if (deviceExist === false) {
      setEnableButton(false)
    } else {
      setEnableButton(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceExist])



  const changeID = (e)  => {
    let serial_number = e.target.value
    const regex = /^[a-fA-F0-9]{12}$/
    if (regex.test(serial_number)) {
      setSerialNotMatch(false)
      dispatch(saveSerialNumber(serial_number))
      dispatch(checkDeviceExist({ user_id, serial_number }))
    } else {
      setSerialNotMatch(true)
      dispatch(resetDeviceExist())
    }
  }


  return (          
    <div className={s.root}>    
      
      <div className={s.content}>
        
        {serialNotMatch === true && <small className={s.invalid_msg}>Identifiant invalide</small>}
        <FormGroup className={s.formGroup}>
          <Input className={s.input} type="text" placeholder="" onChange={changeID} valid={!enableButton} invalid={enableButton} defaultValue={serial_number}/>
        </FormGroup>
        {serialNotMatch === true && <small className={s.invalid_msg}>L'identifiant doit contenir 12 caractères de 0 à 9 et de A à F</small>}
        {deviceExist && <small className={s.invalid_msg}>Ce capteur est déjà associé à votre compte</small>}

      </div>
  
      <div className={s.control}>
        <Button color="primary" onClick={dec} disabled={false}>Précédent</Button>
        <Button color="primary" outline onClick={discard}>Annuler</Button>
        <Button color="primary" onClick={inc} disabled={serialNotMatch || deviceExist || serial_number === ''}>Suivant</Button>
      </div>
    </div>
  )
}

export default Step02CardSerialNumber