import React, { useState, useEffect, useRef } from 'react';
import {  shallowEqual, useDispatch, useSelector } from 'react-redux';
import s from './ControlPeriod.module.scss';
import './customCalendar.css';
import { Button } from 'reactstrap';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { DAYS_SHORT, MONTHS } from '../../../utils/constants/list_calendar';
import { changeActivePeriodUnit, changeActiveDay, changeActiveMonth, changeActiveWeek, changeActiveYear, changeActiveFreePeriod } from '../../../features/navigation/navigationSlice';
import { selectActivePeriodUnit, selectPeriod } from '../../../selectors/selectNavigation';
import moment from 'moment';
import { saveNavigationState } from '../../../features/navigation/navigationActions';
import store from '../../../redux/store';
import Icon from '../../Icon/Icon';
import { isDateMax } from '../../../utils/functions/dateHandler';

moment.locale('fr')

function ControlPeriod() {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const modalCalendarRef = useRef(null)

  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual)

  const [openCalendar, setOpenCalendar] = useState(false)

  const [periodIndex, setPeriodIndex] = useState(0)
  const periodUnits = ['day', 'week', 'month', 'year'];
  const labelPeriodUnit = ['Jour', 'Semaine', 'Mois', 'Année']
  const [periodLabel, setPeriodLabel] = useState('Jour')
  
  const [currentShortcut, setCurrentShortcut] = useState('day')
  const [currentPeriodUnit, setCurrentPeriodUnit] = useState('')
  const [currentPeriod, setCurrentPeriod] = useState({})
  
  useEffect(() => {
    const handleCloseCalendar = (e) => {
      if (modalCalendarRef.current && !modalCalendarRef.current.contains(e.target)) {
        setTimeout(() => {
          setOpenCalendar(false);
        }, 100); 
      }
    };
    document.addEventListener('mousedown', handleCloseCalendar);      
    return () => {
      document.removeEventListener('mousedown', handleCloseCalendar);
    };
  }, [openCalendar]);

  useEffect(() => {
    setCurrentPeriodUnit(periodUnit)
    setCurrentPeriod(period)
  }, [periodUnit, period]);

  const handleChangePeriodUnit = (direction) => {
    const newIndex = (periodIndex + direction + periodUnits.length) % periodUnits.length;
    setPeriodIndex(newIndex);
    setPeriodLabel(labelPeriodUnit[newIndex]);
    setCurrentPeriodUnit(periodUnits[newIndex]);
    dispatch(changeActivePeriodUnit(periodUnits[newIndex])) 
  }

  const handleIncPeriodUnit = () => handleChangePeriodUnit(1);
  const handleDecPeriodUnit = () => handleChangePeriodUnit(-1);

  const handleChangeShortcutPeriod = (unit) => {
    setCurrentPeriodUnit(unit)
    switch (unit) {
      case 'day':
        handleShortcutCurrentDay()
        break;
      case 'week':
        handleShortcutCurrentWeek()
        break;
      case 'month':
        handleShortcutCurrentMonth()
        break;
      case 'year':
        handleShortcutCurrentYear()
        break;
      case 'free':
        break;
      default:
        return null;
    }
  }
  
  const handleShortcutCurrentDay = () => {
    const day = {
      start: moment().format('YYYY-MM-DD 00:00:00'),
      end: moment().format('YYYY-MM-DD 23:59:59')
    }
    setCurrentPeriod(day)
    setCurrentShortcut('day')
    setPeriodLabel('Jour')
    setPeriodIndex(0)
  }

  const handleShortcutCurrentWeek = () => {
    const week = {
      start: moment().startOf('week').isoWeekday(1).format('YYYY-MM-DD 00:00:00'),
      end: moment().endOf('week').isoWeekday(7).format('YYYY-MM-DD 23:59:59')
    }
    setCurrentPeriod(week) 
    setCurrentShortcut('week')
    setPeriodLabel('Semaine')
    setPeriodIndex(1)
  }

  const handleShortcutCurrentMonth = () => {
    const month = {
      start: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
      end: moment().endOf('month').format('YYYY-MM-DD 23:59:59')
    }
    setCurrentPeriod(month)
    setCurrentShortcut('month')
    setPeriodLabel('Mois')
    setPeriodIndex(2)
  }

  const handleShortcutCurrentYear = () => {
    const year = {
      start: moment().startOf('year').format('YYYY-MM-DD 00:00:00'),
      end: moment().endOf('year').format('YYYY-MM-DD 23:59:59')
    }
    setCurrentPeriod(year)
    setCurrentShortcut('year')
    setPeriodLabel('Année')
    setPeriodIndex(3)
  }

  const handleChangeDay = (values) => {
    const day = {
      start: moment(values.toDate()).format('YYYY-MM-DD 00:00:00'),
      end: moment(values.toDate()).format('YYYY-MM-DD 23:59:59')
    }
    setCurrentPeriod(day)
    setCurrentShortcut('')
  }

  const handleChangeWeek = (values) => {
    if (values.length === 2) {
      const week = {
        start: moment(values[0].toDate()).startOf('week').isoWeekday(1).format('YYYY-MM-DD 00:00:00'),
        end: moment(values[0].toDate()).endOf('week').isoWeekday(7).format('YYYY-MM-DD 23:59:59')
      }
      setCurrentPeriod(week)
      setCurrentShortcut('')
    }
  }

  const handleChangeMonth = (values) => {
    const month = {
      start: moment(values.toDate()).startOf('month').format('YYYY-MM-DD 00:00:00'),
      end: moment(values.toDate()).endOf('month').format('YYYY-MM-DD 23:59:59')
    }
    setCurrentPeriod(month)
    setCurrentShortcut('')
  }

  const handleChangeYear = (values) => {
    const year = {
      start: moment(values.toDate()).startOf('year').format('YYYY-MM-DD 00:00:00'),
      end: moment(values.toDate()).endOf('year').format('YYYY-MM-DD 23:59:59')
    }
    setCurrentPeriod(year)
    setCurrentShortcut('')
  }

  const handleChangeFreePeriod = (values) => {
    if (values.length === 2) {
      const free = {
        start: moment(values[0].toDate()).format('YYYY-MM-DD 00:00:00'),
        end: moment(values[1].toDate()).format('YYYY-MM-DD 23:59:59')
      }
      setCurrentPeriod(free)
    }
  }

  const handleDropdownCalendar = (e) => {
    e.stopPropagation()
    setOpenCalendar(!openCalendar)
  }

  const handleClickValidate = () => {
    dispatch(changeActivePeriodUnit(currentPeriodUnit))
    switch (currentPeriodUnit) {
      case 'day':
        dispatch(changeActiveDay(currentPeriod))
        break;
      case 'week':
        dispatch(changeActiveWeek(currentPeriod))
        break;
      case 'month':
        dispatch(changeActiveMonth(currentPeriod))
        break;
      case 'year':
        dispatch(changeActiveYear(currentPeriod))
        break;
      case 'free':
        dispatch(changeActiveFreePeriod(currentPeriod))
        break;
      default:
        return null;
    }
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    setOpenCalendar(false)
  }

  const handleChangeDate = (direction) => {
    const unitMap = {
      day: 'day',
      week: 'week',
      month: 'month',
      year: 'year'
    };

    if (!unitMap[currentPeriodUnit]) return;

    const periodChange = {
      start: moment(currentPeriod.start).add(direction, unitMap[currentPeriodUnit]).startOf(unitMap[currentPeriodUnit]).format('YYYY-MM-DD 00:00:00'),
      end: moment(currentPeriod.end).add(direction, unitMap[currentPeriodUnit]).endOf(unitMap[currentPeriodUnit]).format('YYYY-MM-DD 23:59:59')
    };

    const actionMap = {
      day: changeActiveDay,
      week: changeActiveWeek,
      month: changeActiveMonth,
      year: changeActiveYear,
      free: changeActiveFreePeriod
    };

    dispatch(actionMap[currentPeriodUnit](periodChange));
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    setOpenCalendar(false);
  }

  const CalendarType = () => {
    switch (currentPeriodUnit) {
      case 'day':
        return <Calendar className={s.calendar} value={new DateObject(moment(currentPeriod.start).format('YYYY-MM-DD'))} onChange={handleChangeDay} onlyDayPicker maxDate={new DateObject(moment().toDate())} weekDays={DAYS_SHORT} months={MONTHS} weekStartDayIndex={1} showOtherDays/>  
      case 'week':
        return <Calendar className={s.calendar} value={[new DateObject(moment(currentPeriod.start).format('YYYY-MM-DD')), new DateObject(moment(currentPeriod.end).format('YYYY-MM-DD'))]} onChange={handleChangeWeek} range rangeHover maxDate={new DateObject(moment().toDate())} weekDays={DAYS_SHORT} months={MONTHS} weekStartDayIndex={1} showOtherDays/>
      case 'month':
        return <Calendar className={s.calendar} value={new DateObject(moment(currentPeriod.start).format('YYYY-MM-DD'))} onChange={handleChangeMonth} onlyMonthPicker maxDate={new DateObject(moment().toDate())} months={MONTHS}/>
      case 'year':
        return <Calendar className={s.calendar} value={new DateObject(moment(currentPeriod.start).format('YYYY-MM-DD'))} onChange={handleChangeYear} onlyYearPicker maxDate={new DateObject(moment().toDate())} months={MONTHS}/>
      case 'free':
        return <Calendar className={s.calendar} value={[ new DateObject(moment(currentPeriod.start).format('YYYY-MM-DD')), new DateObject(moment(currentPeriod.end).format('YYYY-MM-DD'))]} onChange={handleChangeFreePeriod} range maxDate={new DateObject(moment().toDate())} weekDays={DAYS_SHORT} months={MONTHS} weekStartDayIndex={1} showOtherDays/>
      default:
        return null;
    }
  }

  const currentDate = () => {
    switch (currentPeriodUnit) {
      case 'day':
        return (moment(currentPeriod.start).locale('fr').format('dddd DD MMM YYYY'))
      case 'week':
        return (moment(currentPeriod.start).locale('fr').format('DD/MM/YY') + ' au ' + moment(currentPeriod.end).locale('fr').format('DD/MM/YY'))
      case 'month':
        return (moment(currentPeriod.start).locale('fr').format('MMMM YYYY')[0].toUpperCase() + moment(currentPeriod.start).locale('fr').format('MMMM YYYY').slice(1))
      case 'year':
        return (moment(currentPeriod.start).format('YYYY'))
      case 'free':
        return (moment(currentPeriod.start).format('DD/MM/YYYY') + ' au ' + moment(currentPeriod.end).format('DD/MM/YYYY'))
      default:
        return moment().locale('fr').format('dddd DD MMM YYYY');
    }
  }

  return (
    <div className={s.root}>

        <div className={s.controller}>
          <div className={s.iconCalendar}>
            <span><Icon name={'date_range'} size={'2rem'} color={'#3192D3'} fill={0}/></span>
          </div>
          <div className={s.navPeriod}>
            <span><Icon name={'arrow_left'} size={'2rem'} color={'#3192D3'} fill={0} cursor onClick={() => handleChangeDate(-1)}/></span>
            <div className={s.currentPeriod} onClick={(e) => handleDropdownCalendar(e)}>{currentDate()}</div>
            <span><Icon name={'arrow_right'} size={'2rem'} color={isDateMax(period.start) ? '#ccc' : '#3192D3'} fill={0} cursor={!isDateMax(period.start)} onClick={() => !isDateMax(period.start) ? handleChangeDate(1) : null}/></span>
          </div>
        </div>

        {openCalendar && 
        <div className={s.modalCalendar} ref={modalCalendarRef}>
          <div className={s.containerCalendar}>
            
            <Button color="primary">
              <Icon name={'arrow_left'} size={'1.5rem'} color={'#FFFFFF'} fill={1} cursor onClick={() => handleDecPeriodUnit()}/>
              <span onClick={() => handleChangePeriodUnit(1)}>{periodLabel}</span>
              <Icon name={'arrow_right'} size={'1.5rem'} color={'#FFFFFF'} fill={1} cursor onClick={() => handleIncPeriodUnit()}/>
            </Button> 
            
            <div className={s.controlShortcut}>
              <Button color="primary" outline={currentShortcut !== 'day'} onClick={() => handleChangeShortcutPeriod('day')}>Aujourd'hui</Button>
              <Button color="primary" outline={currentShortcut !== 'week'} onClick={() => handleChangeShortcutPeriod('week')}>Cette semaine</Button>
              <Button color="primary" outline={currentShortcut !== 'month'} onClick={() => handleChangeShortcutPeriod('month')}>Ce mois-ci</Button>
              <Button color="primary" outline={currentShortcut !== 'year'} onClick={() => handleChangeShortcutPeriod('year')}>Cette année</Button>
            </div>

            {CalendarType()}

            <div className={s.confirmCalendar}>
              <Button color="primary" outline onClick={() => setOpenCalendar(false)}>annuler</Button>
              <Button color="primary" onClick={() => handleClickValidate()}>Valider</Button>
            </div>

          </div>
        </div>}

    </div>
  )
}

export default ControlPeriod