import { configureStore } from '@reduxjs/toolkit'
import resetMiddleware from './resetMiddleware'
import authReducer from '../features/auth/authSlice'
import userReducer from '../features/user/userSlice'
import navigationReducer from '../features/navigation/navigationSlice'
import buildingsReducer from '../features/buildings/buildingsSlice'
import devicesReducer from '../features/devices/devicesSlice'
import sgeReducer from '../features/sge/sgeSlice'
import alertsReducer from '../features/alert/alertsSlice'
import pairingReducer from '../features/pairing/pairingSlice'
import weatherReducer from '../features/weather/weatherSlice'
import reportsReducer from '../features/reports/reportsSlice'
import measuresReducer from '../features/measures/measuresSlice'
import indicatorsReducer from '../features/indicators/indicatorsSlice'
import tiersUserReducer from '../features/tiersUser/tiersUserSlices'

const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        navigation: navigationReducer,
        buildings: buildingsReducer,
        devices: devicesReducer,
        sge: sgeReducer,
        measures: measuresReducer,
        indicators: indicatorsReducer,
        alerts: alertsReducer,
        pairing: pairingReducer,
        reports: reportsReducer,
        weather: weatherReducer,
        tiersUser: tiersUserReducer,
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(resetMiddleware),
})

export default store

