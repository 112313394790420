import React, { useEffect, useMemo, useState } from 'react'
import s from './CardAirQuality.module.scss'
import { useDispatch } from 'react-redux';
import { useNavigate, useMatch } from 'react-router-dom';
import { useInterval } from '../../../../hooks/useInterval'
import Icon from '../../../Icon/Icon';
import { changeActiveBuilding, changeActiveRoute } from '../../../../features/navigation/navigationSlice';
import { saveNavigationState } from '../../../../features/navigation/navigationActions';
import store from '../../../../redux/store';
import { getLastSensorData } from '../../../../features/measures/measuresActions';

function CardAirQuality({ building, devices_ids }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const isMatchBuildings = useMatch('/buildings/*')

  const [data, setData] = useState(null);

  // Fonction pour récupérer les données du capteur
  const fetchSensorData = async () => {
    try {
      // dispatch retourne une promesse, on attend donc son résultat
      const result = await dispatch(
        getLastSensorData({
          building_id: building.building_id,
          devices_ids: devices_ids,
          measurement: ['co2']
        })
      );
      // Selon votre configuration, le résultat peut être dans result.payload
      setData(result.payload ? result.payload : result);
    } catch (error) {
      console.error('Erreur lors de la récupération des données', error);
    }
  };

  // Appel initial lors du montage
  useEffect(() => {
    if (isMatchBuildings) {
      fetchSensorData();
    }
    // Vous pouvez ajouter building.building_id et devices_ids aux dépendances si nécessaire
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [user_id, building.building_id, devices_ids, isMatchBuildings]);

  // Mise à jour périodique
  useInterval(() => {
    if (isMatchBuildings) {
      fetchSensorData();
    }
  }, isMatchBuildings ? 15000 : null);

  // Calculer la moyenne des mesures pour tous les devices
  const averageData = useMemo(() => {
    if (!data) return null;
    const values = Object.values(data);
    if (values.length === 0) return null;
    const sumCo2 = values.reduce((acc, cur) => acc + (cur.co2 || 0), 0);
    return {
      co2: sumCo2 / values.length,
    };
  }, [data]);

  const handleNavigateTo = () => {
    dispatch(changeActiveBuilding(building))
    dispatch(changeActiveRoute('/consult/air_quality'))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    navigate(`/consult/air_quality`)
  }

  return (
    <div className={s.root}>
      
      <header className={s.header}>
        <div className={s.title}>
          <Icon name="aq_indoor" size={'1.5rem'} color={'#30465E'} fill={1}/>
          <span>QUALITÉ D'AIR</span>
        </div>
        <Icon name="arrow_forward" size={'1.5rem'} color={'#30465E'} fill={1} onClick={() => handleNavigateTo()} cursor/> 
      </header>

      <div className={s.content}>
        <div className={s.item}>
          {averageData && averageData.co2 !== undefined ?
          (
          <div className={s.value}>{averageData.co2.toFixed(0)} ppm</div>
          ) : (
          <div className={s.not_value}>--- ppm</div>
          )}
          <div className={s.label}>
            <span>Taux de CO2</span>
            <small>0 - 1200 ppm</small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardAirQuality