import { RESET_STATE } from '../features/auth/authActions';
import { resetAuth } from '../features/auth/authSlice';
import { resetUser } from '../features/user/userSlice';
import { resetNavigation } from '../features/navigation/navigationSlice';
import { resetBuildings } from '../features/buildings/buildingsSlice';
import { resetDevices } from '../features/devices/devicesSlice';

const resetMiddleware = store => next => action => {
  if (action.type === RESET_STATE) {
    store.dispatch(resetAuth());
    store.dispatch(resetUser());
    store.dispatch(resetNavigation());
    store.dispatch(resetBuildings());
    store.dispatch(resetDevices());
    return;
  }
  return next(action);
};

export default resetMiddleware;