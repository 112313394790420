import { createSelector } from "@reduxjs/toolkit";

// Define expected structure for each indicatorType
const indicatorStructureMap = {
    peak_offpeak_ratio: { sum: {hc: 0, hp: 0, total: 0}, ratio: {hc: 0, hp: 0, total: 0} },
    get_building_ranking: { building_names: [], building_values: [] },
    consumption_trend: { previous_consumption: 0, current_consumption: 0, ratio: 0, is_ratio_positive: true },
    high_consumption_alert: { timestamps: [], values: [] },
    // Add other indicators as needed...
};

// Selector to get structured data for an indicatorType and peri        od
export const selectIndicatorType = (state, indicatorType, period) => {
    const periodKey = JSON.stringify(period);
    const indicatorData = state.indicators[indicatorType]?.[periodKey];
    return indicatorData || indicatorStructureMap[indicatorType] || {};
};

// Memorized Selector for optimization
export const makeSelectIndicatorType = () =>
    createSelector(
        (state, indicatorType, period) => selectIndicatorType(state, indicatorType, period),
        (indicatorData) => indicatorData
    );
    
// // Sélecteur pour obtenir un indicateur par ID
// const selectIndicatorById = (state, id) => state.indicators.listIndicators[id] || {};

// export const selectIndicatorData = (id) =>
//     createSelector(
//         (state) => selectIndicatorById(state, id),
//         (indicator) => ({
//         values: indicator.values,
//         types: indicator.types,
//         colors: indicator.colors,
//         }),
//     );

export const selectIsLoading = (state) => state.indicators.isLoading;
export const selectError = (state) => state.indicators.error;