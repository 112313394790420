import React, { useState } from 'react'
import s from './NavigationReport.module.scss'
import Icon from '../../Icon/Icon'

function NavigationReport({ setSortConfig, sortConfig }) {

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    if (sortConfig.key === key && sortConfig.direction === 'descending') {
      direction = 'none';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className={s.nav}>
      <div className={s.title}>Mes rapports</div>
      <div className={s.controlSort}>
        <div className={s.col} onClick={() => requestSort('building')}>
          Bâtiment
          {sortConfig.key === 'building' && sortConfig.direction !== 'none' &&  <Icon name={sortConfig.direction === 'descending' ? 'arrow_drop_up' : 'arrow_drop_down'} size={'1.5rem'} color={'#30465E'} fill={0}/>}  
        </div>
        <div className={s.col} onClick={() => requestSort('universe')}>
          Univers
          {sortConfig.key === 'universe' && sortConfig.direction !== 'none' && <Icon name={sortConfig.direction === 'descending' ? 'arrow_drop_up' : 'arrow_drop_down'} size={'1.5rem'} color={'#30465E'} fill={0}/>}      
        </div>
        <div className={s.col} onClick={() => requestSort('room')}>
          Espace
          {sortConfig.key === 'room' && sortConfig.direction !== 'none' &&  <Icon name={sortConfig.direction === 'descending' ? 'arrow_drop_up' : 'arrow_drop_down'} size={'1.5rem'} color={'#30465E'} fill={0}/>}  
        </div>
        <div className={s.col} onClick={() => requestSort('period')}>
          Période
          {sortConfig.key === 'period' && sortConfig.direction !== 'none' &&  <Icon name={sortConfig.direction === 'descending' ? 'arrow_drop_up' : 'arrow_drop_down'} size={'1.5rem'} color={'#30465E'} fill={0}/>} 
        </div>
        <div className={s.col} onClick={() => requestSort('created_at')}>
          Créé le
          {sortConfig.key === 'created_at' && sortConfig.direction !== 'none' &&  <Icon name={sortConfig.direction === 'descending' ? 'arrow_drop_up' : 'arrow_drop_down'} size={'1.5rem'} color={'#30465E'} fill={0}/>} 
        </div>
        <div className={s.col}>Action</div>   
      </div>    
    </div>
  )
}

export default NavigationReport
