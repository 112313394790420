import React, { useEffect } from 'react'
import s from './HeaderAnomalies.module.scss'
import ControlSelectBuilding from '../../Control/ControlSelectBuilding/ControlSelectBuilding'
import ControlPeriod from '../../Control/ControlPeriod/ControlPeriod'
import { getBuildings } from '../../../features/buildings/buildingsActions'
import { getDevices } from '../../../features/devices/devicesActions'
import { useDispatch } from 'react-redux'

function HeaderAnomalies() {

  const dispatch = useDispatch();

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  useEffect(() => { 
    dispatch(getBuildings({ user_id }))
    dispatch(getDevices({ user_id }))  
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [user_id])

  return (
    <div className={s.header}>
      <ControlSelectBuilding/>
      <ControlPeriod/>
    </div>
  )
}

export default HeaderAnomalies
