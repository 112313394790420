import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import s from './CardCompany.module.scss'

function CardCompany() {

  return (
    <div className={s.root}>
      
      <div className={s.header}>
        <div className={s.title}>Entreprise</div>
      </div>

      <div className={s.content}>
        <ul className={s.cardInfo}>
          <li><span>Nom :</span><span>INSENSIA</span></li>
          <li><span>N° SIRET :</span><span>15479538745811</span></li>
          <li><span>Groupe :</span><span>Informatique</span></li>
        </ul>
      </div>

    </div>
  )
}

export default CardCompany
