import React, { useState } from 'react'
import s from './Step02CardIdentityPro.module.scss'
import { Form, Button, FormGroup, InputGroup, InputGroupText, Input, Label } from 'reactstrap'

function Step02CardIdentityPro({ inc, dec, setAuthorizationData, authorizationData }) {

  const [companyName, setCompanyName] = useState('')
  const [companyLegalStatus, setCompanyLegalStatus] = useState('')
  const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState('')


  const validationForm = () => {
    if (authorizationData.identity.company_name === '' || authorizationData.identity.legal_status === '' || authorizationData.contact.registration_number === '') {
      return true
    } else {
      return false
    }
  }

  const changeCompanyName = (e) => {
    setCompanyName(e.target.value)
    setAuthorizationData(prevData => ({
      ...prevData, 
      identity: {
        ...prevData.identity,
        company_name: e.target.value
      }
    }))
  }

  const changeCompanyLegalStatus = (e) => {
    setCompanyLegalStatus(e.target.value)
    setAuthorizationData(prevData => ({
      ...prevData, 
      identity: {
        ...prevData.identity,
        legal_status: e.target.value
      }
    }))
  }

  const changeCompanyRegistrationNumber = (e)  => {
    setCompanyRegistrationNumber(e.target.value)
    setAuthorizationData(prevData => ({
      ...prevData, 
      identity: {
        ...prevData.identity,
        registration_number: e.target.value
      }
    }))
  }

  const decStep = () => {
    dec()
  }

  const incStep = () => {
    inc()
  }

  return (
    <div className={s.root}>

      <div className={s.content}>
        <div className={s.description}>
          Veuillez renseigner l'identité de l'entreprise figurant sur le contrat de fourniture d'électricité.
        </div>
       
        <Form className={s.form}>
          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Dénomination</Label>
            <Input className={s.input} type="text" onChange={changeCompanyName} placeholder={"Nom de votre entreprise"} value={companyName}/>
          </FormGroup>
          
          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Forme juridique</Label>
            <Input className={s.input} type="text" onChange={changeCompanyLegalStatus} placeholder={"Forme juridique de votre entreprise"} value={companyLegalStatus}/>
          </FormGroup>

          <FormGroup className={s.formGroup}>
            <Label className={s.label}>SIRET</Label>
            <Input className={s.input} type="text" onChange={changeCompanyRegistrationNumber} placeholder={"N° de votre établissement"} value={companyRegistrationNumber}/>
          </FormGroup>
        </Form>
      </div>

      <div className={s.control}>
        <Button color="primary" onClick={() => decStep()}>Précédent</Button> 
        <Button color="primary" onClick={() => incStep()} disabled={validationForm()}>Suivant</Button> 
      </div>
    </div>
  )
}

export default Step02CardIdentityPro
