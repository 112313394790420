import React, { useRef, useState, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import s from './Summary.module.scss';
import { selectActiveBuilding, selectActiveRoom, selectActiveSubRoute, selectPeriod, selectActivePeriodUnit } from '../../../../selectors/selectNavigation';
import { selectRooms } from '../../../../selectors/selectBuildings';
import Icon from '../../../../components/Icon/Icon';
import { Button } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import CardConsumption from '../../../../components/Indicators/Summary/CardConsumption/CardConsumption';
import CardComfort from '../../../../components/Indicators/Summary/CardComfort/CardComfort';
import CardAirQuality from '../../../../components/Indicators/Summary/CardAirQuality/CardAirQuality';
import useCaptureReport from '../../../../hooks/useCaptureReport';
import Popup from '../../../../components/Popup/Popup';
import moment from 'moment';
import CardWeather from '../../../../components/Indicators/Summary/CardWeather/CardWeather';
moment.locale('fr');

function Summary() {
  
  const dispatch = useDispatch();

  const cardStatSummaryRef = useRef(null);
  const cardRoomRef = useRef(null);

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user.user_id;

  const building = useSelector(selectActiveBuilding, shallowEqual);
  const building_id = building?.building_id || null;
  const rooms = useSelector(selectRooms(building_id), shallowEqual);
  const room = useSelector(selectActiveRoom, shallowEqual);
  const activeSubRoute = useSelector(selectActiveSubRoute);
  const periodUnit = useSelector(selectActivePeriodUnit);
  const period = useSelector(selectPeriod, shallowEqual);

  const [showModalCreateReport, setShowModalCreateReport] = useState(false);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  const currentPeriod = useCallback(() => {
    if (!period) return '';
    switch (periodUnit) {
      case 'day':
        return `du ${moment(period.start).format('dddd DD MMM YYYY')}`;
      case 'week':
        return `du ${moment(period.start).format('DD-MM-YYYY')} au ${moment(period.end).format('DD-MM-YYYY')}`;
      case 'month':
        return `du mois de ${moment(period.start)
          .format('MMMM YYYY')
          .replace(/(^|\s)\S/g, l => l.toUpperCase())}`;
      case 'year':
        return `du ${moment(period.start).format('DD-MM-YYYY')} au ${moment(period.end).format('DD-MM-YYYY')}`;
      case 'free':
        return `du ${moment(period.start).format('DD-MM-YYYY')} au ${moment(period.end).format('DD-MM-YYYY')}`;
      default:
        return '';
    }
  }, [period, periodUnit]);

  const reportId = uuid();
  const reportData = {
    report_id: reportId,
    user_id,
    building,
    rooms,
    room,
    universe: activeSubRoute,
    period: period,
    period_unit: periodUnit
  };

  const handleDiscard = useCallback(() => {
    setShowModalCreateReport(false);
    setIsGeneratingReport(false);
  }, []);

  const handleCaptureComplete = useCallback(() => {
    setIsGeneratingReport(false);
  }, []);

  const handleCreateReport = useCallback(() => {
    setIsGeneratingReport(true);
  }, []);

  useCaptureReport({
    isCapture: isGeneratingReport,
    refs: [cardStatSummaryRef, cardRoomRef],
    reportData,
    callbacks: { handleDiscard, handleCaptureComplete },
    dispatch,
  });

  const noSelectedBuilding = () => {
    return (
      <div className={s.noBuilding}>
        Vous n'avez pas sélectionné de bâtiment
      </div>
    )
  }

  return (
    <div className={s.root}>
      {building && (
        <div className={s.header}>
          <div className={s.title}>
            <span className={s.universe}>Synthèse</span>
            <span className={s.building}>{building?.name}</span>
            <span className={s.period}>{currentPeriod()}</span>
          </div>
          <div className={s.control}>
            <Button color="primary" onClick={() => setShowModalCreateReport(true)}>
              <Icon name={'post_add'} size={'1.5rem'} color={'#FFFFFF'} fill={1} />
              <span className={s.label}>Créer un rapport</span>
            </Button>
          </div>
        </div>
      )}

      <div className={s.content}>
                
        {building && 
        <div className={s.cardStatSummary} ref={cardStatSummaryRef}>
          <CardConsumption />
          <CardComfort />
          <CardAirQuality />
          <CardWeather />
        </div>}
        
        {building && 
        <div className={s.cardRoom} ref={cardRoomRef}>
          {/* Affichage des cartes spécifiques à la pièce */}
        </div>}

        {!building && noSelectedBuilding()}

      </div>

      <Popup title="Création d'un rapport" show={showModalCreateReport} onDiscard={() => setShowModalCreateReport(false)} onAccept={handleCreateReport}>
        Êtes-vous sûr de vouloir créer ce rapport ?
      </Popup>
    </div>
  );
}

export default Summary;
