import React, { useEffect } from 'react';
import s from './Step00SelectOptions.module.scss';
import Icon from '../../../../../../../../components/Icon/Icon';
import { Button } from 'reactstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { selectListDevices } from '../../../../../../../../selectors/selectDevices';
import CardDeviceModal from '../../../CardDeviceModal/CardDeviceModal';

function Step00SelectOptions({ inc, handleDevice }) {

  const deviceList = useSelector(selectListDevices, shallowEqual)
  const devices = deviceList.filter(device => device.building === null);
  
  return (
    <div className={s.root}>
      <div className={s.createDevice}>
        <div className={s.message}>
          Si l’équipement que vous souhaitez affecter n’est pas encore sur votre compte :
        </div>
        <Button className={s.btn} onClick={inc}>
          Ajouter un nouvel équipement à mon compte Insensia
          <Icon name={'arrow_outward'} size={'1.5rem'} color={'#fff'}/>
        </Button>
      </div>

      <div className={s.devices}>
        <div className={s.message}>
          Si l’équipement que vous souhaitez affecter est sur votre compte et n’est pas déjà affecté à un espace, sélectionnez le ici :
        </div>
        <div className={s.device}>
          {devices.map((device, index) => (
            <CardDeviceModal key={index} device={device} handleDevice={handleDevice} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Step00SelectOptions;
