import React from "react";
import s from './Automation.module.scss';
import { useMatch } from "react-router-dom";
import HeaderAutomation from "../../components/Headers/HeaderAutomation/HeaderAutomation";
import Rules from "./components/Rules/Rules";
import Scenarios from "./components/Scenarios/Scenarios";

function Automation () {

  const isMatchRules = useMatch('/automation/rules')
  const isMatchScenarios = useMatch('/automation/scenarios')

  return(
    <div className={s.root}> 
      <HeaderAutomation />
      <div className={s.content}>
        {isMatchRules && <Rules />}
        {isMatchScenarios && <Scenarios />}
      </div>
    </div>     
  )
}

export default Automation;

