import { defaultAxiosInstance } from '../../axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit'

// Async actions for connecting, disconnecting, and checking user status
export const getTiersUserStatus = createAsyncThunk(
  'tiers-users/getTiersUserStatus',
  async ({ user_id, data: { api } }) => {
  const response = await defaultAxiosInstance.get(`/tiers_users/${user_id}?api=${api}`);
  if (response.status !== 200)
    throw new Error('Failed to disconnect SmartThings user');
  const data = await response.data;
  return data;
});

export const connectTiersUser = createAsyncThunk(
  'tiers-users/connectTiersUser',
  async ({ user_id, data: { authCode, api } }) => {
    try {
      const response = await defaultAxiosInstance.post(`/tiers_users/${user_id}`, { authCode, api });
      if (response.status !== 200) {
        throw new Error('Failed to connect SmartThings user');
      }
      return true;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const disconnectTiersUser = createAsyncThunk(
  'tiers-users/disconnectTiersUser',
  async ({ user_id, data: { api } }) => {
    try {
      const response = await defaultAxiosInstance.delete(`/tiers_users/${user_id}`, {data: { api }});
      if (response.status !== 200) {
        throw new Error('Failed to disconnect SmartThings user');
      }
      return false;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'An error occurred');
    }
  }
);
