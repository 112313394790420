import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import s from './Reports.module.scss'
import { selectPdfUrl, selectReports, selectReportsIsLoading } from '../../selectors/selectReports';
import HeaderReports from '../../components/Headers/HeaderReports/HeaderReports';
import { deleteReport, getReportFile, getReports } from '../../features/reports/reportsActions';
import Popup from '../../components/Popup/Popup';
import Loader from '../../components/Loader/Loader';
import Icon from '../../components/Icon/Icon';
import moment from 'moment'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

moment.locale('fr')

function Reports() {

  const dispatch = useDispatch();

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id
  
  const isLoading = useSelector(selectReportsIsLoading)
  const reports = useSelector(selectReports, shallowEqual);
  const reportPdf = useSelector(selectPdfUrl)
  
  const [filter, setFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const [reportToDelete, setReportToDelete] = useState(null);
  const [showModalDeleteReport, setShowModalDeleteReport] = useState(false);

  const [isSelectedFile, setIsSelectedFile] = useState(false)
  const [isSelectedDownload, setIsSelectedDownload] = useState(false);
  const [fileToDelete , setFileToDelete] = useState(null)

  useEffect(() => {
    dispatch(getReports({ user_id }));  
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [user_id]);

  useEffect(() => {
    if (reportPdf && isSelectedFile === true ) {
      window.open(reportPdf, '_blank');
    }
    setIsSelectedFile(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPdf]);


  useEffect(() => {
    if (isSelectedDownload === true && reportPdf) {
      const downloadPdf = () => {
        const url = reportPdf;
        const link = document.createElement('a');
        link.href = url;
        link.download = 'report.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        setIsSelectedDownload(false);
      };
      downloadPdf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPdf]);

  const sortedReports = [...reports].sort((a, b) => {
    if (sortConfig.key === null || sortConfig.direction === 'none') return 0;
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
  
  const filteredReports = sortedReports.filter(report =>
    Object.values(report).some(value => 
      value.toString().toLowerCase().includes(filter.toLowerCase())
    )
  );

  const handleReportDelete = (report_id, filename) => {
    dispatch(deleteReport({ report_id, filename }))
      .unwrap()
      .then(() => {
        toast.warn('Rapport supprimé avec succès!');
        dispatch(getReports({ user_id }));
      })
      .catch((error) => {
        toast.error(`Erreur lors de la suppression : ${error.message || 'Une erreur est survenue.'}`);
        console.error('Erreur lors de la suppression du rapport :', error);
      });
    handleDiscard();
  }

  const handleDiscard = () => {
    setShowModalDeleteReport(false);
  };

  const handleDeleteConfirmation = () => {
    if (reportToDelete && fileToDelete) {
      handleReportDelete(reportToDelete, fileToDelete); 
    }
  };

  const toggleOpen = (report) => {
    const filename = `${report.report_id}.pdf`;
    setIsSelectedFile(true)
    dispatch(getReportFile({ filename }));
  };

  const toggleDownload = (report) => {
    const filename = `${report.report_id}.pdf`;
    setIsSelectedDownload(true)
    dispatch(getReportFile({ filename }));
  }

  const toggleDelete = (report) => {
    const report_id = report.report_id
    const filename = `${report.report_id}.pdf`;
    setShowModalDeleteReport(true)
    setReportToDelete(report_id)
    setFileToDelete(filename)
  }

  const periodConverter = (period_unit, period) => {
    switch (period_unit) {
      case "day":
        return <span className={s.period}>{moment(period[0]).locale('fr').format('DD/MM/YY')}</span>
      case "week":
        return <span className={s.period}>Du {moment(period[0]).format('DD/MM/YY')} au {moment(period[1]).format('DD/MM/YY')}</span>
      case "month":
        return <span className={s.period}>{moment(period[0]).locale('fr').format('MMMM YYYY').replace(/(^|\s)\S/g, l => l.toUpperCase())}</span>
      case "year":
        return <span className={s.period}>{moment(period[0]).format('YYYY')}</span>
      case "free":
        return <span className={s.period}>Du {moment(period[0]).format('DD-MM-YYYY')} au {moment(period[1]).format('DD-MM-YYYY')}</span>
      default:
        return null
    }
  }

  return (
    <div className={s.root}>

      <HeaderReports setSortConfig={setSortConfig} sortConfig={sortConfig}/>

      <div className={s.content}>

        {isLoading && <Loader/>}
        
        {!isLoading && filteredReports.length > 0 && filteredReports.map((report, index) => (
        <div key={report.report_id} className={`${s.report} ${index % 2 === 0 ? s.grayBackground : ''}`}>
          <div className={s.col}>{report.building}</div>
          <div className={s.col}>{report.universe}</div>
          <div className={s.col}>{report.room || '-'}</div>
          <div className={s.col}>{periodConverter(report.period_unit, report.period)}</div>
          <div className={s.col}>{moment(report.created_at).format('DD/MM/YYYY HH:mm')}</div>
          <div className={s.col}>
            <span className={s.btn_fileopen}><Icon name={'file_open'} size={'1.5rem'} color={'#30465E'} fill={0} onClick={(e) => toggleOpen(report)} cursor/></span>
            <span className={s.btn_download}><Icon name={'download'} size={'1.5rem'} color={'#30465E'} fill={0} onClick={(e) => toggleDownload(report)} cursor/></span>
            <span className={s.btn_delete}><Icon name={'delete'} size={'1.5rem'} color={'#FF3C3C'} fill={0} onClick={(e) => toggleDelete(report)} cursor/></span>
          </div>
        </div>
        ))}

        {!isLoading && reports?.length === 0 &&
        <div className={s.reports}>
          <div className={s.message}>Aucun rapport</div>
        </div> 
        }
      
      </div>

      <Popup title={"Suppression d'un rapport"} show={showModalDeleteReport} onDiscard={() => setShowModalDeleteReport(false)} onAccept={handleDeleteConfirmation}>
        Êtes-vous sûr de vouloir supprimer ce rapport ?
      </Popup>

    </div>
  )
}

export default Reports;
