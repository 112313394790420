import { createSlice } from "@reduxjs/toolkit"
import { createBuilding, getBuildings, updateBuilding, deleteBuilding } from './buildingsActions'
import { createRoom, updateRoom, deleteRoom } from '../rooms/roomsActions'

export const initialStateBuildings = {
  isLoading: false,
  listBuildings: [],
  countBuildings: 0,
  error: null
}

const buildingsSlice = createSlice({
  name: 'buildings',
  initialState: initialStateBuildings,
  reducers: {
    resetBuildings: () => initialStateBuildings,
  },
  extraReducers: builder => {
    builder
      //CREATE BUILDING
      .addCase(createBuilding.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createBuilding.fulfilled, (state) => {
        state.error = null
        state.isLoading = false
      })
      .addCase(createBuilding.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
      //GET BUILDINGS
      .addCase(getBuildings.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getBuildings.fulfilled, (state, { payload }) => {
        state.listBuildings = payload
        state.countBuildings = payload.length
        state.error = null
        state.isLoading = false
      })
      .addCase(getBuildings.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
        state.listBuildings = []
        state.countBuildings = 0
      })
      //UPDATE BUILDING
      .addCase(updateBuilding.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateBuilding.fulfilled, (state) => {
        state.error = null
        state.isLoading = false
      })
      .addCase(updateBuilding.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
      //DELETE BUILDING
      .addCase(deleteBuilding.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteBuilding.fulfilled, (state) => {
        state.error = null
        state.isLoading = false
      })
      .addCase(deleteBuilding.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
      //CREATE ROOM
      .addCase(createRoom.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createRoom.fulfilled, (state) => {
        state.error = null
        state.isLoading = false
      })
      .addCase(createRoom.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
      //UPDATE ROOM
      .addCase(updateRoom.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateRoom.fulfilled, (state) => {
        state.error = null
        state.isLoading = false
      })
      .addCase(updateRoom.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
      //DELETE ROOM
      .addCase(deleteRoom.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteRoom.fulfilled, (state) => {
        state.error = null
        state.isLoading = false
      })
      .addCase(deleteRoom.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
      .addDefaultCase((state, action) => {})
  }
})

export const { resetBuildings } = buildingsSlice.actions

export default buildingsSlice.reducer