import { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAlgoData } from '../features/measures/measuresActions';

export function useCardLogic(parameters) {

  const dispatch = useDispatch();

  const { algo, measurement, building_id, room_id, devices, start, end, periodUnit } = parameters;

  // const dataIndicator = useSelector(selectIndicatorData(algo, devices, room_id, start, end), shallowEqual);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (algo && measurement && building_id && devices?.length > 0 && start && end && periodUnit) {
      dispatch(getAlgoData({ parameters }));
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  return {
    isLoading,
    // dataIndicator
  };
};

export default useCardLogic;
