import React from 'react'
import s from './ModalDeleteRoom.module.scss'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectActiveRoom } from '../../../../../../selectors/selectNavigation'
import { deleteRoom } from '../../../../../../features/rooms/roomsActions'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Popup from '../../../../../../components/Popup/Popup'

function ModalDeleteRoom({ showModalDeleteRoom, handleClose }) {

  const dispatch = useDispatch()

  const room = useSelector(selectActiveRoom, shallowEqual)

  const handleDelete = () => {
    const room_id = room?.room_id
    dispatch(deleteRoom({ room_id }))
    toast.warn("Espace supprimé !")
    handleClose()
  }

  return (
    <Popup title={"Suppression d'un espace"} show={showModalDeleteRoom} onDiscard={handleClose} onAccept={handleDelete}>
      <div className={s.message}>Etes vous sûr de vouloir supprimer cette espace ?</div>
    </Popup>
  )
}

export default ModalDeleteRoom
