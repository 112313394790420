import React, { useEffect } from "react";
import s from './CardAirQuality.module.scss'
import Icon from "../../../Icon/Icon";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeActiveRoute } from "../../../../features/navigation/navigationSlice";
import { saveNavigationState } from "../../../../features/navigation/navigationActions";
import store from '../../../../redux/store';
import Loader from "../../../Loader/Loader";
import { selectActiveBuilding, selectActiveRoom, selectPeriod, selectActivePeriodUnit } from "../../../../selectors/selectNavigation";
import { selectDevicesByBuildingAndRoom } from "../../../../selectors/selectDevices";
import { selectMeasuresDataAirQuality, selectMeasuresIsLoading } from "../../../../selectors/selectMeasures";
import { getAlgoData } from "../../../../features/measures/measuresActions";

function CardAirQuality() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id || null

  const room = useSelector(selectActiveRoom, shallowEqual)
  const room_id = room?.room_id || null

  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual)

  const devices = useSelector(selectDevicesByBuildingAndRoom(building_id, room_id), shallowEqual)

  const airQualityData = useSelector(selectMeasuresDataAirQuality, shallowEqual)
  const isLoading = useSelector(selectMeasuresIsLoading)

  useEffect(() => {
    if (devices?.length > 0) {
      const parameters = {
        algo: "stat_summary",
        measurement: "co2",
        building_id,
        room_id,
        devices,
        periodUnit,
        period
      }
      dispatch(getAlgoData({ parameters }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building_id, room_id, devices, period]);

  const handleNavigateTo = () => {
    dispatch(changeActiveRoute('/consult/air_quality'))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    navigate(`/consult/air_quality`)
  }

  return (
    <div className={s.root}>
      
      <header className={s.header}>
        <div className={s.title}>
          <Icon name='aq_indoor' size={'1.5rem'} color={'#30465E'} fill={1}/> 
          <span>QUALITÉ D'AIR</span>
        </div>
        <Icon name="arrow_forward" size={'1.5rem'} color={'#30465E'} fill={1} onClick={() => handleNavigateTo()} cursor/> 
      </header>

      {isLoading && <Loader/>}

      {!isLoading && airQualityData &&
      <div className={s.content}>
        <div className={s.item}>
          <div className={s.value}>{airQualityData?.statCo2?.mean?.toFixed(0)} ppm</div>
        </div>
      </div>}

      {!isLoading && airQualityData &&
      <footer className={s.footer}>
        Taux de CO2 actuel moyen
      </footer>}

    </div>
  );
    
}

export default CardAirQuality;