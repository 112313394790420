import { createSlice } from "@reduxjs/toolkit"
import {
  getDevices,
  getDevicesByRoom,
  updateDevice,
  deleteDevice,
} from './devicesActions'

import {
  getTemperatureByDevices,
  getHumidityByDevices,
  getCO2ByDevices,
  getBrightnessByDevices,
  getMotionByDevices,
} from '../measures/measuresActions'

export const initialStateDevices = {
  isLoading: false,
  isLoadingTemperature: false,
  isLoadingHumidity: false,
  isLoadingCO2: false,
  isLoadingMotion: false,
  isLoadingBrightness: false,
  listDevices: [],
  listLastAggregate: [],
  listLastAlert: [],
  error: null
}

const devicesSlice = createSlice({
  name: 'devices',
  initialState: initialStateDevices,
  reducers: {
    resetDevices: () => initialStateDevices,
  },
  extraReducers: builder => {
    builder
      //GET DEVICES
      .addCase(getDevices.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDevices.fulfilled, (state, { payload }) => {
        state.listDevices = payload.data
        state.error = null
        state.isLoading = false
      })
      .addCase(getDevices.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload.data
        state.listDevices = []
        state.countDevices = 0
      })
      //GET DEVICES BY ROOM
      .addCase(getDevicesByRoom.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDevicesByRoom.fulfilled, (state, { payload }) => {
        state.listDevices = payload.data
        state.error = null
        state.isLoading = false
      })
      .addCase(getDevicesByRoom.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload.data
        state.listDevices = []
        state.countDevices = 0
      })
      //UPDATE DEVICE
      .addCase(updateDevice.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateDevice.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.error = null
      })
      .addCase(updateDevice.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
      //DELETE ROOM
      .addCase(deleteDevice.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteDevice.fulfilled, (state, { payload }) => {
        const index = state.listDevices.findIndex(device => device.device_id === payload.device_id)
        if (index !== -1) {
          state.listDevices.splice(index, 1)
        }
        state.error = null
        state.isLoading = false
      })
      .addCase(deleteDevice.rejected, (state, { payload }) => {
        state.isLoading = false
        state.error = payload
      })
      // GET TEMPERATURE BY DEVICES
      .addCase(getTemperatureByDevices.pending, (state) => {
        state.isLoadingTemperature = true;
      })
      .addCase(getTemperatureByDevices.fulfilled, (state, { payload }) => {
        Object.entries(payload.data).forEach(([device_id, measures]) => {
          const index = state.listDevices.findIndex(device => device.device_id === device_id);
          if (index !== -1) {
            state.listDevices[index] = {
              ...state.listDevices[index],
              ...(measures || {})
            }
          }
        })
        state.isLoadingTemperature = false;
        state.error = null;
      })
      .addCase(getTemperatureByDevices.rejected, (state, { payload }) => {
        state.isLoadingTemperature = false;
        state.error = payload;
      })
      // GET HUMIDITY BY DEVICES
      .addCase(getHumidityByDevices.pending, (state) => {
        state.isLoadingHumidity = true;
      })
      .addCase(getHumidityByDevices.fulfilled, (state, { payload }) => {
        Object.entries(payload.data).forEach(([device_id, measures]) => {
          const index = state.listDevices.findIndex(device => device.device_id === device_id);
          if (index !== -1) {
            state.listDevices[index] = {
              ...state.listDevices[index],
              ...(measures || {})
            }
          }
        })
        state.isLoadingHumidity = false;
        state.error = null;
      })
      .addCase(getHumidityByDevices.rejected, (state, { payload }) => {
        state.isLoadingHumidity = false;
        state.error = payload;
      })
      // GET CO2 BY DEVICES
      .addCase(getCO2ByDevices.pending, (state) => {
        state.isLoadingCO2 = true;
      })
      .addCase(getCO2ByDevices.fulfilled, (state, { payload }) => {
        Object.entries(payload.data).forEach(([device_id, measures]) => {
          const index = state.listDevices.findIndex(device => device.device_id === device_id);
          if (index !== -1) {
            state.listDevices[index] = {
              ...state.listDevices[index],
              ...(measures || {})
            }
          }
        })
        state.isLoadingCO2 = false;
        state.error = null;
      })
      .addCase(getCO2ByDevices.rejected, (state, { payload }) => {
        state.isLoadingCO2 = false;
        state.error = payload;
      })
      // GET BRIGHTNESS BY DEVICES
      .addCase(getBrightnessByDevices.pending, (state) => {
        state.isLoadingBrightness = true;
      })
      .addCase(getBrightnessByDevices.fulfilled, (state, { payload }) => {
        Object.entries(payload.data).forEach(([device_id, measures]) => {
          const index = state.listDevices.findIndex(device => device.device_id === device_id);
          if (index !== -1) {
            state.listDevices[index] = {
              ...state.listDevices[index],
              ...(measures || {})
            }
          }
        })
        state.isLoadingBrightness = false;
        state.error = null;
      })
      .addCase(getBrightnessByDevices.rejected, (state, { payload }) => {
        state.isLoadingBrightness = false;
        state.error = payload;
      })
      // GET MOTION BY DEVICES
      .addCase(getMotionByDevices.pending, (state) => {
        state.isLoadingMotion = true;
      })
      .addCase(getMotionByDevices.fulfilled, (state, { payload }) => {
        Object.entries(payload.data).forEach(([device_id, measures]) => {
          const index = state.listDevices.findIndex(device => device.device_id === device_id);
          if (index !== -1) {
            state.listDevices[index] = {
              ...state.listDevices[index],
              ...(measures || {})
            }
          }
        })
        state.isLoadingMotion = false;
        state.error = null;
      })
      .addCase(getMotionByDevices.rejected, (state, { payload }) => {
        state.isLoadingMotion = false;
        state.error = payload;
      })
      .addDefaultCase((state, action) => {})
  }
})

export const { resetDevices } = devicesSlice.actions

export default devicesSlice.reducer