import { createSlice } from "@reduxjs/toolkit"
import { getNavigationState, saveNavigationState } from './navigationActions'
import moment from 'moment'
moment.locale('fr')

export const initialStateNavigation = {
  isLoading: false,
  activeRoute: '/login',
  activeBuilding: null,
  activeRoom: null,
  activeBuildingDevice:null,
  activeRoomDevice: null,
  activeDevice: null,
  activeLinky: null,
  activeConsoUnit: '€',
  activePeriod: {
    periodUnit: 'day',
    day: {start: moment().subtract(1, 'day').format('YYYY-MM-DD 00:00:00'), end: moment().subtract(1, 'day').format('YYYY-MM-DD 23:59:59')},
    week: {start: moment().startOf('week').isoWeekday(1).format('YYYY-MM-DD 00:00:00'), end: moment().startOf('week').isoWeekday(7).format('YYYY-MM-DD 23:59:59')},
    month: {start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD 00:00:00'), end: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD 23:59:59')},
    year: {start: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD 00:00:00'), end: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD 23:59:59')},
  },
  error: null
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: initialStateNavigation,
  reducers: {
    changeActiveRoute: (state, action) => {
      let storageData = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))
      storageData.navigation = action.payload
      localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE, JSON.stringify(storageData))
      state.activeRoute = action.payload
    },
    changeActiveBuilding: (state, action) => {
      state.activeBuilding = action.payload
      let storageData = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))
      storageData.activeBuilding = action.payload
      localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE, JSON.stringify(storageData))
    },
    changeActiveBuildingDevice: (state, action) => {
      state.activeBuildingDevice = action.payload
      let storageData = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))
      storageData.activeBuildingDevice = action.payload
      localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE, JSON.stringify(storageData))
    },
    initActiveBuilding: (state) => {
      state.activeBuilding = initialStateNavigation.activeBuilding
      state.activeRoom = initialStateNavigation.activeRoom
    },
    initActiveBuildingDevice: (state) => {
      state.activeBuildingDevice = initialStateNavigation.activeBuildingDevice
      state.activeRoomDevice = initialStateNavigation.activeRoomDevice
    },
    changeActiveRoom: (state, action) => {
      state.activeRoom = action.payload
    },
    initActiveRoom: (state) => {
      state.activeRoom = initialStateNavigation.activeRoom
    },
    changeActiveRoomDevice: (state, action) => {
      state.activeRoomDevice = action.payload
    },
    initActiveRoomDevice: (state) => {
      state.activeRoomDevice =  initialStateNavigation.activeRoomDevice
    },
    changeActiveDevice: (state, action) => {
      state.activeDevice = action.payload
    },
    initActiveDevice: (state) => {
      state.activeDevice = initialStateNavigation.activeDevice
    },
    changeActiveLinky: (state, action) => {
      state.activeLinky = action.payload
    },
    initActiveLinky: (state) => {
      state.activeLinky = initialStateNavigation.activeLinky
    },
    changeActiveConsoUnit: (state, action) => {
      state.activeConsoUnit = action.payload
    },
    changeActivePeriodUnit: (state, action) => {
      state.activePeriod.periodUnit = action.payload
    },
    changeActiveDay: (state, { payload }) => {
      state.activePeriod.periodUnit = 'day'
      state.activePeriod.day = payload;
    },
    changeActiveWeek: (state, { payload }) => {
      state.activePeriod.periodUnit = 'week'
      state.activePeriod.week = payload;
    },
    changeActiveMonth: (state, { payload }) => {
      state.activePeriod.periodUnit = 'month'
      state.activePeriod.month = payload;
    },
    changeActiveYear: (state, { payload }) => {
      state.activePeriod.periodUnit = 'year'
      state.activePeriod.year = payload;
    },
    changeActiveFreePeriod: (state, { payload }) => {
      state.activePeriod.periodUnit = 'free'
      state.activePeriod.free = payload;
    },
    initNavigation: () => initialStateNavigation,
    resetNavigation: () => initialStateNavigation,
  },
  extraReducers: builder => {
    builder
    // GET NAVIGATION
    .addCase(getNavigationState.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getNavigationState.fulfilled, (state, { payload }) => {
      return { ...state, ...payload, isLoading: false };
    })
    .addCase(getNavigationState.rejected, (state, { payload }) => {
      return initialStateNavigation;
    })
    // SAVE NAVIGATION STATE
    .addCase(saveNavigationState.fulfilled, (state, { payload }) => {
      console.log('State saved successfully');
    })
    .addCase(saveNavigationState.rejected, (state, { payload }) => {
      console.error('Error saving state', payload);
    })
  }
})

export const {
  changeActiveRoute,
  changeActiveBuilding,
  initActiveBuilding,
  changeActiveBuildingDevice,
  initActiveBuildingDevice,
  changeActiveRoom,
  initActiveRoom,
  changeActiveRoomDevice,
  initActiveRoomDevice,
  changeActiveDevice,
  initActiveDevice,
  changeActiveLinky,
  initActiveLinky,
  changeActiveConsoUnit,
  changeActivePeriodUnit,
  changeActiveDay,
  changeActiveWeek,
  changeActiveMonth,
  changeActiveYear,
  changeActiveFreePeriod,
  initNavigation,
  resetNavigation,
} = navigationSlice.actions

export default navigationSlice.reducer


