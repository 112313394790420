import React from 'react'
import s from './ModalDeleteDevice.module.scss'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { selectActiveDevice } from '../../../../../../selectors/selectNavigation'
import { deleteDevice } from '../../../../../../features/devices/devicesActions'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Popup from '../../../../../../components/Popup/Popup'

function ModalDeleteDevice({ showModalDeleteDevice, handleClose }) {

  const dispatch = useDispatch()

  const device = useSelector(selectActiveDevice, shallowEqual)

  const handleDelete = () => {
    const device_id = device?.device_id
    dispatch(deleteDevice({ device_id }))
    toast.success("Le capteur a été supprimé !", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Slide,
    })
    handleClose()
  }

  return (
    <Popup title={"Suppression d'un appareil"} show={showModalDeleteDevice} onDiscard={handleClose} onAccept={handleDelete}>
      <div className={s.message}>Etes vous sûr de vouloir supprimer cet appareil ?</div>
    </Popup>
  )
}

export default ModalDeleteDevice
