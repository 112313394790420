// authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import {
  doInit,
  userLogin,
  userRegister,
  sendPasswordResetEmail,
  resetPassword,
  // checkEmail,
  // updatePassword,
} from './authActions'

export const initialStateAuth = {
  // isLoading: false,
  user: null,
  isAuthenticated: false,
  status: 'idle',
  // loadingInit: true,
  error: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialStateAuth,
  reducers: {
    userLogout: (state) => {
      localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE);
      state.user = null;
      state.isAuthenticated = false;
      state.status = 'idle';
      state.error = null;
    },
    resetAuth: () => initialStateAuth,
  },
  extraReducers: builder => {
    builder
      // REQUEST LOGIN 
      .addCase(userLogin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.user;
        state.isAuthenticated = action.payload.isAuthenticated;
        state.error = null;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      // REQUEST REGISTER
      .addCase(userRegister.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(userRegister.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(userRegister.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      // REQUEST DO INIT
      .addCase(doInit.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(doInit.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.user;
        state.isAuthenticated = action.payload.isAuthenticated;
        state.error = null;
      })    
      .addCase(doInit.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })
      
      
      // REQUEST RESET PASSWORD
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true
        state.errorMessage = ''
      })
      // REQUEST PASSWORD RESET EMAIL
      .addCase(sendPasswordResetEmail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendPasswordResetEmail.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(sendPasswordResetEmail.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      })

      // SUCCESS RESET PASSWORD
      .addCase(resetPassword.fulfilled, (state) => {
        state.isLoading = false
        state.errorMessage = ''
      })
    

  }
})

export const { userLogout, resetAuth } = authSlice.actions

export default authSlice.reducer
