import { defaultAxiosInstance } from '../../axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit'

export const createBuilding = createAsyncThunk(
  'buildings/create',
  async ({ user_id, data }, { rejectWithValue }) => {
    try {     
      await defaultAxiosInstance.post(`/buildings/`, { user_id, data })
    } catch (error) {
      if (error.response_file && (error.response_file.data || error.response_data) && error.response_data.data) {
        return rejectWithValue(error.response_data.data || error.response_file.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getBuildings = createAsyncThunk(
  'buildings/get',
  async ({ user_id }, { rejectWithValue }) => {
    try {
      const params = { user_id }
      const response = await defaultAxiosInstance.get(`/buildings/`, { params })
      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getBuildingById = createAsyncThunk(
  'buildings/get_by_id',
  async ({ building_id }, { rejectWithValue }) => {
    try {
      const params = { building_id }
      const response = await defaultAxiosInstance.get(`/buildings/${building_id}`, { params })
      return response.data || null
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateBuilding = createAsyncThunk(
  'buildings/update',
  async ({ user_id, data }, { rejectWithValue }) => {
    try { 
      const building_id = data?.building_id
      await defaultAxiosInstance.put(`/buildings/${building_id}`, { user_id, data })
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteBuilding = createAsyncThunk(
  'buildings/delete',
  async ({ building_id }, { rejectWithValue }) => {
    try {
      await defaultAxiosInstance.delete(`/buildings/${building_id}`)    
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

