import React, { useEffect, useState } from 'react'
import s from './NavigationConsult.module.scss'
import classnames from 'classnames/bind'
import { Button, FormGroup, Input } from "reactstrap"
import { useDispatch, useSelector } from 'react-redux'
import { changeActiveRoute } from '../../../features/navigation/navigationSlice'
import { selectActiveRoute } from '../../../selectors/selectNavigation'
import { saveNavigationState } from '../../../features/navigation/navigationActions'
import store from '../../../redux/store';
import Icon from '../../Icon/Icon'

const cx = classnames.bind(s)

function NavigationConsult() {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;
 
  const activeRoute = useSelector(selectActiveRoute);
  
  const [navigationIndex, setNavigationIndex] = useState(0)

  const [hoverIndex, setHoverIndex] = useState(null);
  
  const itemNav = [
    {icon_name: 'dashboard', label: 'Synthèse'},
    {icon_name: 'bolt', label: 'Consommations'},
    {icon_name: 'chair', label: 'Confort'},
    {icon_name: 'aq_indoor', label: 'Qualité d\'air'},
    {icon_name: 'sensor_occupied', label: 'Occupation'},
  ]
        
  const labelsEnglish = ['summary', 'consumption', 'comfort', "air_quality", 'occupancy'];

  useEffect(() => { 
    const extractedRoute = activeRoute.includes('/') ? activeRoute.split('/')[2] : '';
    const index = labelsEnglish.findIndex(label => label === extractedRoute)
    setNavigationIndex(index)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [activeRoute])

  const handleMouseEnter = (index) => setHoverIndex(index);
  const handleMouseLeave = () => setHoverIndex(null);

  const isBtnDisabled = (index) => navigationIndex === index
  
  const handleClickNavigation = (index) => {
    setNavigationIndex(index)
    switch (index) {
      case 0:
        dispatch(changeActiveRoute('/consult/summary'))
        break;
      case 1:
        dispatch(changeActiveRoute('/consult/consumption'))
        break;
      case 2:
        dispatch(changeActiveRoute('/consult/comfort'))
        break;
      case 3:
        dispatch(changeActiveRoute('/consult/air_quality'))
        break;
      case 4:
        dispatch(changeActiveRoute('/consult/occupancy'))
        break;
      default:
        break;
    }
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
  }

  return (
    <div className={s.nav}>
      <div className={s.nav_h}>
        {itemNav.map((item, index) => (
        <Button key={index} className={cx(s.btn, { [s.active]: navigationIndex === index, [s.disabled]: isBtnDisabled(index) })}
        onClick={() => handleClickNavigation(index)}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
        disabled={isBtnDisabled(index)}>
          <Icon name={item.icon_name} size={'1.5rem'} color={hoverIndex === index || navigationIndex === index ? '#3192D3' : '#30465E'} fill={1}/>
          <span className={s.item}>{item.label}</span>
        </Button>
        ))}
      </div>
      <FormGroup className={s.nav_v}>
        <Input className={s.input} name="select" type="select" value={navigationIndex} onChange={(e) => handleClickNavigation(parseInt(e.target.value))}>
          {itemNav.map((item, index) => (
          <option className={s.option} key={index} value={index}>{item.label}</option>
          ))}
        </Input>
      </FormGroup>
    </div>
  )
}

export default NavigationConsult
