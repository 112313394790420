import React, { useEffect, useState } from "react";
import s from './Step01SelectBuilding.module.scss';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectListBuildings } from "../../../../../selectors/selectBuildings";
import { selectActiveBuilding, selectActiveRoom, selectRooms } from "../../../../../selectors/selectNavigation";
import { changeActiveBuilding, changeActiveRoom, initActiveBuilding, initActiveRoom } from "../../../../../features/navigation/navigationSlice";
import { saveNavigationState } from "../../../../../features/navigation/navigationActions";
import store from "../../../../../redux/store";
import { Button, FormGroup, Input, InputGroup, ListGroup, ListGroupItem } from "reactstrap";
import Icon from "../../../../../components/Icon/Icon";

function Step01SelectBuilding( {inc}){

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const buildings = useSelector(selectListBuildings, shallowEqual);
  const rooms = useSelector(selectRooms, shallowEqual)

  const building = useSelector(selectActiveBuilding, shallowEqual);
  const room = useSelector(selectActiveRoom, shallowEqual);

  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownBuildingOpen, setIsDropdownBuildingOpen] = useState(false);
  const [isDropdownRoomOpen, setIsDropdownRoomOpen] = useState(false);

  useEffect(() => {
    if (building) {
      setSearchTerm(building.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building]);

  const handleSearchChange = (e) => {
    if (e.target.value === '') {
      setSearchTerm('');
      setIsDropdownBuildingOpen(false);
      setIsDropdownRoomOpen(false);
      handleClickBuildingAll();
      dispatch(initActiveBuilding());
      
    } else {
      setIsDropdownBuildingOpen(true);
      setSearchTerm(e.target.value);
    }
  };

  const filteredBuildings = buildings?.filter((building) => building?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()));

  const handleClickBuildingItem = (building) => {
    setSearchTerm(building.name);
    setIsDropdownBuildingOpen(false);
    dispatch(changeActiveBuilding(building));
    dispatch(initActiveRoom());
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
  };

  const handleClickBuildingAll = () => {
    setSearchTerm('');
    setIsDropdownBuildingOpen(false);
    dispatch(initActiveBuilding());
    dispatch(initActiveRoom());
  };

  const handleClickRoomItem = (room) => {
    dispatch(changeActiveRoom(room));
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    setIsDropdownRoomOpen(false)
  };

  const handleClickResetRoomItem = () => {
    setIsDropdownRoomOpen(false)
  };

  const handleDropdownListBuilding = (e) => {
    e.stopPropagation()
    setIsDropdownRoomOpen(false)
    setIsDropdownBuildingOpen(!isDropdownBuildingOpen)
  };

  const handleDropdownListRoom = (e) => {
    e.stopPropagation()
    setIsDropdownBuildingOpen(false)
    setIsDropdownRoomOpen(!isDropdownRoomOpen)
  };

    return (
    <div className={s.root}>
      <div className={s.description} >
        Pour créer votre règle, nous avons besoin de savoir où elle va se dérouler.
      </div> 
      <div className={s.selectBuilding}>
        <div className={s.title}>
            Dans quel bâtiment ?
        </div>
        <FormGroup className={s.formGroupBuilding}>
          <InputGroup className={s.inputGroupBuilding}>
              <Input className={`${s.input} ${searchTerm ? s.activeText : ''}`} type='search' placeholder={'Rechercher un bâtiment...'} value={searchTerm} onChange={handleSearchChange} onClick={(e) => handleDropdownListBuilding(e)}/>
          </InputGroup>

          {filteredBuildings.length > 0 && isDropdownBuildingOpen &&
          <ListGroup className={s.listGroup}>
          {(searchTerm && searchTerm !== building?.name ? filteredBuildings : buildings)?.map((building, index) => (
          <div className={s.item} key={index}>
              <Icon name={'circle'} size={'1.2rem'} color={'#2d8515'} fill={1} cursor/>
              <ListGroupItem className={s.name} onClick={() => handleClickBuildingItem(building)}>{building.name}</ListGroupItem> 
          </div>
          ))}
          </ListGroup>
          }
        </FormGroup>
      </div>
      <div className={s.selectRoom}>
        <div className={s.title}>
            Dans quelle pièce ? 
        </div>
        <FormGroup className={s.formGroupRoom}>
          <InputGroup className={s.inputGroupRoom} onClick={(e) => handleDropdownListRoom(e)}>   
              <Input className={s.input} name='room' value={room ? room?.name :  'Mes espaces'} readOnly />
          </InputGroup>

          {rooms?.length > 0 && isDropdownRoomOpen &&
          <ListGroup className={s.listGroup}>
            <div className={s.item}>
              <ListGroupItem className={s.name} onClick={() => handleClickResetRoomItem()}>Mes espaces</ListGroupItem>
            </div>
            {rooms?.map((room, index) => (
            <div className={s.item} key={index}>
              <ListGroupItem className={s.name} onClick={() => handleClickRoomItem(room)}>{room.name}</ListGroupItem>
            </div>))}
          </ListGroup>}
        </FormGroup>
      </div>
      <div className={s.btnSteps}>
        <Button className={`${searchTerm ? s.active : s.disable}`} disabled={!searchTerm} onClick={inc} >
          Valider
        </Button>
      </div>
    </div>
  )
}

export default Step01SelectBuilding