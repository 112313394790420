import React from 'react'
import { useDispatch } from 'react-redux';
import s from './CardRoom.module.scss'
import Icon from '../../../../../../components/Icon/Icon';
import { changeActiveRoom } from '../../../../../../features/navigation/navigationSlice';
import { saveNavigationState } from '../../../../../../features/navigation/navigationActions';
import store from '../../../../../../redux/store';
import moment from 'moment';
import 'moment/locale/fr';

function CardRoom({ room, handleShowModal }) {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const handleSetting = () => {
    handleShowModal('update');
    dispatch(changeActiveRoom(room))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
  }

  const handleDelete = () => {    
    handleShowModal('delete');
    dispatch(changeActiveRoom(room))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
  }

  return (
    <div className={s.root}>
      <div className={s.header}>
        <div className={s.title}>{room?.name}</div>
        <div className={s.control}>
          <div className={s.delete}>
            <Icon name={'delete'} size={'1.5rem'} color={'#FF3C3C'} fill={0} onClick={handleDelete} cursor/>
          </div>
          <div className={s.modify}>
            <Icon name={'edit'} size={'1.5rem'} color={'#3192D3'} fill={0} onClick={handleSetting} cursor/>
          </div>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.label}> 
          <Icon name="floor" size={'1.5rem'} color={'#3192D3'} cursor/>
          <span>Étage :</span><span>{room?.settings?.floor_number}</span>
        </div>
        <div className={s.label}> 
          <Icon name="footprint" size={'1.5rem'} color={'#3192D3'} cursor/>
          <span>{room?.settings?.surface} m²</span>
        </div>
        <div className={s.label}> 
          <Icon name="home_iot_device" size={'1.5rem'} color={'#3192D3'} cursor/>
          <span>{room?.devices?.length}</span><span>équipements</span>
        </div>
      </div> 
    </div>
  )
}

export default CardRoom
