import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import s from './NavigationSettings.module.scss';
import classnames from 'classnames/bind';
import { Button, Input } from 'reactstrap';
import Icon from '../../Icon/Icon';
import { changeActiveRoute, initActiveBuilding } from '../../../features/navigation/navigationSlice';
import { saveNavigationState } from '../../../features/navigation/navigationActions';
import store from '../../../redux/store';
import { selectActiveBuilding, selectActiveRoute } from '../../../selectors/selectNavigation';

const cx = classnames.bind(s);

function NavigationSettings() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const activeRoute = useSelector(selectActiveRoute)
 
  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id

  const [navigationIndex, setNavigationIndex] = useState(0)
  const [hoverIndex, setHoverIndex] = useState(null);

  const itemNav = [
    {icon_name: 'domain', label: building?.name || 'Bâtiment'},
    {icon_name: 'meeting_room', label: 'Espaces'},
    {icon_name: 'memory', label: 'Équipements'},
    {icon_name: 'linked_services', label: 'Services'},
    // {icon_name: 'settings_account_box', label: 'Préférences'},
  ]

  const labelsEN = [
    'building', 
    'rooms', 
    'devices', 
    'services', 
    // 'preferences'
  ]

  useEffect(() => { 
    const extractedRoute = activeRoute.includes('/') ? activeRoute.split('/')[2] : '';
    const index = labelsEN.findIndex(label => label === extractedRoute)
    setNavigationIndex(index)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [activeRoute])

  const handleMouseEnter = (index) => setHoverIndex(index);
  const handleMouseLeave = () => setHoverIndex(null);

  const isBtnDisabled = (index) => !building_id || navigationIndex === index;

  const handleClickNavigation = (index) => {
    setNavigationIndex(index)
    switch (index) {
      case 0:
        dispatch(changeActiveRoute(`/settings/building/:${building_id}`))
        break;
      case 1:
        dispatch(changeActiveRoute(`/settings/rooms/:${building_id}`))
        break;
      case 2:
        dispatch(changeActiveRoute(`/settings/devices/:${building_id}`))
        break;
      case 3:
        dispatch(changeActiveRoute(`/settings/services/:${building_id}`))
        break;
      case 4:
        dispatch(changeActiveRoute(`/settings/preferences/:${building_id}`))
        break;
      default:
        break;
    }
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
  }

  const handleReturn = () => {
    navigate('/buildings')
    dispatch(changeActiveRoute('/buildings'))
    dispatch(initActiveBuilding())
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
  }

  return (
    <div className={s.nav}>

      <div className={s.nav_h}>
        
        <Button className={s.btn} onClick={() => handleReturn()}>
          <Icon name="arrow_back" size={'1.5rem'} color={'#30465E'} fill={1}/>
        </Button>

        <div className={s.menu}>
          {itemNav.map((item, index) => (
          <Button key={index} className={cx(s.btn, { [s.active]: navigationIndex === index, [s.disabled]: isBtnDisabled(index)})}
          onClick={() => handleClickNavigation(index)}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          disabled={isBtnDisabled(index)}>
            <Icon name={item.icon_name} size={'1.5rem'} color={hoverIndex === index || navigationIndex === index ? '#3192D3' : '#30465E'} fill={1}/>
            <span className={s.item}>{item.label}</span>
          </Button>
          ))}
        </div>

      </div>
      <div className={s.nav_v}>
        <Input className={s.input} name="select" type="select" value={navigationIndex} onChange={(e) => handleClickNavigation(parseInt(e.target.value))}>
          {itemNav.map((item, index) => (
          <option className={s.option} key={index} value={index}>{item.label}</option>
          ))}
        </Input>
      </div>

    </div>
  );
}

export default NavigationSettings;