import React from 'react'
import s from './InConstruction.module.scss';
import Icon from '../Icon/Icon'

function InConstruction() {

  return (
    <div className={s.root}>

      <div className={s.header}>
        <div className={s.title}>Page en construction</div>
        <div className={s.description}>Revenez plus tard</div>
      </div>

      <div className={s.content}>
        <Icon name={'construction'} color={'#30465E'} size={'10rem'}/>
      </div>

    </div>
  )
}

export default InConstruction