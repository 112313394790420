import React, { useState } from 'react'
import s from './Rules.module.scss'
import { Button, FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap'
import Icon from '../../../../components/Icon/Icon'
import CardRule from '../CardRules/CardRule'
import ModalCreateRule from '../ModalCreateRule/ModalCreateRule';

function Rules() {
  
  const [searchQuery, setSearchQuery] = useState('');
  const [showModalCreateRule, setShowModalCreateRule] = useState(false)

  const mockDataSensorInfos = {
    sensorInfo1 : {
      name: "Sensor Samsung",
      type: "humidité relative",
      serial_number : "e8b58da0-89e7cuygugug",
      last_data: "il y a 20 minutes"
    },
    sensorInfo2 : {
      name: "Pompe à chaleur",
      type: "climatiseur",
      serial_number : "f67ac9-23e1g2h-9f97-858da04b9cuygugug",
      last_data: "il y a 20 minutes"
    },
    sensorInfo3 : {
      name: "CTA",
      type: "climatiseur",
      serial_number : "c6a49eb1-78f2h3j5k6l8-8068da0-4b9cuygugug",
      last_data: "il y a 20 minutes"
    },
    sensorInfo4 : {
      name: "Éclairage",
      type: "climatiseur",
      serial_number : "hfh79vd5-84fkz-9n5h-968bfk9gdh858fn87",
      last_data: "il y a 20 minutes"
    },
    sensorInfo5 : {
      name: "Pompe à chaleur",
      type: "climatiseur",
      serial_number : "e8b58da0-89e7-49da-9f97-806d92224b9c",
      last_data: "il y a 20 minutes"
    },
  }
  
  const mockDataScenario = {
    scenario1: {
      name : 'TEMPERATURE EN JOURNÉE',
      building: 'Insensia Dev',
      room: 'Salle de réunion',
      timeStart: '9h30',
      timeEnd: '19h',
      nbrEquipment: '3',
      unit: 'Temperature',
      comparator: 'au dessus de',
      value:'19°C',
    },
    scenario2: {
      name : 'QUALITÉ D\'AIR',
      building: 'Insensia Ingénierie',
      room: 'Bureau',
      timeStart: '19h30',
      timeEnd:'6h30',
      nbrEquipment:'1',
      unit:'Taux de CO2',
      comparator:'inférieur à',
      value:'1000 ppm',
    },
    scenario3: {
      name : 'ÉTEINDRE LA NUIT',
      building: 'Insensia Siège',
      room: 'Salle Direction',
      timeStart:'00h00',
      timeEnd:'8h30',
      nbrEquipment:'2',
      unit:'Lumière(s)',
      comparator:'est/sont',
      value:'éteinte(s)',
    },
    scenario4: {
      name : 'COUPER LES ÉQUIPEMENTS LA NUIT',
      building: 'Insensia Direction',
      room: 'Salle de repos',
      timeStart:'22h30',
      timeEnd:'4h15',
      nbrEquipment:'3',
      unit:'Prise(s)',
      comparator:'est/sont',
      value:'éteinte(s)',
    }
  }

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className={s.root}>
      <div className={s.control}>
        <div className={s.search}>
          <FormGroup className={s.formGroup}>
            <Input className={s.input} type='text' placeholder='Rechercher une règle' value={searchQuery} onChange={handleSearchChange}/>
            <InputGroupText className={s.inputGroupText}><Icon name="search" size={'1.4rem'} color={'#30465E'} fill={1}/></InputGroupText>
          </FormGroup>
        </div>
        <Button className={s.btn} onClick={() => setShowModalCreateRule(true)}>
          <Icon name="add" size={'1.5rem'} color={'#FFF'} fill={1}/>
          <span>Ajouter une règle</span>
        </Button>
      </div>
      <div className={s.content}>
        <CardRule scenario={mockDataScenario.scenario1} sensorInfo1={mockDataSensorInfos.sensorInfo1} sensorInfo2={mockDataSensorInfos.sensorInfo2} sensorInfo3={mockDataSensorInfos.sensorInfo3}/>
        <CardRule scenario={mockDataScenario.scenario2} sensorInfo1={mockDataSensorInfos.sensorInfo5}/>
        <CardRule scenario={mockDataScenario.scenario3} sensorInfo1={mockDataSensorInfos.sensorInfo2} sensorInfo2={mockDataSensorInfos.sensorInfo4}/>
        <CardRule scenario={mockDataScenario.scenario4} sensorInfo1={mockDataSensorInfos.sensorInfo4} sensorInfo2={mockDataSensorInfos.sensorInfo3} sensorInfo3={mockDataSensorInfos.sensorInfo1}/>
      </div>

      <ModalCreateRule showModalCreateRule={showModalCreateRule} setShowModalCreateRule={setShowModalCreateRule}/>  
    </div>
  )
}

export default Rules
