import React, { useEffect, useState } from 'react';
import s from './ModalDeleteDevice.module.scss';
import { Button} from 'reactstrap';
import Modal from '../../../../components/Modal/Modal';
import 'react-toastify/dist/ReactToastify.css';
import Icon from '../../../../components/Icon/Icon';
import CardDeviceSelected from '../CardDeviceSelected/CardDeviceSelected';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { selectActiveDevice } from '../../../../selectors/selectNavigation';
import { toast } from 'react-toastify';
import { deleteDevice } from '../../../../features/devices/devicesActions';

function ModalDeleteDevice({ showModalDeleteDevice, handleClose }) {

  const dispatch = useDispatch()

  const device = useSelector(selectActiveDevice, shallowEqual)
 
  const [deviceData, setDeviceData] = useState(device)

  useEffect(() => {
    setDeviceData(device)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [device])

  const handleDelete = () => {
    dispatch(deleteDevice({ device_id : deviceData?.device_id }))
    toast.warning("Equipement supprimé !")
    handleClose()
  }

  return (
    <Modal show={showModalDeleteDevice}>
      <div className={s.header}>
        <div className={s.title}>Supprimer un équipement</div>
        <Icon name='close' size={'1.5rem'} color={'#30465E'} fill={0} onClick={handleClose} cursor />
      </div>
      <div className={s.message}>
        Voulez vous supprimer cet équipement ? 
      </div>
      <div className={s.devices}>
        <CardDeviceSelected device={deviceData} />
      </div>

      <div className={s.control}>
        <Button color='primary' onClick={handleDelete}>Valider</Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteDevice;
