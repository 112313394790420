import React from 'react';

function CloudyIcon() {
  return(
    <svg width='116' height='100' viewBox='0 0 116 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_3577_1715)'>
        <mask id='mask0_3577_1715' maskUnits='userSpaceOnUse' x='8' y='0' width='100' height='100'>
          <rect x='8' width='100' height='100' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_3577_1715)'>
          <path fillRule='evenodd' clipRule='evenodd' d='M28.0522 70C22.521 70 17.7962 68.0565 13.8777 64.1695C9.95924 60.2825 8 55.5953 8 50.1079C8 44.9551 9.80118 40.4705 13.4036 36.6541C17.0066 32.8377 21.5764 30.6998 27.1131 30.2403C29.1445 26.4395 31.8776 23.4494 35.3123 21.27C38.7464 19.09 42.6423 18 47 18C52.9102 18 57.9036 19.9384 61.9801 23.8152C66.0566 27.6914 68.4421 32.514 69.1366 38.283C74.5419 38.5022 78.7039 40.1743 81.6228 43.2991C84.5409 46.4239 86 50.0509 86 54.1802C86 58.566 84.4513 62.299 81.3539 65.379C78.2565 68.4597 74.4895 70 70.0531 70H28.0522Z' fill='url(#paint0_radial_3577_1715)'/>
          <path fillRule='evenodd' clipRule='evenodd' d='M87.9479 83C93.479 83 98.2038 81.0565 102.122 77.1695C106.041 73.2825 108 68.5953 108 63.1079C108 57.9551 106.199 53.4705 102.596 49.6541C98.9934 45.8377 94.4236 43.6998 88.8869 43.2403C86.8555 39.4395 84.1224 36.4494 80.6877 34.27C77.2537 32.09 73.3578 31 69 31C63.0898 31 58.0964 32.9384 54.0199 36.8152C49.9434 40.6914 47.5579 45.514 46.8634 51.283C41.4581 51.5022 37.2961 53.1743 34.3772 56.2991C31.4591 59.4239 30 63.0509 30 67.1802C30 71.566 31.5487 75.299 34.6461 78.379C37.7435 81.4597 41.5105 83 45.9469 83H87.9479Z' fill='url(#paint1_radial_3577_1715)'/>
        </g>
      </g>
      <defs>
        <filter id='filter0_d_3577_1715' x='0.2' y='-3.8' width='115.6' height='115.6' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/>
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='3.9' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.266667 0 0 0 0 0.266667 0 0 0 0 0.266667 0 0 0 0.2 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3577_1715' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3577_1715' result='shape' />
        </filter>
        <radialGradient id='paint0_radial_3577_1715' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(75.162 74.6862) rotate(-91.2623) scale(89.449 228.752)'>
          <stop stopColor='#3192D3' />
          <stop offset='0.71' stopColor='#F9F9F9' />
        </radialGradient>
        <radialGradient id='paint1_radial_3577_1715' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(68.588 118.473) rotate(-88.7377) scale(89.449 228.752)'>
          <stop stopColor='#3192D3' />
          <stop offset='0.71' stopColor='#F9F9F9' />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default CloudyIcon;
