import { createSlice } from "@reduxjs/toolkit"
import {
  searchPoints,
  getLoadCurveData
} from './sgeActions'

const initialState = { 
  isLoading: false,
  searchPoints: [],
  dataByHour: [],
  dataByDay: [],
  dataByMonth: [],
  dataByYear: [],
  error: null
}

const sgeSlice = createSlice({
  name: 'sge',
  initialState,
  reducers: {
    init_sge: (state) => {
      state.isLoading = false
      state.searchPoints = []
      state.dataByHour = []
      state.dataByDay = []
      state.dataByMonth = []
      state.dataByYear = []
      state.error = null
    },
    initSearchPoints: (state) => {
      state.isLoading = false
      state.searchPoints = []
      state.error = null
    }
  },
  extraReducers: builder => {
    builder
      //SEARCH POINTS
      .addCase(searchPoints.pending, (state) => {
        state.isLoading = true
      })
      .addCase(searchPoints.fulfilled, (state, { payload }) => {
        state.searchPoints = payload
        state.isLoading = false
      })
      .addCase(searchPoints.rejected, (state, { payload }) => {
        state.error = payload
        state.isLoading = false
      })
      //GET LOAD CURVE
      .addCase(getLoadCurveData.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(getLoadCurveData.fulfilled, (state, { payload }) => {
        const periodUnit = payload.periodUnit
        let index;
        switch (periodUnit) {
          case 'day':
            index = state.dataByHour.findIndex(data => data.sge_authorization_id === payload.sge_authorization_id)
            if (index === -1) {
              state.dataByHour.push(payload)
              state.error = null
              state.isLoading = false
            } else {
              state.dataByHour[index] = payload
              state.error = null
              state.isLoading = false
            }
            break;
          case 'week':
            index = state.dataByDay.findIndex(data => data.sge_authorization_id === payload.sge_authorization_id)
            if (index === -1) {
              state.dataByDay.push(payload)
              state.error = null
              state.isLoading = false
            } else {
              state.dataByDay[index] = payload
              state.error = null
              state.isLoading = false
            }
            break;
          case 'month':
            index = state.dataByMonth.findIndex(data => data.sge_authorization_id === payload.sge_authorization_id)
            if (index === -1) {
              state.dataByMonth.push(payload)
              state.error = null
              state.isLoading = false
            } else {
              state.dataByMonth[index] = payload
              state.error = null
              state.isLoading = false
            }
            break;
          case 'year':
            index = state.dataByYear.findIndex(data => data.sge_authorization_id === payload.sge_authorization_id)
            if (index === -1) {
              state.dataByYear.push(payload)
              state.error = null
              state.isLoading = false
            } else {
              state.dataByYear[index] = payload
              state.error = null
              state.isLoading = false
            }
            break;
          default:
            break;
        }
      })
      .addCase(getLoadCurveData.rejected, (state, { payload }) => {
        state.error = payload
        state.isLoading = false
      })
  }
})

export const { init_sge, initSearchPoints } = sgeSlice.actions

export default sgeSlice.reducer