import React, { useEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'
import s from './BuildingSettings.module.scss'
import HeaderSettings from '../../components/Headers/HeaderSettings/HeaderSettings'
import Building from './components/Building/Building'
import Rooms from './components/Rooms/Rooms'
import Devices from './components/Devices/Devices'
import Services from './components/Services/Services'
import Preferences from './components/Preferences/Preferences'
import { getBuildings } from '../../features/buildings/buildingsActions'
import { getDevices } from '../../features/devices/devicesActions'
import { useDispatch } from 'react-redux'

function BuildingSettings() {
  
  const dispatch = useDispatch()
  const matchSettings = useMatch('/settings/*')
  const isMatchBuilding = useMatch('/settings/building/*')
  const isMatchRooms = useMatch('/settings/rooms/*')
  const isMatchDevices = useMatch('/settings/devices/*')
  const isMatchServices = useMatch('/settings/services/*')
  const isMatchPreferences = useMatch('/settings/preferences/*')

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id

  const [update, setUpdate] = useState(false)

  useEffect(() => { 
    dispatch(getBuildings({ user_id }))
    dispatch(getDevices({ user_id }))  
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [user_id, update])


  const handleUpdateList = () => {
    setUpdate(!update)
  }

  return (
    <div className={s.root}>
      <HeaderSettings/>
      <div className={s.content}>
        {isMatchBuilding && <Building handleUpdateList={handleUpdateList}/>}
        {isMatchRooms && <Rooms/>}
        {isMatchDevices && <Devices/>}
        {isMatchServices && <Services/>}
        {isMatchPreferences && <Preferences/>}
      </div>
    </div>
  )
}
export default BuildingSettings