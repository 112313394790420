import React, { useState } from 'react';
import s from './Step04ConfirmRule.module.scss';
import { Button, FormGroup, Input, InputGroup, Label } from 'reactstrap';

function Step04ConfirmScenario({ handleAdd, dec }) {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheck = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className={s.root}>
      <div className={s.selectBuilding}>
        <div className={s.inputTitle}>Quel nom donner à votre règle ?</div>
        <FormGroup className={s.formGroupBuilding}>
          <InputGroup className={s.inputGroupBuilding}>
            <Input className={s.input} type='text' />
          </InputGroup>
        </FormGroup>
      </div>

      <FormGroup className={s.checkgroup}>
        <Input className={s.checkBox} type='checkbox' onChange={handleCheck} checked={isChecked} />
        <Label check className={s.label}>
          Je souhaite être notifié lors du dépassement du seuil
        </Label>
      </FormGroup>
      <div className={s.btnSteps}>
        <Button className={s.btn} onClick={dec}>
          Précédent
        </Button>
        <Button className={s.btnActive} onClick={handleAdd}>
          Valider le règle
        </Button>
      </div>
    </div>
  );
}

export default Step04ConfirmScenario;
