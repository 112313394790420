import React, { useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import s from './CardChartHumidity.module.scss'
import Loader from '../Loader/Loader'
import ExportCSV from '../ExportCsv/ExportCsv'
import { selectActiveBuilding, selectActivePeriodUnit, selectPeriod, selectActiveRoom } from '../../selectors/selectNavigation'
import moment from 'moment';
import { getHumidityByDevices } from '../../features/measures/measuresActions'
import { selectDevicesByBuildingAndRoom, selectHumidityIsLoading, selectMeasuresForChart } from '../../selectors/selectDevices'
import ChartHumidity from '../Chart/ChartHumidity/ChartHumidity';

moment.locale('fr');

function CardChartHumidity() {

  const dispatch = useDispatch()

  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id

  const room = useSelector(selectActiveRoom, shallowEqual)
  const room_id = room?.room_id

  const devices = useSelector(selectDevicesByBuildingAndRoom(building_id, room_id), shallowEqual)

  const isLoading = useSelector(selectHumidityIsLoading, shallowEqual)

  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual)

  const dataSets = useSelector(selectMeasuresForChart(devices, 'humidity'), shallowEqual);

  useEffect(() => {
    if (building_id !== '' && devices?.length > 0) {
      dispatch(getHumidityByDevices({ measurement: 'humidity', building_id, room_id, devices, periodUnit, period }));  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building, periodUnit, period])

  return (
    <div className={s.root}>

      <div className={s.header}>
        <div className={s.title}>Humidité relative en % <ExportCSV chartData={dataSets} /></div>
      </div>

      <div className={s.content}>

        {/* {isLoading && <Loader/>} */}

        {dataSets && dataSets.datasets.length > 0 && building &&
        <ChartHumidity dataSets={dataSets} periodUnit={periodUnit}/>
        }

        {!isLoading && dataSets && dataSets.datasets.length === 0 && building &&
          <div className={s.noMeasure}>Aucune mesure disponible pour la période sélectionnée</div>
        }

      </div>
    </div>
  )
}

export default CardChartHumidity
