import React from 'react'
import ApexChart from 'react-apexcharts'
import s from './ChartTimeline.module.scss'
// import { COLORS_CHART } from '../../../../../../../../../utils/config/chart_colors'
import moment from 'moment'
moment.locale('fr')
var fr = require("apexcharts/dist/locales/fr.json")

function ChartTimeline({ dataSets, period}) {

  const min = new Date(period.start).getTime()
  const max = new Date(period.end).getTime()

  var options = {
    series: dataSets,
    chart: {
    type: 'rangeBar',
    toolbar: {
      show: true,
    },
    locales: [fr],
    defaultLocale: 'fr',
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '50%'
    }
  },
  xaxis: {
      type: 'datetime',
      min: min,
      max: max
    },
  stroke: {
    width: 1
  },
  fill: {
    type: 'solid',
    opacity: 0.6
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'left'
  }
  };

  return (
    <ApexChart type="rangeBar" height="100%" options={options} series={options.series} className={s.chart}/>
  )
}

export default ChartTimeline