import React from 'react'
import s from './Preferences.module.scss'

function Preferences() {

  return (
    <div className={s.root}>
      <div className={s.content}>
      </div>
    </div>
  )
}

export default Preferences