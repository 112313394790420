import React, { useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import s from './Reset.module.scss'
import { selectIsLoading, selectErrorMessage } from "../../../selectors/selectUser"
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../../assets/logo/LogoInsensiaRow'
import PasswordChecklist from "react-password-checklist";
import PasswordStrengthBar from 'react-password-strength-bar';
import { Alert, Button, FormGroup, InputGroup, InputGroupText, Input, Form, Label } from 'reactstrap'
import { resetPassword } from '../../../features/auth/authActions'
import Copyright from '../../../components/Copyright/Copyright'
import Icon from '../../../components/Icon/Icon'

function Reset() {

  const dispatch = useDispatch()
  const location = useLocation()

  const isLoading = useSelector(selectIsLoading)
  const errorMessage = useSelector(selectErrorMessage, shallowEqual)

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const changePassword = (event) => {
    setPassword(event.target.value);
  };

  const changeConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };


  const doReset = (e) => {
    e.preventDefault()
    const params = new URLSearchParams(location.search)
    const token = params.get('token')
    if (!token) {
      const msg = 'There are no token'
      // dispatch(authError({ msg }))
    }

    dispatch(resetPassword({ token, password }))
    
  }

  return (
    <div className={s.root}>
      <div className={s.logo}><Logo className={s.img}/></div>
      <div className={s.card}>
        
        <div className={s.content}>
          
          <header>Réinitialisation du mot de passe</header>
          
          <Form className={s.form}>
            <FormGroup className={s.formGroup}>
              <Label>Mot de passe</Label>
              <InputGroup>
                <Input id="password" value={password} onChange={changePassword} type={showPassword ? "text" : "password"} required name="password" placeholder="Mot de passe" />
                <InputGroupText>
                  <Icon name={showPassword ? "visibility_off" : "visibility"} size={'1.5rem'} color={'#3192D3'} fill={1} cursor onClick={togglePasswordVisibility} />
                </InputGroupText>
              </InputGroup>
            </FormGroup>

            <FormGroup className={s.formGroup}>
              <Label>Confirmez votre mot de passe</Label>
              <InputGroup>
                <Input id="confirm_password" value={confirmPassword} onChange={changeConfirmPassword} type={showConfirmPassword ? "text" : "password"} required name="confirmPassword" placeholder="Confirmez Mot de passe" />
                <InputGroupText>
                  <Icon name={showConfirmPassword ? "visibility_off" : "visibility"} size={'1.5rem'} color={'#3192D3'} fill={1} cursor onClick={toggleConfirmPasswordVisibility} />
                </InputGroupText>
              </InputGroup>
            </FormGroup>

            <div className={s.password_checklist}>Votre mot de passe doit avoir :</div>
            
            <PasswordChecklist
              className={"password_checklist"}
              rules={["minLength", "maxLength", "specialChar", "number", "capitalAndLowercase", "match"]}
              minLength={12}
              maxLength={50}
              value={password}
              valueAgain={confirmPassword}
              messages={{
                minLength: "12 caractères minimum.",
                maxLength: "50 caractères maximum.",
                specialChar: "au moins un caractère spécial.",
                number: "contenir au moins un chiffre.",
                capitalAndLowercase: "au moins une lettre minuscule et une lettre majuscule.",
                match: "Les mots de passe doivent correspondre."
              }}
              onChange={(isValid) => setIsPasswordValid(isValid)}
              hideIcon={true}
            />

            <PasswordStrengthBar
              className={s.passwordStrengthBar}
              password={password}
              scoreWords={[
                'Force du mot de passe : faible',
                'Force du mot de passe : faible',
                'Force du mot de passe : moyen',
                'Force du mot de passe : bon',
                'Force du mot de passe : fort'
              ]}
              minLength={12}
              shortScoreWord='Trop court'
            />
            <div className={s.control}>
              <Button color="primary" onClick={doReset} disabled={!isPasswordValid}>Valider</Button>   
            </div>
          </Form>

        </div>
        <Copyright/>
      </div>
      
    </div>
  )
}
  
export default Reset

