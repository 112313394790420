import React, { useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from "react-redux"
import s from './CardChartMotion.module.scss'
import Loader from '../Loader/Loader'
import ExportMotionCSV from '../ExportCsv/ExportMotionCsv'
import { selectActiveBuilding, selectActivePeriodUnit, selectPeriod } from '../../selectors/selectNavigation'
import moment from 'moment';
import { getMotionByDevices } from '../../features/measures/measuresActions'
import { selectMeasuresForTimeline, selectMotionIsLoading } from '../../selectors/selectDevices'
import ChartTimeline from '../Chart/ChartTimeline/ChartTimeline'

moment.locale('fr');

function CardChartMotion({ building_id, room_id, devices }) {

  const dispatch = useDispatch()

  const building = useSelector(selectActiveBuilding, shallowEqual)

  const isLoadingMotion = useSelector(selectMotionIsLoading, shallowEqual)

  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual)

  const dataSetsMotionTimeLine = useSelector(selectMeasuresForTimeline(devices, 'motion'), shallowEqual);

  useEffect(() => {
    if (building_id !== '' && devices?.length > 0) {
      dispatch(getMotionByDevices({ measurement: 'motion', building_id, room_id, devices, periodUnit, period }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building_id, devices, periodUnit, period])

  return (
      <div className={s.root}>

      {building && 
      <div className={s.graphic}>
        <div className={s.header}>
          <div className={s.title}>Occupation <ExportMotionCSV chartData={dataSetsMotionTimeLine} /></div>
        </div>
        <div className={s.content}>
          {isLoadingMotion && <Loader/>}
          {!isLoadingMotion && dataSetsMotionTimeLine && dataSetsMotionTimeLine[0].data?.length > 0 && <ChartTimeline dataSets={dataSetsMotionTimeLine} period={period}/>}
          {!isLoadingMotion && dataSetsMotionTimeLine && dataSetsMotionTimeLine[0].data?.length === 0 && building &&
          <div className={s.noData}>Aucune mesure disponible pour la période sélectionnée</div>
          }
        </div>
      </div>
      }



    </div>
  )
}

export default CardChartMotion
