import React from 'react'
import s from './HeaderReports.module.scss'
import NavigationReport from '../../Navigation/NavigationReport/NavigationReport'

function HeaderReports({ setSortConfig,  sortConfig }) {

  return (
    <div className={s.header}>
      <NavigationReport setSortConfig={setSortConfig} sortConfig={sortConfig}/>
    </div>
  )
}

export default HeaderReports