import React, { useEffect, useState } from 'react';
import s from './ModifyPricingForm.module.scss';
import { Button, Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import Icon from '../../../../../../components/Icon/Icon';

function ModifyPricingForm({ setIsEditing, pricing, contract }) {

  const [isBaseContract, setIsBaseContract] = useState(false)
  const [isHpHcContract, setIsHpHcContract] = useState(false)

  const subscriptionPricing = isBaseContract? pricing.pricing?.subscription_base : pricing.pricing?.subscription_hphc || '---';

  const lowPricing = pricing.pricing?.kwh_hc || '---';
  const highPricing = pricing.pricing?.kwh_hp || '---';
  const basePricing = pricing.pricing?.kwh_base || '---';
  const effectiveAt = pricing?.effective_at || '---';
  const energyProvider = pricing?.energy_provider || '---';
  const contractOffer = pricing?.contract_offer || '---';
  const contractType = contract

  const [formData, setFormData] = useState({
      energyProvider: energyProvider || '',
      contractOffer: contractOffer || '',
      contractType: contractType || '',
      subscriptionPricing: subscriptionPricing || '',
      basePricing: basePricing || '',
      lowPricing: lowPricing || '',
      highPricing: highPricing || ''
    });

  const handleCancel = () => {
    setIsEditing(false)
  }

  const handleSave = () => {
    setIsEditing(false)
  }

  const handleContract  = (contract) => {
    switch(contract) {
      case 'base':
        setIsBaseContract(true)
        setIsHpHcContract(false)
        break;
      case 'Heures Pleines/Creuses':
        setIsBaseContract(false)
        setIsHpHcContract(true)
        break;
    }
    setFormData({ ...formData, [contractType]: contract})
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    handleContract(contractType);
  }, [contractType]);

  return (
    <div className={s.root}>
      <div className={s.content}>
        <Form className={s.form}>
          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Date de début d'application de ce tarif</Label>
            <Input className={s.input} type='date' value={effectiveAt}/>
          </FormGroup>
  
          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Fournisseur</Label>
            <Input className={s.input} type='text' value={energyProvider}/>
          </FormGroup>
  
          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Offre de contrat</Label>
            <Input className={s.input} type='text' value={contractOffer} />
          </FormGroup>
  
          <FormGroup className={s.checkBox}>
            <FormGroup check>
              <Input className={s.checkbox} type='radio' name='radio1' value={1} checked={isBaseContract} onChange={() => handleContract('base')}/>{' '}
              <Label check className={s.labelRadio}>
                Base
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input className={s.checkbox} type='radio' name='radio1' value={2} checked={isHpHcContract} onChange={() => handleContract('Heures Pleines/Creuses')} />{' '}
              <Label check className={s.labelRadio}>
                Heure pleine/Heure creuse
              </Label>
            </FormGroup>
          </FormGroup>
  
          {isBaseContract && (
            <div className={s.contract}>
              <FormGroup className={s.formGroup}>
                  <Label className={s.label}>Tarif consomation</Label>
                  <InputGroup className={s.inputGroup}> 
                    <Input className={s.input} type='text' name='subscriptionPricing' id='subscriptionPricing' value={basePricing} onChange={handleChange} required/>
                    <InputGroupText className={s.inputGroupText}>€ TTC/kWh</InputGroupText>
                  </InputGroup>
                </FormGroup>
  
                <FormGroup className={s.formGroup}>
                <Label className={s.label} for='subscriptionPricing'>
                  Tarif abonnement
                </Label>
                <InputGroup className={s.inputGroup}>
                  <Input className={s.input} type='text' name='subscriptionPricing' id='subscriptionPricing' value={subscriptionPricing} onChange={handleChange} required/>
                  <InputGroupText className={s.inputGroupText}>€ TTC/mois</InputGroupText>
                </InputGroup>
              </FormGroup>
            </div>
          )}
          {isHpHcContract && (
            <>
              <div className={s.contract}>
                <FormGroup className={s.formGroup}>
                  <Label className={s.label}>Début d'heure creuse</Label>
                  <Input className={s.input} type='time' onChange={handleChange} />
                </FormGroup>
  
                <FormGroup className={s.formGroup}>
                  <Label className={s.label}>Fin d'heure creuse</Label>
                  <Input className={s.input} type='time' onChange={handleChange} />
                </FormGroup>
              </div>
              <div className={s.contract}>
              <FormGroup className={s.formGroup}>
                  <Label className={s.label}>Tarif heure pleine</Label>
                  <InputGroup className={s.inputGroup}>
                    <Input className={s.input} type='text' name='subscriptionPricing' id='subscriptionPricing' value={highPricing} onChange={handleChange} required/>
                    <InputGroupText className={s.inputGroupText}>€ TTC/kWh</InputGroupText>
                  </InputGroup>
                </FormGroup>
  
                <FormGroup className={s.formGroup}>
                  <Label className={s.label}>Tarif heure creuse</Label>
                  <InputGroup className={s.inputGroup}>
                    <Input className={s.input} type='text' name='subscriptionPricing' id='subscriptionPricing' value={lowPricing} onChange={handleChange} required/>
                    <InputGroupText className={s.inputGroupText}>€ TTC/kWh</InputGroupText>
                  </InputGroup>
                </FormGroup>
              </div>
              <FormGroup className={s.formGroup}>
                <Label className={s.label} for='subscriptionPricing'>
                  Tarif abonnement
                </Label>
                <InputGroup className={s.inputGroup}>
                  <Input className={s.input} type='text' name='subscriptionPricing' id='subscriptionPricing' value={subscriptionPricing} required/>
                  <InputGroupText className={s.inputGroupText}>€ TTC/mois</InputGroupText>
                </InputGroup>
              </FormGroup>
            </>
          )}
        </Form>
      </div>
      <footer className={s.footer}>
        <Button className={s.btnAdd} onClick={handleSave}>
          <span>Enregistrer</span>
        </Button>
        <Button className={s.btnCancel} outline onClick={handleCancel}>
          <span>Annuler</span>
        </Button>
      </footer>
    </div>
  );
}

export default ModifyPricingForm