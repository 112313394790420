import React from 'react';
import s from './CardCurrentHumidity.module.scss';
import Icon from '../../../Icon/Icon';
import { shallowEqual, useSelector } from 'react-redux';
import { selectActiveRoom } from '../../../../selectors/selectNavigation';

function CardCurrentHumidity() {
  const activeRoom = useSelector(selectActiveRoom, shallowEqual);

  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.items}>
          <Icon name={'humidity_percentage'} color={'#30465E'} size={'2.5rem'} />
          <div className={s.item}>76%</div>
        </div>
        <div className={s.description}>
          {activeRoom === null ? 'Humidité actuelle en moyenne sur le bâtiment' : 'Humidité actuelle'}
        </div>
      </div>
    </div>
  );
}

export default CardCurrentHumidity;
