import React, { useEffect, useState } from 'react'
import s from './ModalCreateRoom.module.scss'
import { Button, Form, FormGroup, InputGroup, InputGroupText, Input, Label } from "reactstrap"
import { v4 as uuid } from 'uuid'
import Modal from '../../../../../../components/Modal/Modal'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DualListBoxAddDevice from '../DualListBoxAddDevice/DualListBoxAddDevice'
import { createRoom } from '../../../../../../features/rooms/roomsActions'
import { selectActiveBuilding } from '../../../../../../selectors/selectNavigation'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Icon from '../../../../../../components/Icon/Icon'

function ModalCreateRoom({ showModalAddRoom, handleClose}) {

  const dispatch = useDispatch()

  const building = useSelector(selectActiveBuilding, shallowEqual)

  const [data, setData] = useState({
    building_id: building?.building_id,
    room_id: '',
    name: '',
    settings: {
      surface: 0,
      floor_number: 0
    },
    devices: []
  })

  useEffect(() => {
    if (showModalAddRoom) {
      setData(prevData => ({
        ...prevData,
        room_id: uuid()
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [showModalAddRoom])

  const handleFormChange = (e) => {
    const { name, value } = e.target
    switch(name) {
      case 'name':
        setData(prevData => ({
          ...prevData,
          [name]: value 
        }))
        break;
      case 'surface':
      case 'floor_number':
        setData(prevData => ({
          ...prevData, 
          settings: {
            ...prevData.settings,
            [name]: parseInt(e.target.value)
          }
        }))
        break;
      default:
        break;
    }
  }

  const handleSetLinkedDevices = (devices) => {
    setData(prevData => ({
      ...prevData,
      devices: devices
    }));
  };

  const handleCreateRoom = () => {
    const updatedData = {
      ...data,
      devices: data.devices.map(device => device.device_id)
    };
    dispatch(createRoom({ data: updatedData }))
    toast.success("Espace créé !")
    handleClose()
  }

  return (
    <Modal show={showModalAddRoom}>

      <div className={s.header}>
        <div className={s.title}>Ajouter un espace</div>
        <Icon name="close" size={'1.5rem'} color={'#30465E'} fill={0} onClick={handleClose} cursor/>
      </div>
      
      <Form className={s.form}>
        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Nom</Label>
          <Input className={s.input} type="text" name="name" onChange={handleFormChange}/>
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Surface (m²)</Label>
          <Input className={s.input} type="text" name="surface" onChange={handleFormChange} placeholder={"Ex: 150"}/>          
        </FormGroup>

        <FormGroup className={s.formGroup}>
          <Label className={s.label}>Etage</Label>
          <Input className={s.input} type="text" name="floor_number" onChange={handleFormChange} placeholder={"Ex: 1"}/>          
        </FormGroup>
        
        <div className={s.dualListBox}>
          <DualListBoxAddDevice handleSetLinkedDevices={handleSetLinkedDevices} />
        </div>
      </Form>


      <div className={s.control}>
        <Button color="primary" onClick={handleCreateRoom} disabled={data.name === ''}>Valider</Button> 
      </div>
      


    </Modal>
  )
}

export default ModalCreateRoom
