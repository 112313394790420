import React, { useState } from 'react'
import s from './Scenarios.module.scss'
import InConstruction from '../../../../components/InConstruction/InConstruction'

function Scenarios() {
  
  const [searchQuery, setSearchQuery] = useState('');


  return (
    <div className={s.root}>
      <div className={s.content}>
        <InConstruction />
      </div>
    </div>
  )
}

export default Scenarios
