import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import s from './Forgot.module.scss'
import { sendPasswordResetEmail } from '../../../features/auth/authActions'
import Copyright from '../../../components/Copyright/Copyright'
import { Alert, Button, FormGroup, Input, Label, Form, FormText } from 'reactstrap'
import Logo from '../../../assets/logo/LogoInsensiaRow'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetAuth } from "../../../features/auth/authSlice"

function Forgot() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const { status, error } = useSelector((state) => state.auth);
  
  const [email, setEmail] = useState('')
  
  const changeEmail = (event) => {
    setEmail(event.target.value)
  }
  
  const isEmailValid = () => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    return pattern.test(email)
  }

  const doSendResetEmail = ()  => {
    if (!isEmailValid()) {
      toast.error("Veuillez saisir un email valide.")
    } else {
      dispatch(sendPasswordResetEmail({ email })) 
      .unwrap()
      .then(() => {
        navigate('/login')
        toast.success("Un email avec les instructions de réinitialisation de votre mot de passe a été envoyé.")
      })  
      .catch(() => {
        // Message générique sans révéler si l'email existe ou non
        dispatch(resetAuth());
        navigate('/login')
        toast.info("Si cet email est associé à un compte, vous recevrez un email avec les instructions de réinitialisation.")
      })
    }
  }

  const handleCancel = ()  => {
    navigate('/login')
  }

  return (
    <div className={s.root}>
      <div className={s.logo}><Logo className={s.img}/></div>
      <div className={s.card}>
        <div className={s.content}>
          <header>Mot de passe oublié</header> 
          <Form className={s.form}>
            <FormGroup className={s.formGroup}>
              <Label>Email</Label>
              <Input id="email" value={email} onChange={changeEmail} type="text" required name="email" placeholder="Email"/>
            </FormGroup>
            <div className={s.control}>
              <Button color="primary" onClick={doSendResetEmail}>{status === 'loading' ? 'Chargement...' : 'Envoyer'}</Button>  
              <Button color="primary" outline onClick={handleCancel}>Retour</Button> 
            </div>
          </Form>
          <div className={s.footer}>
            <p className={s.label}>
              <strong>Vous allez recevoir un lien par mail</strong> pour réinitialiser votre mot de passe. Ce lien expirera au bout de 24h.
            </p>
            <p className={s.label}>
              Si vous ne trouvez pas ce mail, même dans vos spams, vous n'avez probablement pas de compte avec cet email.
            </p>
          </div>
        </div>
        <Copyright/>
      </div>
    </div>
  )
}

export default Forgot