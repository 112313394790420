import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import s from './Filter.module.scss'
import { getAlerts, getAlertLabels } from '../../../../features/alert/alertsActions'
import { Form, FormGroup, Input, Label } from 'reactstrap'
import { selectListAlertLabels } from '../../../../selectors/selectAlerts'
import { selectActiveBuilding, selectActivePeriodUnit, selectPeriod } from '../../../../selectors/selectNavigation'

function Filter() {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id

  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id || null

  const filterListLabel = useSelector(selectListAlertLabels, shallowEqual)

  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual)

  const [currentFilterName, setCurrentFilterName] = useState({ id: 0, name: 'Tous' })
  const [currentFilterLabel, setCurrentFilterLabel] = useState('Toutes')
  const [currentFilterActive, setCurrentFilterActive] = useState('Tous')

  useEffect(() => {
    if (user_id) {
      dispatch(getAlertLabels());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  useEffect(() => {
    let device_name = currentFilterName.name === 'Tous' ? null : currentFilterName.name;
    let label = currentFilterLabel === 'Toutes' ? null : currentFilterLabel;
    let active = currentFilterActive === 'Tous' ? null : currentFilterActive === 'En cours' ? 'true' : 'false';
    if (user_id) {
      dispatch(getAlerts({ building_id, device_name, label, active, period }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building_id, currentFilterLabel, currentFilterActive, periodUnit, period]);

 const handleChangeAlertLabel = (label) => {
  setCurrentFilterLabel(label);
 }

 const handleChangeAlertStatus = (status) => {
  setCurrentFilterActive(status);
 }

 const currentAlertLabel = () => {

 }

 const currentAlertStatus = () => {

 }

  return (
    <Form className={s.form}>
      <FormGroup className={s.formGroup}>
          <Label className={s.label}>Anomalies</Label>
          <Input className={s.input} type='select' onChange={(e) => handleChangeAlertLabel(e.target.value)} value={currentAlertLabel()}>
            {filterListLabel?.map((period, index) => (
              <option className={s.option} key={index} value={period}>{period}</option>
            ))}
          </Input>
      </FormGroup>

      <FormGroup className={s.formGroup}>
        <Label className={s.label}>Status</Label>
        <Input className={s.input} type='select' onChange={(e) => handleChangeAlertStatus(e.target.value)} value={currentAlertStatus()}>
          <option className={s.option} value={'Tous'}>Tous</option>
          <option className={s.option} value={'En cours'}>En cours</option>
          <option className={s.option} value={'Terminée'}>Terminée</option>
        </Input>
      </FormGroup>
    </Form>
  );
}

export default Filter;
