import { createSelector } from '@reduxjs/toolkit'
import { COLORS_CHART_ARRAY } from '../utils/config/chart_colors';
import moment from 'moment'
moment.locale('fr')

export const selectDevices = (state) => {
  return state.devices
}

export const selectListDevices = createSelector(
  (state) => state.devices?.listDevices,
  (listDevices) => listDevices
)

export const selectDevicesIsLoading = createSelector(
  (state) => state.devices.isLoading,
  (isLoading) => isLoading
)

export const selectTemperatureIsLoading = createSelector(
  (state) => state.devices.isLoadingTemperature,
  (isLoadingTemperature) => isLoadingTemperature
)

export const selectHumidityIsLoading = createSelector(
  (state) => state.devices.isLoadingHumidity,
  (isLoadingHumidity) => isLoadingHumidity
)

export const selectCO2IsLoading = createSelector(
  (state) => state.devices.isLoadingCO2,
  (isLoadingCO2) => isLoadingCO2
)

export const selectBrightnessIsLoading = createSelector(
  (state) => state.devices.isLoadingBrightness,
  (isLoadingBrightness) => isLoadingBrightness
)

export const selectMotionIsLoading = createSelector(
  (state) => state.devices.isLoadingMotion,
  (isLoadingMotion) => isLoadingMotion
)

export const selectListMeasures =  createSelector(
  (state) => state.devices.listMeasures,
  (listMeasures) => listMeasures
)

export const selectDeviceWithBuilding = createSelector(
  [selectListDevices],
  (listDevices) => {
    return listDevices ? listDevices.filter(device => device?.building !== null) : []
  }
)

export const selectDeviceWithoutBuilding = createSelector(
  [selectListDevices],
  (listDevices) => {
    return listDevices ? listDevices.filter(device => device?.building === null) : []
  }
)

export const selectDevicesByBuilding = (building_id) => createSelector(
  [selectListDevices],
  (listDevices) => {
    return listDevices && building_id !== '' ? listDevices.filter(device => device.building !== null && device.building.building_id === building_id) : []
  }
)

export const selectDevicesByBuildingAndRoom = (building_id, room_id = null) => createSelector(
  [selectListDevices],
  (listDevices) => {
    return listDevices
      ? listDevices.filter(device => 
          device.building !== null && 
          device.building.building_id === building_id && 
          (room_id === null || (device.room !== null && device.room.room_id === room_id))
        )
        .map(device => device.device_id)
      : [];
  }
);

 export const selectDevicesSerialByBuildingAndRoom = (building_id, room_id = null) => createSelector(
   [selectListDevices],
   (listDevices) => {
     return listDevices
       ? listDevices.filter(device => 
           device.building !== null && 
           device.building.building_id === building_id && 
           (room_id === null || (device.room !== null && device.room.room_id === room_id))
         )
         .map(device => device.serial_number.toLowerCase())
       : [];
   }
 );

export const selectMeasuresForChart = (devices, measureType) => createSelector(
  [selectListDevices],
  (listDevices) => {
    let datasets = [];
    let labels = [];
    let colorIndex = 0;

    devices.forEach(device_id => {
      const device = listDevices.find(d => d.device_id === device_id);
      if (device && device[measureType]) {
        const deviceData = {
          label: device.name,
          data: [],
          backgroundColor: COLORS_CHART_ARRAY[colorIndex % COLORS_CHART_ARRAY.length],
          borderColor: COLORS_CHART_ARRAY[colorIndex % COLORS_CHART_ARRAY.length],
          borderWidth: 2,
          pointRadius: 0,
          tension: 0.4
        };

        device[measureType].forEach(measure => {

          if (!labels.includes(measure.time)) {
            labels.push(measure.time);
          }

          deviceData.data.push({
            x: measure.time,
            y: measure.value
          });
        });

        datasets.push(deviceData);
        colorIndex++;
      }
    });
    return { labels, datasets };
  }
);

export const selectMeasuresForTimeline = (deviceIds, measureType) => createSelector(
  [selectListDevices],
  (listDevices) => {
    return deviceIds.map(deviceId => {
      const device = listDevices.find(d => d.device_id === deviceId);
      let name = device.name
      let seriesData = [];
      
      if (device && device[measureType]) {
        let motionStart = null;

        device[measureType].forEach(record => {
          let timeStamp = new Date(record.time).getTime();
          let motion = record.value;

          if (motion === 1 && motionStart === null) {
            // Motion started
            motionStart = timeStamp;
          } else if (motion === 0 && motionStart !== null) {
            // Motion ended
            seriesData.push({
              x: name,
              y: [motionStart, timeStamp]
            });
            motionStart = null;
          }
        });

        // If motion is still ongoing at the end of the data set
        if (motionStart !== null) {
          seriesData.push({
            x: name,
            y: [motionStart, new Date(device[measureType][device[measureType].length - 1].time).getTime()]
          });
        }
      }

      return {
        name: name,
        data: seriesData
      };
    });
  }
);



export const selectDeviceIdsByBuilding = (building_id) => createSelector(
  [selectListDevices],
  (listDevices) => {
    const filteredDevices = listDevices ? listDevices.filter(device => device.building !== null && device.building.building_id === building_id) : []
    return filteredDevices.map(device => device.device_id)
  }
)

export const selectDevicesUnlinked = createSelector(
  [selectListDevices],
  (listDevices) => {
    return listDevices ? listDevices.filter(device => device.room === null && device.building !== null) : []
  }
);

export const selectDevicesUnlinkedRoom = (building_id) => createSelector(
  [selectListDevices],
  (listDevices) => {
    return listDevices ? listDevices.filter(device => device.building?.building_id === building_id && device.room === null) : []
  }
);

export const selectDevicesLinkedByBuilding = (building_id) => createSelector(
  [selectListDevices],
  (listDevices) => {
    return listDevices ? listDevices.filter(device => device.building?.building_id === building_id && device.room !== null) : []
  }
)

export const selectDevicesLinkedByRoom = (building_id, room_id) => createSelector(
  [selectListDevices],
  (listDevices) => {
    return listDevices ? listDevices.filter(device => device.building?.building_id === building_id && device.room?.room_id  === room_id) : []
  }
);


export const selectDevice = (device_id) =>
  createSelector(
  [selectListDevices],
  (listDevices) => listDevices.find(device => device.device_id === device_id)
)
    
export const selectCreatedAt = (device_id) =>
  createSelector(
  [selectListDevices],
  (listDevices) => {
    const device = listDevices.find(device => device.device_id === device_id)
    return device ? device.created_at : null
  }
)

export const selectDeviceSerialNumber = (device_id) =>
  createSelector(
  [selectListDevices],
  (listDevices) => {
    const device = listDevices.find(device => device.device_id === device_id)
    return device ? device.serial_number : null
  }
)
    
// export const selectDeviceDelay = (device_id) =>
//   createSelector(
//   [selectListDevices],
//   (listDevices) => {
//     const device = listDevices.find(device => device.device_id === device_id)
//     return device && device.settings ? device.settings.delay : null
//   }
// )

// export const selectDeviceVolume = (device_id) =>
//   createSelector(
//   [selectListDevices],
//   (listDevices) => {
//     const device = listDevices.find(device => device.device_id === device_id)
//     return device && device.settings ? device.settings.speaker : null
//   }
// )
    
// export const selectDeviceLed = (device_id) =>
//   createSelector(
//   [selectListDevices],
//   (listDevices) => {
//     const device = listDevices.find(device => device.device_id === device_id)
//     return device && device.settings ? device.settings.brightness : null
//   }
// )
    
export const selectLastData = (device_id) =>
  createSelector(
  [selectListDevices],
  (listDevices) => {
    const device = listDevices.find(device => device.device_id === device_id)
    return device && device.lastdata ? device.lastdata : null
  }
)

export const selectLastDataCharging = (device_id) =>
  createSelector(
  [selectListDevices],
  (listDevices) => {
    const device = listDevices.find(device => device.device_id === device_id)
    return device && device.lastdata ? device.lastdata.battery_charging === '4' : false
  }
)
  
export const selectLastDataUsbPower = (device_id) => 
  createSelector(
  [selectListDevices],
  (listDevices) => {
    const device = listDevices.find(device => device.device_id === device_id)
    return device && device.lastdata ? device.lastdata.battery_charging === '1' : false
  }
)
  
export const selectLastDataBattery = (device_id) =>
  createSelector(
  [selectListDevices],
  (listDevices) => {
    const device = listDevices.find(device => device.device_id === device_id)
    return device && device.lastdata ? device.lastdata.battery : null
  }
)

export const selectLastDataRSSI= (device_id) =>
createSelector(
  [selectListDevices],
  (listDevices) => {
    const device = listDevices.find(device => device.device_id === device_id)
    return device && device.lastdata ? device.lastdata.rssi : null
  }
)

export const selectMeasures = (device_id) =>
  createSelector(
  [selectListDevices],
  (listDevices) => {
    const device = listDevices.find(device => device.device_id === device_id)
    return device && device.measures ? device.measures : null
  }
)

export const selectLastAggregate =  createSelector(
  (state) => state.devices.listLastAggregate,
  (listLastAggregate) => listLastAggregate
)
    
export const selectAggregate = (device_id) =>   
  createSelector(
  [selectLastAggregate],
  (lastAggregates) => lastAggregates.find(lastAggregate => lastAggregate.device_id === device_id)
)

export const selectLastAlerts =  createSelector(
  (state) => state.devices.listLastAlert,
  (listLastAlert) => listLastAlert
)
    
export const selectLastAlert = (device_id) =>
  createSelector(
  [selectLastAlerts],
  (listLastAlerts) => listLastAlerts.find(listLastAlert => listLastAlert.device_id === device_id)
)

export const selectFilterListName = (state) => {
  return state.devicesFilter.name
}

export const selectFilterListLabel  = (state) => {
  return state.devicesFilter.label
}

export const selectFilter_IsLoading = (state) => {
  return state.devicesFilter.isLoading
}
