import React, { useState } from "react"
import { useDispatch, useSelector} from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import s from './Login.module.scss'
import { Button, FormGroup, InputGroup, InputGroupText, Input, Alert, Form, Label, FormText } from "reactstrap"
import Logo from '../../../assets/logo/LogoInsensiaRow'
import Copyright from "../../../components/Copyright/Copyright"
import Icon from "../../../components/Icon/Icon"
import { userLogin } from '../../../features/auth/authActions'
import { changeActiveRoute } from "../../../features/navigation/navigationSlice"

function Login() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { status, error } = useSelector((state) => state.auth);

  const [email, setEmail] = useState(process.env.REACT_APP_HOST === "localhost" ? "contact@insensia.com" : "")
  const [password, setPassword] = useState(process.env.REACT_APP_HOST === "localhost" ? "insensia2024" : "")

  const [showPassword, setShowPassword] = useState(false);

  const changeEmail = (e) => {
    setEmail(e.target.value)
  }

  const changePassword = (e) => {
    setPassword(e.target.value)
  }

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const doLogin = () => {
    dispatch(userLogin({ email, password }))
    .unwrap()
    .then(() => {
      dispatch(changeActiveRoute("/consult/summary"))    
      // navigate("/consult/summary")
    })
    .catch((error) => {
      console.error('Erreur lors de la connexion:', error)
    })
  }

  const doRegister = () => {
    navigate("../register")
  }
    
  return (
    <div className={s.root}>
      <div className={s.logo}><Logo className={s.img}/></div>
      <div className={s.card}>

        <div className={s.content}>

          <header>Se connecter</header>

          <Form className={s.form}>
            <FormGroup className={s.formGroup}>
              <Label>Email</Label>
              <Input id="email" value={email} onChange={changeEmail} type="text" required name="email" placeholder="Email"/>
            </FormGroup>

            <FormGroup className={s.formGroup}>
              <Label>Mot de passe</Label>
              <InputGroup>
                <Input id="password" value={password} onChange={changePassword} type={showPassword ? "text" : "password"} required name="password" placeholder="Mot de passe"/>
                <InputGroupText>
                  <Icon name={showPassword ? "visibility_off" : "visibility"} size={'1.5rem'} color={'#3192D3'} fill={1} cursor onClick={() => togglePasswordVisibility()}/>
                </InputGroupText>
              </InputGroup>
              <FormText><Link to="../forgot">Mot de passe oublié</Link> </FormText>
            </FormGroup>
             
            {status === 'failed' && (<Alert className={s.error_message}>{error?.message || error}</Alert>)} 
            <div className={s.control}>
              <Button color="primary" onClick={doLogin}>{status === 'loading' ? 'Chargement ...' : 'Continuer'}</Button>
            </div>           
          </Form>

          <div className={s.separator}><span></span></div>

          <footer>
            <div className={s.label}>Vous n'avez pas de compte ?</div>
            <Button color="primary" outline onClick={doRegister}>S'inscrire</Button>
          </footer>

        </div>
        <Copyright/>
      </div>
    </div>
  )
}
export default Login