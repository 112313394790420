import React from 'react'
import s from './CardOccupancy.module.scss'
import { useCardLogic } from '../../../../hooks/useCardLogic';
import Icon from '../../../Icon/Icon';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeActiveBuilding, changeActiveRoute } from '../../../../features/navigation/navigationSlice';
import { saveNavigationState } from '../../../../features/navigation/navigationActions';
import store from '../../../../redux/store';

function CardOccupancy({ building, params }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const { dataIndicator } = useCardLogic(params);

  const handleNavigateTo = () => {
    dispatch(changeActiveBuilding(building))
    dispatch(changeActiveRoute('/consult/occupancy'))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    navigate(`/consult/occupancy`)
  }

  return (
    <div className={s.root}>
      
      <header className={s.header}>
        <div className={s.title}>
          <Icon name="sensor_occupied" size={'1.5rem'} color={'#30465E'} fill={1}/>
          <span>OCCUPATION</span>
        </div>
        <Icon name="arrow_forward" size={'1.5rem'} color={'#30465E'} fill={1} onClick={() => handleNavigateTo()} cursor/> 
      </header>

      <div className={s.content}>
        
        <div className={s.item}>
          {dataIndicator?.values?.data1 != null && dataIndicator?.values?.data1 !== undefined ?
          (
          <div className={s.value}>{dataIndicator?.values?.data1} {dataIndicator?.types?.data1}</div>
          ) : (
          <div className={s.not_value}>--- %/j</div> 
          )
          }
          <div className={s.label}>
            <span>Pic d'occupation</span>
            <small>20 - 70 %/j</small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardOccupancy