import { defaultAxiosInstance } from '../../axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit'

export const createRoom = createAsyncThunk(
  'rooms/create',
  async ({ data }, { rejectWithValue }) => {
    try {  
      await defaultAxiosInstance.post(`/rooms/`, { data })
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getRooms = createAsyncThunk(
  'rooms/get',
  async ({ building_id }, { rejectWithValue }) => {
    try {
      const params = { building_id }
      const response = await defaultAxiosInstance.get(`/rooms/`, { params })
      return response.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const getRoomById = createAsyncThunk(
  'rooms/get_by_id',
  async ({ user_id, building_id, room_id }, { rejectWithValue }) => {
    try {
      const params = { user_id, building_id, room_id }
      const response = await defaultAxiosInstance.get(`/rooms/:id`, { params })
      const data = response.data[0]
      return { building_id, room_id, data }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const updateRoom = createAsyncThunk(
  'rooms/update',
  async ({ data }, { rejectWithValue }) => {
    try { 
      const room_id = data.room_id
      await defaultAxiosInstance.put(`/rooms/${room_id}`, { data })    
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)

export const deleteRoom = createAsyncThunk(
  'rooms/delete',
  async ({ room_id }, { rejectWithValue }) => {
    try {
      await defaultAxiosInstance.delete(`/rooms/${room_id}`)     
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)
