import { createSelector } from '@reduxjs/toolkit'

export const selectAlerts = (state) => {
    return state.alerts
}

export const selectListAlerts = createSelector(
    (state) => state.alerts.listAlerts,
    (listAlerts) => listAlerts
  )

export const selectAlertsIsLoading = createSelector(
    (state) => state.alerts.isLoading,
    (isLoading) => isLoading
  )

  export const selectListAlertLabels = createSelector(
    (state) => state.alerts.filtering,
    (filtering) => filtering.alertLabels.filter(label => label !== 'Défaut capteur' && label !== 'Connexion perdue')
);