import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import s from './Step04CardPRM.module.scss'
import { Form, Button, FormGroup, InputGroup, Input, InputGroupText, Alert, Label } from 'reactstrap'
import { selectPoints } from '../../../../../../../../selectors/selectSGE'
import { checkExistingPoint } from '../../../../../../../../features/sge/sgeActions'

function Step04CardPRM({ inc, dec, onError, setAuthorizationData, authorizationData }) {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id

  const points = useSelector(selectPoints, shallowEqual)

  const [prm, setPRM] = useState(authorizationData?.point_id || '')
  const [attempt, setAttempt] = useState(3)
  const [inputError, setInputError] = useState(''); 
  const [isPointValid, setIsPointValid] = useState(true);

  const validateInput = (value) => /^\d+$/.test(value);

  const handleChangePRM = async (e) => {
    const value = e.target.value;
  
    setPRM(value);
    setAuthorizationData((prevData) => ({
      ...prevData,
      point_id: value,
    }));
  
    if (!validateInput(value) && value !== '') {
      setInputError('Seuls les chiffres sont autorisés');
      setIsPointValid(false);
      return;
    }
  
    setInputError('');
    setIsPointValid(true);

    if (value.length > 14) {
      setInputError('Le n° de compteur doit contenir 14 chiffres');
      setIsPointValid(false);
      return;
    }

    setInputError('');
    setIsPointValid(true);
  
    if (value.length === 14) {
      const existsInPoints = points.some((point) => point === value);
      if (!existsInPoints) {
        setInputError('Le n° de compteur saisi ne correspond à aucun point de livraison');
        setIsPointValid(false);
        return;
      }
  
      const result = await dispatch(checkExistingPoint({ user_id, point_id: value }));
  
      if (result.payload) {
        setInputError('Ce point existe déjà pour cet utilisateur');
        setIsPointValid(false);
        return;
      }
  
      setInputError('');
      setIsPointValid(true);
    }
  };
  

  const decStep = () => {
    setAuthorizationData(prevData => ({
      ...prevData, 
      point_id: ''
    }))
    dec()
  }

  const incStep = () => {
    const prm_existing = points.find((point) => point === prm);
    
    if (!prm_existing) {
      setAttempt(attempt - 1)
      if (attempt === 1) {
        onError()
      }
    } else {
      inc()
    }
  }

  return (
    <div className={s.root}>

      <div className={s.content}>
        
        <div className={s.description}>
          <div className={s.item}>Veuillez renseigner votre numéro de compteur figurant sur le contrat de fourniture d'électricité.</div>
        </div>

        <Form className={s.form}>
          
          {attempt !== 3 &&
          <Alert className={s.alert} color="danger" fade>
            <div>Le numéro de compteur incorrect, il vous reste {attempt} {attempt > 1 ? 'essais' : 'essai'}</div>
          </Alert> 
          }

          <FormGroup className={s.formGroup}>
            <Label className={s.label}>N° compteur</Label>
            <Input className={s.input} type="text" value={prm} onChange={handleChangePRM} placeholder={"Le numéro de votre compteur"} invalid={!!inputError}/>  
          </FormGroup>
          
          {inputError && <Alert className={s.alert}>{inputError}</Alert>}

        </Form>
      </div>
      
      <div className={s.control}>
        <Button color="primary" onClick={() => decStep()}>Précédent</Button> 
        <Button color="primary" onClick={() => incStep()} disabled={!isPointValid || prm === ''}>Suivant</Button> 
      </div>
    </div>
  )
}

export default Step04CardPRM
